import {
    GET_PAY_VIA_EMAIL,
    LOADING_PAY_VIA_EMAIL,
    ERROR_PAY_VIA_EMAIL
  } from "../actions/types";
  
  const initialState = {
    isLoading: false,
    PayViaEmailArray: [],
  };
  // eslint-disable-next-line
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_PAY_VIA_EMAIL:
        return {
          ...state,
          PayViaEmailArray: action.payload,
          isLoading: false,
        };
      case LOADING_PAY_VIA_EMAIL:
        return {
          ...state,
          isLoading: true,
        };
      case ERROR_PAY_VIA_EMAIL:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  }
  