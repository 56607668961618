import {
  GET_PAPER_EXAM,
  PAPER_EXAM_LOADING,
  PAPER_EXAM_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  PaperExam: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAPER_EXAM:
      return {
        ...state,
        PaperExam: action.payload,
        isLoading: false,
      };

    case PAPER_EXAM_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case PAPER_EXAM_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
