import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import MockUpResultNew from "./MockUpResultNew";
export default class MockUpResultTestOuter extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Sidebar />
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <NavbarKeen />
            <div style={{ marginTop: "20px" }} className="content d-flex flex-column flex-column-fluid">
              <Subheader name="MockUp Result" />
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <MockUpResultNew
                        MockExamId={this.props.match.params.id}
                        GradeId={this.props.match.params.GradeId}
                        SubjectId={this.props.match.params.SubjectId}
                        ClassId={this.props.match.params.ClassId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
