import axios from 'axios'
import {
  ADD_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  SUBSCRIPTION_LOADING,
  SUBSCRIPTION_FAIL,
  PAY_VIA_EMAIL_ERROR,
  PAY_VIA_EMAIL,
  PAY_LOADING,
  SUBVIATID_LOADING,
  SUBVIATID,
  SUBVIATIDFAIL
} from '../types'
import { createMessage, returnErrors } from '../message/message'
import { tokenConfig } from '../auth_action/auth'

export const getSubscription = () => (dispatch, getState) => {
  axios
    .get('Subscription/getSubscription/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CHECK_SUBSCRIPTION,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({ type: SUBSCRIPTION_FAIL })
      dispatch(returnErrors(err.response.data, err.response.status))
    })
}

export const addSubscription = (data, order) => (dispatch, getState) => {
  dispatch({ type: SUBSCRIPTION_LOADING })
  const user = data.user
  const firstName = data.firstName
  const lastName = data.lastName
  const email = data.email
  const phone = data.phone
  const Country_state = data.Country_state
  const stateName = data.stateName
  const Citi_data = data.Citi_data
  const Transaction_ID = order.id
  const bundle = data.PackageID
  const Subject = data.Subject
  const School = data.School
  const Annual = data.Annual
  const PaymentMethod = data.checkout
  const order_status = order.status
  const order_payer_payer_id = order.payer.payer_id
  const Price = data.Price
  const order_time = order.create_time
  const order_payer_name_given_name = order.payer.name.given_name
  const order_payer_name_surname = order.payer.name.surname
  // const order_payer_phone = order.payer.phone
  // const order_payer_birth_date = order.payer.birth_date
  const order_payer_email_address = order.payer.email_address
  const order_purchase_units_amount_currency_code =
    order.purchase_units[0].amount.currency_code
  const order_purchase_units_amount_value = order.purchase_units[0].amount.value
  const order_purchase_units_payee_email_address =
    order.purchase_units[0].payee.email_address
  const order_purchase_units_payee_merchant_id =
    order.purchase_units[0].payee.merchant_id
  const body = {
    user,
    firstName,
    lastName,
    email,
    phone,
    Country_state,
    stateName,
    Citi_data,
    Transaction_ID,
    bundle,
    Subject,
    School,
    Annual,
    PaymentMethod,
    order_status,
    order_payer_payer_id,
    order_payer_name_surname,
    // order_payer_phone,
    // order_payer_birth_date,
    order_payer_email_address,
    order_purchase_units_amount_currency_code,
    order_purchase_units_amount_value,
    order_purchase_units_payee_email_address,
    order_purchase_units_payee_merchant_id,
    Price,
    order_time,
    order_payer_name_given_name,
  }
  axios
    .post('Subscription/Subscription/', body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: 'Subscribed' }))
      dispatch({
        type: ADD_SUBSCRIPTION,
        payload: res.data,
      })
    })
    .catch((err) => {
      console.log(err.response.data,"i am e")
      dispatch({ type: SUBSCRIPTION_FAIL })
      dispatch(returnErrors(err.response.data), err.response.status)
    })
}

export const addSubscriptionStripe = (data, order) => (dispatch, getState) => {
  const user = data.data.user
  const firstName = data.data.firstName
  const lastName = data.data.lastName
  const email = data.data.email
  const phone = data.data.phone
  const Country_state = data.data.Country_state
  const stateName = data.data.stateName
  const Price = data.data.Price
  const Citi_data = data.data.Citi_data
  const bundle = data.data.PackageID
  const Subject = data.data.Subject
  const School = data.data.School
  const PaymentMethod = data.data.checkout
  const Transaction_ID = order.id

  const body = {
    user,
    firstName,
    lastName,
    email,
    phone,
    Country_state,
    stateName,
    Citi_data,
    bundle,
    Subject,
    School,
    Price,
    PaymentMethod,
    Transaction_ID,
  }
  axios
    .post('Subscription/Subscription/', body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: 'Subscribed' }))
      dispatch({
        type: ADD_SUBSCRIPTION,
        payload: res.data,
      })
    })
    .catch((err) =>{
      dispatch(returnErrors(err.response.data), err.response.status)
      console.log(err)
    },
    )
}

export const PayViaEmail = (data) => (dispatch) => {
  dispatch({ type: PAY_LOADING })
  axios
    .post('payments/Payment-email/', data)
    .then((res) => {
      dispatch(
        createMessage({
          addLead:
            'If you do not see the email in a few minutes, check your “JUNK mail” folder or “SPAM” folder',
        }),
      )
      dispatch({
        type: PAY_VIA_EMAIL,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch(createMessage({ deleteLead: 'Oops! some error Occur!' }))
      dispatch({ type: PAY_VIA_EMAIL_ERROR })
      dispatch(returnErrors(err.response.data, err.response.status))
    })
}

export const addSubscriptionViaEmail = (data, order) => (
  dispatch,
  getState,
) => {
  dispatch({ type: SUBSCRIPTION_LOADING })
  const type = 'Paypal'
  const body = { data, order, type }
  axios
    .put(`payments/PayContent/${data.unique_ID}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: 'Subscribed Successfully' }))
      dispatch({
        type: ADD_SUBSCRIPTION,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch(createMessage({ deleteLead: 'Oops! Error occurred' }))
      dispatch({ type: SUBSCRIPTION_FAIL })
      dispatch(returnErrors(err.response.data), err.response.status)
    })
}

export const getSubscriptionViaTID = (TID) => (dispatch, getState) => {
  dispatch({type:SUBVIATID_LOADING})
  axios
    .get(`Subscription/getSubscriptionViaTID/${TID}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SUBVIATID,
        payload: res.data,
      })
    })
    .catch((err) => { 
      dispatch({ type: SUBVIATIDFAIL })
      dispatch(returnErrors(err.response.data, err.response.status))
    })
}