import {
  SET_GRADE_YEAR_LOADING,
  SET_GRADE_YEAR_FAIL,
  GET_GRADE_YEAR,
} from "../actions/types";

const initialState = {
  isLoading: false,
  GradeYearList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GRADE_YEAR:
      return {
        ...state,
        GradeYearList: action.payload,
        isLoading: false,
      };
    case SET_GRADE_YEAR_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case SET_GRADE_YEAR_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
