import { Component, Fragment } from "react";
import email from "../../media/email.png";
import invalid from "../../media/corrupt-file.png";
import checked from "../../media/check.png";
import logo from "../../media/Logo/logo.png";
import OtpInput from "react-otp-input";
import CryptoJS from "crypto-js";
import { Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Verify_School_OTP } from "../../actions/VerifyOTPs/VerifyOTP_School_action";
import { CHECK_STATUS_School_OTP } from "../../actions/VerifyOTPs/checkOTPStatus";
import { ResendOTP } from "../../actions/VerifyOTPs/resendOTP_Action";
const schema = yup.object({
  otp: yup
    .string()
    .required("Enter OTP")
    .min(6, "OTP must be 6 digits")
    .max(6, "OTP must be 6 digits"),
});

class SchoolVerification extends Component {
  static propTypes = {
    Verify_School_OTP: PropTypes.func.isRequired,
    CHECK_STATUS_School_OTP: PropTypes.func.isRequired,
    otpStatus: PropTypes.array,
    otpStatusLoading: PropTypes.bool,
    ResendOTP: PropTypes.func,
  };
  state = {
    otp: "",
    encrypted_email: "",
    decryptedEmail: "",
    staremail: "",
    totp: "",
    iv: "",
    InvalidLink: false,
    OTPVerified: false,
    Timer: false,
    minutes: 0,
    seconds: 59,
  };

  Timer = () => {
    this.setState({ Timer: true, minutes: 3, seconds: 59 });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          this.setState({ Timer: false });
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  resend = () => {
    this.Timer();
    const email = this.state.decryptedEmail;
    const body = { email };
    this.props.ResendOTP(body);
  };

  decode = () => {
    const key = "yDbigwo58k!M9!8f";
    try {
      const iv = CryptoJS.enc.Base64.parse(this.state.iv);
      const ciphertext = CryptoJS.enc.Base64.parse(this.state.encrypted_email);
      const key_update = CryptoJS.enc.Utf8.parse(key);
      const decipher = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        key_update,
        { iv: iv }
      );
      const decryptedEmail = CryptoJS.enc.Utf8.stringify(decipher).toString();
      const startemail = decryptedEmail.slice(0, 3);
      const emailbeforeA = decryptedEmail.split("@")[0].slice(-2);
      const emailafterA = decryptedEmail.split("@")[1];
      var starString = "";
      for (let x = 1; x <= decryptedEmail.split("@")[0].length - 5; x++) {
        starString += "*";
      }
      const staremail =
        startemail + starString + emailbeforeA + "@" + emailafterA;
      this.setState({ decryptedEmail: decryptedEmail, staremail: staremail });
    } catch (error) {
      this.setState({ InvalidLink: true });
    }
  };
  onSubmit = (values) => {
    const totp = this.state.totp;
    const otp = values.otp;
    const email = this.state.decryptedEmail;
    const body = { otp, email, totp };
    this.props.Verify_School_OTP(body);
  };
  checkstatus = () => {
    const totp = this.state.totp;
    const email = this.state.decryptedEmail;
    const body = { email, totp };
    this.props.CHECK_STATUS_School_OTP(body);
  };

  status_setter = () => {
    if (this.props.otpStatus === "Invalid Link") {
      this.setState({ InvalidLink: true });
    }
    if (this.props.otpStatus == "OTP already verified") {
      this.setState({ OTPVerified: true });
    }
  };

  componentDidMount() {
    if (this.props.location.search) {
      const encrypted_email = this.props.location.search
        .split("?email=")[1]
        .split("&totp=")[0];
      const totp = this.props.location.search
        .split("&totp=")[1]
        .split("&iv=")[0];
      const iv = this.props.location.search.split("&iv=")[1];
      this.setState({
        encrypted_email: encrypted_email,
        totp: totp,
        iv: iv,
      });
    }
    this.Timer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.encrypted_email !== this.state.encrypted_email) {
      this.decode();
    }
    if (prevState.decryptedEmail !== this.state.decryptedEmail) {
      this.checkstatus();
    }
    if (prevProps.otpStatus !== this.props.otpStatus) {
      this.status_setter();
    }
  }

  render() {
    const { InvalidLink, staremail, OTPVerified, Timer } = this.state;
    const { otpStatusLoading } = this.props;
    if (otpStatusLoading) {
      return (
        <Fragment>
          <div className="d-flex flex-column" id="kt_app_root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
              <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                  <Spinner animation="border" variant="warning" />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    if (OTPVerified) {
      return (
        <div className="d-flex flex-column" id="kt_app_root">
          <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
              <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                <a href="#" className="mb-0 mb-lg-5 mt-lg-5">
                  <img alt="Logo" src={logo} className="h-60px h-lg-75px" />
                </a>
                <div className="w-lg-500px p-10">
                  <Form className="form w-100 mb-13" noValidate>
                    <div className="text-center mb-10">
                      <img
                        width="100"
                        height="100"
                        alt="Logo"
                        className="mh-125px"
                        src={checked}
                      />
                    </div>
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">
                        School is already verified
                      </h1>
                      <div className="text-muted fw-semibold fs-5 mb-5">
                        Thank you for verifing your school.
                      </div>
                      <div className="fw-bold text-dark fs-3">
                        You can login to your account now.
                      </div>
                    </div>
                    <div className="mb-10">
                      <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
                        {/* Type your 6 digit security code */}
                      </div>
                      <div className="d-flex flex-wrap flex-stack">
                        <OtpInput
                          inputType="number"
                          name="otp"
                          placeholder="000000"
                          disabled={true}
                          numInputs={6}
                          inputStyle={
                            "form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                          }
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-center">
                      <button
                        disabled={OTPVerified}
                        type="submit"
                        className="btn btn-lg btn-success fw-bold"
                      >
                        <span className="indicator-label">Verified</span>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div className="d-flex flex-column" id="kt_app_root">
          <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
              <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                <a href="#" className="mb-0 mb-lg-5 mt-lg-5">
                  <img alt="Logo" src={logo} className="h-60px h-lg-75px" />
                </a>
                <div className="w-lg-500px p-10">
                  <Formik
                    validationSchema={schema}
                    onSubmit={this.onSubmit}
                    initialValues={{
                      otp: "",
                    }}
                  >
                    {({ handleSubmit, handleChange, values, errors }) => (
                      <Form
                        className="form w-100 mb-13"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <div className="text-center mb-10">
                          <img
                            width="100"
                            height="100"
                            alt="Logo"
                            className="mh-125px"
                            src={InvalidLink ? invalid : email}
                          />
                        </div>
                        <div className="text-center mb-10">
                          <h1 className="text-dark mb-3">
                            {InvalidLink
                              ? "Oops! Invalid Link"
                              : "School Verification"}
                          </h1>
                          <div className="text-muted fw-semibold fs-5 mb-5">
                            {InvalidLink
                              ? "Please Check your email again!"
                              : "Enter the verification code we sent to"}
                          </div>
                          <div className="fw-bold text-dark fs-3">
                            {InvalidLink ? "" : staremail}
                          </div>
                        </div>
                        {InvalidLink ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-10">
                              <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
                                Type your 6 digit security code
                              </div>
                              <div className="d-flex flex-wrap flex-stack">
                                {errors.otp ? (
                                  <OtpInput
                                    inputType="number"
                                    name="otp"
                                    value={values.otp}
                                    placeholder="000000"
                                    onChange={(e, event) => {
                                      handleChange({
                                        ...event,
                                        target: {
                                          name: "otp",
                                          value: e,
                                        },
                                      });
                                    }}
                                    numInputs={6}
                                    inputStyle={
                                      "form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2 red-highlight"
                                    }
                                    renderSeparator={<span> </span>}
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                  />
                                ) : (
                                  <OtpInput
                                    value={values.otp}
                                    inputType="number"
                                    placeholder="000000"
                                    onChange={(e, event) => {
                                      handleChange({
                                        ...event,
                                        target: {
                                          name: "otp",
                                          value: e,
                                        },
                                      });
                                    }}
                                    numInputs={6}
                                    inputStyle={
                                      "form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                                    }
                                    renderSeparator={<span> </span>}
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                  />
                                )}
                                {errors.otp && (
                                  <span className="text-danger">
                                    {errors.otp ? errors.otp : ""}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-center">
                              <button
                                disabled={OTPVerified}
                                type="submit"
                                className="btn btn-lg btn-primary fw-bold"
                              >
                                <span className="indicator-label">Submit</span>
                              </button>
                            </div>
                            <div className="text-center fw-semibold fs-5 mt-5">
                              <span className="text-muted me-1">
                                Didn’t get the code ? <br />0
                                {this.state.minutes} : {this.state.seconds}
                              </span>
                              <button
                                type="button"
                                disabled={Timer}
                                onClick={() => this.resend()}
                                className="btn btn-success"
                              >
                                Resend
                              </button>
                            </div>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}
const mapStateToProps = (state) => ({
  otpStatus: state.otpStatus.otpStatus,
  otpStatusLoading: state.otpStatus.loading,
});
export default connect(mapStateToProps, {
  Verify_School_OTP,
  CHECK_STATUS_School_OTP,
  ResendOTP,
})(SchoolVerification);
