import axios from "axios";
import { returnErrors } from "../message/message";
import { GET_EXAM, SET_EXAM_LOADING, SET_EXAM_FAIL } from "../types";

export const getExams = () => (dispatch) => {
  dispatch({ type: SET_EXAM_LOADING });

  axios
    .get("QLA/Exam/")
    .then((res) =>
      dispatch({
        type: GET_EXAM,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: SET_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const getExamsById = (id) => (dispatch) => {
  dispatch({ type: SET_EXAM_LOADING });
  axios
    .get(`QLA/Exam/ById/${id}`)
    .then((res) =>
      dispatch({
        type: GET_EXAM,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: SET_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
