import axios from "axios";
import {
  GET_EXAM_DATA,
  SET_EXAM_DATA_LOADING,
  SET_EXAM_DATA_FAIL,
} from "../types";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

export const getStudentExam = (school, subject, exam) => (dispatch, getState) => {
  console.log(school,subject,exam)
  dispatch({ type: SET_EXAM_DATA_LOADING });
  axios
    .get(`QLA/classExam/${school}/${subject}/${exam}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EXAM_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_EXAM_DATA_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
