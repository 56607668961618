import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import { connect } from "react-redux";
import UserPermissionPage from "../KeenTheme/UserPermissionPage";
class UserPermission extends Component {
  static propTypes = {};

  componentDidMount() {}

  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Sidebar />
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <NavbarKeen />
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
            >
              <Subheader name="User Permission" />
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <UserPermissionPage />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(UserPermission);
