import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import { connect } from "react-redux";
import PropType from "prop-types";
import { getExams } from "../../actions/Exam/ExamsAction";
import ExamData from "../KeenTheme/ExamData";

class Exams extends Component {
  static propTypes = {
    getExams: PropType.func.isRequired,
    ExamList: PropType.array.isRequired,
  };

  componentDidMount() {
    this.props.getExams();
  }

  render() {
    return (
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Aside*/}
          <Sidebar />
          {/*end::Aside*/}
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper">
            {/*begin::Header*/}
            <NavbarKeen />
            {/*end::Header*/}
            {/*begin::Content*/}
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              {/*begin::Subheader*/}
              <Subheader name="Exams" />
              {/*end::Subheader*/}
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <ExamData data={this.props.ExamList} />
                    </div>
                  </div>
                  {/*begin::Statistics*/}
                  {/*end::Statistics*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ExamList: state.ExamList.ExamList,
});
export default connect(mapStateToProps, { getExams })(Exams);
