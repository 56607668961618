import {
  GET_CLASS_FILTER,
  SET_CLASS_FILTER_LOADING,
  SET_CLASS_FILTER_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  classFilterList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLASS_FILTER:
      return {
        ...state,
        classFilterList: action.payload,
        isLoading: false,
      };
    case SET_CLASS_FILTER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CLASS_FILTER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
