import axios from "axios";
import { returnErrors } from "../message/message";
import { GET_POLICY } from "../types";

export const getPolicy = () => (dispatch) => {
  axios
    .get("Policy/Policy/")
    .then((res) =>
      dispatch({
        type: GET_POLICY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
