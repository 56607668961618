import { GET_FILE_MEDIA, FILE_LOADING, FILE_FAILED } from "../actions/types";

const initialState = {
  isLoading: false,
  FileMedia: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILE_MEDIA:
      return {
        ...state,
        FileMedia: action.payload,
        isLoading: false,
      };

    case FILE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FILE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
