import {
  GET_SCHOOL_CREATED,
  GET_SCHOOL_CREATED_LOADING,
  GET_SCHOOL_CREATED_LOADING_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  SchoolByCreated: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOL_CREATED:
      return {
        ...state,
        SchoolByCreated: action.payload,
        isLoading: false,
      };

    case GET_SCHOOL_CREATED_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SCHOOL_CREATED_LOADING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
