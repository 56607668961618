import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADD_RAG_RATING,
  GET_RAG_RATING,
  UPDATE_RAG_RATING,
  RAG_RATING_LOADING,
  RAG_RATING_FAIL,
  GET_RAG_RATING_LIST,
  RAG_RATING_LOADING_NEW,
  RAG_RATING_FAIL_NEW,
} from "../types";

export const getRAGRating = () => (dispatch) => {
  dispatch({ type: RAG_RATING_LOADING });
  axios
    .get("QLA/RAG/")
    .then((res) => {
      dispatch({
        type: GET_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: RAG_RATING_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const addRAGRating = (data) => (dispatch, getState) => {
  dispatch({ type: RAG_RATING_LOADING });
  axios
    .post("QLA/RAG/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Rating Added" }));
      dispatch({
        type: ADD_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const updateRAGRating = (id, data) => (dispatch, getState) => {
  dispatch({ type: RAG_RATING_LOADING });
  axios
    .put(`QLA/RAG/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Rating Updated!" }));
      dispatch({
        type: UPDATE_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getRAGRatingId = (id) => (dispatch) => {
  dispatch({ type: RAG_RATING_LOADING });
  axios
    .get(`QLA/RAGGet/${id}`)
    .then((res) => {
      dispatch({
        type: GET_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: RAG_RATING_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const addRatingRAG = (data) => (dispatch, getState) => {
  dispatch({ type: RAG_RATING_LOADING });
  axios
    .post("QLA/RAGADD/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Rating Added" }));
      dispatch({
        type: ADD_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const GETRAGRating = (data) => (dispatch, getState) => {
  dispatch({ type: RAG_RATING_LOADING_NEW });
  axios
    .get(`QLA/GetRAGRating/${data}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_RAG_RATING_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: RAG_RATING_FAIL_NEW });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
