import {
  NEW_MOCK_EXAM_FAIL,
  NEW_MOCK_EXAM_LOADING,
  GET_NEW_MOCK_EXAM,

  DELETE_MOCK_RESULTS,
  DELETE_MOCK_RESULT_LOADING,
  DELETE_MOCK_RESULT_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
  NewMockResultList: undefined,
  isFailed: false,
  isChecked: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEW_MOCK_EXAM:
      return {
        ...state,
        isChecked:true,
        NewMockResultList: action.payload,
        isLoading: false,
        isFailed: false,
      };

    case NEW_MOCK_EXAM_LOADING:
      return {
        ...state,
        isLoading: true,
        isChecked:true,
        isFailed: false,
      };

    case NEW_MOCK_EXAM_FAIL:
      return {
        ...state,
        NewMockResultList:undefined,
        isChecked:true,
        isLoading: false,
        isFailed: true,
      };
      case DELETE_MOCK_RESULTS:
        return {
          ...state,
          isChecked:true,
          isLoading: false,
          isFailed: false,
          NewMockResultList: undefined
        }
    default:
      return state;
  }
}
