import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  GET_MOCK_EXAM,
  ADD_MOCK_EXAM,
  DELETE_MOCK_EXAM,
  UPDATE_MOCK_EXAM,
  MOCK_EXAM_LOADING,
  MOCK_EXAM_FAIL,
  GET_MOCK_EXAM_FILTER,
  ADD_MOCK_EXAM_TEST, UPDATE_MOCK_EXAM_TEST,
  ADD_MOCK_EXAM_LOADING, ADD_MOCK_EXAM_FAIL
} from "../types";

export const getMock = () => (dispatch, getState) => {
  dispatch({ type: MOCK_EXAM_LOADING });
  axios
    .get("QLA/MockExam/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MOCK_EXAM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteMock = (id) => (dispatch, getState) => {
  axios
    .delete(`QLA/MockExam/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Mock Delete" }));
      dispatch({
        type: DELETE_MOCK_EXAM,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addMock = (data) => (dispatch, getState) => {
  axios
    .post("QLA/MockExam/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Mock exam created" }));
      dispatch({
        type: ADD_MOCK_EXAM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const updateMock = (id, data) => (dispatch, getState) => {
  axios
    .put(`QLA/MockExam/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Mock Updated!" }));
      dispatch({
        type: UPDATE_MOCK_EXAM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getMockFilter = (classid, school) => (dispatch, getState) => {
  // console.log(body, "DATA");
  dispatch({ type: MOCK_EXAM_LOADING });
  axios
    .get(`QLA/MockExamFilter/${classid}/${school}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MOCK_EXAM_FILTER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getMockFilterSchool = (school) => (dispatch, getState) => {
  dispatch({ type: MOCK_EXAM_LOADING });
  axios
    .get(`QLA/MockExamFilterSchool/${school}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MOCK_EXAM_FILTER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addStudentMockMarks = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_MOCK_EXAM_LOADING });
  const newdata = JSON.stringify(data);
  axios
    .post("QLA/StudentMarksMockTest/", newdata, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Student Marks Added" }));
      dispatch({
        type: ADD_MOCK_EXAM_TEST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: ADD_MOCK_EXAM_FAIL });

      dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const UpdateStudentMockMarks = (data) => (dispatch, getState) => {
  dispatch({ type: MOCK_EXAM_LOADING });
  const newdata = JSON.stringify(data);
  axios
    .put("QLA/StudentMarksMockTest/", newdata, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Student Marks Updated" }));
      dispatch({
        type: UPDATE_MOCK_EXAM_TEST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_EXAM_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
