import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPayViaEmailAction } from "../../actions/PayViaEmail/PayViaEmailAction";
import { Modal, Spinner } from "react-bootstrap";
import { getSubscriptionViaTID } from "../../actions/Subscription/Subscription_Action";
import Imagex from "../../assets/media/bg/demo-7.jpg";
import cross from "../../assets/media/svg/delete.png";
import tick from "../../assets/media/svg/check.png";
import Moment from "react-moment";
import PaypalViaEmail from "./Paypal/PayPalViaEmail";
import BaseViaEmail from "./Stripe/BaseViaEmail";
// import PrintPayment from "./PrintPayment";

class PayViaEmail extends Component {
  static propTypes = {
    getPayViaEmailAction: PropTypes.func.isRequired,
    PayViaEmailArray: PropTypes.array.isRequired,
    getSubscriptionViaTID: PropTypes.func.isRequired,
    array_sub_TID: PropTypes.array,
    Loading_SubViaTID: PropTypes.bool,
  };
  state = {
    checkout: undefined,
    PrintModal: false,
  };
  PrintKey = (bool_val, Transaction_ID) => {
    if (bool_val) {
      this.setState({ PrintModal: true });
      this.props.getSubscriptionViaTID(Transaction_ID);
    }
  };
  changeState = (data) => {
    this.setState({ checkout: data });
  };

  componentDidMount() {
    this.props.getPayViaEmailAction(this.props.match.params.slug);
  }
  render() {
    const { checkout, PrintModal } = this.state;
    const { PayViaEmailArray, array_sub_TID, Loading_SubViaTID } = this.props;
    return (
      <Fragment>
        {PayViaEmailArray.map((arr, key) => (
          <div key={key} className="d-flex flex-column flex-root">
            <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid wizard ">
              <div className="login-aside d-flex flex-column flex-row-auto pt-lg-20">
                <div className="d-flex flex-column-auto flex-column pt-15">
                  <div className="wizard-nav pt-5 pt-lg-40 ">
                    <div className="wizard-steps pt-lg-40">
                      <div
                        className="wizard-step"
                        data-wizard-type="step"
                        data-wizard-state="current"
                      >
                        <div className="wizard-wrapper px-lg-40">
                          <div className="wizard-icon">
                            <i className="wizard-check ki ki-check" />
                            <span className="wizard-number">QLA</span>
                          </div>
                          <div className="wizard-label">
                            <h2 className="wizard-title pt-lg-4">QLA Target</h2>
                          </div>
                        </div>
                        <h6 className="wizard-desc mt-5 pl-lg-15 pr-lg-10">
                          Hi!{" "}
                          <span className="text-capitalize">
                            {arr.Requested_By.firstname}
                          </span>{" "}
                          from {arr.school.name} is requesting an amount of £{" "}
                          {parseFloat(arr.Amount)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-content flex-column-fluid d-flex flex-column">
                <div className="text-right d-flex justify-content-center"></div>
                <div className="d-flex flex-row-fluid flex-centernew">
                  <div className="PayRequest">
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-5 shadow-soft bg-white rounded-sm">
                          <div className="d-flex flex-column-fluid">
                            <div className="container">
                              <div className="card card-custom overflow-hidden">
                                <div className="card-body p-0">
                                  {(() => {
                                    if (checkout === undefined) {
                                      return (
                                        <Fragment>
                                          <div
                                            className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0"
                                            style={{
                                              backgroundImage: `url(${Imagex})`,
                                            }}
                                          >
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                                  Payment Request
                                                </h1>
                                                <div className="d-flex flex-column align-items-md-end px-0">
                                                  <span className="mb-5 navbar-brand text-black">
                                                    Target QLA
                                                  </span>
                                                  <span className="text-white d-flex flex-column align-items-md-end opacity-70">
                                                    <span>
                                                      221-B Baker St, London NW1
                                                      6XE,
                                                    </span>
                                                    <span>United Kingdom</span>
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-bottom w-100 opacity-20" />
                                              <div className="d-flex justify-content-between text-white pt-6">
                                                <div className="d-flex flex-column flex-root">
                                                  <span className="font-weight-bolde mb-2r">
                                                    INVOICE NO.
                                                  </span>
                                                  <span className="opacity-70">
                                                    #{arr.id}
                                                    {/* <Moment>{arr.TimeStart}</Moment> */}
                                                  </span>
                                                </div>
                                                <div className="d-flex flex-column flex-root">
                                                  <span className="font-weight-bolder mb-2">
                                                    Date&Time
                                                  </span>
                                                  <span className="opacity-70">
                                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                                      {arr.Requested_Time}
                                                    </Moment>
                                                  </span>
                                                </div>
                                                <div className="d-flex flex-column flex-root">
                                                  <span className="font-weight-bolder mb-2">
                                                    Requested by
                                                  </span>
                                                  <span className="opacity-70 text-capitalize">
                                                    {arr.Requested_By.firstname}
                                                    &nbsp;
                                                    {arr.Requested_By.lastname}
                                                  </span>
                                                </div>
                                                <div className="d-flex flex-column flex-root">
                                                  <span className="font-weight-bolder mb-2">
                                                    Paid
                                                  </span>
                                                  <span className="opacity-70 text-capitalize">
                                                    <img
                                                      alt="tick or cross"
                                                      src={
                                                        arr.Paid ? tick : cross
                                                      }
                                                      height="25px"
                                                    />
                                                  </span>
                                                </div>

                                                <div className="d-flex flex-column flex-root">
                                                  <span className="font-weight-bolder mb-2">
                                                    Download Invoice
                                                  </span>
                                                  <span className="opacity-70 text-capitalize">
                                                    {Loading_SubViaTID ? (
                                                      <Spinner
                                                        animation="grow"
                                                        variant="warning"
                                                      />
                                                    ) : (
                                                      <i
                                                        style={
                                                          arr.Paid
                                                            ? {
                                                                cursor:
                                                                  "pointer",
                                                              }
                                                            : {}
                                                        }
                                                        onClick={() =>
                                                          this.PrintKey(
                                                            arr.Paid,
                                                            arr.Transaction_ID
                                                          )
                                                        }
                                                        className={`fas fa-file-download icon-xl ${
                                                          arr.Paid
                                                            ? "text-success"
                                                            : "text-light"
                                                        }`}
                                                      />
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th className="pl-0 font-weight-bold text-muted text-uppercase">
                                                        # ID
                                                      </th>
                                                      <th className="text-center font-weight-bold text-muted text-uppercase">
                                                        Date
                                                      </th>

                                                      <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                                                        Requested By
                                                      </th>

                                                      <th className="text-center pr-0 font-weight-bold text-muted text-uppercase">
                                                        School
                                                      </th>

                                                      <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                                                        Amount
                                                      </th>

                                                      <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                                                        Paid Status
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr className="font-weight-boldest font-size-lg">
                                                      <td className="pl-0 pt-7">
                                                        {arr.unique_ID}
                                                      </td>
                                                      <td className="pl-0 pt-7">
                                                        <Moment format="DD/MM/YY HH:mm">
                                                          {arr.Requested_Time}
                                                        </Moment>
                                                      </td>
                                                      <td className="pl-0 pt-7 text-center text-capitalize">
                                                        {
                                                          arr.Requested_By
                                                            .firstname
                                                        }
                                                        &nbsp;
                                                        {
                                                          arr.Requested_By
                                                            .lastname
                                                        }
                                                      </td>
                                                      <td className="pl-0 pt-7 text-center text-capitalize">
                                                        {arr.school.name}
                                                      </td>
                                                      <td className="pl-0 pt-7 text-center text-capitalize">
                                                        £ {arr.Amount}
                                                      </td>
                                                      <td className="text-center pt-7">
                                                        <img
                                                          alt="cross n tick"
                                                          src={
                                                            arr.Paid
                                                              ? tick
                                                              : cross
                                                          }
                                                          height="25px"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                                                <div className="d-flex flex-column mb-10 mb-md-0">
                                                  <div className="d-flex justify-content-between mb-3">
                                                    <p>
                                                      <b>
                                                        We can add Some Terms
                                                        and conditions here
                                                      </b>
                                                      &nbsp; Lorem ipsum dolor
                                                      sit amet, consectetur
                                                      adipiscing elit, sed do
                                                      eiusmod tempor incididunt
                                                      ut labore et dolore magna
                                                      aliqua. Nunc mi ipsum
                                                      faucibus vitae. Elementum
                                                      curabitur vitae nunc sed
                                                      velit. Ac tortor dignissim
                                                      convallis aenean et. In
                                                      fermentum posuere urna nec
                                                      tincidunt. Ac turpis
                                                      egestas maecenas pharetra
                                                      convallis posuere morbi
                                                      leo urna. Purus in mollis
                                                      nunc sed id semper risus
                                                      in hendrerit.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between">
                                                <button
                                                  disabled={arr.Paid}
                                                  onClick={() =>
                                                    this.changeState("Paypal")
                                                  }
                                                  type="button"
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/196/196566.png"
                                                    height={100}
                                                    alt="Pay Via Paypal"
                                                  />
                                                </button>
                                                <button
                                                  disabled={arr.Paid}
                                                  type="button"
                                                  onClick={() =>
                                                    this.changeState("Stripe")
                                                  }
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/5968/5968382.png"
                                                    height={100}
                                                    alt="Pay Via Stripe"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    } else if (checkout === "Paypal") {
                                      return (
                                        <Fragment>
                                          <div
                                            className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0"
                                            style={{
                                              backgroundImage: `url(${Imagex})`,
                                            }}
                                          >
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                                  Payment Request
                                                </h1>
                                                <div className="d-flex flex-column align-items-md-end px-0">
                                                  <span className="mb-5 navbar-brand text-black">
                                                    Target QLA
                                                  </span>
                                                  <span className="text-white d-flex flex-column align-items-md-end opacity-70">
                                                    <span>
                                                      221-B Baker St, London NW1
                                                      6XE,
                                                    </span>
                                                    <span>United Kingdom</span>
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-bottom w-100 opacity-20" />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="table-responsive"></div>
                                            </div>
                                          </div>
                                          <div className="maxWidth text-center">
                                            <PaypalViaEmail
                                              unique_ID={
                                                this.props.match.params.slug
                                              }
                                              Price={arr.Amount}
                                            />
                                          </div>
                                          <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                                                <div className="d-flex flex-column mb-10 mb-md-0">
                                                  <div className="d-flex justify-content-between mb-3">
                                                    <p>
                                                      <b>
                                                        We can add Some Terms
                                                        and conditions here
                                                      </b>
                                                      &nbsp; Lorem ipsum dolor
                                                      sit amet, consectetur
                                                      adipiscing elit, sed do
                                                      eiusmod tempor incididunt
                                                      ut labore et dolore magna
                                                      aliqua. Nunc mi ipsum
                                                      faucibus vitae. Elementum
                                                      curabitur vitae nunc sed
                                                      velit. Ac tortor dignissim
                                                      convallis aenean et. In
                                                      fermentum posuere urna nec
                                                      tincidunt. Ac turpis
                                                      egestas maecenas pharetra
                                                      convallis posuere morbi
                                                      leo urna. Purus in mollis
                                                      nunc sed id semper risus
                                                      in hendrerit.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between">
                                                <button
                                                  disabled={arr.Paid}
                                                  onClick={() =>
                                                    this.changeState("Paypal")
                                                  }
                                                  type="button"
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/196/196566.png"
                                                    height={100}
                                                    alt="Pay Via Paypal"
                                                  />
                                                </button>
                                                <button
                                                  disabled={arr.Paid}
                                                  type="button"
                                                  onClick={() =>
                                                    this.changeState("Stripe")
                                                  }
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/5968/5968382.png"
                                                    height={100}
                                                    alt="Pay Via Stripe"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    } else if (checkout === "Stripe") {
                                      return (
                                        <Fragment>
                                          <div
                                            className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0"
                                            style={{
                                              backgroundImage: `url(${Imagex})`,
                                            }}
                                          >
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                                  Payment Request
                                                </h1>
                                                <div className="d-flex flex-column align-items-md-end px-0">
                                                  <span className="mb-5 navbar-brand text-black">
                                                    Target QLA
                                                  </span>
                                                  <span className="text-white d-flex flex-column align-items-md-end opacity-70">
                                                    <span>
                                                      221-B Baker St, London NW1
                                                      6XE,
                                                    </span>
                                                    <span>United Kingdom</span>
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-bottom w-100 opacity-20" />
                                            </div>
                                          </div>
                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="table-responsive"></div>
                                            </div>
                                          </div>
                                          <div className="maxWidth">
                                            <BaseViaEmail
                                              unique_ID={
                                                this.props.match.params.slug
                                              }
                                              Price={arr.Amount}
                                            />
                                          </div>
                                          <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                                                <div className="d-flex flex-column mb-10 mb-md-0">
                                                  <div className="d-flex justify-content-between mb-3">
                                                    <p>
                                                      <b>
                                                        We can add Some Terms
                                                        and conditions here
                                                      </b>
                                                      &nbsp; Lorem ipsum dolor
                                                      sit amet, consectetur
                                                      adipiscing elit, sed do
                                                      eiusmod tempor incididunt
                                                      ut labore et dolore magna
                                                      aliqua. Nunc mi ipsum
                                                      faucibus vitae. Elementum
                                                      curabitur vitae nunc sed
                                                      velit. Ac tortor dignissim
                                                      convallis aenean et. In
                                                      fermentum posuere urna nec
                                                      tincidunt. Ac turpis
                                                      egestas maecenas pharetra
                                                      convallis posuere morbi
                                                      leo urna. Purus in mollis
                                                      nunc sed id semper risus
                                                      in hendrerit.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                            <div className="col-md-9">
                                              <div className="d-flex justify-content-between">
                                                <button
                                                  disabled={arr.Paid}
                                                  onClick={() =>
                                                    this.changeState("Paypal")
                                                  }
                                                  type="button"
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/196/196566.png"
                                                    height={100}
                                                    alt="Pay Via Paypal"
                                                  />
                                                </button>
                                                <button
                                                  disabled={arr.Paid}
                                                  type="button"
                                                  onClick={() =>
                                                    this.changeState("Stripe")
                                                  }
                                                >
                                                  {" "}
                                                  <img
                                                    src="https://cdn-icons-png.flaticon.com/512/5968/5968382.png"
                                                    height={100}
                                                    alt="Pay Via Stripe"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Modal
          show={PrintModal}
          onHide={() => this.setState({ PrintModal: false })}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Donwload Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className="">
                  <div className="">
                    {(() => {
                      if (
                        Loading_SubViaTID === false &&
                        array_sub_TID !== undefined &&
                        array_sub_TID.length !== 0
                      ) {
                        return (
                          <div className="row">
                            {/* <div className="col-12 text-center">
                              <PrintPayment data={array_sub_TID} />
                            </div> */}
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  PayViaEmailArray: state.PayViaEmailArray.PayViaEmailArray,
  PayViaEmailLoading: state.PayViaEmailArray.isLoading,
  Loading_SubViaTID: state.subscription.isLoading_SubViaTID,
  array_sub_TID: state.subscription.array_sub_TID,
});
export default connect(mapStateToProps, {
  getPayViaEmailAction,
  getSubscriptionViaTID,
})(PayViaEmail);
