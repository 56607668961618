import axios from "axios";
import { returnErrors, createMessage } from "../message/message";
import {
  ADD_STUDENT_CSV,
  // DELETE_STUDENT_CSV,
  // UPDATE_STUDENT_CSV,
  GET_STUDENT_CSV,
  GET_STUDENT_CSV_LOADING,
  GET_STUDENT_CSV_FAIL,
} from "../types";
import { tokenConfig } from "../auth_action/auth";

// var fileDownload = require("js-file-download");

export const getStudentCSV =
  (YearGradeID, SchoolID) => (dispatch, getState) => {
    dispatch({ type: GET_STUDENT_CSV_LOADING });
    axios
      .get(
        `QLA/CSVStudentList/${YearGradeID}/${SchoolID}/`,
        tokenConfig(getState)
      )
      .then((res) => {
        const url_local = res.data["FileUrl"];
        const splittedURL = url_local.split(
          process.env.REACT_APP_LIVE_EXTENSTION
        );
        const url_final = splittedURL[1];
        const filename = url_final.split("/")[2];
        axios({
          url: url_final,
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // console.log(url);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
        dispatch({
          type: GET_STUDENT_CSV,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_STUDENT_CSV_FAIL });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const addStudentCSV = (Data) => (dispatch, getState) => {
  dispatch({ type: GET_STUDENT_CSV_LOADING });
  axios
    .post(`QLA/CSVStudentList/`, Data, tokenConfig(getState), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(createMessage({ addLead: "CSV Uploaded" }));
      dispatch({
        type: ADD_STUDENT_CSV,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_STUDENT_CSV_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
