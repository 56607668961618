import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Footer } from "./Footer";
import MobileHeader from "./KeenTheme/MobileHeader";
import UserPanel from "./KeenTheme/UserPanel";
import QuickCart from "./KeenTheme/QuickCart";
import QuickPanel from "./KeenTheme/QuickPanel";
import ChatPanel from "./KeenTheme/ChatPanel";
import Scrolltop from "./KeenTheme/Scrolltop";
import StickyToolbar from "./KeenTheme/StickyToolbar";
import DemoPanel from "./KeenTheme/DemoPanel";
import Waiting from "./functions/Waiting";
import "../index.css";

const ThemeTemplate = ({
  component: Component,
  auth,
  user,
  token,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isLoading === true) {
          return <Waiting message="Please Wait While content is loading!" />;
        } else if (auth.user) {
          return (
            <>
              {/*<NavbarComponent/>*/}.
              <div id="workingSideBar" className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable aside-minimize">
                <MobileHeader />
                {/*start::Main*/}
                {/*end::Header Mobile*/}
                <Component {...props} />
                {/*end::Main*/}
                {/* begin::User Panel*/}
                <UserPanel />
                {/* end::User Panel*/}
                {/*begin::Quick Cart*/}
                <QuickCart />
                {/*end::Quick Cart*/}
                {/*begin::Quick Panel*/}
                <QuickPanel />
                {/*end::Quick Panel*/}
                {/*begin::Chat Panel*/}
                <ChatPanel />
                {/*end::Chat Panel*/}
                {/*begin::Scrolltop*/}
                <Scrolltop />
                {/*end::Scroll*/}
                {/*begin::Sticky Toolbar*/}
                <StickyToolbar />
                {/*end::Sticky Toolbar*/}
                {/*begin::Demo Panel*/}
                <DemoPanel />
              </div>
              <Footer />
            </>
          );
        } else if (auth.token === null) {
          return (
            <Redirect
              to={{
                pathname: "/Login",
                message: "Please Login to Continue",
              }}
            />
          );
        }
      }}
    />
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.token,
});
export default connect(mapStateToProps)(ThemeTemplate);
