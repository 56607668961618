import {
  GET_QUESTION_PAPER,
  SET_QUESTION_PAPER_FAIL,
  SET_QUESTION_PAPER_LOADING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  QuestionPaper: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_PAPER:
      return {
        ...state,
        isLoading: false,
        QuestionPaper: action.payload,
      };
    case SET_QUESTION_PAPER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_QUESTION_PAPER_FAIL:
      return {
        ...state,
        QuestionPaper: [],
        isLoading: false,
      };
    default:
      return state;
  }
}
