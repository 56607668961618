import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import LicensePage from "../KeenTheme/LicensePage";

class License extends Component {
  render() {
    return (
      <>
        {/*start::Main*/}
        <div className="d-flex flex-column flex-root">
          {/*begin::Page*/}
          <div className="d-flex flex-row flex-column-fluid page">
            {/*begin::Aside*/}
            <Sidebar />
            {/*end::Aside*/}
            {/*begin::Wrapper*/}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              {/*begin::Header*/}
              <NavbarKeen />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                style={{ marginTop: "20px" }}
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                {/*begin::Subheader*/}
                <Subheader name="License Page" />
                {/*end::Subheader*/}
                {/*begin::Entry*/}
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-12">
                        <LicensePage />
                        {/*<QuestionPaperData data={QuestionPaper} />*/}
                      </div>
                    </div>
                    {/*begin::Statistics*/}
                    {/*end::Statistics*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Entry*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}
      </>
    );
  }
}

export default License;
