import axios from "axios";
import { tokenConfig } from "../auth_action/auth";

import { VERIFY_USER, VERIFY_USER_LOADING, VERIFY_USER_FAIL } from "../types";
import toast from "react-hot-toast";

export const Verify_USER_OTP = (data) => (dispatch, getState) => {
  const TID = toast.loading("Verifing OTP...⏳");
  dispatch({ type: VERIFY_USER_LOADING });
  axios
    .post("accounts/verifyUserOtp/", data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: VERIFY_USER,
        payload: res.data,
      });
      toast.success(res.data.message, {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      dispatch({ type: VERIFY_USER_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};
