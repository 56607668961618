import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  GET_CLASS,
  ADD_CLASS,
  DELETE_CLASS,
  UPDATE_CLASS,
  SET_CLASS_FAIL,
  SET_UPDATE_FAIL,
  GET_CLASS_FILTER,
  SET_CLASS_LOADING,
  SET_CLASS_FILTER_FAIL,
  SET_CLASS_LOADING_ADD,
  GET_CLASS_FILTER_SCHOOL,
  SET_CLASS_FILTER_LOADING,
  SET_CLASS_SCHOOL_FILTER_FAIL,
  SET_CLASS_SCHOOL_FILTER_LOADING,
  GET_CLASS_ENHANCE,
  ENHANCE_CLASS_LOADING,
  ENHANCE_CLASS_FAIL,
} from "../types";

import toast from "react-hot-toast";

export const getClass = () => (dispatch, getState) => {
  dispatch({ type: SET_CLASS_LOADING });
  axios
    .get("QLA/Class/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLASS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_CLASS_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteClass = (id) => (dispatch, getState) => {
  axios
    .delete(`QLA/Class/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Class Delete" }));
      dispatch({
        type: DELETE_CLASS,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addClass = (data) => (dispatch, getState) => {
  axios
    .post("QLA/Class/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Class Added" }));
      dispatch({
        type: ADD_CLASS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const updateClass = (id, data) => (dispatch, getState) => {
  const TID = toast.loading("Please wait updaing class...⏳");
  dispatch({ type: SET_CLASS_LOADING_ADD });
  axios
    .put(`QLA/Class/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Class Updated!", TID }));
      dispatch({
        type: UPDATE_CLASS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_UPDATE_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status, TID));
    });
};
export const get_Class_Filter = (body) => (dispatch, getState) => {
  dispatch({ type: SET_CLASS_FILTER_LOADING });
  axios
    .get(
      `QLA/classData/${body.schoolid}/${body.yearGrade}/${body.subject}/`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: GET_CLASS_FILTER, payload: res.data[0] });
    })
    .catch((err) => {
      dispatch({ type: SET_CLASS_FILTER_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const get_Class_Filter_class = (body) => (dispatch, getState) => {
  dispatch({ type: SET_CLASS_FILTER_LOADING });
  axios
    .get(`QLA/ClassDataListSchool/${body.classId}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLASS_FILTER,
        payload: res.data[0],
      });
    })
    .catch((err) => {
      dispatch({ type: SET_CLASS_FILTER_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const getClassSchool = (id) => (dispatch, getState) => {
  dispatch({ type: SET_CLASS_SCHOOL_FILTER_LOADING });
  axios
    .get(`QLA/ClassSchool/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLASS_FILTER_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_CLASS_SCHOOL_FILTER_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getEnhanceClass = (id) => (dispatch, getState) => {
  dispatch({ type: ENHANCE_CLASS_LOADING });
  axios
    .get(`QLA/ClassSchoolEnhance/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_CLASS_ENHANCE, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ENHANCE_CLASS_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
