import React, { Component, Fragment } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import store from "./store";
import ReactDOM from "react-dom";
import PrivateRoute from "./Components/PrivateRoute";
import Alerts from "../src/Alerts/Alerts";
import axios from "axios";
import Page_404 from "../src/Components/Page_404";
import Signup from "../src/Components/AuthPage/Signup";
import Login from "./Components/AuthPage/Login";
import PageParent from "./Components/subscription/PageParent";
import DashboardHome from "./Components/Dashboard/Dashboard-home";
import Report from "./Components/Pages/Report";
import AddMarks from "./Components/Pages/AddMarks";
import QuestionPaper from "./Components/Pages/QuestionPaper";
import Aboutus from "./Components/Pages/Aboutus";
import Privacy from "./Components/Pages/Privacy";
import Team from "./Components/Pages/Team";
import TermsCondition from "./Components/Pages/TermsCondition";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadUser } from "./actions/auth_action/auth";
import StudentsList from "./Components/Pages/Studentslist";
import Exams from "./Components/Pages/Exams";
import UserPermission from "./Components/Pages/UserPermission";
import ClassPage from "./Components/Pages/Class_Page";
import Profile from "./Components/Pages/Profile";
import FAQ from "./Components/Pages/FAQ";
import Feedback from "./Components/Pages/Feedback";
import License from "./Components/Pages/License";
import ThemeTemplate from "./Components/ThemeTemplate";
// import School from "./Components/Pages/School";
import Help from "./Components/Pages/Help";
import ExamMock from "./Components/Pages/ExamMock";
// import MockUpResult from "./Components/Pages/MockUpResult";
import Documents from "./Components/Pages/Documents";
import FileTesting from "./Components/FileTesting/FileTesting";
import FileTestingPage2 from "./Components/FileTesting/FileTestingPage2";
import PayViaEmail from "./Components/subscription/PayViaEmail";
import MockUpResultTestOuter from "./Components/Pages/MockUpResultTestOuter";
import { Toaster } from "react-hot-toast";
import PaymentParent from "./Components/subscription/PaymentParent";
import SchoolVerification from "./Components/Pages/SchoolVerification";
import AdminSchool from "./Components/AdminSchool";
import { AccountVerify } from "./Components/Pages/AccountVerify";
import StudentMarksOuter from "./Components/Pages/StudentMarksOuter";
import StudentListOuter from "./Components/Pages/StudentListOuter";
import DocumentsUI from "./Components/Pages/DocumentsUID";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <Toaster />
        <BrowserRouter basename={"/"}>
          <Fragment>
            <Alerts />
            <Switch>
              <PrivateRoute exact path={"/"} component={DashboardHome} />
              <Route exact path={"/Signup"} component={Signup} />
              <Route exact path={"/VerifyAccount"} component={AccountVerify} />
              <Route
                exact
                path={"/SchoolVerify"}
                component={SchoolVerification}
              />
              <Route exact path={"/FileTesting"} component={FileTesting} />
              <Route
                exact
                path={"/FileTesting/:slug"}
                component={FileTestingPage2}
              />

              <Route exact path={"/Login"} component={Login} />
              <PrivateRoute
                exact
                path={"/StudentMarks"}
                component={StudentMarksOuter}
              />
              <PrivateRoute exact path={"/About-us"} component={Aboutus} />
              <PrivateRoute
                exact
                path={"/AdminSchool"}
                component={AdminSchool}
              />
              <PrivateRoute exact path={"/Privacy"} component={Privacy} />
              <PrivateRoute exact path={"/Team"} component={Team} />
              <PrivateRoute
                exact
                path={"/Terms-&-Conditions"}
                component={TermsCondition}
              />
              {/*MockUpResult*/}
              {/* TODO:OLD URL */}
              {/* <PrivateRoute
                exact
                path={"/MockUpResult"}
                component={MockUpResult}
              /> */}

              <PrivateRoute
                exact
                path={"/MockUpResult"}
                component={MockUpResultTestOuter}
              />

              <PrivateRoute
                exact
                path={"/MockUpResult/:id/:GradeId/:SubjectId/:ClassId"}
                component={MockUpResultTestOuter}
              />
              {/* TODO OLD url */}
              {/* <PrivateRoute
                exact
                path={"/MockUpResult/:id/:GradeId/:SubjectId/:ClassId"}
                component={MockUpResult}
              /> */}

              <PrivateRoute exact path={"/Support"} component={Feedback} />
              <PrivateRoute exact path={"/License"} component={License} />
              {/*<PrivateRoute exact path={"/Support"} component={Support} />*/}
              <PrivateRoute exact path={"/FAQ"} component={FAQ} />
              <PrivateRoute exact path={"/Documents"} component={Documents} />

              <PrivateRoute
                exact
                path={
                  "/Documents/:MockID/:ClassID/:YearID/:SubjectID/:PaperID/"
                }
                component={Documents}
              />

              <PrivateRoute
                exact
                path={"/Documents/:slug"}
                component={DocumentsUI}
              />

              <PrivateRoute exact path={"/Classes"} component={ClassPage} />

              <Route path="/Subscription/:slug" component={PayViaEmail} />

              <ThemeTemplate
                exact
                path={"/Subscription_old"}
                component={PageParent}
              />

              <ThemeTemplate
                exact
                path={"/Subscription"}
                component={PaymentParent}
              />

              <PrivateRoute
                exact
                path={"/Dashboard"}
                component={DashboardHome}
              />
              <PrivateRoute exact path={"/Exams"} component={Exams} />

              <PrivateRoute
                exact
                path={"/UserPermission/"}
                component={UserPermission}
              />

              <PrivateRoute
                exact
                path="/ClassExams/:id/:slug"
                component={ExamMock}
              />
              <PrivateRoute
                exact
                path={"/Students-list"}
                component={StudentListOuter}
              />
              <PrivateRoute exact path={"/Reports"} component={Report} />
              <PrivateRoute exact path={"/Marks"} component={AddMarks} />
              <PrivateRoute
                exact
                path={"/Question-Paper"}
                component={QuestionPaper}
              />
              <PrivateRoute exact path={"/Profile"} component={Profile} />
              {/* <PrivateRoute exact path={"/School"} component={School} /> */}
              <PrivateRoute exact path={"/Help"} component={Help} />
              <Route component={Page_404} />
            </Switch>
          </Fragment>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.querySelector("#root"));
