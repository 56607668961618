import axios from "axios";
import {
  GET_USER,
  LOADING_USER,
  DELETE_USER_FAIL,
  DELETE_USER,
  LOAD_DELETE_USER,
  UPDATE_USER_NEW,
  UPDATE_USER_NEW_LOADING,
  UPDATE_USER_NEW_FAIL,
} from "../types";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import toast from "react-hot-toast";

export const getUsers = () => (dispatch, getState) => {
  axios
    .get("accounts/GetUser/", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: LOADING_USER });

      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const getUserBySchool = (SchoolID) => (dispatch, getState) => {
  axios
    .get(`accounts/GetUserBySchool/${SchoolID}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: LOADING_USER });
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const deleteUser = (userid) => (dispatch, getState) => {
  const TID = toast.loading("Deleting user...⏳");
  dispatch({ type: LOAD_DELETE_USER });
  axios
    .delete(`accounts/update/${userid}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_USER, payload: userid });
      toast.success("User deleted!", {
        id: TID,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_USER_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
    });
};

export const UpdateUserStatus = (id, data) => (dispatch, getState) => {
  const TID = toast.loading("Updating user status...⏳");
  dispatch({ type: UPDATE_USER_NEW_LOADING });
  axios
    .patch(`accounts/updateStatus/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: UPDATE_USER_NEW, payload: res.data });
      toast.success("User updated!", {
        id: TID,
      });
    })

    .catch((err) => {
      dispatch({ type: UPDATE_USER_NEW_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
    });
};
