import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import DocumentPage from "./DocumentPage";

export default class Documents extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Sidebar />
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <NavbarKeen />
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
            >
              <Subheader name="Documents" />
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <DocumentPage
                        MockExamId={this.props.match.params.MockID}
                        ClassID={this.props.match.params.ClassID}
                        YearID={this.props.match.params.YearID}
                        SubjectID={this.props.match.params.SubjectID}
                        PaperID={this.props.match.params.PaperID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<Footer/>*/}
          </div>
        </div>
      </div>
    );
  }
}
