import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment, useRef } from "react";
import { getEnhanceClass } from "../../actions/ClassActionFolder/ClassActions";
import { MockResultSave } from "../../actions/MockExamResultSave/MockResultSaveAction";
import { Form, Button, Modal } from "react-bootstrap";

export const AddStudentMockResult = ({ classid, MockExamId, YearGrade }) => {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const [selectedInput, setSelectedInput] = useState(null);
  const Question = useSelector((state) => state.QuestionsList);
  const EnhanceClassObject = useSelector((state) => state.EnhanceClassObject);
  const [studentObject, setStudentObject] = useState(undefined);
  const [StudentHeader, setStudentHeader] = useState(undefined);
  const [ErrorMarks, setErrorMarks] = useState(false);
  const [small, setSmall] = useState(false);
  const [show, setShow] = useState(false);
  const [MessageBool, setMessageBool] = useState(false);
  const [ShowAll, setShowAll] = useState([]);
  const [studentstart, setStudentStart] = useState(0);
  const [studentend, setStudentEnd] = useState(10);

  const numRows = studentObject ? studentObject.length : 0;

  useEffect(() => {
    dispatch(getEnhanceClass(classid));
  }, []);

  useEffect(() => {
    ArrayTrimmer();
  }, [StudentHeader]);

  useEffect(() => {
    setMessageBool(true);
    const timeoutId = setTimeout(() => {
      setMessageBool(false);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const updateStudentMarks = (
    studentID,
    questionID,
    obtainMarks,
    attempted
  ) => {
    const updatedStudentObject = [...studentObject];
    const itemByQuestion = updatedStudentObject.find(
      (item) => item.QuestionID === questionID
    );
    const studentByQuestion = itemByQuestion.Student.find(
      (student) => student.studentID === studentID
    );
    studentByQuestion["obtainMarks"] = obtainMarks;
    studentByQuestion["attempted"] = attempted;
    setStudentObject(updatedStudentObject);
  };

  const keyUpFunction = (e) => {
    const element = document.getElementById(e.target.id);
    if (
      Number(e.target.min) <= Number(e.target.value) &&
      Number(e.target.value) <= Number(e.target.max)
    ) {
      if (element.classList.contains("is-invalid")) {
        setErrorMarks(false);
        element.classList.remove("is-invalid");
      }
    } else {
      setErrorMarks(true);
      element.classList.add("is-invalid");
      // element.value = "";
    }
  };

  useEffect(() => {
    if (
      selectedInput === null &&
      inputRefs.current[0] &&
      inputRefs.current[0][0]
    ) {
      inputRefs.current[0][0].focus();
      setSelectedInput({ row: 0, col: 0 });
    }
  }, [selectedInput, numRows]);

  const handleKeyUp = (e, row, col) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      let nextCol = col;
      let nextRow = row;

      // Attempt to move downwards within the same column
      if (nextRow < numRows - 1) {
        nextRow++;
      } else {
        // If at the last row, move to the next column's first element
        nextRow = 0;
        nextCol++;
        if (nextCol >= studentstart + studentend) {
          nextCol = studentstart;
        }
      }

      // Check if there's an input field at the new location
      if (inputRefs.current[nextRow] && inputRefs.current[nextRow][nextCol]) {
        inputRefs.current[nextRow][nextCol].focus();
        setSelectedInput({ row: nextRow, col: nextCol });
      } else {
        // If there's no input field in the same column downwards, move to the next column's first element
        let nextColInSameRow = col + 1;
        if (nextColInSameRow >= studentstart + studentend) {
          nextColInSameRow = studentstart;
          nextRow++;
        }
        if (nextRow < numRows) {
          if (
            inputRefs.current[nextRow] &&
            inputRefs.current[nextRow][nextColInSameRow]
          ) {
            inputRefs.current[nextRow][nextColInSameRow].focus();
            setSelectedInput({ row: nextRow, col: nextColInSameRow });
          }
        }
      }
    } else if (e.key === "Tab" && e.shiftKey) {
      // Handle Shift + Tab for moving backward
      e.preventDefault();
      let prevCol = col;
      let prevRow = row;

      // Attempt to move upwards within the same column
      if (prevRow > 0) {
        prevRow--;
      } else {
        // If at the first row, move to the previous column's last element
        prevRow = numRows - 1;
        prevCol--;
        if (prevCol < studentstart) {
          prevCol = studentstart + studentend - 1;
        }
      }

      // Check if there's an input field at the new location
      if (inputRefs.current[prevRow] && inputRefs.current[prevRow][prevCol]) {
        inputRefs.current[prevRow][prevCol].focus();
        setSelectedInput({ row: prevRow, col: prevCol });
      }
    }
  };

  const MakeObject = () => {
    const StudentMarksObject = Question.QuestionsList.map((item) => ({
      topics: item.topics.map((topic) => ({
        topicID: topic.id,
        topicName: topic.name,
      })),
      PaperID: item.paper.id,
      subject: item.paper.subject,
      year: item.year,
      YearGrade: YearGrade,
      QuestionID: item.id,
      totalmarks: item.marks,
      MockExamId: MockExamId,
      question_label: item.question_label,
      SchoolID: localStorage.getItem("Schoolid"),
      // isSelected: false,

      // Students Mapping
      classID: EnhanceClassObject.EnhanceClassObject.id,
      Student: EnhanceClassObject.EnhanceClassObject.students.map(
        (student) => ({
          studentID: student.id,
          studentName: student.firstname + " " + student.surname,
          obtainMarks: -1,
          attempted: false,
          // isStudentSelected: false,
          QuestionID: item.id,
        })
      ),
    }));

    const StudentHeader = EnhanceClassObject.EnhanceClassObject.students.map(
      (student) => ({
        studentID: student.id,
        studentName: student.firstname + " " + student.surname,
      })
    );
    setStudentHeader(StudentHeader);
    setStudentObject(StudentMarksObject);
  };

  const submit = (e) => {
    e.preventDefault();
    const topics = [];
    Question.QuestionsList.map((arr) => topics.push(arr.topics));
    const body = {
      studentObject: studentObject,
      topics: topics,
    };
    dispatch(MockResultSave(body));
  };

  useEffect(() => {
    if (EnhanceClassObject.EnhanceClassObject !== undefined) {
      MakeObject();
    }
  }, [EnhanceClassObject]);

  const sliceCalculator = (value) => {
    if (value === "showall") {
      setStudentStart(0);
      setStudentEnd(StudentHeader.length);
      setSmall(false);
    } else {
      setStudentStart(value * 10);
      setStudentEnd((value + 1) * 10);
      setSmall(false);
    }
  };
  const ArrayTrimmer = () => {
    if (StudentHeader !== undefined) {
      if (StudentHeader.length > 0) {
        const value = Math.round(StudentHeader.length / 10);
        let localArray = [];
        for (let i = 0; i < value; i++) {
          let temp1 = {
            value: i,
            label: `${i * 10 + 1} to ${(i + 1) * 10}`,
          };
          localArray.push(temp1);
        }
        let temp1 = {
          value: "showall",
          label: "Show All",
        };
        localArray.push(temp1);
        setShowAll(localArray);
      }
    }
  };

  return (
    <Fragment>
      <div className="row mt-5">
        <div className="col-12">
          <div
            className="card card-custom"
            style={{ minHeight: "10px !important" }}
          >
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label"></h3>
              </div>
              <div className="card-toolbar">
                <button
                  onClick={() => setShow(!show)}
                  className="btn btn-icon btn-sm btn-hover-light-primary mr-1"
                >
                  <i
                    className={`ki ${
                      show
                        ? "ki-arrow-down icon-nm rotate"
                        : "ki-arrow-up icon-nm"
                    }`}
                  />
                </button>
              </div>
            </div>
            <div
              className="card-body py-0"
              style={
                show
                  ? {
                      display: "none",
                      overflow: "hidden",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }
                  : {}
              }
            >
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                  <thead>
                    <tr>
                      <th style={{ maxWidth: "20px" }}>#</th>
                      <th className="pl-0 " style={{ maxWidth: "100px" }}>
                        Question Number
                      </th>
                      <th className="text-left" style={{ maxWidth: "100px" }}>
                        Topic
                      </th>
                      <th className="text-center" style={{ maxWidth: "70px" }}>
                        Total Marks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Question.QuestionsList !== undefined
                      ? Question.QuestionsList.sort((a, b) => a.id - b.id).map(
                          (item, index) => (
                            <tr key={index}>
                              <td style={{ maxWidth: "20px" }}>{index + 1}</td>
                              <td className="text-left pl-0" style={{ maxWidth: "10px" }}>
                                <span className="text-dark-75 font-weight-bolder font-size-lg">
                                  {item.question_label}
                                </span>
                              </td>
                              <td className="text-left">
                                {item.topics.map((topic, key) => (
                                  <span key={key}>
                                    {topic.name}
                                    <br />
                                  </span>
                                ))}
                              </td>
                              <td className="text-center">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {item.marks}
                                </span>
                              </td>
                            </tr>
                          )
                        )
                      : "No Questions Details available"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <div className="card card-custom" style={{ minHeight: "10px !important" }}>
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label"></h3>
              </div>
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline">
                  <button
                    onClick={() => setSmall(!small)}
                    className="btn btn-icon btn-sm btn-hover-light-primary mr-1"
                  >
                    <i className="ki ki-bold-more-ver  icon-nm" />
                  </button>
                  <div
                    className={`dropdown-menu dropdown-menu-sm dropdown-menu-right ${
                      small ? "show" : "hide"
                    }`}
                  >
                    <ul className="navi navi-hover ScrollerSmall">
                      <li className="navi-header pb-1">
                        <span className="text-primary font-size-sm">
                          Select
                        </span>
                      </li>
                      {ShowAll.map((arr, key) => (
                        <li key={key} className="navi-item">
                          <a
                            className="navi-link"
                            onClick={() => sliceCalculator(arr.value)}
                          >
                            <span className="navi-icon">
                              <i className="flaticon2-graph-1"></i>
                            </span>
                            <span className="navi-text">{arr.label}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body py-0">
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center FreezeTable">
                  <thead>
                    <tr className="text-left">
                      <th className="pl-0" style={{ minWidth: "120px" }}>
                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                          <input type="checkbox" disabled />
                          <span />
                        </label>
                      </th>
                      <th style={{ minWidth: "200px" }}>Topic</th>
                      {StudentHeader !== undefined
                        ? StudentHeader.slice(studentstart, studentend).map(
                            (item) => (
                              <th style={{ minWidth: "150px" }}>
                                {item.studentName}
                              </th>
                            )
                          ): ""}
                    </tr>
                  </thead>
                  <tbody>
                    {studentObject !== undefined
                      ? studentObject.map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="pl-0 py-2">
                              <div className="d-flex align-items-center">
                                <label className="checkbox checkbox-lg checkbox-inline flex-shrink-0 m-0 mr-2">
                                  <input
                                    type="checkbox"
                                    name="select"
                                    disabled
                                    // defaultValue={1}
                                  />
                                  <span />
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <span className="text-dark-75 font-weight-bold font-size-lg">
                                    Q {item.question_label}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.topics.map((topic, key) => (
                                <span key={key}>
                                  {topic.topicName}
                                  <br />
                                </span>
                              ))}
                            </td>
                            {/* TODO: trim this */}
                            {item.Student.slice(studentstart, studentend).map(
                              (student, colIndex) => (
                                <td
                                  key={colIndex}
                                  style={{ minWidth: "100px" }}
                                >
                                  <Form.Control
                                    ref={(input) => {
                                      inputRefs.current[rowIndex] =
                                        inputRefs.current[rowIndex] || [];
                                      inputRefs.current[rowIndex][colIndex] =
                                        input;
                                    }}
                                    type="number"
                                    min="-1"
                                    max={item.totalmarks}
                                    onChange={(e) =>
                                      updateStudentMarks(
                                        student.studentID,
                                        item.QuestionID,
                                        e.target.value,
                                        true
                                      )
                                    }
                                    onKeyUp={(e) => keyUpFunction(e)}
                                    onKeyDown={(e) =>
                                      handleKeyUp(e, rowIndex, colIndex)
                                    }
                                    id={`S${student.studentID}-EnterMarks-${item.QuestionID}`}
                                    className="form-control box-border-style"
                                    placeholder={`${student.studentName}`}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Maximum Marks is {item.totalmarks}
                                  </Form.Control.Feedback>
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="row mb-2">
                <div className="col-12 text-center">
                  <Button
                    variant="outline-success"
                    onClick={(e) => submit(e)}
                    // disabled={paper === undefined}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        size="md"
        show={MessageBool}
        onHide={() => setMessageBool(!MessageBool)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If a student did not attempt the question then enter <b>-1</b> in the input field. <br/>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setMessageBool(!MessageBool)} variant="danger">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
