import React from "react";
import moment from "moment";

export default function DaysCalculator(props) {
  var today = new Date();

  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  var dateTime = date + " " + time;

  const expireDate = props.data;
  const ExpiredDateFormatted = moment(expireDate).format("llll");
  const todayFormatted = moment(dateTime).format("llll");
  const RemainingDate = Math.round(
    (Date.parse(ExpiredDateFormatted) - Date.parse(todayFormatted)) /
      (1000 * 60 * 60 * 24)
  );

  return (
    <span
      className={`fs-7 text-${
        RemainingDate < 30
          ? "danger"
          : RemainingDate < 180
          ? "warning"
          : "success"
      } d-flex align-items-center`}
    >
      <span
        className={`bullet bullet-dot bg-${
          RemainingDate < 30
            ? "danger"
            : RemainingDate < 180
            ? "warning"
            : "success"
        } me-2`}
      />
      {RemainingDate} days left
    </span>
  );
}
