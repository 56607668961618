import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert, Form } from "react-bootstrap";
import { ChangeUserPassword } from "../../../actions/auth_action/auth";
import { Formik } from "formik";
import * as yup from "yup";
import Waiting from "../../functions/Waiting";

const schema = yup.object({
  old_password: yup.string().required("Please Type Current Password"),
  new_password: yup
    .string()
    .required("Enter Password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Strong Password Must Contain 8 Characters"
    ),
  cpassword: yup
    .string()
    .required("Please Confirm Password")
    .when("new_password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("new_password")],
          "Password and Confirm Password didn't match"
        ),
    }),
});

class ChangePassword extends Component {
  static propTypes = {
    isLoadingPassword: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
  };
  state = {
    show: true,
  };

  onsubmit = (value) => {
    const { old_password, new_password } = value;
    const data = { old_password, new_password };
    this.props.ChangeUserPassword(data);
  };

  render() {
    const loading = (
      <Fragment>
        <div className="container " style={{ marginTop: 180 }}>
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                <div className="">
                  <Waiting message="Please Wait While Password is Updated!" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
    const { show } = this.state;
    const { isLoadingPassword } = this.props;
    return (
      <div className="flex-row-fluid ml-lg-8">
        {/*begin::Card*/}
        {isLoadingPassword ? (
          loading
        ) : (
          <div className="card card-custom">
            <Formik
              validationSchema={schema}
              onSubmit={this.onsubmit}
              initialValues={{
                old_password: "",
                new_password: "",
                cpassword: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <>
                  {/*begin::Form*/}
                  <Form className="" noValidate onSubmit={handleSubmit}>
                    {/*begin::Header*/}
                    <div className="card-header py-3 ">
                      <div className="row pt-2">
                        <div className="col-6 text-left">
                          <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">
                              Change Password
                            </h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">
                              Change your account password
                            </span>
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="card-toolbar">
                            <button
                              type="submit"
                              className="btn btn-success mr-2"
                            >
                              Save Changes
                            </button>
                            <button type="reset" className="btn btn-secondary">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    <div className="card-body">
                      {/*begin::Alert*/}
                      {show ? (
                        <Alert
                          className="alert alert-custom alert-light-danger fade show mb-10"
                          onClose={() => this.setState({ show: !show })}
                          dismissible
                        >
                          <Alert.Heading>
                            <div className="alert-icon">
                              <span className="svg-icon svg-icon-3x svg-icon-danger">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x={0} y={0} width={24} height={24} />
                                    <circle
                                      fill="#000000"
                                      opacity="0.3"
                                      cx={12}
                                      cy={12}
                                      r={10}
                                    />
                                    <rect
                                      fill="#000000"
                                      x={11}
                                      y={10}
                                      width={2}
                                      height={7}
                                      rx={1}
                                    />
                                    <rect
                                      fill="#000000"
                                      x={11}
                                      y={7}
                                      width={2}
                                      height={2}
                                      rx={1}
                                    />
                                  </g>
                                </svg>
                                {/*end::Svg Icon*/}
                              </span>
                            </div>
                          </Alert.Heading>
                          <div className="alert-text font-weight-bold">
                            Configure user passwords to expire periodically.
                            Users will need warning that their passwords are
                            going to expire,
                            <br />
                            or they might inadvertently get locked out of the
                            system!
                          </div>
                        </Alert>
                      ) : (
                        ""
                      )}

                      {/*end::Alert*/}
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                          Current Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <Form.Control
                            type="password"
                            className="form-control form-control-lg form-control-solid mb-2"
                            defaultValue
                            name="old_password"
                            placeholder="Current password"
                            value={values.old_password}
                            isInvalid={!!errors.old_password}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            <span>{errors.old_password}</span>
                          </Form.Control.Feedback>
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "accounts/reset_password"
                            }
                            className="text-sm font-weight-bold"
                          >
                            Forgot password ?
                          </a>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                          New Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <Form.Control
                            className="form-control form-control-lg form-control-solid"
                            type="password"
                            name="new_password"
                            placeholder="Password"
                            value={values.new_password}
                            isInvalid={!!errors.new_password}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            <span>{errors.new_password}</span>
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                          Verify Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <Form.Control
                            className="form-control form-control-lg form-control-solid"
                            type="password"
                            name="cpassword"
                            placeholder="Confirm Password"
                            value={values.cpassword}
                            onChange={handleChange}
                            isInvalid={!!errors.cpassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpassword}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
            {/*end::Form*/}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoadingPassword: state.auth.isLoadingPassword,
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});
export default connect(mapStateToProps, { ChangeUserPassword })(ChangePassword);
