import {
  USER_GROUP_LOADING_FAIL,
  GET_USER_GROUP,
  USER_GROUP_LOADING,
} from "../actions/types";

const initialState = {
  UserGroup: [],
  isLoading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_GROUP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_GROUP:
      return {
        ...state,
        UserGroup: action.payload,
        isLoading: false,
      };
    case USER_GROUP_LOADING_FAIL:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
