import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import MixedWidget7 from "../KeenTheme/Widget/Mixed/MixedWidget7";
import AdvanceTableWidget2 from "../KeenTheme/Widget/Advance/AdvanceTableWidget2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSchoolById } from "../../actions/School/school_actions";

class DashboardHome extends Component {
  static propTypes = {
    getSchoolById: PropTypes.func.isRequired,
    SchoolById: PropTypes.array.isRequired,
    SchoolByIdLoading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
    if (localStorage.getItem("Schoolid") !== null) {
      const id = localStorage.getItem("Schoolid");
      const body = { id };
      this.props.getSchoolById(body);
    }
  }

  render() {
    // const { SchoolById, SchoolByIdLoading } = this.props;
    return (
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Aside*/}
          <Sidebar />
          {/*end::Aside*/}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Header*/}
            <NavbarKeen />
            {/*end::Header*/}
            {/*begin::Content*/}
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
            >
              {/*begin::Subheader*/}
              <Subheader name={"Dashboard"} />
              {/*end::Subheader*/}
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Statistics*/}
                  <div className="d-flex flex-sm-row flex-column">
                    {/*begin::Aside*/}
                    <div className="flex-md-row-auto w-sm-250px w-md-275px w-xl-325px">
                      <MixedWidget7 />
                      {/*begin::Nav Panel Widget 3*/}
                      {/*{SchoolByIdLoading === false &&*/}
                      {/*SchoolById !== undefined ? (*/}
                      {/*  <NavPanelWidget3 data={SchoolById} />*/}
                      {/*) : (*/}
                      {/*  "Loading Data"*/}
                      {/*)}*/}

                      {/*end::Nav Panel Widget 3*/}
                      {/*begin::List Widget 17*/}
                      {/*<ListWidget17 />*/}
                      {/*end::List Widget 17*/}
                      {/*begin::List Widget 9*/}
                      {/*<ListWidget9/>*/}
                      {/*end: List Widget 9*/}
                    </div>
                    {/*end::Aside*/}
                    {/*begin::Content*/}
                    <div className="flex-row-fluid ml-sm-8">
                      <div className="row">
                        <div className="col-xxl-12">
                          <AdvanceTableWidget2 />
                          {/*begin::Mixed Widget 2*/}
                          {/*<MixedWidget2 />*/}
                          {/*end::Mixed Widget 2*/}
                          {/*begin::Mixed Widget 13*/}
                          {/*<Mixed_Widget_13/>*/}
                          {/*end::Mixed Widget 13*/}
                          {/*begin::Mixed Widget 16*/}
                          {/*<MixedWidget16 />*/}
                          {/*end::Mixed Widget 16*/}
                          {/*begin::Mixed Widget 4*/}
                          {/*<Mixed_Widget_4/>*/}
                          {/*end::Mixed Widget 4*/}
                        </div>
                        <div className="col-xxl-6">
                          {/*begin::Mixed Widget 7*/}
                          {/*<MixedWidget7 />*/}
                          {/*end::Mixed Widget 7*/}
                          {/*<ListWidget9 />*/}
                        </div>
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Statistics*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  SchoolById: state.SchoolById.SchoolById,
  SchoolByIdLoading: state.SchoolById.isLoading,
});
export default connect(mapStateToProps, { getSchoolById })(DashboardHome);
