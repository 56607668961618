import axios from "axios";
import { GET_COUNTRY, GET_COUNTRY_FAILED, GET_COUNTRY_LOADING } from "../types";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

export const getCountry = () => (dispatch, getState) => {
  dispatch({ type: GET_COUNTRY_LOADING });
  axios
    .get("QLA/Country/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COUNTRY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_COUNTRY_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
