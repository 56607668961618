import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {Form,Button,Spinner,Tooltip,OverlayTrigger,Modal,} from "react-bootstrap";
import { getMockFilterSchool } from "../../actions/Mock/MockAction";
import { getClassSchool } from "../../actions/ClassActionFolder/ClassActions";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getQuestionPaperFilter } from "../../actions/QuestionPaper/QuestionPaperAction";
import { getQuestion } from "../../actions/Question/QuestionAction";
import { SHA256 } from "crypto-js";
import { getMockExamByUniqueID } from "../../actions/MockExamResultSave/MockResultSaveAction";
import { AddStudentMockResult } from "./AddStudentMockResult";
import { ViewStudentMockResult } from "./ViewStudentMockResult";
import Status from "../functions/Status";
import defaultRAG from "../../Components/DummyData/DefaultRAG.json";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { MockResultDelete } from "../../actions/MockExamResultSave/MockResultSaveAction";
import * as Yup from "yup";
import { CreateNewRAG } from "../../actions/NewRAGRating/NewRAGRatingAction";
import { NewGenerateTopicPaper } from "../../actions/MockExamResultSave/MockResultSaveAction";
import { EditStudentMockResult } from "./EditStudentMockResult";

const checkboxOptions = [
  { key: "Red", value: "Red", color: "danger", name: "Red" },
  { key: "Amber", value: "Amber", color: "warning", name: "Amber" },
  { key: "Green", value: "Green", color: "success", name: "Green" },
];
const OutputSelection = [
  {
    key: "QandA",
    value: "QandA",
    color: "warning",
    name: "Question and Solutions",
  },
  { key: "Question", value: "Question", color: "info", name: "Question" },
  { key: "Answer", value: "Answer", color: "dark", name: "Solutions" },
];
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "20px",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggable
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#645f5f" : "#d4d4d4",
  borderRadius: "20px",
  padding: grid,
  width: "auto",
});
const CardHeader = styled.div`
  font-weight: 500;
`;

class MockUpResultPage extends Component {
  static propTypes = {
    getMockFilterSchool: PropTypes.func,
    MockList: PropTypes.array,
    MockListLoading: PropTypes.bool,
    getClassSchool: PropTypes.func,
    class_by_School: PropTypes.array,
    getGradeYear: PropTypes.func,
    GradeYearList: PropTypes.array,
    GradeYearListLoading: PropTypes.bool,
    getSubject: PropTypes.func,
    subjectsList: PropTypes.array,
    subjectsListLoading: PropTypes.bool,
    getQuestionPaperFilter: PropTypes.func,
    QuestionPaper: PropTypes.array,
    QuestionPaperLoading: PropTypes.bool,
    QuestionsList: PropTypes.array,
    QuestionLoading: PropTypes.bool,
    getQuestion: PropTypes.func,
    getMockExamByUniqueID: PropTypes.func,
    NewMockResultList: PropTypes.array,
    NewMockResultLoading: PropTypes.bool,
    CreateNewRAG: PropTypes.func,
    MockResultDelete: PropTypes.func,
    NewGenerateTopicPaper: PropTypes.func,
    MockChecked: PropTypes.bool,
  };

  state = {
    MockExamValue: undefined,
    ClassValue: undefined,
    YearValue: undefined,
    SubjectValue: undefined,
    PaperValue: undefined,
    checked: false,
    close: false,
    Edit: false,
    CreateNew: false,
    CreateNewModal: false,
    RAG: false,
    ViewBool: false,
    GenerateBool: false,
    DeleteBool: false,
    defaultRAGState: undefined,
    uniqueList: [],
    DeleteID: undefined,
    uniqueID: undefined,
    Error_Upper: {
      id: undefined,
      error: undefined,
    },
    Error_Lower: {
      id: undefined,
      error: undefined,
    },
    ErrorGeneral: undefined,
    defaultBool: false,
    show: false,
    displayDropDown: true,
  };
  
  onSelectMockExam = (e) => {
    this.setState({
      MockExamValue: e.target.value,
      ClassValue: undefined,
      YearValue: undefined,
      SubjectValue: undefined,
      PaperValue: undefined,
    });
    const item1 = document.getElementById("selectClass");
    const item2 = document.getElementById("SelectYear");
    const item3 = document.getElementById("SelectSubject");
    const item4 = document.getElementById("SelectPaper");
    item1.value = item2.value = item3.value = item4.value = "dis";
  };

  onSelectClass = (e) => {
    this.setState({
      ClassValue: e.target.value,
      YearValue: undefined,
      SubjectValue: undefined,
      PaperValue: undefined,
    });
    const item2 = document.getElementById("SelectYear");
    const item3 = document.getElementById("SelectSubject");
    const item4 = document.getElementById("SelectPaper");
    item2.value = item3.value = item4.value = "dis";
  };

  onSelectYear = (e) => {
    this.setState({
      YearValue: e.target.value,
      SubjectValue: undefined,
      PaperValue: undefined,
    });
    const item3 = document.getElementById("SelectSubject");
    const item4 = document.getElementById("SelectPaper");
    item3.value = item4.value = "dis";
  };

  onSelectSubject = (e) => {
    this.setState({
      SubjectValue: e.target.value,
      PaperValue: undefined,
      checked: true,
    });
    const item4 = document.getElementById("SelectPaper");
    item4.value = "dis";
  };

  onSelectPaper = (e) => {
    this.setState({ PaperValue: e.target.value });
  };

  SearchExistingResults = (e) => {
    this.setState({ defaultBool: true, Edit: false, ViewBool: false });
    const data = this.state.PaperValue;
    this.props.getQuestion({ data });
    const MockExamId = this.props.MockExamId;
    const ClassId = this.props.ClassId;
    const GradeId = this.props.GradeId;
    const SubjectId = this.props.SubjectId;
    const School = localStorage.getItem("Schoolid");
    const PaperID = this.state.PaperValue;
    const inputText =
      "C" +
      String(ClassId) +
      "GY" +
      String(GradeId) +
      "Sub" +
      String(SubjectId) +
      "Sch" +
      String(School) +
      "M" +
      String(MockExamId) +
      "P" +
      String(PaperID);
    const hash = SHA256(inputText).toString();
    this.setState({ uniqueID: hash });
    this.props.getMockExamByUniqueID(hash);
    this.setState({ CreateNew: false, CreateNewModal: false });
  };

  CreateNew = () => {
    this.SearchExistingResults();
    this.setState({
      CreateNew: !this.state.CreateNew,
      CreateNewModal: !this.state.CreateNewModal,
    });
  };

  onChangeRAG = (e, id, type) => {
    const localRAG = [...this.state.defaultRAGState];
    const itemRAG = localRAG.find((item) => item.id === id);

    // Parse the input value as a number
    const newValue = parseFloat(e.target.value);

    if (!isNaN(newValue)) {
      if (type === "Upper") {
        // Check if the new Upper value is less than or equal to the Lower value of the previous item
        const previousItem = localRAG.find(
          (item) => item.id === (parseInt(id) - 1).toString()
        );
        if (!previousItem || newValue < previousItem.Lower) {
          itemRAG["Upper"] = newValue;
          const localError_Upper = {
            id: undefined,
            error: undefined,
          };
          this.setState({
            defaultRAGState: localRAG,
            Error_Upper: localError_Upper,
          });
        } else {
          const localError_Upper = {
            id: id,
            error: "Upper cannot be less than the Lower of the previous item",
          };
          this.setState({ Error_Upper: localError_Upper });
        }
      } else {
        if (newValue >= 0 && newValue <= itemRAG.Upper) {
          itemRAG["Lower"] = newValue;
          const Error_Lower = {
            id: undefined,
            error: undefined,
          };
          this.setState({
            defaultRAGState: localRAG,
            Error_Upper: Error_Lower,
          });
        } else {
          const Error_Lower = {
            id: id,
            error: "Lower cannot be less than 0 or greater than Upper",
          };
          this.setState({ Error_Upper: Error_Lower });
        }
      }
    } else {
      this.setState({ ErrorGeneral: "Invalid input. Please enter a number." });
    }
  };

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const uniqueList = reorder(
      this.state.uniqueList,
      result.source.index,
      result.destination.index
    );
    this.setState({ uniqueList });
  }

  onSaveRAG = (e) => {
    e.preventDefault();
    const { defaultRAGState } = this.state;
    const body = { defaultRAGState };
    this.props.CreateNewRAG(this.props.NewMockResultList.id, body);
  };

  onGenerateRAG = (values) => {
    const body = {
      uniqueList: this.state.uniqueList,
      values: values,
    };
    this.props.NewGenerateTopicPaper(body);
    this.setState({GenerateBool:!this.state.GenerateBool})
  };

  onDelete = () => {
    this.props.MockResultDelete(this.state.DeleteID);
    this.setState({ DeleteBool: !this.state.DeleteBool });
  };

  updateParentState = (newState) => {
    this.setState({ ViewBool: newState });
  };

  updateParentStateEdit = (newState) => {
    this.setState({ Edit: newState });
  };

  componentDidMount() {
    this.setState({ defaultRAGState: defaultRAG });
    this.props.getMockFilterSchool(localStorage.getItem("Schoolid"));
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
    this.props.getGradeYear();
    this.props.getSubject();
    if (
      this.props.MockExamId == undefined ||
      this.props.GradeId == undefined ||
      this.props.SubjectId == undefined ||
      this.props.ClassId == undefined
    ) {
      this.setState({ displayDropDown: false });
    }
    this.setState({
      MockExamValue: this.props.MockExamId,
      YearValue: this.props.GradeId,
      SubjectValue: this.props.SubjectId,
      ClassValue: this.props.ClassId,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.NewMockResultLoading !== this.props.NewMockResultLoading) {
      this.setState({ defaultBool: this.props.NewMockResultLoading });
    }
    if (
      prevState.MockExamId !== this.state.MockExamId ||
      prevState.SubjectValue !== this.state.SubjectValue
    ) {
      this.props.getQuestionPaperFilter(
        this.state.SubjectValue,
        this.state.MockExamValue
      );
    }
    if (prevProps.NewMockResultList !== this.props.NewMockResultList) {
      if (this.props.NewMockResultList !== undefined) {
        this.setState({ uniqueList: this.props.NewMockResultList.Topics });
      }
    }
  }

  render() {
    const {
      MockChecked,
      MockList,
      MockListLoading,
      class_by_School,
      GradeYearList,
      subjectsList,
      subjectsListLoading,
      QuestionPaper,
      QuestionPaperLoading,
      QuestionsList,
      NewMockResultList,
      NewMockResultLoading,
    } = this.props;
    
    const {
      displayDropDown,
      defaultBool,
      MockExamValue,
      ClassValue,
      YearValue,
      SubjectValue,
      PaperValue,
      checked,
      Edit,
      CreateNew,
      CreateNewModal,
      RAGBool,
      ViewBool,
      GenerateBool,
      DeleteBool,
      defaultRAGState,
      Error_Lower,
      Error_Upper,
      uniqueList,
      uniqueID,
    } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="row mt-5">
            {!displayDropDown ? (
              <>
                <div className="col-2 text-center">
                  <div className="d-flex flex-sm-row flex-column">
                    {MockListLoading ? (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp; Loading Mock Exam...
                      </Button>
                    ) : (
                      <Form.Select
                        className="form-control"
                        name="MockListS"
                        // defaultValue={MockListS}
                        disabled={MockListLoading}
                        value={MockExamValue}
                        onChange={(e) => this.onSelectMockExam(e)}
                      >
                        {MockList.length === 0 ? (
                          <option selected disabled>
                            ---No Data found---
                          </option>
                        ) : (
                          <option selected disabled>
                            ---Select Mock Exam---
                          </option>
                        )}
                        {MockList.length !== 0
                          ? MockList.map((arr, key) => (
                              <option
                                key={key}
                                value={arr.id}
                                className="text-capitalize"
                              >
                                {arr.exam.name}-{arr.name}-{arr.exam.exam_year}
                              </option>
                            ))
                          : ""}
                      </Form.Select>
                    )}
                  </div>
                </div>
                <div className="col-2 text-center">
                  <div className="d-flex flex-sm-row flex-column">
                    <Form.Select
                      // defaultValue={classId}
                      className="form-control"
                      id="selectClass"
                      name="class"
                      value={ClassValue}
                      disabled={MockExamValue === undefined}
                      onChange={(e) => this.onSelectClass(e)}
                    >
                      {class_by_School.length === 0 ? (
                        <option selected disabled>
                          ---No Data found---
                        </option>
                      ) : (
                        <option value={"dis"} selected disabled>
                          ---Select Class---
                        </option>
                      )}
                      {class_by_School.map((arr, key) => (
                        <option key={key} value={arr.id}>
                          {arr.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-2 text-center">
                  <div className="d-flex flex-sm-row flex-column">
                    <Form.Select
                      // defaultValue={yearGrade}
                      className="form-control"
                      name="SelectYear"
                      id="SelectYear"
                      disabled={ClassValue === undefined}
                      onChange={(e) => this.onSelectYear(e)}
                      value={YearValue}
                    >
                      {GradeYearList.length === 0 ? (
                        <option selected disabled>
                          ---No Data found---
                        </option>
                      ) : (
                        <option
                          value={"dis"}
                          disabled
                          selected
                          className="text-capitalize"
                        >
                          --Select Year--
                        </option>
                      )}
                      {GradeYearList.map((arr, key) => (
                        <option
                          key={key}
                          value={arr.id}
                          className="text-capitalize"
                        >
                          {arr.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-2 text-center">
                  <div className="d-flex flex-sm-row flex-column">
                    <Form.Select
                      // defaultValue={yearGrade}
                      className="form-control"
                      name="SelectSubject"
                      id="SelectSubject"
                      onChange={(e) => this.onSelectSubject(e)}
                      disabled={YearValue === undefined}
                      value={SubjectValue}
                    >
                      {subjectsList.length === 0 ? (
                        <option selected disabled>
                          ---No Data found---
                        </option>
                      ) : (
                        <option
                          value={"dis"}
                          disabled
                          selected
                          className="text-capitalize"
                        >
                          --Select subject--
                        </option>
                      )}
                      {subjectsList.map((arr, key) => (
                        <option key={key} value={arr.id}>
                          {arr.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </>
            ) : ("")}

            {displayDropDown ? <div className="col-2" /> : ""}
            <div className={displayDropDown ? "col-6" : `col-2 text-center`}>
              <div className="d-flex flex-sm-row flex-column">
                {QuestionPaperLoading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp; Loading Paper...
                  </Button>
                ) : (
                  <Form.Select
                    defaultValue={PaperValue}
                    disabled={SubjectValue === undefined}
                    className="form-control"
                    name="SelectPaper"
                    id="SelectPaper"
                    onChange={(e) => this.onSelectPaper(e)}
                  >
                    {QuestionPaper.length === 0 && checked ? (
                      <option selected disabled>
                        No Paper found
                      </option>
                    ) : (
                      <Fragment>
                        <option
                          value={"dis"}
                          disabled
                          selected
                          className="text-capitalize"
                        >
                          --Select Paper--
                        </option>
                        {QuestionPaper.map((arr, key) => (
                          <option key={key} value={arr.id}>
                            {arr.name}
                          </option>
                        ))}
                      </Fragment>
                    )}
                  </Form.Select>
                )}
              </div>
            </div>
            <div className={displayDropDown ? "col-4" : `col-2 text-center`}>
              <button
                className="btn btn-success"
                disabled={PaperValue===undefined||PaperValue===null}
                onClick={(e) => this.SearchExistingResults(e)}
              >
                <i className="flaticon2-search-1 icon-md" />
                Search
              </button>
            </div>
          </div>
          {(() => {
            if (defaultBool) {
              return (
                <div className="row mt-5">
                  <div className="col-12">
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-header border-0 py-5">
                        <h3 className="card-title  flex-column">
                          <span className="card-label font-weight-bolder text-center">
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Loading Please wait....
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            if (CreateNew && NewMockResultList === undefined) {
              return (
                <AddStudentMockResult
                  classid={ClassValue}
                  MockExamId={MockExamValue}
                  YearGrade={YearValue}
                />
              );
            } else if (ViewBool) {
              return (
                <ViewStudentMockResult
                  RAG={NewMockResultList.RAG_Rating.RAG}
                  classid={ClassValue}
                  MockExamId={MockExamValue}
                  YearGrade={YearValue}
                  uniqueID={uniqueID}
                  data={NewMockResultList}
                  uniqueList={uniqueList}
                  updateParentState={this.updateParentState}
                />
              );
            } else if (Edit) {
              return (
                <EditStudentMockResult
                  classid={ClassValue}
                  MockExamId={MockExamValue}
                  RAG={NewMockResultList.RAG_Rating.RAG}
                  YearGrade={YearValue}
                  uniqueID={uniqueID}
                  Result={NewMockResultList}
                  updateParentStateEdit={this.updateParentStateEdit}
                />
              );
            } else if (!MockChecked) {
              return (
                <div className="row mt-5">
                  <div className="col-12">
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-header border-0 py-5">
                        <h3 className="card-title  flex-column">
                          <span className="card-label font-weight-bolder text-center">
                            Select the paper and click search
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (NewMockResultList !== undefined) {
              return (
                <div className="row mt-5">
                  <div className="col-12">
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label font-weight-bolder text-dark" />
                          <span className="text-muted mt-3 font-weight-bold font-size-sm" />
                        </h3>
                        <div className="card-toolbar">
                          <button
                          disabled={PaperValue===undefined||PaperValue===null}
                            onClick={() => this.SearchExistingResults()}
                            className="btn btn-icon btn-sm btn-hover-light-primary mr-1"
                          >
                            <i className="ki ki-reload icon-nm" />
                          </button>
                        </div>
                      </div>

                      <div className="card-body pt-0 pb-3">
                        <div className="tab-content">
                          <div className="table-responsive">
                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                              <thead>
                                <tr className="text-left text-uppercase">
                                  <th
                                    style={{ minWidth: "10px" }}
                                    className="pl-7"
                                  >
                                    <span className="text-dark-75">ID</span>
                                  </th>
                                  <th style={{ minWidth: "80px" }}>Class</th>
                                  <th style={{ minWidth: "80px" }}>
                                    Mock Exam
                                  </th>
                                  <th style={{ minWidth: "80px" }}>
                                    Grade Year
                                  </th>
                                  <th style={{ minWidth: "80px" }}>Subject</th>
                                  <th style={{ minWidth: "80px" }}>Paper</th>
                                  <th style={{ minWidth: "80px" }}>Students</th>
                                  <th
                                    className="text-center"
                                    style={{ minWidth: "50px" }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    <p className="text-dark-75 font-weight-bolder mb-1">
                                      {NewMockResultList.id}
                                    </p>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                      {NewMockResultList.Class_Field.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                      {NewMockResultList.Mock_Exam.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                      {NewMockResultList.grade_year.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                      {NewMockResultList.Subject.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                      {NewMockResultList.Paper.name}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span className="text-dark-75 font-weight-bolder d-block">
                                        {/*TODO Add number of student in class*/}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      {/* View */}
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {NewMockResultList.RAG_Rating ===
                                            null ? (
                                              <span className="text-danger">
                                                RAG Rating missing
                                              </span>
                                            ) : (
                                              "Generate Topic Paper"
                                            )}
                                          </Tooltip>
                                        }
                                        placement="bottom"
                                      >
                                        <button
                                          onClick={
                                            NewMockResultList.RAG_Rating ===
                                            null
                                              ? () =>
                                                  console.log("add RAG rating")
                                              : () =>
                                                  this.setState({
                                                    ViewBool: !ViewBool,
                                                  })
                                          }
                                          className="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer"
                                        >
                                          <span className="svg-icon svg-icon-md svg-icon-primary svg-icon-2x mb-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x={0}
                                                  y={0}
                                                  width={24}
                                                  height={24}
                                                />
                                                <path
                                                  d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  opacity="0.3"
                                                />
                                                <path
                                                  d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      </OverlayTrigger>
                                      {/* Edit */}
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {NewMockResultList.RAG_Rating ===
                                            null ? (
                                              <span className="text-danger">
                                                RAG Rating missing
                                              </span>
                                            ) : (
                                              "Add/Edit Student Marks"
                                            )}
                                          </Tooltip>
                                        }
                                        placement="bottom"
                                      >
                                        <button
                                          onClick={
                                            NewMockResultList.RAG_Rating ===
                                            null
                                              ? () =>
                                                  console.log("add RAG rating")
                                              : () =>
                                                  this.setState({ Edit: !Edit })
                                          }
                                          className="btn btn-icon btn-light btn-hover-success btn-sm mx-3 cursor-pointer"
                                        >
                                          <span className="svg-icon svg-icon-md svg-icon-success mb-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x={0}
                                                  y={0}
                                                  width={24}
                                                  height={24}
                                                />
                                                <path
                                                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                                />
                                                <path
                                                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  opacity="0.3"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      </OverlayTrigger>
                                      {/* Generate Topic Paper */}
                                      {/* <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {NewMockResultList.RAG_Rating ===
                                            null ? (
                                              <span className="text-danger">
                                                RAG Rating missing
                                              </span>
                                            ) : (
                                              "Generate Topic Paper"
                                            )}
                                          </Tooltip>
                                        }
                                        placement="bottom"
                                      >
                                        <button
                                          onClick={
                                            NewMockResultList.RAG_Rating ===
                                            null
                                              ? () =>
                                                  console.log("add RAG rating")
                                              : () =>
                                                  this.setState({
                                                    GenerateBool: !GenerateBool,
                                                  })
                                          }
                                          className="btn btn-icon btn-light btn-hover-info btn-sm mx-3 cursor-pointer"
                                        >
                                          <span className="svg-icon svg-icon-md svg-icon-info mb-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path
                                                  d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  opacity="0.3"
                                                />
                                                <rect
                                                  fill="#000000"
                                                  x={6}
                                                  y={11}
                                                  width={9}
                                                  height={2}
                                                  rx={1}
                                                />
                                                <rect
                                                  fill="#000000"
                                                  x={6}
                                                  y={15}
                                                  width={5}
                                                  height={2}
                                                  rx={1}
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      </OverlayTrigger> */}
                                      {/* RAG rating */}
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            RAG Rating
                                          </Tooltip>
                                        }
                                        placement="bottom"
                                      >
                                        <button
                                          onClick={() =>
                                            this.setState({ RAGBool: !RAGBool })
                                          }
                                          className="btn btn-icon btn-light btn-hover-warning btn-sm mr-3 cursor-pointer"
                                        >
                                          <span className="svg-icon svg-icon-md svg-icon-warning mb-2">
                                            {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Shopping\Sale1.svg*/}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x={0}
                                                  y={0}
                                                  width={24}
                                                  height={24}
                                                />
                                                <path
                                                  d="M16.0322024,5.68722152 L5.75790403,15.945742 C5.12139076,16.5812778 5.12059836,17.6124773 5.75613416,18.2489906 C5.75642891,18.2492858 5.75672377,18.2495809 5.75701875,18.2498759 L5.75701875,18.2498759 C6.39304347,18.8859006 7.42424328,18.8859006 8.060268,18.2498759 C8.06056298,18.2495809 8.06085784,18.2492858 8.0611526,18.2489906 L18.3196731,7.9746922 C18.9505124,7.34288268 18.9501191,6.31942463 18.3187946,5.68810005 L18.3187946,5.68810005 C17.68747,5.05677547 16.6640119,5.05638225 16.0322024,5.68722152 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                />
                                                <path
                                                  d="M9.85714286,6.92857143 C9.85714286,8.54730513 8.5469533,9.85714286 6.93006028,9.85714286 C5.31316726,9.85714286 4,8.54730513 4,6.92857143 C4,5.30983773 5.31316726,4 6.93006028,4 C8.5469533,4 9.85714286,5.30983773 9.85714286,6.92857143 Z M20,17.0714286 C20,18.6901623 18.6898104,20 17.0729174,20 C15.4560244,20 14.1428571,18.6901623 14.1428571,17.0714286 C14.1428571,15.4497247 15.4560244,14.1428571 17.0729174,14.1428571 C18.6898104,14.1428571 20,15.4497247 20,17.0714286 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                          </span>
                                        </button>
                                      </OverlayTrigger>
                                      {/* Delete */}
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            Delete
                                          </Tooltip>
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              DeleteBool: !DeleteBool,
                                              DeleteID: NewMockResultList.id,
                                            })
                                          }
                                          className="btn btn-icon btn-light btn-hover-danger btn-sm cursor-pointer"
                                        >
                                          <span className="svg-icon svg-icon-md svg-icon-danger mb-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x={0}
                                                  y={0}
                                                  width={24}
                                                  height={24}
                                                />
                                                <path
                                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                />
                                                <path
                                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="row mt-5">
                  <div className="col-12">
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label font-weight-bolder">
                            Oops! <span className="text-danger">No</span> Mock
                            exam result found!
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <button
                            onClick={() => this.CreateNew()}
                            className="btn btn-sm btn-warning"
                          >
                            <i className="ki ki-plus icon-md"></i>Create New
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })()}
        </div>
        {/* Already Exist */}
        <Modal
          centered
          size="md"
          show={CreateNewModal && NewMockResultList !== undefined}
          onHide={() => this.setState({ CreateNewModal: !CreateNewModal })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Already Exist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-danger">
              <b>Result Already Exist:</b> You create another mockup result
            </p>
          </Modal.Body>
        </Modal>
        {/* RAG Rating */}
        <Modal
          centered
          size="md"
          show={RAGBool}
          onHide={() => this.setState({ RAGBool: !RAGBool })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add RAG Rating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Upper Boundary</th>
                  <th scope="col">Lower Boundary</th>
                </tr>
              </thead>
              <tbody>
                {defaultRAGState?.map((rating, key) => (
                  <tr key={key}>
                    <th>
                      <Status data={rating.color} />
                    </th>
                    <td>
                      <Form>
                        <Form.Control
                          size="sm"
                          onChange={(e) =>
                            this.onChangeRAG(e, rating.id, "Upper")
                          }
                          name="Marks_Upper"
                          defaultValue={rating.Upper}
                          type="number"
                          className="form-control box-border-style"
                          placeholder={`Upper Rating for ${rating.color} in %`}
                        />
                        {Error_Upper.id === rating.id ? (
                          <span className="text-danger">
                            {Error_Upper.error}
                          </span>
                        ) : (
                          ""
                        )}
                      </Form>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        name="Marks_lower"
                        onChange={(e) =>
                          this.onChangeRAG(e, rating.id, "Lower")
                        }
                        defaultValue={rating.Lower}
                        type="number"
                        className="form-control box-border-style"
                        placeholder={`lower Rating for ${rating.color} in %`}
                      />
                      {Error_Lower.id === rating.id ? (
                        <span className="text-danger">{Error_Lower.error}</span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row text-center">
              <div className="col-12">
                <p className="text-muted">
                  You can <b>edit / update</b> this RAG rating or use{" "}
                  <b>default</b> rating.
                </p>
                <Button
                  onClick={(e) => this.onSaveRAG(e)}
                  variant="outline-success"
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Generate topic paper */}
        <Modal
          centered
          size="lg"
          show={GenerateBool}
          onHide={() => this.setState({ GenerateBool: !GenerateBool })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Generate topic paper</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={Yup.object({
                TopicNumbers: Yup.number()
                  .positive()
                  .required(`Enter Maximum Topics`)
                  .min(1, "It should be Greater than 1")
                  .max(
                    uniqueList.length,
                    `It Must be lesser than ${uniqueList.length}`
                  ),
                checkboxOption: Yup.array().min(1, "Please Select one"),
                OutputSelection: Yup.array().min(1, "Please Select one"),
              })}
              onSubmit={(values) => this.onGenerateRAG(values)}
              initialValues={{
                TopicNumbers: 5,
                checkboxOption: ["Red"],
                OutputSelection: ["QandA"],
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-4 col-form-label">
                        Topics<span className="text-danger">*</span>
                      </label>
                      <div className="col-8 col-form-label">
                        <Form.Control
                          type="number"
                          className="form-control formW"
                          placeholder="Enter Topics Numbers"
                          name="TopicNumbers"
                          value={values.TopicNumbers}
                          onChange={handleChange}
                          isInvalid={!!errors.TopicNumbers}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.TopicNumbers}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-4 col-form-label">
                        Select boundary for marks..
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-8 col-form-label">
                        <div className="checkbox-inline">
                          {checkboxOptions.map((arr, key) => (
                            <label
                              key={key}
                              className={`checkbox checkbox-outline checkbox-${arr.color}`}
                            >
                              <Form.Control
                                defaultChecked={arr.value === "Red"}
                                type="checkbox"
                                name="checkboxOption"
                                value={arr.value}
                                onChange={handleChange}
                                isInvalid={!!errors.checkboxOption}
                              />
                              <span />
                              {arr.value}
                            </label>
                          ))}
                        </div>
                        {errors.checkboxOption ? (
                          <span
                            className="form-text"
                            style={{ color: "#F64E60" }}
                          >
                            {errors.checkboxOption}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-4 col-form-label">
                        Output Selection
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-8 col-form-label">
                        <div className="checkbox-inline">
                          {OutputSelection.map((arr, key) => (
                            <label
                              key={key}
                              className={`checkbox checkbox-outline checkbox-${arr.color}`}
                            >
                              <Form.Control
                                defaultChecked={arr.value === "QandA"}
                                type="checkbox"
                                name="OutputSelection"
                                value={arr.value}
                                onChange={handleChange}
                                isInvalid={!!errors.checkboxOption}
                              />
                              <span />
                              {arr.name}
                            </label>
                          ))}
                        </div>
                        {errors.OutputSelection ? (
                          <span
                            className="form-text"
                            style={{ color: "#F64E60" }}
                          >
                            {errors.OutputSelection}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <span className="form-text text-muted mt-2 ml-2">
                    <b>Rearrange Topics (if Required)</b>
                  </span>
                  <DragDropContext
                    onDragEnd={(result) => this.onDragEnd(result, uniqueList)}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {uniqueList.map((arr, key, array) => (
                            <Draggable
                              key={arr.id}
                              draggableId={arr.id.toString()}
                              index={key}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    ),
                                    margin:
                                      key === array.length - 1
                                        ? "0 0 0 0"
                                        : `0 0 ${grid}px 0`,
                                  }}
                                >
                                  <CardHeader>
                                    {arr.id} - {arr.name}
                                  </CardHeader>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="row my-5 text-center">
                    <div className="col-12">
                      <Button type="submit" variant="outline-success">
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        {/* Delete */}
        <Modal
          centered
          size="md"
          show={DeleteBool}
          onHide={() => this.setState({ DeleteBool: !DeleteBool })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Mock Exam Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-danger">
              <b>Warning:</b> You are about to delete this mock exam result,
              this action is irreversible.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ DeleteBool: !DeleteBool })}
              variant="outline-success"
            >
              Close
            </Button>
            <Button onClick={() => this.onDelete()} variant="outline-danger">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  MockList: state.MockList.MockList,
  MockListLoading: state.MockList.isLoading,
  class_by_School: state.class_by_School.class_by_School,
  GradeYearList: state.GradeYearList.GradeYearList,
  GradeYearListLoading: state.GradeYearList.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  subjectsListLoading: state.subjectsList.isLoading,
  QuestionPaper: state.QuestionPaper.QuestionPaper,
  QuestionPaperLoading: state.QuestionPaper.isLoading,
  QuestionsList: state.QuestionsList.QuestionsList,
  QuestionLoading: state.QuestionsList.isLoading,
  NewMockResultList: state.NewMockResultList.NewMockResultList,
  NewMockResultLoading: state.NewMockResultList.isLoading,
  MockChecked: state.NewMockResultList.isChecked,
});
export default connect(mapStateToProps, {
  getMockFilterSchool,
  getClassSchool,
  getGradeYear,
  getSubject,
  getQuestionPaperFilter,
  getQuestion,
  getMockExamByUniqueID,
  CreateNewRAG,
  MockResultDelete,
  NewGenerateTopicPaper,
})(MockUpResultPage);
