import {
  SET_STUDENT_MARKS_LOADING,
  ADD_STUDENT_MARKS,
  GET_STUDENT_MARKS,
  UPDATE_STUDENT_MARKS,
  DELETE_STUDENT_MARKS_LOADING,
  SET_STUDENT_MARKS_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  StudentMarksList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_MARKS:
      return {
        ...state,
        StudentMarksList: action.payload,
        isLoading: false,
      };
    case DELETE_STUDENT_MARKS_LOADING:
      return {
        ...state,
        StudentMarksList: state.StudentMarksList.filter(
          (about) => about.id !== action.payload
        ),
        isLoading: false,
      };
    case ADD_STUDENT_MARKS:
      return {
        ...state,
        StudentMarksList: [...state.StudentMarksList, action.payload],
        isLoading: false,
      };

    case UPDATE_STUDENT_MARKS: {
      return {
        ...state,
        StudentMarksList: state.StudentMarksList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
        isLoading: false,
      };
    }

    case SET_STUDENT_MARKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case SET_STUDENT_MARKS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
