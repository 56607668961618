import React, { Component } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth_action/auth";
import { getSubscription } from "../../actions/Subscription/Subscription_Action";
import WordFinder from "./WordFinder";
// import logo from "../../media/Logo/logo.png";
// import QuickPanel from "./QuickPanel";
// import QuickActions from "./QuickActions";
// import Cart from "./Cart";
// import Notifications from "./Notifications";
// import Search from "./Search";
// import Chat from "./Widget/Chat";
// import CryptoJS from 'crypto-js'
class NavbarKeen extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    getSubscription: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    await getSubscription();
  }

  render() {
    return (
      <div id="kt_header" className="header header-fixed">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/*begin::Header Menu Wrapper*/}
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            {/*begin::Header Menu*/}
            <div
              id="kt_header_menu"
              className="header-menu header-menu-mobile header-menu-layout-default"
            >
              <Navbar expand="lg">
                <Container>
                  {this.props.logo === true ? (
                    <Navbar.Brand href="/">
                      {/*<img*/}
                      {/*  alt=""*/}
                      {/*  src={logo}*/}
                      {/*  width="30"*/}
                      {/*  height="30"*/}
                      {/*  className="d-inline-block align-top"*/}
                      {/*/>{" "}*/}
                      Target QLA.
                    </Navbar.Brand>
                  ) : (
                    ""
                  )}
                  {this.props.logo ? (
                    ""
                  ) : (
                    <>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      {/*<Navbar.Collapse id="basic-navbar-nav">*/}
                      {/*  <Nav className="me-auto">*/}
                      {/*    <Nav.Link href="/">Home</Nav.Link>*/}
                      {/*    <Nav.Link href="/Dashboard">Dashboard</Nav.Link>*/}
                      {/*    <Nav.Link href="/Subscription">Subscription</Nav.Link>*/}
                      {/*    <Nav.Link href="/Students-list">Students List</Nav.Link>*/}
                      {/*    <Nav.Link href="/Reports">Reports</Nav.Link>*/}
                      {/*    <Nav.Link href="/Marks">Marks</Nav.Link>*/}
                      {/*    <Nav.Link href="/Question-Paper">*/}
                      {/*      Question Papers*/}
                      {/*    </Nav.Link>*/}

                      {/*    <NavDropdown title="More" id="basic-nav-dropdown">*/}
                      {/*      <NavDropdown.Item href="/About-us">*/}
                      {/*        About us*/}
                      {/*      </NavDropdown.Item>*/}
                      {/*      <NavDropdown.Item href="/Privacy">*/}
                      {/*        Privacy*/}
                      {/*      </NavDropdown.Item>*/}
                      {/*      <NavDropdown.Item href="/Team">Team</NavDropdown.Item>*/}
                      {/*      <NavDropdown.Item href="/Terms-&-Conditions">*/}
                      {/*        Terms & Conditions*/}
                      {/*      </NavDropdown.Item>*/}
                      {/*    </NavDropdown>*/}
                      {/*  </Nav>*/}
                      {/*</Navbar.Collapse>*/}
                    </>
                  )}
                </Container>
              </Navbar>
            </div>
            {/*end::Header Menu*/}
          </div>
          {/*end::Header Menu Wrapper*/}
          {/*begin::Topbar*/}
          <div className="topbar">
            {/*begin::Search*/}
            {/*<Search/>*/}
            {/*end::Search*/}
            {/*begin::Notifications*/}
            {/*<Notifications/>*/}
            {/*end::Notifications*/}
            {/*begin::Quick Actions*/}
            {/*<QuickActions/>*/}
            {/*end::Quick Actions*/}
            {/*begin::Cart*/}
            {/*<Cart/>*/}
            {/*end::Cart*/}

            {/*begin::Quick panel*/}
            {/*<QuickPanel/>*/}
            {/*end::Quick panel*/}

            {/*begin::Chat*/}
            {/*<Chat/>*/}
            {localStorage.getItem("token") !== null ? (
              <div className="topbar-item">
                <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                  <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                    Hi,
                  </span>
                  <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 text-capitalize">
                    {this.props.auth.user.user.firstname}
                  </span>
                  <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                    <span className="symbol-label font-size-h5 font-weight-bold">
                      {localStorage.getItem("Name") !== null ? (
                        <WordFinder
                          data={this.props.auth.user.user.firstname}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <div className="btn  w-auto btn-clean d-flex align-items-center btn-lg px-2">
                    <Button href="/Signup">Create</Button>
                  </div>
                </div>
                <div>
                  <div className="btn  w-auto btn-clean d-flex align-items-center btn-lg px-2">
                    <Button href="/Login">Login</Button>
                  </div>
                </div>
              </>
            )}
            {localStorage.getItem("token") !== null ? (
              <div>
                <div
                  className="btn  w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle"
                >
                  <Button variant="outline-warning" onClick={this.props.logout}>
                    Logout
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {/*end::User*/}
          </div>
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout, getSubscription })(
  NavbarKeen
);
