import {
  DELETE_FEEDBACK,
  ADD_FEEDBACK,
  GET_FEEDBACK,
  UPDATE_FEEDBACK,
} from "../actions/types";

const initialState = {
  FeedbackArray: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACK:
      return {
        ...state,
        FeedbackArray: action.payload,
      };
    case DELETE_FEEDBACK:
      return {
        ...state,
        FeedbackArray: state.FeedbackArray.filter(
          (about) => about.id !== action.payload
        ),
      };
    case ADD_FEEDBACK:
      return {
        ...state,
        FeedbackArray: [...state.FeedbackArray, action.payload],
      };
    case UPDATE_FEEDBACK:
      return {
        ...state,
        FeedbackArray: state.FeedbackArray.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    default:
      return state;
  }
}
