import React, { Component, Fragment } from "react";
import { Spinner, Modal, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import "../../style/KeenTheme/plugins/global/plugins.bundle.css";
import "../../style/KeenTheme/plugins/custom/prismjs/prismjs.bundle.css";
import "../../style/KeenTheme/css/style.bundle.css";
import "../../style/KeenTheme/css/themes/layout/header/base/light.css";
import "../../style/KeenTheme/css/themes/layout/header/menu/light.css";
import "../../style/KeenTheme/css/themes/layout/brand/dark.css";
import "../../style/KeenTheme/css/themes/layout/aside/dark.css";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DobCal from "../Calculator/DobCal";
import { addListStudents } from "../../actions/Students/StudentsActions";
import { getStudentUser } from "../../actions/Students/StudentsActions";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import { getTeacher } from "../../actions/Teacher/TeacherAction";
import {updatePatchStudents,deleteStudents,
} from "../../actions/Students/StudentsActions";
import { Formik } from "formik";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getLevel } from "../../actions/Level/LevelAction";
import { updateClass } from "../../actions/ClassActionFolder/ClassActions";
import * as yup from "yup";
import { getClassSchool } from "../../actions/ClassActionFolder/ClassActions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";

const animatedComponents = makeAnimated();

const schema = yup.object({
  firstname: yup.string().required("Firstname required"),
  surname: yup.string().required("Surname required"),
  class: yup.string().required("Enter School"),
});
const GenderValues = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

class StudentsList extends Component {
  static propTypes = {
    studentList: PropTypes.array.isRequired,
    studentLoading: PropTypes.bool.isRequired,
    getGradeYear: PropTypes.func,
    GradeYearList: PropTypes.array,
    GradeYearListLoading: PropTypes.bool,
    updatePatchStudents: PropTypes.func,
    deleteStudents: PropTypes.func,
    addListStudents: PropTypes.func,
    getClassSchool: PropTypes.func,
    class_by_School: PropTypes.array,
    getSubject: PropTypes.func,
    subjectsList: PropTypes.array,
    subjectsListLoading: PropTypes.bool,
    getLevel: PropTypes.func,
    LevelList: PropTypes.array,
    LevelListLoading: PropTypes.bool,
    getStudentUser: PropTypes.func,
    getTeacher: PropTypes.func,
    Teacher_List: PropTypes.array,
    Teacher_ListLoading: PropTypes.bool,
    updateClass: PropTypes.func,
    class_loading: PropTypes.bool,
  };
  state = {
    reverse: false,
    options: [],
    year_grade: "",
    studentstart: 0,
    studentend: 10,
    ShowAll: [],
    Students: [],
    deleteModel: false,
    deleteId: null,
    showEdit: false,
    StudentArray: [],
    image: null,
    profilePic: null,
    StudentEditor: "list", //list,class,multi
    totalRows: 10,
    studentsdata: [],
    CSVFile: undefined,
    SelectedClass: "",
    BoolYearGrade: true,
    selectedClass: "",
    classData: undefined,
    classFilter: false,
    studentRef: "Auto",
    yearGradeOptions: [],
    SelectYearGrade: "",
    Error_grade: false,
    Error_class: false,
    Fields: "All",
  };
  resetValues = () => {
    this.setState({
      studentstart: 0,
      studentend: 10,
      ShowAll: [],
    });
  };
  sortArray = (value1, value2) => {
    if (this.state.reverse) {
      return value2 - value1;
    } else {
      return value1 - value2;
    }
  };
  GradeYearOptions = () => {
    const opts = this.props.GradeYearList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ options: opts });
  };
  updateGradeYear = (Studentid, yearGradeValue) => {
    const year_grade = yearGradeValue;
    const body = { year_grade };
    this.props.updatePatchStudents(Studentid, body);
  };
  TypeSearch = (e) => {
    if (e.target.value === "") {
      this.setState({ Students: this.props.studentList });
      this.resetValues();
    } else {
      const query = e.target.value;
      const searchAttributes = [
        "Email",
        "Gender",
        "firstname",
        "surname",
        "generated_email",
        "student_ref_number",
        "dob",
      ];
      const updatedList = this.props.studentList.filter((user) => {
        return searchAttributes.some((attribute) => {
          if (
            !(user[attribute] === "") ||
            user[attribute] === undefined ||
            user[attribute] === null
          ) {
            return user[attribute]
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase());
          }
        });
      });
      this.setState({ Students: updatedList });
      this.resetValues();
    }
  };
  sliceCalculator = (e) => {
    let value = e.target.value;
    if (value === "showall") {
      this.setState({
        studentstart: 0,
        studentend: Math.ceil(this.state.Students.length),
      });
    } else {
      this.setState({
        studentstart: value * 10,
        studentend: (value + 1) * 10,
      });
    }
  };
  OptionSet = () => {
    let postsPerPage = 10;
    let pageNumbers = [];
    let totalPosts = this.state.Students.length;
    if (totalPosts > 0) {
      for (let i = 1; i < Math.ceil(totalPosts / postsPerPage); i++) {
        let temp1 = {
          value: i,
          label: `${i * 10} to ${(i + 1) * 10}`,
        };
        pageNumbers.push(temp1);
      }
      this.setState({ ShowAll: pageNumbers });
    }
  };
  deleteStudent = (id) => {
    this.setState({ deleteModel: true, deleteId: id });
  };
  deleteUserfun = (e) => {
    e.preventDefault();
    this.props.deleteStudents(this.state.deleteId);
    this.setState({ deleteModel: false, deleteId: null });
  };
  EditStudent = (StudentArray) => {
    this.setState({ showEdit: true, StudentArray: StudentArray });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ image: URL.createObjectURL(event.target.files[0]) });
      this.setState({ profilePic: event.target.files[0] });
    }
  };
  updateStudent = (values) => {
    const { userid, firstname, surname, Email, year_grade, Gender, dob } =
      values;
    let formData = new FormData();
    if (typeof year_grade !== "object") {
      formData.append("year_grade", year_grade);
    }
    if (typeof Gender !== "object") {
      formData.append("Gender", Gender);
    }
    formData.append("firstname", firstname);
    formData.append("surname", surname);
    formData.append("Email", Email);
    formData.append("dob", dob);
    formData.append("Image", this.state.profilePic);
    this.props.updatePatchStudents(userid, formData);
    this.setState({ showEdit: false, image: null, profilePic: null });
  };
  Gender = (e) => {
    if (e.target.value === "Both") {
      this.setState({ Students: this.props.studentList });
      this.resetValues();
    } else {
      const updatedList = this.props.studentList.filter(
        (user) => user.Gender === e.target.value
      );
      this.setState({ Students: updatedList });
      this.resetValues();
    }
  };
  YearGrade = (e) => {
    if (e.target.value === "reset") {
      this.setState({ Students: this.props.studentList });
      this.resetValues();
    } else {
      const updatedList = this.props.studentList.filter(
        (user) => user.year_grade.id === parseInt(e.target.value)
      );
      this.setState({ Students: updatedList });
      this.resetValues();
    }
  };
  ChangeRows = (e, value) => {
    e.preventDefault();
    if (value === "minus") {
      if (this.state.totalRows > 1) {
        this.setState({ totalRows: this.state.totalRows - 1 });
      }
    } else if (value === "plus") {
      this.setState({ totalRows: this.state.totalRows + 1 });
    } else if (value === "input") {
      if (e.target.value > 0 && e.target.value <= 99) {
        this.setState({ totalRows: parseInt(e.target.value) });
      }
    }
  };
  onChangeRow = (e, index) => {
    this.state.studentsdata[index][e.target.name] = e.target.value;
    this.state.studentsdata[index]["null"] = false;
    this.setState({ studentsdata: this.state.studentsdata });
  };
  onChangeDropdown = (e, event, index) => {
    this.state.studentsdata[index][event.name] = e.value;
    this.state.studentsdata[index]["null"] = false;
    this.setState({ studentsdata: this.state.studentsdata });
  };
  ImportCSV = (e) => {
    e.preventDefault();
    this.setState({ CSVFile: e.target.files[0] });
  };
  SaveCSV = (e) => {
    e.preventDefault();
    if (this.state.SelectYearGrade !== "" && this.state.SelectedClass !== "") {
      let formData = new FormData();
      formData.append("CVSFILE", this.state.CSVFile);
      formData.append("type", "filetype");
      formData.append("school", localStorage.getItem("Schoolid"));
      formData.append("class", this.state.SelectedClass);
      formData.append("year_grade", this.state.SelectYearGrade);
      this.setState({ Error_grade: false, Error_class: false });
      this.props.addListStudents(formData, "filetype");
    }
    if (this.state.SelectYearGrade == "") {
      this.setState({ Error_grade: true });
    }
    if (this.state.SelectedClass == "") {
      this.setState({ Error_class: true });
    }
  };
  generateCSV = (data, headers) => {
    const csvRows = [];
    // Add headers
    csvRows.push(headers.join(","));
    // Add data rows
    data.forEach((row) => {
      const values = headers.map((header) => {
        const cellValue = row[header];
        // Handle special characters and escape values if needed
        return cellValue;
      });
      csvRows.push(values.join(","));
    });

    // Combine rows into a single string
    return csvRows.join("\n");
  };
  downloadCSV(e) {
    const TID = toast.loading("Generating CSV...⏳");
    e.preventDefault();
    this.setState({ BoolYearGrade: !this.state.BoolYearGrade });
    let data = "";
    let headers = "";
    if (this.state.studentRef === "Manual") {
      data = [
        {
          firstname: "Required",
          surname: "Required",
          Email: "",
          dob: "",
          Gender: "",
          year_grade: this.state.SelectedClass,
          student_ref_number: "Required",
        },
      ];
      headers = [
        "firstname",
        "surname",
        "Email",
        "dob",
        "Gender",
        "student_ref_number",
      ];
    } else {
      data = [
        {
          firstname: "Required",
          surname: "Required",
          Email: "",
          dob: "",
          Gender: "",
          year_grade: this.state.SelectedClass,
        },
      ];
      headers = ["firstname", "surname", "Email", "dob", "Gender"];
    }

    const csvContent = this.generateCSV(data, headers);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const date = new Date().getTime();
    a.download = String(date) + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    toast.success("CSV Generated!", { id: TID });
  }
  getYearGrades = (e) => {
    e.preventDefault();
    this.setState({ BoolYearGrade: !this.state.BoolYearGrade });
  };
  setClass = (e) => {
    this.setState({ SelectedClass: e.value });
  };
  setYearGrade = (e) => {
    this.setState({ SelectYearGrade: e.value });
  };
  RowChangeStudent = (e) => {
    const existingData = [...this.state.studentsdata];
    const newRowsCount = this.state.totalRows - existingData.length;
    if (newRowsCount > 0) {
      const newStudents = Array.from(Array(newRowsCount).keys()).map((x) => ({
        temp_id: uuidv4(),
        Image: "",
        firstname: "",
        surname: "",
        Email: "",
        dob: "2000-01-01",
        Gender: "",
        class: "",
        year_grade: "",
        school: localStorage.getItem("Schoolid"),
      }));
      const updatedStudentsData = [...existingData, ...newStudents];
      this.setState({ studentsdata: updatedStudentsData });
    } else if (newRowsCount < 0) {
      let temp = this.state.studentsdata;
      this.setState({ studentsdata: temp.slice(0, newRowsCount) });
    }
  };
  ReFresh = () => {
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
  };
  AddStudentsList = () => {
    if (this.state.SelectYearGrade !== "" && this.state.SelectedClass !== "") {
      const localupdate = this.state.studentsdata.map((item) => ({
        ...item,
        class: this.state.SelectedClass,
        year_grade: this.state.SelectYearGrade,
      }));
      this.setState({ Error_grade: false, Error_class: false });
      this.props.addListStudents(localupdate, "list");
    }
    if (this.state.SelectYearGrade == "") {
      this.setState({ Error_grade: true });
    }
    if (this.state.SelectedClass == "") {
      this.setState({ Error_class: true });
    }
  };
  updateClass = (values) => {
    this.props.updateClass(this.state.selectedClass, values);
  };
  updatedata = () => {
    const filterdata = this.props.class_by_School.filter((item) => {
      return item.id === this.state.selectedClass;
    });
    this.setState({ classData: filterdata[0], classFilter: true });
  };
  updateGradeYear = () => {
    const selectedClassData = this.props.class_by_School.filter(
      (arr) => arr.id === this.state.SelectedClass
    );
    const transformedData = selectedClassData.flatMap((arr) =>
      arr.year_grade.map((grades) => ({
        value: grades.id,
        label: grades.name,
      }))
    );
    this.setState({ yearGradeOptions: transformedData });
  };
  componentDidMount() {
    this.props.getTeacher();
    this.props.getStudentUser(localStorage.getItem("Schoolid"));
    this.props.getGradeYear();
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
    const studentsdata = Array.from(Array(this.state.totalRows).keys()).map(
      (x) => ({
        temp_id: uuidv4(),
        Image: "",
        firstname: "",
        surname: "",
        Email: "",
        dob: "2000-01-01",
        Gender: "",
        class: "",
        year_grade: "",
        school: localStorage.getItem("Schoolid"),
        null: true,
      })
    );
    this.setState({ studentsdata: studentsdata });
    this.props.getSubject();
    this.props.getLevel();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.GradeYearList !== this.props.GradeYearList) {
      this.GradeYearOptions();
    }
    if (prevProps.studentList !== this.props.studentList) {
      this.setState({ Students: this.props.studentList });
      this.resetValues();
    }
    if (prevState.Students !== this.state.Students) {
      this.OptionSet();
    }
    if (prevState.totalRows !== this.state.totalRows) {
      this.RowChangeStudent();
    }
    if (prevState.selectedClass !== this.state.selectedClass) {
      this.setState({ classFilter: false }, () => this.updatedata());
    }
    if (prevState.SelectedClass !== this.state.SelectedClass) {
      this.updateGradeYear();
    }
    if (prevState.studentRef !== this.state.studentRef) {
      let studentsdata = "";
      if (this.state.studentRef === "Manual") {
        studentsdata = Array.from(Array(this.state.totalRows).keys()).map(
          (x) => ({
            temp_id: uuidv4(),
            Image: "",
            firstname: "",
            surname: "",
            Email: "",
            dob: "2000-01-01",
            Gender: "",
            class: "",
            school: localStorage.getItem("Schoolid"),
            null: true,
            year_grade: "",
            student_ref_number: null,
          })
        );
      } else {
        studentsdata = Array.from(Array(this.state.totalRows).keys()).map(
          (x) => ({
            temp_id: uuidv4(),
            Image: "",
            firstname: "",
            surname: "",
            Email: "",
            dob: "2000-01-01",
            Gender: "",
            class: "",
            year_grade: "",
            school: localStorage.getItem("Schoolid"),
            null: true,
          })
        );
      }
      this.setState({ studentsdata: studentsdata });
    }
  }

  render() {
    const {
      reverse,
      classFilter,
      options,
      ShowAll,
      studentstart,
      studentend,
      Students,
      deleteModel,
      StudentArray,
      showEdit,
      image,
      StudentEditor,
      totalRows,
      SelectedClass,
      CSVFile,
      classData,
      studentsdata,
      studentRef,
      yearGradeOptions,
      Error_grade,
      Error_class,
      Fields,
    } = this.state;
    const {
      class_loading,
      studentList,
      studentLoading,
      GradeYearListLoading,
      GradeYearList,
      class_by_School,
      subjectsList,
      subjectsListLoading,
      LevelList,
      LevelListLoading,
      Teacher_List,
    } = this.props;
    if (studentLoading) {
      return (
        <Fragment>
          <Spinner animation="border" variant="warning" />
          &nbsp;Loading Students...
        </Fragment>
      );
    } else {
      return (
        <div className="d-flex flex-column-fluid">
          <div className="container-fluid">
            <div className="d-flex flex-row">
              <div className="flex-row-fluid ml-lg-12">
                <div className="card card-custom">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark">
                        Students
                      </h3>
                    </div>
                    <div className="card-toolbar">
                      <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                        <li className="nav-item">
                          <button
                            onClick={() =>
                              this.setState({ StudentEditor: "list" })
                            }
                            className={`nav-link py-2 px-4 ${
                              StudentEditor === "list" ? "active" : ""
                            }`}
                          >
                            Student List
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            onClick={() =>
                              this.setState({ StudentEditor: "multi" })
                            }
                            className={`nav-link py-2 px-4 ${
                              StudentEditor === "multi" ? "active" : ""
                            }`}
                          >
                            Mutiple Students
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            onClick={() =>
                              this.setState({ StudentEditor: "class" })
                            }
                            className={`nav-link py-2 px-4 ${
                              StudentEditor === "class" ? "active" : ""
                            }`}
                          >
                            Class list
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {StudentEditor === "list" ? (
                    <div className="card-body">
                      <div className="card-body py-5 px-3">
                        <div className="mb-10">
                          <div className="row align-items-center">
                            <div className="col-lg-12 col-xl-12">
                              <div className="row align-items-center">
                                <div className="col-md-4 my-2 my-md-0">
                                  <div className="input-icon">
                                    <input
                                      onKeyUp={(e) => this.TypeSearch(e)}
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Type name or email or student id SYSTEM email or gender or DOB"
                                    />
                                    <span>
                                      <i className="flaticon2-search-1 text-muted" />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-4 my-2 my-md-0">
                                  {/*<select*/}
                                  {/*  onChange={(e) => this.Gender(e)}*/}
                                  {/*  className="form-control form-control-solid"*/}
                                  {/*>*/}
                                  {/*  <option value={"Both"}>*/}
                                  {/*    -Select gender-*/}
                                  {/*  </option>*/}
                                  {/*  <option value={"Male"}>Male</option>*/}
                                  {/*  <option value={"Female"}>Female</option>*/}
                                  {/*</select>*/}
                                </div>
                                <div className="col-md-4 my-2 my-md-0">
                                  <select
                                    onChange={(e) => this.YearGrade(e)}
                                    className="form-control form-control-solid"
                                  >
                                    <option value={"reset"}>
                                      -select year grade-
                                    </option>
                                    {GradeYearList.map((arr, key) => (
                                      <option key={key} value={arr.id}>
                                        {arr.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-content">
                          <div className="table-responsive">
                            {studentLoading.isLoading &&
                            Students.length !== 0 ? (
                              ""
                            ) : (
                              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center table-striped">
                                <thead>
                                  <tr className="text-left text-uppercase">
                                    <th
                                      style={{ minWidth: "60px" }}
                                      onClick={() =>
                                        this.setState({ reverse: !reverse })
                                      }
                                    >
                                      <span className="text-primary">#</span>
                                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                                        {reverse ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              strokeWidth={1}
                                              fill="none"
                                              fillRule="evenodd"
                                            >
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect
                                                fill="#000000"
                                                opacity="0.3"
                                                x={11}
                                                y={5}
                                                width={2}
                                                height={14}
                                                rx={1}
                                              />
                                              <path
                                                d="M6.70710678,18.7071068 C6.31658249,19.0976311 5.68341751,19.0976311 5.29289322,18.7071068 C4.90236893,18.3165825 4.90236893,17.6834175 5.29289322,17.2928932 L11.2928932,11.2928932 C11.6714722,10.9143143 12.2810586,10.9010687 12.6757246,11.2628459 L18.6757246,16.7628459 C19.0828436,17.1360383 19.1103465,17.7686056 18.7371541,18.1757246 C18.3639617,18.5828436 17.7313944,18.6103465 17.3242754,18.2371541 L12.0300757,13.3841378 L6.70710678,18.7071068 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                                transform="translate(12.000003, 14.999999) scale(1, -1) translate(-12.000003, -14.999999) "
                                              />
                                            </g>
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              strokeWidth={1}
                                              fill="none"
                                              fillRule="evenodd"
                                            >
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <rect
                                                fill="#000000"
                                                opacity="0.3"
                                                x={11}
                                                y={5}
                                                width={2}
                                                height={14}
                                                rx={1}
                                              />
                                              <path
                                                d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                              />
                                            </g>
                                          </svg>
                                        )}
                                      </span>
                                    </th>
                                    <th style={{ minWidth: "50px" }}>S ID#</th>
                                    <th
                                      style={{ minWidth: "100px" }}
                                      className="pl-7"
                                    >
                                      <span className="text-dark-50">User</span>
                                    </th>
                                    <th style={{ minWidth: "50px" }}>Email</th>
                                    <th style={{ minWidth: "50px" }}>
                                      Year Grade
                                    </th>
                                    {/*<th style={{ minWidth: "100px" }}>DOB</th>*/}
                                    {/*<th style={{ minWidth: "30px" }}>*/}
                                    {/*  System Email*/}
                                    {/*</th>*/}
                                    {/*<th style={{ minWidth: "30px" }}>Gender</th>*/}
                                    <th
                                      className="text-center"
                                      style={{ minWidth: "200px" }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Students.slice(studentstart, studentend)
                                    .sort((a, b) => this.sortArray(a.id, b.id))
                                    .map((user, key) => (
                                      <tr key={key}>
                                        <td>
                                          <span className="text-muted font-weight-bold">
                                            {user.id}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-muted font-weight-bold">
                                            {user.student_ref_number}
                                          </span>
                                        </td>
                                        <td className="pl-0 py-8">
                                          <div className="d-flex align-items-center">
                                            {/* <div className="symbol symbol-40 symbol-sm flex-shrink-0 mr-2">
                                              <span className="symbol-label">
                                                {user.Image === null ? (
                                                  <WordFinder
                                                    data={user.firstname}
                                                  />
                                                ) : (
                                                  <img
                                                    src={user.Image}
                                                    className="h-75 align-self-end"
                                                    alt={user.Image + "'s"}
                                                  />
                                                )}
                                              </span>
                                            </div> */}
                                            <div>
                                              <p className="text-dark-75 font-weight-bolder mb-1 font-size-lg text-capitalize">
                                                {user.firstname} {user.surname}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <span className="font-weight-bold d-block font-size-sm">
                                            {user.Email}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="font-weight-bold d-block font-size-sm">
                                            {user.year_grade.name}
                                          </span>
                                        </td>
                                        {/*<td>*/}
                                        {/*  <span className="font-weight-bold d-block font-size-sm">*/}
                                        {/*    {user.dob}*/}
                                        {/*    <span className="text-muted font-weight-bold d-block font-size-sm text-warning">*/}
                                        {/*      <DobCal data={user.dob} />*/}
                                        {/*    </span>*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*  <span className="font-weight-bold d-block font-size-sm">*/}
                                        {/*    {user.generated_email}*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*  <span*/}
                                        {/*    className={`label label-lg font-weight-bold label-inline ${*/}
                                        {/*      user.Gender === "Male"*/}
                                        {/*        ? "label-light-primary"*/}
                                        {/*        : "label-light-info"*/}
                                        {/*    }`}*/}
                                        {/*  >*/}
                                        {/*    {user.Gender}*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        <td className="pr-0">
                                          <div className="row">
                                            <div className="col-6 p-0 m-0">
                                              <Select
                                                value={options.filter(
                                                  (arr) =>
                                                    arr.value ===
                                                    user.year_grade.id
                                                )}
                                                isLoading={GradeYearListLoading}
                                                options={options}
                                                name="year_grade"
                                                onChange={(e) => {
                                                  this.updateGradeYear(
                                                    user.id,
                                                    e.value
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="col-6 p-0 m-0">
                                              <button
                                                onClick={() =>
                                                  this.EditStudent(user)
                                                }
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                              >
                                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    version="1.1"
                                                  >
                                                    <g
                                                      stroke="none"
                                                      strokeWidth={1}
                                                      fill="none"
                                                      fillRule="evenodd"
                                                    >
                                                      <rect
                                                        x={0}
                                                        y={0}
                                                        width={24}
                                                        height={24}
                                                      />
                                                      <path
                                                        d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                        fill="#000000"
                                                        fillRule="nonzero"
                                                        transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                                      />
                                                      <path
                                                        d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                        fill="#000000"
                                                        fillRule="nonzero"
                                                        opacity="0.3"
                                                      />
                                                    </g>
                                                  </svg>
                                                </span>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  this.deleteStudent(user.id)
                                                }
                                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                              >
                                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    version="1.1"
                                                  >
                                                    <g
                                                      stroke="none"
                                                      strokeWidth={1}
                                                      fill="none"
                                                      fillRule="evenodd"
                                                    >
                                                      <rect
                                                        x={0}
                                                        y={0}
                                                        width={24}
                                                        height={24}
                                                      />
                                                      <path
                                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                        fill="#000000"
                                                        fillRule="nonzero"
                                                      />
                                                      <path
                                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                        fill="#000000"
                                                        opacity="0.3"
                                                      />
                                                    </g>
                                                  </svg>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex align-items-center">
                          <select
                            onChange={(e) => this.sliceCalculator(e)}
                            className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
                            style={{ width: "100px" }}
                          >
                            <option selected disabled>
                              Pages
                            </option>
                            {ShowAll.map((arr, key) => (
                              <option key={key} value={arr.value}>
                                {arr.label}
                              </option>
                            ))}
                            <option value="showall">Show All</option>
                          </select>
                          <span className="text-muted">
                            {Students.length} Students
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : StudentEditor === "multi" ? (
                    <div className="card-body">
                      <div className="card-body py-5 px-3">
                        <div className="mb-10">
                          <Formik
                            validate={(values) => {
                              const errors = {};
                              studentsdata.forEach((student, index) => {
                                if (!student.firstname) {
                                  errors[`students[${index}].firstname`] =
                                    "First name is required";
                                }
                                if (!student.surname) {
                                  errors[`students[${index}].surname`] =
                                    "Surname is required";
                                }
                                // if (!student.class) {
                                //   errors[`students[${index}].class`] =
                                //     "Class is required";
                                // }
                                if (this.state.studentRef === "Manual") {
                                  if (!student.student_ref_number) {
                                    errors[
                                      `students[${index}].student_ref_number`
                                    ] = "Student Ref Number is required";
                                  }
                                }
                              });
                              return errors;
                            }}
                            onSubmit={this.AddStudentsList}
                            initialValues={{ students: studentsdata }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              errors,
                              touched,
                            }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <div className="tab-content">
                                  <div className="tab-pane show active px-7">
                                    <div className="row text-center">
                                      <div className="col-12">
                                        <h6 className="text-dark font-weight-bold mb-10">
                                          Add Multiple Students:
                                        </h6>
                                      </div>
                                    </div>
                                    <table className="table table-head-custom table-head-bg table-vertical-center ">
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={2}
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <div className="row p-0 m-0">
                                              <label className="col-4 text-capitalize">
                                                Student Ref
                                              </label>
                                              <div className="col-8 ">
                                                <div className="radio-inline">
                                                  <label className="radio radio-primary text-capitalize">
                                                    <input
                                                      type="radio"
                                                      defaultChecked={true}
                                                      value="Auto"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          studentRef:
                                                            e.target.value,
                                                        })
                                                      }
                                                      name="studentRef"
                                                    />
                                                    <span />
                                                    Auto
                                                  </label>
                                                  <label className="radio radio-primary text-capitalize">
                                                    <input
                                                      type="radio"
                                                      value="Manual"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          studentRef:
                                                            e.target.value,
                                                        })
                                                      }
                                                      name="studentRef"
                                                    />
                                                    <span />
                                                    Manual
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </th>

                                          <th
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <div className="row p-0 m-0">
                                              <label className="col-4 text-capitalize">
                                                Fields
                                              </label>
                                              <div className="col-8 ">
                                                <div className="radio-inline">
                                                  <label className="radio radio-primary text-capitalize">
                                                    <input
                                                      type="radio"
                                                      defaultChecked={true}
                                                      value="All"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          Fields:
                                                            e.target.value,
                                                        })
                                                      }
                                                      name="Fields"
                                                    />
                                                    <span />
                                                    All
                                                  </label>
                                                  <label className="radio radio-primary text-capitalize">
                                                    <input
                                                      type="radio"
                                                      value="Required"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          Fields:
                                                            e.target.value,
                                                        })
                                                      }
                                                      name="Fields"
                                                    />
                                                    <span />
                                                    Required
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </th>

                                          <th
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <div className="row mr-2 pr-2s">
                                              <div className="col-12">
                                                <Select
                                                  width="50%"
                                                  placeholder="Select Class"
                                                  className="basic-multi-select blackcolor"
                                                  isLoading={class_loading}
                                                  components={
                                                    animatedComponents
                                                  }
                                                  options={class_by_School.map((arr) => ({value: arr.id,label: arr.name}))}
                                                  name="year_grade"
                                                  onChange={(e, event) =>
                                                    this.setClass(e, event)
                                                  }
                                                />
                                                <span className="text-danger">
                                                  {Error_class
                                                    ? "Select Class"
                                                    : ""}
                                                </span>
                                              </div>
                                            </div>
                                          </th>
                                          <th
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                {SelectedClass ? (
                                                  <div className="row">
                                                    <div className="col-10">
                                                      <Select
                                                        width="50%"
                                                        placeholder="Select Year Grade"
                                                        className="basic-multi-select blackcolor"
                                                        components={
                                                          animatedComponents
                                                        }
                                                        options={
                                                          yearGradeOptions
                                                        }
                                                        name="year_grade_new"
                                                        onChange={(e, event) =>
                                                          this.setYearGrade(
                                                            e,
                                                            event
                                                          )
                                                        }
                                                      />
                                                      <span className="text-danger">
                                                        {Error_grade
                                                          ? "Select Year Grade"
                                                          : ""}
                                                      </span>
                                                    </div>
                                                    <div className="col-2">
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip id="tooltip-disabled">
                                                            Generate CSV
                                                          </Tooltip>
                                                        }
                                                        placement="top"
                                                      >
                                                        <button
                                                          disabled={
                                                            SelectedClass === ""
                                                          }
                                                          className="btn btn-sm btn-icon btn-bg-light btn-icon-info btn-hover-success"
                                                          onClick={(e) =>
                                                            this.downloadCSV(e)
                                                          }
                                                        >
                                                          <i className="la la-check icon-2x" />
                                                        </button>
                                                      </OverlayTrigger>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </th>
                                          <th
                                            className="text-center"
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Upload CSV
                                                </Tooltip>
                                              }
                                              placement="top"
                                            >
                                              <Fragment>
                                                {CSVFile == undefined ? (
                                                  <Fragment>
                                                    <input
                                                      disabled={
                                                        SelectedClass === ""
                                                      }
                                                      onChange={(e) =>
                                                        this.ImportCSV(e)
                                                      }
                                                      type="file"
                                                      accept=".csv"
                                                      id="upload"
                                                      hidden
                                                    />
                                                    <Form.Label
                                                      disabled="true"
                                                      htmlFor="upload"
                                                      className={`btn btn-sm btn-icon btn-bg-light btn-icon-${
                                                        SelectedClass === ""
                                                          ? "danger"
                                                          : "warning"
                                                      } btn-hover-success my-0`}
                                                    >
                                                      <i className="la la-file-csv icon-2x" />
                                                    </Form.Label>
                                                  </Fragment>
                                                ) : (
                                                  <button
                                                    className="btn btn-sm btn-icon btn-bg-light btn-icon-warning btn-hover-warning"
                                                    onClick={(e) =>
                                                      this.SaveCSV(e)
                                                    }
                                                  >
                                                    <i className="flaticon-upload-1 icon-2x" />
                                                  </button>
                                                )}
                                              </Fragment>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Save Form
                                                </Tooltip>
                                              }
                                            >
                                              <button
                                                disabled={CSVFile !== undefined}
                                                type="submit"
                                                className="btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success mx-2 my-0"
                                              >
                                                <i className="la la-save icon-2x" />
                                              </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Refresh
                                                </Tooltip>
                                              }
                                            >
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success "
                                                onClick={(e) => this.ReFresh(e)}
                                              >
                                                <i className="la la-refresh icon-2x" />
                                              </button>
                                            </OverlayTrigger>
                                          </th>
                                        </tr>
                                        <tr className="text-uppercase">
                                          <th style={{ width: "20px" }}>
                                            <span className="text-primary">
                                              #
                                            </span>
                                          </th>
                                          <th
                                            colSpan={
                                              Fields === "Required" ? "2" : "1"
                                            }
                                            style={{ minWidth: "50px" }}
                                          >
                                            <span className="text-dark-50">
                                              Firstname
                                            </span>
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </th>
                                          <th
                                            colSpan={
                                              Fields === "Required"
                                                ? studentRef === "Manual"
                                                  ? "3"
                                                  : "2"
                                                : "1"
                                            }
                                            style={{ minWidth: "50px" }}
                                          >
                                            <span className="text-dark-50">
                                              Surname
                                            </span>
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </th>
                                          {studentRef === "Manual" ? (
                                            <th style={{ minWidth: "50px" }}>
                                              <span className="text-dark-50">
                                                Student Ref
                                              </span>
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                          ) : (
                                            ""
                                          )}
                                          {/*{Fields === "All" ? (*/}
                                          {/*  <th style={{ minWidth: "50px" }}>*/}
                                          {/*    <span className="text-dark-50">*/}
                                          {/*      Email*/}
                                          {/*    </span>*/}
                                          {/*  </th>*/}
                                          {/*) : (*/}
                                          {/*  ""*/}
                                          {/*)}*/}
                                          {/*{Fields === "All" ? (*/}
                                          {/*  <th style={{ minWidth: "10px" }}>*/}
                                          {/*    <span className="text-dark-50">*/}
                                          {/*      Date of birth*/}
                                          {/*    </span>*/}
                                          {/*  </th>*/}
                                          {/*) : (*/}
                                          {/*  ""*/}
                                          {/*)}*/}
                                          {/*{Fields === "All" ? (*/}
                                          {/*  <th*/}
                                          {/*    className="mx-5"*/}
                                          {/*    style={{ minWidth: "100px" }}*/}
                                          {/*  >*/}
                                          {/*    <span className="text-dark-50">*/}
                                          {/*      Gender2*/}
                                          {/*    </span>*/}
                                          {/*  </th>*/}
                                          {/*) : (*/}
                                          {/*  ""*/}
                                          {/*)}*/}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Array.from(
                                          Array(totalRows).keys()
                                        ).map((index) => (
                                          <tr>
                                            <td>
                                              <span className="text-muted font-weight-bold">
                                                {index + 1}
                                              </span>
                                            </td>
                                            <td
                                              colSpan={
                                                Fields === "Required"
                                                  ? "2"
                                                  : "1"
                                              }
                                            >
                                              <div className="form-group row">
                                                <div className="col-12">
                                                  <Form.Control
                                                    className="form-control form-control-solid form-control-md"
                                                    onChange={(e) =>
                                                      this.onChangeRow(e, index)
                                                    }
                                                    name="firstname"
                                                    onBlur={handleBlur}
                                                    type="text"
                                                    placeholder="First name"
                                                    isInvalid={
                                                      !!errors[
                                                        `students[${index}].firstname`
                                                      ]
                                                    }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      errors[
                                                        `students[${index}].firstname`
                                                      ]
                                                    }
                                                  </Form.Control.Feedback>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colSpan={
                                                Fields === "Required"
                                                  ? studentRef === "Manual"
                                                    ? "3"
                                                    : "2"
                                                  : "1"
                                              }
                                            >
                                              <div className="form-group row">
                                                <div className="col-12">
                                                  <Form.Control
                                                    onChange={(e) =>
                                                      this.onChangeRow(e, index)
                                                    }
                                                    className="form-control form-control-solid form-control-md"
                                                    type="text"
                                                    name="surname"
                                                    onBlur={handleBlur}
                                                    placeholder="Surname"
                                                    isInvalid={
                                                      !!errors[
                                                        `students[${index}].surname`
                                                      ]
                                                    }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      errors[
                                                        `students[${index}].surname`
                                                      ]
                                                    }
                                                  </Form.Control.Feedback>
                                                </div>
                                              </div>
                                            </td>
                                            {studentRef === "Manual" ? (
                                              <td className="pl-0 py-8">
                                                <div className="form-group row">
                                                  <div className="col-12">
                                                    <Form.Control
                                                      onChange={(e) =>
                                                        this.onChangeRow(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      className="form-control form-control-solid form-control-md"
                                                      type="text"
                                                      name="student_ref_number"
                                                      onBlur={handleBlur}
                                                      placeholder="Ref number"
                                                      isInvalid={
                                                        !!errors[
                                                          `students[${index}].student_ref_number`
                                                        ]
                                                      }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {
                                                        errors[
                                                          `students[${index}].student_ref_number`
                                                        ]
                                                      }
                                                    </Form.Control.Feedback>
                                                  </div>
                                                </div>
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            {/*{Fields === "All" ? (*/}
                                            {/*  <td>*/}
                                            {/*    <div className="form-group row">*/}
                                            {/*      <div className="col-12">*/}
                                            {/*        <Form.Control*/}
                                            {/*          onChange={(e) =>*/}
                                            {/*            this.onChangeRow(*/}
                                            {/*              e,*/}
                                            {/*              index*/}
                                            {/*            )*/}
                                            {/*          }*/}
                                            {/*          className="form-control form-control-solid form-control-md"*/}
                                            {/*          type="email"*/}
                                            {/*          name="email"*/}
                                            {/*          placeholder="Email"*/}
                                            {/*        />*/}
                                            {/*      </div>*/}
                                            {/*    </div>*/}
                                            {/*  </td>*/}
                                            {/*) : (*/}
                                            {/*  ""*/}
                                            {/*)}*/}
                                            {/*{Fields === "All" ? (*/}
                                            {/*  <td>*/}
                                            {/*    <div className="form-group row">*/}
                                            {/*      <div className="col-12">*/}
                                            {/*        <Form.Control*/}
                                            {/*          onChange={(e) =>*/}
                                            {/*            this.onChangeRow(*/}
                                            {/*              e,*/}
                                            {/*              index*/}
                                            {/*            )*/}
                                            {/*          }*/}
                                            {/*          className="form-control form-control-solid form-control-md"*/}
                                            {/*          type="date"*/}
                                            {/*          name="dob"*/}
                                            {/*        />*/}
                                            {/*      </div>*/}
                                            {/*    </div>*/}
                                            {/*  </td>*/}
                                            {/*) : (*/}
                                            {/*  ""*/}
                                            {/*)}*/}
                                            {/*{Fields === "All" ? (*/}
                                            {/*  <td className="pb-10">*/}
                                            {/*    <Select*/}
                                            {/*      name="Gender"*/}
                                            {/*      placeholder="Select gender"*/}
                                            {/*      components={*/}
                                            {/*        animatedComponents*/}
                                            {/*      }*/}
                                            {/*      options={GenderValues}*/}
                                            {/*      className="basic-multi-select"*/}
                                            {/*      onChange={(e, event) =>*/}
                                            {/*        this.onChangeDropdown(*/}
                                            {/*          e,*/}
                                            {/*          event,*/}
                                            {/*          index*/}
                                            {/*        )*/}
                                            {/*      }*/}
                                            {/*    />*/}
                                            {/*  </td>*/}
                                            {/*) : (*/}
                                            {/*  ""*/}
                                            {/*)}*/}
                                          </tr>
                                        ))}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan={"2"}>
                                            <div className="row">
                                              <div className="col-6">
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                      Delete row
                                                    </Tooltip>
                                                  }
                                                  placement="bottom"
                                                >
                                                  <button
                                                    onClick={(e) =>
                                                      this.ChangeRows(
                                                        e,
                                                        "minus"
                                                      )
                                                    }
                                                    className="btn btn-sm btn-icon btn-bg-light btn-icon-danger btn-hover-danger mr-2"
                                                  >
                                                    <i className="fa la-minus-square icon-2x" />
                                                  </button>
                                                </OverlayTrigger>
                                              </div>
                                              <div className="col-6">
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                      Add row
                                                    </Tooltip>
                                                  }
                                                  placement="bottom"
                                                >
                                                  <button
                                                    onClick={(e) =>
                                                      this.ChangeRows(e, "plus")
                                                    }
                                                    className="btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success"
                                                  >
                                                    <i className="fas fa-plus-square icon-2x" />
                                                  </button>
                                                </OverlayTrigger>
                                              </div>
                                            </div>
                                          </td>
                                          <td colSpan={"2"}>
                                            <input
                                              onChange={(e) =>
                                                this.ChangeRows(e, "input")
                                              }
                                              style={{ width: "50%" }}
                                              className="form-control form-control-solid form-control-md"
                                              type="number"
                                              min="0"
                                              placeholder="Max 99 students"
                                            />
                                          </td>
                                          <td />
                                          <td className="text-right">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Save Form
                                                </Tooltip>
                                              }
                                            >
                                              <button
                                                disabled={CSVFile !== undefined}
                                                type="submit"
                                                className="btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success mx-2 my-0"
                                              >
                                                <i className="la la-save icon-2x" />
                                              </button>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="card-body py-5 px-3">
                        <div className="mb-10">
                          <div className="tab-content">
                            <div className="tab-pane show active px-7">
                              <div className="row">
                                <div className="col-2"/>
                                <div className="col-8">
                                  <Select
                                    placeholder="Select Class"
                                    className="basic-multi-select"
                                    isLoading={GradeYearListLoading}
                                    components={animatedComponents}
                                    options={class_by_School.map((arr) => ({
                                      value: arr.id,
                                      label: arr.name,
                                    }))}
                                    name="class"
                                    onChange={(e) =>
                                      this.setState({ selectedClass: e.value })
                                    }
                                  />
                                </div>
                                <div className="col-2"/>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  {classData !== undefined && classFilter ? (
                                    <Formik
                                      initialValues={{}}
                                      onSubmit={this.updateClass}
                                    >
                                      {({
                                        handleSubmit,
                                        handleChange,
                                        errors,
                                        values,
                                      }) => (
                                        <Form
                                          noValidate
                                          onSubmit={handleSubmit}
                                        >
                                          <div className="row">
                                            <div className="col-xl-2" />
                                            <div className="col-xl-7 my-2">
                                              <div className="row">
                                                <Form.Label className="col-3" />
                                                <div className="col-9">
                                                  <h6 className="text-dark font-weight-bold mb-10 text-center">
                                                    Class Info:
                                                  </h6>
                                                </div>
                                              </div>

                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  School Name
                                                </Form.Label>
                                                <div className="col-9">
                                                  <input
                                                    disabled={true}
                                                    className="form-control form-control-lg form-control-solid"
                                                    type="schoool"
                                                    defaultValue={classData.school.name}
                                                  />
                                                  <span className="form-text text-muted">
                                                    You can't edit school data
                                                  </span>
                                                </div>
                                              </div>
                                              {/* Class name */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Class Name
                                                </Form.Label>
                                                <div className="col-9">
                                                  <Form.Control
                                                    className="form-control form-control-lg form-control-solid"
                                                    type="text"
                                                    name="name"
                                                    defaultValue={classData.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                  />
                                                </div>
                                              </div>
                                              {/* Subject */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Subject
                                                </Form.Label>
                                                <div className="col-9">
                                                  <Select
                                                    defaultValue={subjectsList.filter((sub) =>sub.id ===classData.subject.id)
                                                      .map((arr) => ({
                                                        value: arr.id,
                                                        label: arr.name,
                                                      }))}
                                                    placeholder="Select Subject"
                                                    className="basic-multi-select"
                                                    isLoading={
                                                      subjectsListLoading
                                                    }
                                                    components={
                                                      animatedComponents
                                                    }
                                                    options={subjectsList.map(
                                                      (subj) => ({
                                                        value: subj.id,
                                                        label: subj.name,
                                                      })
                                                    )}
                                                    name="subject"
                                                    onChange={(e, event) => {
                                                      handleChange({
                                                        ...event,
                                                        target: {
                                                          name: "subject",
                                                          value: e.value,
                                                        },
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* Level */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Level
                                                </Form.Label>
                                                <div className="col-9">
                                                  <Select
                                                    defaultValue={LevelList.filter(
                                                      (sub) =>
                                                        sub.id ===
                                                        classData.level.id
                                                    ).map((arr) => ({
                                                      value: arr.id,
                                                      label: arr.name,
                                                    }))}
                                                    placeholder="Select Level"
                                                    className="basic-multi-select"
                                                    isLoading={LevelListLoading}
                                                    components={
                                                      animatedComponents
                                                    }
                                                    options={LevelList.map(
                                                      (arr) => ({
                                                        value: arr.id,
                                                        label: arr.name,
                                                      })
                                                    )}
                                                    name="level"
                                                    onChange={(e, event) => {
                                                      handleChange({
                                                        ...event,
                                                        target: {
                                                          name: "level",
                                                          value: e.value,
                                                        },
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* Year Grade */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Year Grade
                                                </Form.Label>
                                                <div className="col-9">
                                                  <Select
                                                    defaultValue={GradeYearList.filter(
                                                      (grade) => {
                                                        return classData.year_grade.some(
                                                          (filterGrade) =>
                                                            filterGrade.id ===
                                                            grade.id
                                                        );
                                                      }
                                                    ).map((arr) => ({value: arr.id,label: arr.name,}))}
                                                    placeholder="Select Year Grade"
                                                    isMulti
                                                    className="basic-multi-select"
                                                    isLoading={
                                                      GradeYearListLoading
                                                    }
                                                    components={
                                                      animatedComponents
                                                    }
                                                    options={GradeYearList.map(
                                                      (arr) => ({
                                                        value: arr.id,
                                                        label: arr.name,
                                                      })
                                                    )}
                                                    name="year_grade"
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      let event = {
                                                        target: {
                                                          name: "year_grade",
                                                          value:
                                                            selectedOption.map(
                                                              (arr) => arr.value
                                                            ),
                                                        },
                                                      };
                                                      handleChange(event);
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              {/* Teacher */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Teacher
                                                </Form.Label>
                                                <div className="col-9">
                                                  
                                                  <Select
                                                    defaultValue={Teacher_List.filter(
                                                      (grade) => {
                                                        return classData.teacher.some((filterGrade) =>filterGrade.id ===grade.id
                                                        );
                                                      }
                                                    ).map((arr) => ({
                                                      value: arr.id,
                                                      label: arr.name,
                                                    }))}
                                                    placeholder="Select teacher"
                                                    className="basic-multi-select"
                                                    isMulti

                                                    components={animatedComponents}
                                                    options={Teacher_List.map(
                                                      (arr) => ({
                                                        value: arr.id,
                                                        label: arr.name,
                                                      })
                                                    )}
                                                    name="teacher"
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      let event = {
                                                        target: {
                                                          name: "teacher",
                                                          value:
                                                            selectedOption.map(
                                                              (arr) => arr.value
                                                            ),
                                                        },
                                                      };
                                                      handleChange(event);
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              {/* Student */}
                                              <div className="form-group row">
                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                                  Students
                                                </Form.Label>

                                                <div className="col-9">
                                                  <Select
                                                    placeholder="Select Students"
                                                    className="basic-multi-select"
                                                    isMulti={true}
                                                    isClearable={true}
                                                    defaultValue={studentList
                                                      .filter((grade) => {
                                                        return classData.students.some(
                                                          (filterGrade) =>
                                                            filterGrade.id ===
                                                            grade.id
                                                        );
                                                      })
                                                      .map((arr) => ({
                                                        value: arr.id,
                                                        label:
                                                          arr.firstname +
                                                          " " +
                                                          arr.surname,
                                                      }))}
                                                    isLoading={
                                                      this.props.studentLoading
                                                    }
                                                    components={
                                                      animatedComponents
                                                    }
                                                    options={studentList.map(
                                                      (arr) => ({
                                                        value: arr.id,
                                                        label:
                                                          arr.firstname +
                                                          " " +
                                                          arr.surname +
                                                          " " +
                                                          arr.student_ref_number,
                                                      })
                                                    )}
                                                    name="students"
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      let event = {
                                                        target: {
                                                          name: "students",
                                                          value:
                                                            selectedOption.map(
                                                              (arr) => arr.value
                                                            ),
                                                        },
                                                      };
                                                      handleChange(event);
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-12 text-center">
                                              <button className="btn btn-success" type="submit">
                                                submit
                                              </button>
                                            </div>
                                          </div>
                                        </Form>
                                      )}
                                    </Formik>
                                  ) : (
                                  <div className="row">
                                  <div className="col-xl-2" />
                                  <div className="col-xl-7 my-2">
                                    <div className="row">
                                      <Form.Label className="col-3" />
                                      <div className="col-9">
                                        <h6 className="text-dark font-weight-bold mb-10 text-center">
                                          No Data Found
                                        </h6>
                                      </div>
                                    </div>
                                    </div></div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={deleteModel}
            onHide={() => this.setState({ deleteModel: !deleteModel })}
            aria-labelledby="contained-modal-title-vcenter"
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <p>Are you sure to delete user? This action is irreversible</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-light-warning font-weight-bolder font-size-sm mx-3"
                onClick={() => this.setState({ deleteModel: !deleteModel })}
              >
                Close
              </button>
              <button
                onClick={(e) => this.deleteUserfun(e)}
                className="btn btn-light-danger font-weight-bolder font-size-sm mx-3"
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showEdit}
            onHide={() => this.setState({ showEdit: !showEdit })}
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Edit {StudentArray.firstname} {StudentArray.surname}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {StudentArray.length !== 0 ? (
                <div className="card-body">
                  <Formik
                    validationSchema={schema}
                    onSubmit={(values) => this.updateStudent(values)}
                    initialValues={{
                      userid: StudentArray.id,
                      firstname: StudentArray.firstname,
                      surname: StudentArray.surname,
                      Email: StudentArray.Email,
                      generated_email: StudentArray.generated_email,
                      year_grade: options.filter(
                        (arr) => arr.value === StudentArray.year_grade.id
                      ),
                      Gender: GenderValues.filter(
                        (arr) => arr.value === StudentArray.Gender
                      ),
                      Image: StudentArray.Image,
                      dob: StudentArray.dob,
                      student_ref_number: StudentArray.student_ref_number,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form className="form" noValidate onSubmit={handleSubmit}>
                        <div className="tab-content">
                          <div className="tab-pane show px-7 active">
                            <div className="row">
                              <div className="col-xl-2" />
                              <div className="col-xl-7">
                                <div className="row">
                                  <Form.Label className="col-form-label col-3 text-left" />
                                  <div className="col-9">
                                    <h6 className="text-dark font-weight-bold mb-10">
                                      Editable Details:
                                    </h6>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-lg-right text-left">
                                    Image
                                  </Form.Label>
                                  <div className="col-9">
                                    <div
                                      className="image-input image-input-empty image-input-outline"
                                      id="kt_user_edit_avatar"
                                      style={{
                                        backgroundImage: `url(${
                                          image === null ? values.Image : image
                                        })`,
                                      }}
                                    >
                                      <div className="image-input-wrapper" />
                                      <Form.Label
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="change"
                                        data-toggle="tooltip"
                                        title
                                        data-original-title="Change avatar"
                                      >
                                        <i className="fa fa-pen icon-sm text-muted" />
                                        <input
                                          onChange={(e) =>
                                            this.onImageChange(e)
                                          }
                                          type="file"
                                          name="profile_avatar"
                                          accept=".png, .jpg, .jpeg"
                                        />
                                        <input
                                          type="hidden"
                                          name="profile_avatar_remove"
                                        />
                                      </Form.Label>
                                      <span
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="cancel"
                                        data-toggle="tooltip"
                                        title="Cancel avatar"
                                      >
                                        <i className="ki ki-bold-close icon-xs text-muted" />
                                      </span>
                                      <span
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="remove"
                                        data-toggle="tooltip"
                                        title="Remove avatar"
                                      >
                                        <i className="ki ki-bold-close icon-xs text-muted" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-left">
                                    Email Address
                                  </Form.Label>
                                  <div className="col-9">
                                    <div className="input-group input-group-lg input-group-solid">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="la la-at" />
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        name="Email"
                                        className="form-control form-control-lg form-control-solid"
                                        defaultValue={values.Email}
                                        placeholder="Email"
                                        onChange={handleChange}
                                        isInvalid={!!errors.Email}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.Email}
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-left">
                                    Firstname
                                  </Form.Label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="firstname"
                                      className="form-control form-control-lg form-control-solid"
                                      type="text"
                                      defaultValue={values.firstname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.firstname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.firstname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-left">
                                    surname
                                  </Form.Label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="surname"
                                      className="form-control form-control-lg form-control-solid"
                                      type="text"
                                      defaultValue={values.surname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.surname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.surname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-left">
                                    Grade Year
                                  </Form.Label>
                                  <div className="col-9">
                                    <Select
                                      value={values.year_grade}
                                      isLoading={GradeYearListLoading}
                                      options={options}
                                      name="year_grade"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "year_grade",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label className="col-form-label col-3 text-left">
                                    Gender
                                  </Form.Label>
                                  <div className="col-9">
                                    <Select
                                      name="Gender"
                                      components={animatedComponents}
                                      value={values.Gender}
                                      options={GenderValues}
                                      className="basic-multi-select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "Gender",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <Form.Label
                                    for="example-date-input"
                                    className="col-form-label col-3 text-left"
                                  >
                                    Date of Birth
                                  </Form.Label>
                                  <div className="col-9">
                                    <Form.Control
                                      className="form-control"
                                      type="date"
                                      value={values.dob}
                                      name="dob"
                                      onChange={handleChange}
                                    />
                                    <span className="form-text text-muted">
                                      <DobCal data={values.dob} />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-2" />
                            </div>

                            <div className="separator separator-dashed my-10" />
                            <div className="row">
                              <div className="col-xl-2" />
                              <div className="col-xl-7">
                                <div className="my-2">
                                  <div className="row">
                                    <Form.Label className="col-form-label col-3 text-left" />
                                    <div className="col-9">
                                      <h6 className="text-dark font-weight-bold mb-10">
                                        Details:
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <Form.Label className="col-form-label col-3 text-left">
                                      System Email:
                                    </Form.Label>
                                    <div className="col-9">
                                      <Form.Control
                                        name="generated_email"
                                        className="form-control form-control-lg form-control-solid"
                                        type="email"
                                        defaultValue={values.generated_email}
                                        disabled={true}
                                      />
                                      <span className="form-text text-muted">
                                        System email can't be changed
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <Form.Label className="col-form-label col-3 text-left">
                                      Student ref num
                                    </Form.Label>
                                    <div className="col-9">
                                      <Form.Control
                                        name="student_ref_number"
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        defaultValue={
                                          StudentArray.student_ref_number
                                        }
                                        onChange={handleChange}
                                        disabled="true"
                                      />
                                      <span className="form-text text-muted">
                                        Student Ref num can't be changed
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row text-center">
                          <div className="col-6">
                            <button
                              className="btn btn-light-warning font-weight-bolder font-size-sm mx-3"
                              onClick={() =>
                                this.setState({ showEdit: !showEdit })
                              }
                            >
                              Close
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              type="submit"
                              className="btn btn-light-success font-weight-bolder font-size-sm mx-3"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                ""
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  studentList: state.studentList.studentList,
  studentLoading: state.studentList.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
  GradeYearListLoading: state.GradeYearList.isLoading,
  class_by_School: state.class_by_School.class_by_School,
  class_loading: state.class_by_School.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  subjectsListLoading: state.subjectsList.isLoading,
  LevelList: state.LevelList.LevelList,
  LevelListLoading: state.LevelList.isLoading,
  Teacher_List: state.Teacher_List.Teacher,
  Teacher_ListLoading: state.Teacher_List.isLoading,
});
export default connect(mapStateToProps, {
  getStudentUser,
  getGradeYear,
  updatePatchStudents,
  deleteStudents,
  addListStudents,
  getClassSchool,
  getSubject,
  getLevel,
  getTeacher,
  updateClass,
})(StudentsList);