import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { getUserBySchool } from "../../actions/User/UserAction";
import Select from "react-select";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import {
  addTeacher,
  // getTeacher,
  getTeacherSchoolActive,
} from "../../actions/Teacher/TeacherAction";
import TeacherImg from "../../media/Subheader/T.png";
import ClassImg from "../../media/Subheader/c.png";
import StudentImg from "../../media/Subheader/s.png";
import aimg from "../../media/Subheader/a.png";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import {
  addStudents,
  getStudents,
  getStudentUser,
} from "../../actions/Students/StudentsActions";
import { addClass } from "../../actions/ClassActionFolder/ClassActions";
import { getLevel } from "../../actions/Level/LevelAction";
import ClockFun from "./ClockFun";
import { AssignTeacherSubject } from "../../actions/Teacher/TeacherAction";
import { getClassSchool } from "../../actions/ClassActionFolder/ClassActions";


const schemaTeacher = yup.object({
  name: yup.string().required("Enter Name"),
  email: yup.string().email("Invalid Email").required("Email Required"),
  subjects: yup
    .array()
    .min(1, "Select At least 1 subjects")
    .required("Select subjects"),
});
const schemaAssignTeacher = yup.object({
  Teacher: yup.string().required("Select Teacher"),
  Subject: yup.string().required("Select Subject"),
  Class: yup.string().required("Select Class"),
  YearGrade: yup.string().required("Select Year grade"),
});
const schema = yup.object({
  name: yup.string().required("Enter Name"),
  subject: yup.string().required("Select Subject"),
  level: yup.string().required("Select Level"),
  year_grade: yup
    .array()
    .min(1, "Select At least 1 Year Grade")
    .required("Select Year Grade"),
  // teacher: yup
  //   .array()
  //   .min(1, "Select At least 1 Teacher")
  //   .required("Select Teachers"),
  // students: yup
  //   .array()
  //   .min(1, "Select At least 1 students")
  //   .required("Select students"),
});
const schema2 = yup.object({
  firstname: yup.string().required("Enter first name"),
  surname: yup.string().required("Enter surname"),
  student_ref_number: yup.string().required("Enter student reference number"),
  year_grade: yup.string().required("Select year grade"),
  class_: yup.string().required("Select class"),
});

class Subheader extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getUserBySchool: PropTypes.func.isRequired,
    addTeacher: PropTypes.func.isRequired,
    getSubject: PropTypes.func.isRequired,
    UsersList: PropTypes.array.isRequired,
    addStudents: PropTypes.func.isRequired,
    getGradeYear: PropTypes.func.isRequired,
    UserLoading: PropTypes.bool.isRequired,
    subjectsList: PropTypes.array.isRequired,
    subjectLoading: PropTypes.bool.isRequired,
    GradeYearList: PropTypes.array.isRequired,
    GradeYearLoading: PropTypes.bool.isRequired,
    Teacher_List: PropTypes.array.isRequired,
    TeacherLoading: PropTypes.bool.isRequired,
    studentList: PropTypes.array.isRequired,
    studentLoading: PropTypes.bool.isRequired,
    getStudents: PropTypes.func.isRequired,
    addClass: PropTypes.func.isRequired,
    getLevel: PropTypes.func.isRequired,
    LevelList: PropTypes.array.isRequired,
    LevelListLoading: PropTypes.bool.isRequired,
    getTeacherSchoolActive: PropTypes.func.isRequired,
    getStudentUser: PropTypes.func.isRequired,
    AssignTeacherSubject: PropTypes.func,
    getClassSchool: PropTypes.func,
    class_by_School: PropTypes.array,
    class_by_School_isLoading: PropTypes.bool,
    TeacherActive: PropTypes.array,
    TeacherActiveLoading: PropTypes.bool,
  };
  state = {
    show: false,
    showClass: false,
    showStudent: false,
    UserArray: [],
    SubjectList: [],
    User: undefined,
    year_grade: undefined,
    valueSubject: undefined,
    YearGrade: undefined,
    teacher: undefined,
    students: undefined,
    TeacherList: undefined,
    studentList: undefined,
    LevelList: undefined,
    Level_List: undefined,
    showTeacherAssigner: false,
    SelectedClass: "",
    yearGradeOptions: [],
  };
  PascalCase = (word) => {
    if (!word) {
      return "";
    } else {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    }
  };
  getUsersList = () => {
    const options = this.props.UsersList.map((arr) => ({
      value: arr.id,
      label:
        this.PascalCase(arr.email) +
        " - " +
        this.PascalCase(arr.firstname) +
        " " +
        this.PascalCase(arr.lastname),
    }));
    this.setState({ UserArray: options });
  };
  getSubjectsList = () => {
    const options = this.props.subjectsList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ SubjectList: options });
  };
  UserSubject = async () => {
    await this.props.getUsers();
    if (this.props.UsersList.length !== 0 && !this.props.UserLoading) {
      this.setState({ UserArray: this.props.UsersList });
    }
  };
  dropdownSubject = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ valueSubject: value });
  };
  dropdownTeacher = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ teacher: value });
  };
  dropdownStudent = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ students: value });
  };
  UserGetter = async () => {
    await this.props.getUserBySchool();
    if (this.props.UsersList.length !== 0 && !this.props.UserLoading) {
      this.setState({ UserArray: this.props.UsersList });
    }
  };
  ModelSet = () => {
    this.getUsersList();
    this.getSubjectsList();
    this.setState({ show: true });
  };
  ModelSetAssignTeacher = () => {
    this.getSubjectsList();
    this.setState({ showTeacherAssigner: true });
  };
  getYearGrade = () => {
    const options = this.props.GradeYearList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ YearGrade: options });
  };
  getTeacher = () => {
    // console.log(this.props.TeacherActive)
    const options = this.props.TeacherActive
      .map((arr) => ({
        value: arr.id,
        label: arr.name + " - " + arr.email,
      }));
    this.setState({ TeacherList: options });
  };
  getStudentfun = () => {
    const options = this.props.studentList
      .filter(
        (arr) => arr.school.id === Number(localStorage.getItem("Schoolid"))
      )
      .map((arr) => ({
        value: arr.id,
        label: arr.firstname + " " + arr.surname + "-" + arr.student_ref_number,
      }));
    this.setState({ studentList: options });
  };
  ModelClass = () => {
    this.getYearGrade();
    this.getSubjectsList();
    this.getStudentfun();
    this.setState({ showClass: true });
  };
  ModelStudent = () => {
    this.getYearGrade();
    this.getYearGrade();

    this.setState({ showStudent: true });
  };
  onSubmitData = (values) => {
    let { email, name, subjects } = values;
    subjects = subjects.map((arr) => arr.value);
    const school = Number(localStorage.getItem("Schoolid"));
    const body = { email, name, subjects, school };
    this.props.addTeacher(body);
    this.setState({ show: false });
  };
  onSubmitStudent = (values) => {
    const {
      firstname,
      surname,
      student_ref_number,
      dob,
      Email,
      year_grade,
      class_,
    } = values;
    const school = Number(localStorage.getItem("Schoolid"));
    const body = {
      firstname,
      surname,
      student_ref_number,
      dob,
      Email,
      year_grade,
      school,
      class_,
    };
    this.props.addStudents(body);
    this.setState({ showStudent: false });
  };
  onSubmitClass = (values) => {
    const school = localStorage.getItem("Schoolid");
    let { students, teacher, name, subject, year_grade, level } = values;
    year_grade = year_grade.map((arr) => arr.value);
    const body = {
      name,
      school,
      subject,
      year_grade,
      level,
    };
    if (teacher.length !== 0) {
      teacher = teacher.map((arr) => arr.value);
      body.teacher = teacher;
    }
    if (students.length !== 0) {
      students = students.map((arr) => arr.value);
      body.students = students;
    }
    this.props.addClass(body);
    this.setState({ showClass: false });
  };
  SetData = () => {
    const options = this.props.LevelList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ LevelList: options });
  };
  AssignTeacher = (values) => {
    const { Teacher, Subject,Class,YearGrade } = values;
    const status = "Pending";
    const data = { Teacher, Subject, status,Class,YearGrade };
    this.props.AssignTeacherSubject(data);
    this.setState({ showTeacherAssigner: false });
  };
  updateGradeYear = () => {
    const selectedClassData = this.props.class_by_School.filter(
      (arr) => arr.id === this.state.SelectedClass
    );
    const transformedData = selectedClassData.flatMap((arr) =>
      arr.year_grade.map((grades) => ({
        value: grades.id,
        label: grades.name,
      }))
    );
    this.setState({ yearGradeOptions: transformedData });
  };
  componentDidMount() {
    this.props.getUserBySchool(localStorage.getItem("Schoolid"));
    this.props.getGradeYear();
    this.props.getSubject();
    this.props.getTeacherSchoolActive(localStorage.getItem("Schoolid"),'Accepted');
    this.props.getStudentUser(localStorage.getItem("Schoolid"));
    this.props.getLevel();
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.LevelList !== this.props.LevelList) {
      this.SetData();
    }
    // update this based on onModalActive
    if (prevProps.TeacherActive.length !== this.props.TeacherActive.length) {
      this.getTeacher();
    }
    if (prevState.SelectedClass !== this.state.SelectedClass) {
      this.updateGradeYear();
    }
  }

  render() {
    const {
      auth,
      subjectLoading,
      GradeYearLoading,
      TeacherLoading,
      studentLoading,
      LevelListLoading,
      class_by_School_isLoading,
      class_by_School,
      GradeYearList,
      class_loading
    } = this.props;
    const {
      show,
      valueSubject,
      SubjectList,
      showClass,
      showStudent,
      studentList,
      YearGrade,
      TeacherList,
      LevelList,
      showTeacherAssigner,
      yearGradeOptions,
      // Level_List
    } = this.state;
    return (
      <div className="subheader py-2 py-lg-6 subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline flex-wrap mr-5">
              <h5 className="text-dark font-weight-bold my-1 mr-5">
                {auth.user.user.School.name}
              </h5>
              <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                <li className="breadcrumb-item text-muted">
                  <a href className="text-muted">
                    {this.props.name}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span className="btn btn-light-primary font-weight-bolder btn-sm mr-1">
              <ClockFun />
            </span>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Add Teacher</Tooltip>}
            >
              <span className="d-inline-block dropdown dropdown-inline">
                <button
                  className="btn btn-icon bg-hover-success"
                  onClick={() => this.ModelSet()}
                >
                  <span className="svg-icon  svg-icon-success svg-icon-2x">
                    {/*Add Teacher*/}
                    <img src={TeacherImg} alt="TeacherImg" />
                  </span>
                </button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Add Class</Tooltip>}
            >
              <span className="d-inline-block dropdown dropdown-inline">
                <button
                  className="btn btn-icon bg-hover-success"
                  onClick={() => this.ModelClass()}
                >
                  <span className="svg-icon  svg-icon-success svg-icon-2x">
                    {/*Add Class*/}
                    <img src={ClassImg} alt="ClassImg" />
                  </span>
                </button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Add Student</Tooltip>}
            >
              <span className="d-inline-block dropdown dropdown-inline">
                <button
                  className="btn btn-icon bg-hover-success"
                  onClick={() => this.ModelStudent()}
                >
                  <span className="svg-icon svg-icon-success svg-icon-2x">
                    <img src={StudentImg} alt="StudentImg" />
                  </span>
                </button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Assign Teacher</Tooltip>}
            >
              <span className="d-inline-block dropdown dropdown-inline">
                <button
                  className="btn btn-icon bg-hover-success"
                  onClick={() => this.ModelSetAssignTeacher()}
                >
                  <span className="svg-icon svg-icon-success svg-icon-2x">
                    {/*Add Teacher*/}
                    <img src={aimg} alt="Assign teacher" />
                  </span>
                </button>
              </span>
            </OverlayTrigger>
          </div>
        </div>

        <Modal
          centered
          size="xl"
          show={show}
          onHide={() => this.setState({ show: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Teacher</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card card-custom card-shadowless rounded-top-0">
              <div className="card-body p-0">
                <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-10">
                    <Formik
                      validationSchema={schemaTeacher}
                      onSubmit={this.onSubmitData}
                      initialValues={{
                        email: "",
                        school: localStorage.getItem("SchoolName"),
                        subjects: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-xl-12">
                              <div className="my-5 step">
                                {/* <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    User
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="User"
                                      isDisabled={UserLoading}
                                      isLoading={UserLoading}
                                      options={UserArray}
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "User",
                                            value: e.value
                                          }
                                        });
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      You can view users from your school <b>{localStorage.getItem('SchoolName')}</b>
                                    </span>
                                    {errors.User ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.User}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div> */}

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Email
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="email"
                                      type="email"
                                      placeholder="Type email"
                                      value={values.email}
                                      onChange={handleChange}
                                      isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Name
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="name"
                                      type="text"
                                      placeholder="Name"
                                      value={values.name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.name}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    School
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="school"
                                      type="text"
                                      disabled
                                      value={values.school}
                                      onChange={handleChange}
                                      isInvalid={!!errors.school}
                                    />
                                    <span className="form-text text-muted">
                                      You can add Data for your school{" "}
                                      <b>
                                        {localStorage.getItem("SchoolName")}
                                      </b>
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.school}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Subjects
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="subjects"
                                      isMulti
                                      isDisabled={subjectLoading}
                                      isLoading={subjectLoading}
                                      value={valueSubject}
                                      options={SubjectList}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(selectedOption) => {
                                        let event = {
                                          target: {
                                            name: "subjects",
                                            value: selectedOption,
                                          },
                                        };
                                        handleChange(event);
                                      }}
                                    />
                                    {errors.subjects ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.subjects}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                <div className="row">
                                  <div className="col-6 text-right">
                                    <button
                                      onClick={() =>
                                        this.setState({ show: false })
                                      }
                                      type="button"
                                      className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                      data-wizard-type="action-submit"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-6 text-left">
                                    <button
                                      type="submit"
                                      className="btn btn-outline-success font-weight-bolder font-size-sm"
                                    >
                                      Save
                                    </button>
                                  </div>
                                  <span className="text-muted text-center font-weight-bold font-size-sm mt-1">
                                    School Admin can add a teacher
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="xl"
          show={showClass}
          onHide={() => this.setState({ showClass: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Class</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card card-custom card-shadowless rounded-top-0">
              {/*begin::Body*/}
              <div className="card-body p-0">
                <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-10">
                    <Formik
                      validationSchema={schema}
                      onSubmit={this.onSubmitClass}
                      initialValues={{
                        name: "",
                        school: localStorage.getItem("SchoolName"),
                        year_grade: "",
                        teacher: "",
                        students: "",
                        level: "",
                        subject: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-xl-12">
                              <div className="my-5 step">
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Name
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="name"
                                      type="text"
                                      placeholder="Name"
                                      value={values.name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.name}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    School
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="school"
                                      type="text"
                                      disabled
                                      value={values.school}
                                      onChange={handleChange}
                                      isInvalid={!!errors.school}
                                    />
                                    <span className="form-text text-muted">
                                      You can add Data for your school only
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.school}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Subjects
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="subject"
                                      isDisabled={subjectLoading}
                                      isLoading={subjectLoading}
                                      value={valueSubject}
                                      options={SubjectList}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      // touched={touched.state}
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "subject",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.subject ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.subject}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Year Grade
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="year_grade"
                                      isDisabled={GradeYearLoading}
                                      isLoading={GradeYearLoading}
                                      options={YearGrade}
                                      isMulti
                                      // onChange={(e) =>
                                      //   this.setState({ year_grade: e.value })
                                      // }

                                      onChange={(selectedOption) => {
                                        let event = {
                                          target: {
                                            name: "year_grade",
                                            value: selectedOption,
                                          },
                                        };
                                        handleChange(event);
                                      }}
                                    />
                                    {errors.year_grade ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.year_grade}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Level
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="level"
                                      isDisabled={LevelListLoading}
                                      isLoading={LevelListLoading}
                                      options={LevelList}
                                      isInvalid={!!errors.LevelList}
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "level",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.level ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.level}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Teacher
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="teacher"
                                      isMulti
                                      isDisabled={TeacherLoading}
                                      isLoading={TeacherLoading}
                                      options={TeacherList}
                                      onChange={(selectedOption) => {
                                        let event = {
                                          target: {
                                            name: "teacher",
                                            value: selectedOption,
                                          },
                                        };
                                        handleChange(event);
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      You can view Teachers from your school{" "}
                                      <b>
                                        {localStorage.getItem("SchoolName")}
                                      </b>
                                    </span>
                                    {errors.teacher ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.teacher}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Students
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="students"
                                      isMulti
                                      isDisabled={studentLoading}
                                      isLoading={studentLoading}
                                      options={studentList}
                                      onChange={(selectedOption) => {
                                        let event = {
                                          target: {
                                            name: "students",
                                            value: selectedOption,
                                          },
                                        };
                                        handleChange(event);
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      You can view Students from your school{" "}
                                      <b>
                                        {localStorage.getItem("SchoolName")}
                                      </b>
                                    </span>
                                    {errors.students ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.students}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                <div>
                                  <button
                                    onClick={() =>
                                      this.setState({ showClass: false })
                                    }
                                    type="button"
                                    className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                    data-wizard-type="action-submit"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-outline-success font-weight-bolder font-size-sm"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="xl"
          show={showStudent}
          onHide={() => this.setState({ showStudent: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Student</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card card-custom card-shadowless rounded-top-0">
              <div className="card-body p-0">
                <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-10">
                    <Formik
                      validationSchema={schema2}
                      onSubmit={this.onSubmitStudent}
                      initialValues={{
                        firstname: "",
                        surname: "",
                        school: localStorage.getItem("SchoolName"),
                        student_ref_number: "",
                        dob: "1990-01-01",
                        Email: "",
                        year_grade: "",
                        class_: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-xl-12">
                              <div className="my-5 step">
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    First Name
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="firstname"
                                      type="text"
                                      placeholder="First name"
                                      value={values.firstname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.firstname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.firstname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    surname
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="surname"
                                      type="text"
                                      placeholder="Surname"
                                      value={values.surname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.surname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.surname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Student Ref #
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="student_ref_number"
                                      type="text"
                                      placeholder="Student Ref #"
                                      value={values.student_ref_number}
                                      onChange={handleChange}
                                      isInvalid={!!errors.student_ref_number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.student_ref_number}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Date of Birth
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="dob"
                                      type="date"
                                      placeholder="Date of Birth"
                                      value={values.dob}
                                      onChange={handleChange}
                                      isInvalid={!!errors.dob}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dob}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Email
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="Email"
                                      type="email"
                                      placeholder="Email"
                                      value={values.Email}
                                      onChange={handleChange}
                                      isInvalid={!!errors.Email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.Email}
                                    </Form.Control.Feedback>
                                  </div>
                                </div> */}
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Class
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      placeholder="Select Class"
                                      name="class"
                                      isDisabled={class_by_School_isLoading}
                                      isLoading={class_by_School_isLoading}
                                      options={class_by_School.map((arr) => ({
                                        value: arr.id,
                                        label: arr.name,
                                      }))}
                                      isInvalid={!!errors.class}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "class_",
                                            value: e.value,
                                          },
                                        });
                                        this.setState({
                                          SelectedClass: e.value,
                                        });
                                      }}
                                    />
                                    {errors.class_ ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.class_}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Year Grade
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      placeholder="Select Year Grade"
                                      name="year_grade"
                                      options={yearGradeOptions}
                                      disabled={this.state.SelectedClass === ""}
                                      isInvalid={!!errors.year_grade}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "year_grade",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.year_grade ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.year_grade}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    School
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="school"
                                      type="text"
                                      disabled
                                      value={values.school}
                                      onChange={handleChange}
                                      isInvalid={!!errors.school}
                                    />
                                    <span className="form-text text-muted">
                                      You can add Data for your school only
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.school}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                <div>
                                  <button
                                    onClick={() =>
                                      this.setState({ showStudent: false })
                                    }
                                    type="button"
                                    className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                    data-wizard-type="action-submit"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-outline-success font-weight-bolder font-size-sm"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="xl"
          show={showTeacherAssigner}
          onHide={() => this.setState({ showTeacherAssigner: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign subject to Teacher</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card card-custom card-shadowless rounded-top-0">
              {/*begin::Body*/}
              <div className="card-body p-0">
                <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-10">
                    <Formik
                      validationSchema={schemaAssignTeacher}
                      onSubmit={(values) => this.AssignTeacher(values)}
                      initialValues={{
                        Teacher: "",
                        Subject: "",
                        Class:"",
                        YearGrade:''
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-xl-12">
                              <div className="my-5 step">
                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Teacher
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="Teacher"
                                      isDisabled={TeacherLoading}
                                      isLoading={TeacherLoading}
                                      options={TeacherList}
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "Teacher",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      You can view users from your school{" "}
                                      <b>
                                        {localStorage.getItem("SchoolName")}
                                      </b>
                                    </span>
                                    {errors.Teacher ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.Teacher}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    Subject
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="Subject"
                                      isDisabled={subjectLoading}
                                      isLoading={subjectLoading}
                                      value={valueSubject}
                                      options={this.props.subjectsList.map((arr) => ({value: arr.id,label: arr.name}))}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "Subject",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.Subject ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.Subject}
                                      </span>
                                    ) : ("")}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                  Class
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="Class"
                                      placeholder="Select class"
                                      isDisabled={class_loading}
                                      isLoading={class_loading}
                                      value={valueSubject}
                                      // options={SubjectList}
                                      options={class_by_School.map((arr) => ({value: arr.id,label: arr.name}))}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "Class",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.Class ? (
                                      <span
                                        className="form-text"
                                        style={{ color: "#F64E60" }}
                                      >
                                        {errors.Class}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                  Year grade
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Select
                                      name="YearGrade"
                                      placeholder="Select year grade"
                                      isDisabled={GradeYearLoading}
                                      isLoading={GradeYearLoading}
                                      value={valueSubject}
                                      options={GradeYearList.map((arr) => ({value: arr.id,label: arr.name}))}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e, event) => {
                                        handleChange({
                                          ...event,
                                          target: {
                                            name: "YearGrade",
                                            value: e.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.YearGrade ? 
                                    (
                                      <span className="form-text" style={{ color: "#F64E60" }}>
                                        {errors.YearGrade}
                                      </span>
                                    ) : ("")}
                                  </div>
                                </div>



                                <div className="form-group row">
                                  <label className="col-xl-3 col-lg-3 col-form-label">
                                    School
                                  </label>
                                  <div className="col-lg-9 col-xl-9">
                                    <Form.Control
                                      className="form-control form-control-solid form-control-lg"
                                      name="school"
                                      type="text"
                                      disabled
                                      value={localStorage.getItem("SchoolName")}
                                      isInvalid={!!errors.school}
                                    />
                                    <span className="form-text text-muted">
                                      You can add Data for your school{" "}
                                      <b>
                                        {localStorage.getItem("SchoolName")}
                                      </b>
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.school}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                <div>
                                  <button
                                    onClick={() =>
                                      this.setState({ show: false })
                                    }
                                    type="button"
                                    className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                    data-wizard-type="action-submit"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-outline-success font-weight-bolder font-size-sm"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  UsersList: state.UsersList.UsersList,
  UserLoading: state.UsersList.isLoading,
  subjectLoading: state.subjectsList.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  GradeYearLoading: state.GradeYearList.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
  Teacher_List: state.Teacher_List.Teacher_List,
  TeacherLoading: state.Teacher_List.isLoading,
  studentList: state.studentList.studentList,
  studentLoading: state.studentList.isLoading,
  LevelList: state.LevelList.LevelList,
  LevelListLoading: state.LevelList.isLoading,
  class_by_School: state.class_by_School.class_by_School,
  class_by_School_isLoading: state.class_by_School.isLoading,
  TeacherActive: state.TeacherActive.TeacherActive,
  TeacherActiveLoading: state.TeacherActive.isLoading,
});
export default connect(mapStateToProps, {
  getUserBySchool,
  getSubject,
  addTeacher,
  getGradeYear,
  addStudents,
  // getTeacher,
  getStudents,
  addClass,
  getLevel,
  getTeacherSchoolActive,
  getStudentUser,
  AssignTeacherSubject,
  getClassSchool,
})(Subheader);
