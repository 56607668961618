import {
  GET_MOCK_RESULT_EXAM,
  ADD_MOCK_RESULT_EXAM,
  DELETE_MOCK_RESULT_EXAM,
  UPDATE_MOCK_RESULT_EXAM,
  MOCK_EXAM_RESULT_LOADING,
  MOCK_EXAM_RESULT_FAIL,
  GET_MOCK_EXAM_RESULT_FILTER,
} from "../actions/types";

const initialState = {
  isLoading: false,
  MockFilterList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MOCK_RESULT_EXAM:
    case GET_MOCK_EXAM_RESULT_FILTER:
      return {
        ...state,
        MockFilterList: action.payload,
        isLoading: false,
      };

    case MOCK_EXAM_RESULT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_MOCK_RESULT_EXAM:
      return {
        ...state,
        MockFilterList: state.MockFilterList.filter((about) => about.id !== action.payload),
      };

    case MOCK_EXAM_RESULT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_MOCK_RESULT_EXAM:
      return {
        ...state,
        MockFilterList: state.MockFilterList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_MOCK_RESULT_EXAM:
      return {
        ...state,
        MockFilterList: [...state.MockFilterList, action.payload],
      };
    default:
      return state;
  }
}
