import axios from "axios";
import { tokenConfig } from "../auth_action/auth";

import { RESEND_OTP, RESEND_OTP_LOADING, RESEND_OTP_FAIL } from "../types";
import toast from "react-hot-toast";

export const ResendOTP = (data) => (dispatch, getState) => {
  const TID = toast.loading("Resending OTP...📨");
  dispatch({ type: RESEND_OTP_LOADING });
  axios
    .post("QLA/ResendOTPSchool/", data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RESEND_OTP,
        payload: res.data,
      });
      toast.success(res.data.status, {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      dispatch({ type: RESEND_OTP_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};
