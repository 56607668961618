import {
  ADD_STUDENT_CSV,
  GET_STUDENT_CSV,
  GET_STUDENT_CSV_LOADING,
  GET_STUDENT_CSV_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  CsvStudentList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_CSV:
    case ADD_STUDENT_CSV:
      return {
        ...state,
        CsvList: action.payload,
        isLoading: false,
      };

    case GET_STUDENT_CSV_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STUDENT_CSV_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
