import {
  GET_EXAM_GENERATOR,
  GET_EXAM_GENERATOR_LOADING,
  GET_EXAM_GENERATOR_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  ExamGenerator: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_GENERATOR:
      return {
        ...state,
        ExamGenerator: action.payload,
        isLoading: false,
      };
    case GET_EXAM_GENERATOR_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_EXAM_GENERATOR_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
