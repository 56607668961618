import {
  ADD_SUPPORT,
  ADD_SUPPORT_FAILED,
  ADD_SUPPORT_LOADING,
  DELETE_SUPPORT,
  DELETE_SUPPORT_FAILED,
  DELETE_SUPPORT_LOADING,
  GET_SUPPORT,
  GET_SUPPORT_FAILED,
  GET_SUPPORT_LOADING,
  UPDATE_SUPPORT,
  UPDATE_SUPPORT_FAILED,
  UPDATE_SUPPORT_LOADING,
} from "../actions/types";

const initialState = {
  Support: [],
  isLoading: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SUPPORT_LOADING:
    case DELETE_SUPPORT_LOADING:
    case GET_SUPPORT_LOADING:
    case UPDATE_SUPPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUPPORT:
      return {
        ...state,
        isLoading: false,
        Support: action.payload,
      };
    case DELETE_SUPPORT:
      return {
        ...state,
        isLoading: false,
        Support: state.Support.filter((about) => about.id !== action.payload),
      };
    case ADD_SUPPORT:
      return {
        ...state,
        isLoading: false,
        Support: [...state.Support, action.payload],
      };
    case UPDATE_SUPPORT:
      return {
        ...state,
        isLoading: false,
        Support: state.Support.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_SUPPORT_FAILED:
    case DELETE_SUPPORT_FAILED:
    case GET_SUPPORT_FAILED:
    case UPDATE_SUPPORT_FAILED:
      return {
        ...state,
        Support: [],
        isLoading: false,
      };
    default:
      return state;
  }
}
