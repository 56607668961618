import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  Remove_SCHOOL,
  USER_LOADED,
  USER_LOADING,
  REGISTER_LOADING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_LOADING,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_LOADING, AUTH_ERRORS
} from "../types";
import { getSubscription } from "../Subscription/Subscription_Action";

const headers = {
  header: {
    "Content-Type": "application/json",
  },
};
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  axios
    .get("accounts/user/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      dispatch(getSubscription);
    })

    .catch((err) => {
      console.log(err)
      // dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERRORS,
      });
    });
};
export const login = (email, password) => (dispatch) => {
  dispatch({ type: USER_LOADING });

  const body = { email, password };
  axios
    .post("accounts/login/", body, headers)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(getSubscription());
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};
export const register =
  (data) => (dispatch) => {
    dispatch({ type: REGISTER_LOADING });
    // const data = {
    //   email,
    //   firstname,
    //   lastname,
    //   password,
    //   School,
    //   Country,
    // };
    axios
      .post("accounts/register/", data, headers)
      .then((res) => {
        dispatch(createMessage({ addLead: res.data.message }));
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({ type: REGISTER_FAIL });
      });
  };
export const logout = () => (dispatch, getState) => {
  axios
    .post("accounts/logout/", null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: Remove_SCHOOL });
      dispatch({ type: LOGOUT_SUCCESS });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const ChangeUserPassword = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PASSWORD_LOADING });
  axios
    .put("accounts/change_password/", data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: UPDATE_PASSWORD_SUCCESS });

      dispatch(createMessage({ addLead: "Password Updated Successfully" }));
    })
    .catch((err) => {
      dispatch({ type: UPDATE_PASSWORD_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const UpdateUser = (data, id) => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_INFO_LOADING });

  axios
    .put(`accounts/update/${id}/`, data, tokenConfig(getState), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(
        createMessage({ addLead: "User Information Updated Successfully" })
      );
      dispatch({ type: UPDATE_USER_INFO, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_USER_INFO_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};
