import axios from "axios";
import { GET_SUBJECTS, SET_SUBJECT_FAIL, SET_SUBJECT_LOADING } from "../types";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

export const getSubject = () => (dispatch, getState) => {
  dispatch({ type: SET_SUBJECT_LOADING });
  axios
    .get("QLA/Subject/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUBJECTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_SUBJECT_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
