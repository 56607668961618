import axios from "axios";
import { returnErrors } from "../message/message";
import {
  SET_GRADE_YEAR_FAIL,
  SET_GRADE_YEAR_LOADING,
  GET_GRADE_YEAR,
} from "../types";

export const getGradeYear = () => (dispatch) => {
  dispatch({ type: SET_GRADE_YEAR_LOADING });
  axios
    .get("QLA/StudentYearGrade/")
    .then((res) => {
      dispatch({
        type: GET_GRADE_YEAR,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_GRADE_YEAR_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
