import React, { Component } from "react";
import "../../style/KeenTheme/plugins/global/plugins.bundle.css";
import "../../style/KeenTheme/plugins/custom/prismjs/prismjs.bundle.css";
import "../../style/KeenTheme/css/style.bundle.css";
import "../../style/KeenTheme/css/themes/layout/header/base/light.css";
import "../../style/KeenTheme/css/themes/layout/header/menu/light.css";
import "../../style/KeenTheme/css/themes/layout/brand/dark.css";
import "../../style/KeenTheme/css/themes/layout/aside/dark.css";
import { Button, Modal, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getExams } from "../../actions/Exam/ExamsAction";
import { getStudentExam } from "../../actions/StudenExam/StudentExamAction";
import DobCal from "../Calculator/DobCal";
import {
  addStudentCSV,
  getStudentCSV,
} from "../../actions/StudentCSV/StudentCSVAction";
import generate from "../../media/svg/generate.png";
// import StudentGradeColor from "../functions/StudentGradeColor";
import ProgressBarColor from "../functions/ProgressBarColor";
import { PaperGenerator } from "../../actions/PaperGenerator/PaperGeneratorAction";
import Waiting from "../functions/Waiting";
import dummylogo from "../../assets/media/users/dummy.svg";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import Select from "react-select";
class StudentMarks extends Component {
  static propTypes = {
    ExamList: PropTypes.array.isRequired,
    ExamDataList: PropTypes.array.isRequired,
    getStudentUser: PropTypes.func,
    subjectsList: PropTypes.array.isRequired,
    ExamGenerator: PropTypes.array.isRequired,
    PaperGenerator: PropTypes.func.isRequired,
    getStudentExam: PropTypes.func.isRequired,
    ExamListLoading: PropTypes.bool.isRequired,
    subjectsListLoading: PropTypes.bool.isRequired,
    ExamDataListLoading: PropTypes.bool.isRequired,
    ExamGeneratorLoading: PropTypes.bool.isRequired,
    getStudentCSV: PropTypes.func.isRequired,
    GradeYearList: PropTypes.func.isRequired,
    addStudentCSV: PropTypes.func.isRequired,
    GradeYearLoading: PropTypes.array.isRequired,
    CsvStudentListLoading: PropTypes.bool.isRequired,
  };
  state = {
    show: false,
    showStudent: false,
    subject: undefined,
    exam: undefined,
    data_array: undefined,
    Range: undefined,
    RangeState: false,
    showGen: false,
    StudentDataArray: [],
    GradeYearOption: undefined,
    year_grade: undefined,
    showExport: false,
    file: undefined,
  };
  generatePaper = (e) => {
    e.preventDefault();
    const schoolid = localStorage.getItem("Schoolid");
    const ExamID = this.state.StudentDataArray.Exam;
    const StudentID = this.state.StudentDataArray.StudentID.id;
    this.props.PaperGenerator(schoolid, StudentID, ExamID, this.state.Range);
    this.setState({ RangeState: !this.state.RangeState });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  FindStudent = (e) => {
    e.preventDefault(e);
    const schoolid = localStorage.getItem("Schoolid");
    if (
      this.state.subject !== undefined &&
      this.state.exam !== undefined &&
      localStorage.getItem("Schoolid") !== null
    ) {
      this.props.getStudentExam(schoolid, this.state.subject, this.state.exam);
    } else {
      e.stopPropagation();
      window.alert("Error");
    }
  };
  ImportStudent = () => {
    this.setState({ showGen: !this.state.showGen });
  };
  handleFilesChosen = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  getGradeYearOption = () => {
    const options = this.props.GradeYearList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ GradeYearOption: options });
  };
  StudentCSV = () => {
    this.props.getStudentCSV(
      this.state.year_grade,
      localStorage.getItem("Schoolid")
    );
  };
  UploadFile = () => {
    let formData = new FormData();
    formData.append("CVSFILE", this.state.file);
    formData.append("YearGradeID", this.state.year_grade);
    formData.append("SchoolID", Number(localStorage.getItem("Schoolid")));
    this.props.addStudentCSV(formData);
    this.setState({ showExport: !this.state.showExport });
  };
  ExportStudent = () => {
    this.setState({ showExport: !this.state.showExport }, () =>
      this.getGradeYearOption()
    );
  };
  componentDidMount() {
    this.props.getSubject();
    this.props.getExams();
    this.props.getGradeYear();
    this.getGradeYearOption();
  }

  render() {
    const {
      subject,
      showGen,
      exam,
      data_array,
      showStudent,
      Range,
      GradeYearOption,
      RangeState,
      year_grade,
      showExport,
      file,
    } = this.state;
    const {
      subjectsList,
      ExamDataListLoading,
      ExamDataList,
      ExamList,
      CsvStudentListLoading,
    } = this.props;
    if (CsvStudentListLoading) {
      return <Waiting message="Please Wait we are adding students" />;
    } else {
      return (
        <div>
          <div className="d-flex flex-column-fluid">
            <div className="container">
              <div className="d-flex flex-row">
                <div className="flex-row-fluid ml-lg-12">
                  <div className="card card-custom">
                    <div className="card-header py-3">
                      <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                          Students
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                          {ExamDataList.length !== 0
                            ? `Manage over ${ExamDataList.length} students`
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="card-body">
                      {ExamDataListLoading ? (
                        <div style={{ marginTop: "350px" }}>
                          <Waiting message="Please Wait we are Loading Students List" />
                        </div>
                      ) : (
                        <>
                          <div className="mb-6">
                            <div className="row align-items-center">
                              <div className="col-lg-9 col-xl-8">
                                <div className="row align-items-center mt-0 mb-5 ml-1">
                                  <div className="col-md-4 my-2 my-md-0">
                                    <button
                                      onClick={() => this.ImportStudent()}
                                      className="btn btn-outline-success"
                                    >
                                      Exports Format CSV
                                    </button>
                                  </div>
                                  <div className="col-md-4 my-2 my-md-0">
                                    <button
                                      onClick={() => this.ExportStudent()}
                                      className="btn btn-outline-warning"
                                    >
                                      Import Students CSV
                                    </button>
                                  </div>
                                </div>

                                <div className="row align-items-center">
                                  <div className="col-md-4 my-2 my-md-0">
                                    <select
                                      name="subject"
                                      onChange={(e) => this.onChange(e)}
                                      className="form-control form-control-solid"
                                    >
                                      <option selected disabled>
                                        ---Select Subject---
                                      </option>
                                      {subjectsList.map((arr, key) => (
                                        <option key={key} value={arr.id}>
                                          {arr.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-4 my-2 my-md-0">
                                    <select
                                      name="exam"
                                      onChange={(e) => this.onChange(e)}
                                      disabled={subject === undefined}
                                      className="form-control form-control-solid"
                                    >
                                      <option selected disabled>
                                        ---Select Exam---
                                      </option>
                                      {ExamList.map((arr, key) => (
                                        <option key={key} value={arr.id}>
                                          {arr.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                                <button
                                  onClick={(e) => this.FindStudent(e)}
                                  disabled={
                                    subject === undefined || exam === undefined
                                  }
                                  className="btn btn-light-primary px-6 font-weight-bold"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card card-custom">
                            <div className="card-body py-0">
                              <div className="table-responsive">
                                <table className="table table-head-custom table-vertical-center">
                                  <thead>
                                    <tr className="text-left">
                                      <th
                                        className="pl-0"
                                        style={{ width: "30px" }}
                                      >
                                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                          <input
                                            type="checkbox"
                                            defaultValue={1}
                                          />
                                          <span />
                                        </label>
                                      </th>
                                      <th
                                        className="pl-0"
                                        style={{ minWidth: "120px" }}
                                      >
                                        Student id
                                      </th>
                                      <th style={{ minWidth: "120px" }}>
                                        Firstname
                                      </th>
                                      <th style={{ minWidth: "120px" }}>
                                        Surname
                                      </th>
                                      <th style={{ minWidth: "120px" }}>
                                        Date of Birth
                                      </th>
                                      {/*<th style={{ minWidth: "120px" }}>*/}
                                      {/*  Grade*/}
                                      {/*</th>*/}
                                      {/*<th style={{ minWidth: "50px" }}>*/}
                                      {/*  Status*/}
                                      {/*</th>*/}
                                      {/*<th style={{ minWidth: "50px" }}>*/}
                                      {/*  Obtain Marks*/}
                                      {/*</th>*/}
                                      {/*<th style={{ minWidth: "50px" }}>*/}
                                      {/*  Total Marks*/}
                                      {/*</th>*/}
                                      <th
                                        className="pr-0 text-right"
                                        style={{ minWidth: "120px" }}
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ExamDataList.map((arr, key) => (
                                      <tr key={key}>
                                        <td className="pl-0 py-6">
                                          <label className="checkbox checkbox-lg checkbox-inline">
                                            <input
                                              type="checkbox"
                                              defaultValue={1}
                                            />
                                            <span />
                                          </label>
                                        </td>
                                        <td className="pl-0">
                                          <span className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                                            {arr.StudentID.student_ref_number}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {arr.StudentID.firstname}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {arr.StudentID.surname}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-primary font-weight-bolder d-block font-size-lg">
                                            {arr.StudentID.dob}
                                          </span>
                                          <span className="text-muted font-weight-bold">
                                            <DobCal data={arr.StudentID.dob} />
                                          </span>
                                        </td>
                                        {/*<td>*/}
                                        {/*  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">*/}
                                        {/*    {arr.Grade}*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*  <StudentGradeColor*/}
                                        {/*    data={arr.Status}*/}
                                        {/*  />*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">*/}
                                        {/*    {arr.ObtainedMarks}*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">*/}
                                        {/*    {arr.TotalMarks}*/}
                                        {/*  </span>*/}
                                        {/*</td>*/}
                                        <td className="pr-0 text-right">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                Generate Paper for{" "}
                                                {arr.StudentID.firstname}
                                              </Tooltip>
                                            }
                                          >
                                            <span className="d-inline-block">
                                              <button
                                                onClick={() =>
                                                  this.setState({
                                                    RangeState: !RangeState,
                                                    StudentDataArray: arr,
                                                  })
                                                }
                                                className="btn btn-icon btn-light btn-hover-warning btn-sm"
                                              >
                                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                                  <img
                                                    src={generate}
                                                    alt="..."
                                                    height="20px"
                                                  />
                                                </span>
                                              </button>
                                            </span>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                View {arr.StudentID.firstname}
                                                's Progress
                                              </Tooltip>
                                            }
                                          >
                                            <span className="d-inline-block">
                                              <button
                                                onClick={() =>
                                                  this.setState({
                                                    showStudent: !showStudent,
                                                    data_array: arr,
                                                  })
                                                }
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                              >
                                                <span className="svg-icon svg-icon-primary svg-icon-2x">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    version="1.1"
                                                  >
                                                    <g
                                                      stroke="none"
                                                      strokeWidth={1}
                                                      fill="none"
                                                      fillRule="evenodd"
                                                    >
                                                      <rect
                                                        x={0}
                                                        y={0}
                                                        width={24}
                                                        height={24}
                                                      />
                                                      <path
                                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                        fill="#000000"
                                                        fillRule="nonzero"
                                                        opacity="0.3"
                                                      />
                                                      <path
                                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                        fill="#000000"
                                                        fillRule="nonzero"
                                                      />
                                                      <rect
                                                        fill="#000000"
                                                        opacity="0.3"
                                                        x={9}
                                                        y="10.5"
                                                        width={4}
                                                        height={1}
                                                        rx="0.5"
                                                      />
                                                    </g>
                                                  </svg>
                                                  {/*end::Svg Icon*/}
                                                </span>
                                              </button>
                                            </span>
                                          </OverlayTrigger>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={showExport}
            onHide={() => this.setState({ showExport: !showExport })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Student File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>Please Follow</b> the specified format
                    </p>
                  </div>
                </div>
                <div className="col-md-12 my-2 my-md-0">
                  <div className="form-group row">
                    <label className="col-12 col-form-label">Year Grade</label>
                    <div className="col-12">
                      <Select
                        isLoading={GradeYearOption === undefined}
                        isDisabled={GradeYearOption === undefined}
                        name="Year Grade"
                        options={GradeYearOption}
                        onChange={(e) => this.setState({ year_grade: e.value })}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="formFileSm" className="form-label">
                          Year Grade
                        </label>
                        <input
                          onChange={this.handleFilesChosen}
                          className="form-control"
                          id="formFileSm"
                          type="file"
                          accept=".csv"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ showExport: !showExport })}
              >
                Close
              </Button>
              <Button
                disabled={file === undefined}
                variant="primary"
                onClick={() => this.UploadFile()}
              >
                Upload File
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showGen}
            onHide={() => this.setState({ showGen: !showGen })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Year Grade</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="row">
                  <div className="col-12">
                    <p>
                      Please Select Year Grade to generate CSV format file{" "}
                      <br />
                      <b>Please Follow</b> the specified format
                    </p>
                  </div>
                </div>
                <div className="col-md-12 my-2 my-md-0">
                  <div className="form-group row">
                    <label className="col-12 col-form-label">Year Grade</label>
                    <div className="col-12">
                      <Select
                        name="Year Grade"
                        options={GradeYearOption}
                        onChange={(e) => this.setState({ year_grade: e.value })}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ showGen: !showGen })}
              >
                Close
              </Button>
              <Button
                disabled={year_grade === undefined}
                variant="primary"
                onClick={() => this.StudentCSV()}
              >
                Generate Paper
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={RangeState}
            centered
            onHide={() =>
              this.setState({ RangeState: !RangeState, Range: undefined })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Range Selector for Exam Generator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="row">
                  <div className="col-12">
                    <p>
                      The Papers generated would be for the questions that have
                      marks lesser than the <b>selected Percentage</b>
                    </p>
                  </div>
                </div>
                <div className="col-md-12 my-2 my-md-0">
                  <div className="form-group row">
                    <label className="col-12 col-form-label">
                      Percentage filter
                    </label>
                    <div className="col-12">
                      <input
                        name="Range"
                        onChange={(e) => this.onChange(e)}
                        type="range"
                        className="custom-range"
                        min="1"
                        max="99"
                        id="customRange2"
                      />
                      {Range !== undefined ? (
                        <div className="text-muted col-12">{Range}%</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ RangeState: !RangeState, Range: undefined })
                }
              >
                Close
              </Button>
              <Button
                disabled={Range !== undefined ? false : true}
                variant="primary"
                onClick={(e) => this.generatePaper(e)}
              >
                Generate Paper
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            show={showStudent}
            onHide={() =>
              this.setState({
                showStudent: !this.state.showStudent,
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {data_array !== undefined ? data_array.StudentID.firstname : ""}
                's Progress
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {data_array ? (
                <div className="gutter-b">
                  <div className="">
                    <div className="d-flex">
                      <div className="flex-shrink-0 mr-7">
                        <div className="symbol symbol-50">
                          <img
                            height="50"
                            width="50"
                            alt="student image here"
                            src={
                              data_array.StudentID.Image
                                ? data_array.StudentID.Image
                                : dummylogo
                            }
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                          <div className="mr-3">
                            <span className="d-flex align-items-center text-dark  font-size-h5 font-weight-bold mr-3">
                              {data_array.StudentID.firstname
                                ? data_array.StudentID.firstname
                                : "NO NAME"}
                              &nbsp;
                              {data_array.StudentID.surname
                                ? data_array.StudentID.surname
                                : "N/A"}
                            </span>
                            <div className="d-flex flex-wrap my-2">
                              <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 m-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x={0}
                                        y={0}
                                        width={24}
                                        height={24}
                                      />
                                      <path
                                        d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                        fill="#000000"
                                      />
                                      <circle
                                        fill="#000000"
                                        opacity="0.3"
                                        cx="19.5"
                                        cy="17.5"
                                        r="2.5"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                <span className="m-2">
                                  {data_array !== undefined
                                    ? data_array.StudentID.Email
                                      ? data_array.StudentID.Email
                                      : "Not provided"
                                    : "Not Provided"}
                                </span>
                              </span>
                              <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M20.5,11 L22.5,11 C23.3284271,11 24,11.6715729 24,12.5 C24,13.3284271 23.3284271,14 22.5,14 L20.5,14 C19.6715729,14 19,13.3284271 19,12.5 C19,11.6715729 19.6715729,11 20.5,11 Z M1.5,11 L3.5,11 C4.32842712,11 5,11.6715729 5,12.5 C5,13.3284271 4.32842712,14 3.5,14 L1.5,14 C0.671572875,14 1.01453063e-16,13.3284271 0,12.5 C-1.01453063e-16,11.6715729 0.671572875,11 1.5,11 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        />
                                        <path
                                          d="M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z M12,18 C9.23857625,18 7,15.7614237 7,13 C7,10.2385763 9.23857625,8 12,8 C14.7614237,8 17,10.2385763 17,13 C17,15.7614237 14.7614237,18 12,18 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                                {data_array.StudentID.year_grade.name
                                  ? data_array.StudentID.year_grade.name
                                  : "Year Grade"}
                              </span>
                              <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0">
                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1 mb-5">
                                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <polygon points="0 0 24 0 24 24 0 24" />
                                          <path
                                            d="M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z M21,8 L17,8 C16.4477153,8 16,7.55228475 16,7 C16,6.44771525 16.4477153,6 17,6 L21,6 C21.5522847,6 22,6.44771525 22,7 C22,7.55228475 21.5522847,8 21,8 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            opacity="0.3"
                                          />
                                          <path
                                            d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </span>
                                </span>
                                {data_array.StudentID.dob
                                  ? data_array.StudentID.dob
                                  : "dob"}{" "}
                                &nbsp;&nbsp;&nbsp;
                                <DobCal
                                  data={
                                    data_array !== undefined
                                      ? data_array.StudentID.dob
                                      : ""
                                  }
                                />
                              </span>
                              <span className="text-muted  font-weight-bold">
                                {data_array !== undefined ? (
                                  <ProgressBarColor
                                    data={data_array.ObtainedMarks}
                                    data1={data_array.TotalMarks}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                          <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                            {/*{data_array.Remarks}*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "No Data found"
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    showStudent: !this.state.showStudent,
                  })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ExamList: state.ExamList.ExamList,
  ExamListLoading: state.ExamList.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  ExamDataList: state.ExamDataList.ExamDataList,
  subjectsListLoading: state.subjectsList.isLoading,
  ExamDataListLoading: state.ExamDataList.isLoading,
  ExamGenerator: state.ExamGenerator.ExamGenerator,
  ExamGeneratorLoading: state.ExamGenerator.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
  GradeYearLoading: state.GradeYearList.isLoading,
  CsvStudentList: state.CsvStudentList.CsvStudentList,
  CsvStudentListLoading: state.CsvStudentList.isLoading,
});
export default connect(mapStateToProps, {
  getSubject,
  getExams,
  getStudentExam,
  PaperGenerator,
  getStudentCSV,
  getGradeYear,
  addStudentCSV,
})(StudentMarks);
