import {
  ADD_RAG_RATING,
  GET_RAG_RATING,
  UPDATE_RAG_RATING,
  RAG_RATING_LOADING,
  RAG_RATING_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  RABRatingList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RAG_RATING:
      return {
        ...state,
        RABRatingList: action.payload,
        isLoading: false,
      };
    case RAG_RATING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case RAG_RATING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_RAG_RATING:
      return {
        ...state,
        RABRatingList: state.RABRatingList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_RAG_RATING:
      return {
        ...state,
        RABRatingList: [...state.RABRatingList, action.payload],
      };
    default:
      return state;
  }
}
