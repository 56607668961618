import React from "react";

export function Footer() {
  const today = new Date().getFullYear();
  return (
    <div className="footer bg-white py-4 d-flex flex-lg-column mt-100">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today}©</span>
          <a
            href="http://targetqla.co.uk"
            className="text-dark-75"
            rel="noreferrer"
          >
            TargetQLA
          </a>
        </div>
        <div className="nav nav-dark">
          <a
            href="#"
            target="_blank"
            className="nav-link pl-0 pr-5"
            rel="noreferrer"
          >
            About
          </a>
          <a
            href="#"
            target="_blank"
            className="nav-link pl-0 pr-5"
            rel="noreferrer"
          >
            Team
          </a>
          <a
            href="#"
            target="_blank"
            className="nav-link pl-0 pr-0"
            rel="noreferrer"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}
