import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADD_STUDENT,
  DELETE_STUDENT,
  SET_STUDENT_FAIL,
  SET_STUDENT_LOADING,
  GET_STUDENT,
  UPDATE_STUDENT,
  ADD_LIST_STUDENT,
} from "../types";
import toast from "react-hot-toast";

export const getStudents = () => (dispatch) => {
  dispatch({ type: SET_STUDENT_LOADING });
  axios
    .get("QLA/Student/")
    .then((res) => {
      dispatch({
        type: GET_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const getStudentUser = (schoolID) => (dispatch) => {
  dispatch({ type: SET_STUDENT_LOADING });
  axios
    .get(`QLA/StudentBySchool/${schoolID}/`)
    .then((res) => {
      dispatch({
        type: GET_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteStudents = (id) => (dispatch, getState) => {
  const TID = toast.loading("Deleting Student...❌");
  axios
    .delete(`QLA/Student/${id}/`, tokenConfig(getState))
    .then((res) => {
      toast.success("Student deleted!", {
        id: TID,
      });
      dispatch({
        type: DELETE_STUDENT,
        payload: id,
      });
    })
    .catch((err) => {
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const addStudents = (data) => (dispatch, getState) => {
  axios
    .post("QLA/Student/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Student Added" }));
      dispatch({
        type: ADD_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response.data.student_ref_number) {
        dispatch(
          createMessage({ deleteLead: "Registration number already exist" })
        );
      }
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const updateStudents = (id, data) => (dispatch, getState) => {
  axios
    .put(`QLA/Student/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Student Updated!" }));
      dispatch({
        type: UPDATE_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updatePatchStudents = (id, data) => (dispatch, getState) => {
  const TID = toast.loading("Updating Grade Year...⏳");
  axios
    .patch(`QLA/StudentPatch/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      toast.success("User Grade Year!", {
        id: TID,
      });
      dispatch({
        type: UPDATE_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
    });
};

export const getStudentsBySchool = (id) => (dispatch) => {
  dispatch({ SET_STUDENT_LOADING });
  axios
    .get(`QLA/Student/finder/${id}/`)
    .then((res) => {
      dispatch({
        type: GET_STUDENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ SET_STUDENT_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addListStudents = (data, type) => (dispatch, getState) => {
  // console.log(type);
  // const newdata={data:data,type:type}
  const TID = toast.loading("Adding Students...⏳");
  if (type === "list") {
    axios
      .post("QLA/CreateStudentList/", data, tokenConfig(getState))
      .then((res) => {
        toast.success("Students added!", { id: TID });
        dispatch({ type: ADD_LIST_STUDENT, payload: res.data });
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong", { id: TID });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  } else {
    axios
      .post("QLA/CreateStudentList/", data, tokenConfig(getState), {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        toast.success("Students added!", { id: TID });
        dispatch({ type: ADD_LIST_STUDENT, payload: res.data });
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong", { id: TID });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  }
};
