import axios from "axios";
import { returnErrors } from "../message/message";
import { GET_FAQ } from "../types";

export const getFAQ = () => (dispatch, getState) => {
  axios
    .get("Policy/FAQ/")
    .then((res) =>
      dispatch({
        type: GET_FAQ,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
