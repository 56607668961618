import axios from "axios";
import { returnErrors, createMessage } from "../message/message";
import {
  GET_CSV,
  SET_CSV_LOADING,
  SET_CSV_FAIL,
  ADD_CSV,
  SET_ADD_CSV_FAIL,
  SET_ADD_CSV_LOADING,
} from "../types";
import { tokenConfig } from "../auth_action/auth";

export const getCSV =
  (SchoolID, SubjectID, examID, PaperID, yearGrade) => (dispatch, getState) => {
    dispatch({ type: SET_CSV_LOADING });
    axios
      .get(
        `QLA/CSVGenerator/${SchoolID}/${SubjectID}/${examID}/${PaperID}/${yearGrade}/`,
        tokenConfig(getState)
      )
      .then((res) => {
        const url_local = res.data["FileUrl"];
        const splittedURL = url_local.split(
          process.env.REACT_APP_LIVE_EXTENSTION
        );
        const url_final = splittedURL[1];
        const filename = url_final.split("/")[2];
        axios({
          url: url_final,
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
        dispatch({
          type: GET_CSV,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: SET_CSV_FAIL });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const addCSV = (Data) => (dispatch, getState) => {
  dispatch({ type: SET_ADD_CSV_LOADING });
  // const lol="lol"
  // const data ={Data,lol}
  axios
    .post("QLA/CSVGenerator/", Data, tokenConfig(getState), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(createMessage({ addLead: "CSV Uploaded" }));
      dispatch({
        type: ADD_CSV,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_ADD_CSV_FAIL });

      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
