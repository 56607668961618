import React, { Component, Fragment } from "react";
import "../../style/StyleSignin/css/main.css";
import "../../style/StyleSignin/css/util.css";
import "../../style/StyleSignin/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../../style/StyleSignin/fonts/iconic/css/material-design-iconic-font.min.css";
import "../../style/StyleSignin/vendor/animate/animate.css";
import "../../style/StyleSignin/vendor/css-hamburgers/hamburgers.min.css";
import "../../style/StyleSignin/vendor/animsition/css/animsition.min.css";
import "../../style/StyleSignin/vendor/select2/select2.min.css";
import "../../style/StyleSignin/vendor/daterangepicker/daterangepicker.css";
import { register } from "../../actions/auth_action/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { banList } from "../../Banlist";
import Select from "react-select";
import { getCountry } from "../../actions/Country/country_action";
import { getSchool, addSchool } from "../../actions/School/school_actions";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import "../../style/KeenTheme/css/pages/login/login-3.css";
import YupPassword from "yup-password";

YupPassword(yup); // extend yup

const schema1 = yup.object({
  email: yup.string().email("Invalid Email").required("Email Required"),
  firstname: yup.string().required("Enter First Name"),
  lastname: yup.string().required("Enter Last Name"),
  username: yup.string().required("Enter Username"),
  password: yup
    .string()
    .required("Enter Password").min(
      8,
      "Must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 UPPER letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),

  cpassword: yup
    .string()
    .required("Please Confirm Password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("password")],
          "Password and Confirm Password doesn't match"
        )
    })
});
const schema2 = yup.object({
  Country: yup.string().required("Enter Country"),
  School: yup.string().required("Enter School")
});
const schemaSchool = yup.object({
  name: yup.string().required("Enter School Name"),
  urn: yup.string().required("Enter URN/ Registration Number"),
  url: yup.string().required("Enter URL"),
  ContactEmail: yup.string().email("Invalid Email").required("We may need to Send you Email!"),
  ContactPhone: yup.string().required("We may need to contact you!"),
  Address: yup.string().required("Please Enter Address"),
  country: yup.string().required("Please Select your country")
});

class Signup extends Component {
  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    getCountry: PropTypes.func.isRequired,
    addSchool: PropTypes.func.isRequired,
    getSchool: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    countries_loading: PropTypes.bool.isRequired,
    school_list: PropTypes.array.isRequired,
    school_list_loading: PropTypes.bool.isRequired,
    RegisterLoading: PropTypes.bool.isRequired,
    RegisterIsRegistered: PropTypes.bool.isRequired,
    RegisterIsFailed: PropTypes.bool.isRequired
  };
  state = {
    email: undefined,
    password: undefined,
    firstname: undefined,
    lastname: undefined,
    date_of_birth: undefined,
    cpassword: undefined,
    countryOption: undefined,
    NOT_listed: false,
    LoadingOption: true,
    schoolOption: undefined,
    validate: false,
    name: undefined,
    urn: undefined,
    url: undefined,
    country: undefined,
    Country_Bool: true,
    form1: true,
    form2: undefined,
    form3: undefined,
    SchoolName: undefined,
    CountryName: undefined,
    ContactEmail: undefined,
    ContactPhone: undefined,
    Address: undefined,
    username: undefined,
    password_show: "password",
    cpassword_show:"password",
    Password_Class: "fa-eye",
    CPassword_Class: "fa-eye"

  };
  showPassword = () => {
    if (this.state.password_show === "password") {
      this.setState({
        password_show: "text",
        Password_Class: "fa-eye fa-eye-slash"
      });
    } else {
      this.setState({ password_show: "password", Password_Class: "fa-eye" });
    }
  };
  showCPassword = () => {
    if (this.state.cpassword_show === "password") {
      this.setState({
        cpassword_show: "text",
        CPassword_Class: "fa-eye fa-eye-slash"
      });
    } else {
      this.setState({ cpassword_show: "password", CPassword_Class: "fa-eye" });
    }
  };
  onsubmit = (value) => {
    const { email, firstname, lastname, username, password, School, Country } =
      this.state;
    const data = {
      email,
      firstname,
      lastname,
      username,
      password,
      School,
      Country
    };
    this.props.register(data);
    this.setState({
      email: undefined,
      password: undefined,
      firstname: undefined,
      lastname: undefined
      // date_of_birth: undefined,
    });
    // document.getElementById("loginform").reset();
  };
  onsubmit_ADD = (e) => {
    const { name, urn, url, country, ContactEmail, ContactPhone, Address } =
      e;
    const data = {
      name,
      urn,
      url,
      country,
      ContactEmail,
      ContactPhone,
      Address
    };
    this.props.addSchool(data);
    this.setState({
      name: undefined,
      urn: undefined,
      url: undefined,
      Country: undefined,
      NOT_listed: false,
      ContactEmail: undefined,
      ContactPhone: undefined,
      Address: undefined
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  validateEmail = (value) => {
    if (value !== undefined) {
      let error;
      for (const x in banList) {
        if (value.toLowerCase().includes(banList[x])) {
          error = "Domain not allowed";
        }
      }
      if (!value) {
        error = "Email Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email School";
      }
      return error;
    }
  };
  getOption_Country = () => {
    const options = this.props.countries.map((arr) => ({
      value: arr.id,
      label: arr.name
    }));
    this.setState({ countryOption: options, LoadingOption: false });
  };
  getOption_School = (data) => {
    const options = this.props.school_list
      .filter((arr) => arr.country.name === data)
      .map((arr) => ({
        value: arr.id,
        label: arr.name
      }));
    this.setState({ schoolOption: options });
  };
  schoolFinder = async (data) => {
    await this.getOption_School(data);
  };
  onSubmit1 = (value) => {
    this.setState({
      email: value.email,
      password: value.password,
      username: value.username,
      firstname: value.firstname,
      lastname: value.lastname,
      form1: false,
      form2: true
    });
  };
  onSubmit2 = (value) => {
    this.setState({
      School: value.School,
      Country: value.Country,
      form2: false,
      form3: true
    });
  };

  componentDidMount() {
    this.props.getCountry();
    this.props.getSchool();
    this.getOption_Country();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.countries.length !== this.props.countries.length) {
      console.log(this.props.countries.length, prevProps.countries.length);
      this.getOption_Country();
    }
    if (prevProps.school_list.length !== this.props.school_list.length) {
      this.props.getSchool();
    }
  }

  render() {
    const { school_list, countries, RegisterIsRegistered, school_list_loading } = this.props;
    const {
      countryOption,
      NOT_listed,
      form1,
      form2,
      form3,
      Password_Class,
      password_show,
      cpassword_show,
      CPassword_Class,
      email,
      firstname,
      lastname,
      username,
      LoadingOption
    } = this.state;

    return (
      <Fragment>
        <NavbarKeen logo={true} />
        <div
          className="d-flex flex-column flex-root"
          style={{ marginTop: 50, height: "135%" }}
        >
          <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid wizard">
            <div className="login-aside d-flex flex-column flex-row-auto">
              <div className="d-flex flex-column-auto flex-column pt-15 px-30">
                <a href="#" className="login-logo py-6">
                </a>
                <div className="wizard-nav pt-5 pt-lg-30">
                  <div className="wizard-steps">
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      {form1 ? (
                        <div className="wizard-wrapper">
                          <div className="wizard-icon">
                            <i className="wizard-check ki ki-check" />
                            <span className="wizard-number">1</span>
                          </div>
                          <div className="wizard-label">
                            <h3 className="wizard-title">Account Settings</h3>
                            <div className="wizard-desc">
                              Setup Your Account Details
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="wizard-wrapper">
                          <div className="wizard-icon">
                            <i
                              style={{ display: "block" }}
                              className="wizard-check ki ki-check"
                            />
                          </div>
                          <div className="wizard-label">
                            <h3
                              className="wizard-title"
                              style={{ color: "#B5B5C3" }}
                            >
                              Account Settings
                            </h3>
                            <div
                              className="wizard-desc"
                              style={{ color: "#D1D3E0" }}
                            >
                              Setup Your Account Details
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {(() => {
                      if (form2 === undefined) {
                        return (
                          <div data-wizard-type="step" className="wizard-step">
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i className="wizard-check ki ki-check" />
                                <span className="wizard-number">2</span>
                              </div>
                              <div className="wizard-label">
                                <h3 className="wizard-title">School Details</h3>
                                <div className="wizard-desc">Setup School</div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (form2 === true) {
                        return (
                          <div
                            data-wizard-type="step"
                            data-wizard-state="current"
                            className="wizard-step"
                          >
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i className="wizard-check ki ki-check" />
                                <span className="wizard-number">2</span>
                              </div>
                              <div className="wizard-label">
                                <h3 className="wizard-title">School Details</h3>
                                <div className="wizard-desc">Setup School</div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (form2 === false) {
                        return (
                          <div
                            data-wizard-type="step"
                            data-wizard-state="current"
                            className="wizard-step"
                          >
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i
                                  style={{ display: "block" }}
                                  className="wizard-check ki ki-check"
                                />
                              </div>
                              <div className="wizard-label">
                                <h3
                                  className="wizard-title"
                                  style={{ color: "#D1D3E0" }}
                                >
                                  School Details
                                </h3>
                                <div
                                  className="wizard-desc"
                                  style={{ color: "#D1D3E0" }}
                                >
                                  Setup School
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}


                    {(() => {
                      if (form3 === undefined) {
                        return (
                          <div data-wizard-type="step" className="wizard-step">
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i className="wizard-check ki ki-check" />
                                <span className="wizard-number">3</span>
                              </div>
                              <div className="wizard-label">
                                <h3 className="wizard-title">Completed!</h3>
                                <div className="wizard-desc">
                                  Review and Submit
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (form3 === true) {
                        return (
                          <div
                            data-wizard-type="step"
                            data-wizard-state="current"
                            className="wizard-step"
                          >
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i className="wizard-check ki ki-check" />
                                <span className="wizard-number">3</span>
                              </div>
                              <div className="wizard-label">
                                <h3 className="wizard-title">Completed!</h3>
                                <div className="wizard-desc">
                                  Review and Submit
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (form3 === false) {
                        return (
                          <div
                            data-wizard-type="step"
                            data-wizard-state="current"
                            className="wizard-step"
                          >
                            <div className="wizard-wrapper">
                              <div className="wizard-icon">
                                <i
                                  style={{ display: "block" }}
                                  className="wizard-check ki ki-check"
                                />
                              </div>
                              <div className="wizard-label">
                                <h3
                                  className="wizard-title"
                                  style={{ color: "#D1D3E0" }}
                                >
                                  Completed!
                                </h3>
                                <div
                                  className="wizard-desc"
                                  style={{ color: "#D1D3E0" }}
                                >
                                  Review and Submit
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>

              <div
                className="aside-img-wizard d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center pt-2 pt-lg-5"
                // style={{
                //   backgroundPositionY: "calc(100% + 3rem)",
                //   backgroundImage: `url(${pic})`,
                // }}
              />
            </div>
            <div className="login-content flex-column-fluid d-flex flex-column p-10">
              <div className="text-right d-flex justify-content-center">
                <div className="top-signup text-right d-flex justify-content-end pt-5 pb-lg-0 pb-10">
                  <span className="font-weight-bold text-muted font-size-h4">
                    Having issues?
                  </span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="font-weight-bolder text-primary font-size-h4 ml-2"
                  >
                    Get Help
                  </a>
                </div>
              </div>

              <div className="d-flex flex-row-fluid flex-center1">
                <div className="login-form login-form-signup">
                  {form1 ? (
                    <Formik
                      validationSchema={schema1}
                      onSubmit={this.onSubmit1}
                      initialValues={{
                        email: email,
                        username: username,
                        password: "",
                        firstname: firstname,
                        lastname: lastname
                      }}
                    >
                      {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors
                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                          <div
                            className="pb-5"
                            data-wizard-state="current"
                            data-wizard-type="step-content"
                          >
                            <div className="pb-10 pb-lg-15">
                              <h3 className="font-weight-bolder text-dark display5">
                                Create Account
                              </h3>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-6">
                                  <label className="font-size-h6 font-weight-bolder text-dark">
                                    Email <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="emailform"
                                    placeholder="Email"
                                    className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                    name="email"
                                    validate={this.validateEmail}
                                  />
                                  {errors.email && touched.email && (
                                    <div style={{ color: "#dc3545" }}>
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                                <div className="col-6">
                                  <label className="font-size-h6 font-weight-bolder text-dark">
                                    Username{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Form.Control
                                    className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    value={values.username}
                                    onChange={handleChange}
                                    isInvalid={!!errors.username}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-6">
                                <label className="font-size-h6 font-weight-bolder text-dark">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Form.Control
                                  className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                  type="text"
                                  name="firstname"
                                  placeholder="First Name"
                                  value={values.firstname}
                                  onChange={handleChange}
                                  isInvalid={!!errors.firstname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.firstname}
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-6">
                                <label className="font-size-h6 font-weight-bolder text-dark">
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Form.Control
                                  className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                  type="text"
                                  name="lastname"
                                  placeholder="Last Name"
                                  value={values.lastname}
                                  onChange={handleChange}
                                  isInvalid={!!errors.lastname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.lastname}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-6">
                                <label className="font-size-h6 font-weight-bolder text-dark">
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Form.Control
                                  className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                  type={password_show}
                                  name="password"
                                  placeholder="Password"
                                  value={values.password}
                                  isInvalid={!!errors.password}
                                  onChange={handleChange}
                                />
                                <span
                                  onClick={(e) => this.showPassword(e)}
                                  className={`fa fa-fw ${Password_Class}  field-icon toggle-password `}
                                />
                                <Form.Control.Feedback type="invalid">
                                  <span>{errors.password}</span>
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-6">
                                <label className="font-size-h6 font-weight-bolder text-dark">
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Form.Control
                                  className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                  type={cpassword_show}
                                  placeholder="Confirm Password"
                                  name="cpassword"
                                  value={values.cpassword}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cpassword}
                                />
                                <span
                                  onClick={(e) => this.showCPassword(e)}
                                  className={`fa fa-fw ${CPassword_Class}  field-icon toggle-password `}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cpassword}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="text-muted font-weight-bold font-size-h6">
                              Already have an Account ?
                              <a
                                href="/login/"
                                className="text-primary font-weight-bolder"
                              >
                                {" "}
                                &nbsp; Sign In
                              </a>
                            </div>
                            <div className="d-flex justify-content-between pt-3">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                                >
                                  Next Step
                                  <span className="svg-icon svg-icon-md ml-1">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                          x="7.5"
                                          y="7.5"
                                          width={2}
                                          height={9}
                                          rx={1}
                                        />
                                        <path
                                          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    ""
                  )}

                  {form2 ? (
                    <Formik
                      validationSchema={schema2}
                      onSubmit={this.onSubmit2}
                      initialValues={{}}
                    >
                      {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors
                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div
                            className="pb-5"
                            data-wizard-state="current"
                            data-wizard-type="step-content"
                          >
                            <div className="pt-lg-0 pt-5 pb-15">
                              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                                School Details
                              </h3>
                            </div>

                            <div className="row">
                              <div className="col-xl-12">
                                <Form.Group className="form-group">
                                  <Form.Label className="font-size-h6 font-weight-bolder text-dark">
                                    Select Country{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Form.Control
                                    as="select"
                                    className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                    name="Country"
                                    value={values.Country}
                                    onChange={handleChange}
                                    isInvalid={!!errors.Country}
                                  >
                                    <option>Select Country</option>
                                    {countries.map((arr, key) => (
                                      <option key={key} value={arr.id}>
                                        {arr.name}
                                      </option>
                                    ))}
                                  </Form.Control>

                                  {/*<Select*/}
                                  {/*  isLoading={this.props.countries_loading}*/}
                                  {/*  name="Country"*/}
                                  {/*  value={values.value}*/}
                                  {/*  options={countryOption}*/}
                                  {/*  isInvalid={!!errors.Country}*/}
                                  {/*  onChange={(e) => {*/}
                                  {/*    this.setState({*/}
                                  {/*      Country_Bool: false,*/}
                                  {/*    });*/}
                                  {/*    this.schoolFinder(e.label);*/}
                                  {/*    this.setState({*/}
                                  {/*      CountryName: e.label,*/}
                                  {/*    });*/}
                                  {/*    // setFieldValue("Country", e.value);*/}
                                  {/*  }}*/}
                                  {/*/>*/}
                                  <Form.Control.Feedback type="invalid">
                                    {errors.Country}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-xl-12">
                                {school_list_loading ? <Fragment>
                                  <Button variant="primary" disabled>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    &nbsp; Adding your School...
                                  </Button>
                                </Fragment> : <div className="form-group">
                                  <label className="font-size-h6 font-weight-bolder text-dark">
                                    Select School{" "}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <Form.Control
                                    as="select"
                                    className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                                    name="School"
                                    value={values.School}
                                    onChange={handleChange}
                                    isInvalid={!!errors.School}
                                  >
                                    <option>Select School</option>
                                    {school_list.map((arr, key) => (
                                      <option key={key} value={arr.id}>
                                        {arr.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {/*<Select*/}
                                  {/*  // isDisabled={Country_Bool}*/}
                                  {/*  isLoading={this.props.school_list_loading}*/}
                                  {/*  name="School"*/}
                                  {/*  value={values.value}*/}
                                  {/*  options={schoolOption}*/}
                                  {/*  isInvalid={!!errors.School}*/}
                                  {/*  onChange={(e) => {*/}
                                  {/*    this.setState({*/}
                                  {/*      SchoolName: e.label,*/}
                                  {/*    });*/}
                                  {/*    // setFieldValue("School", e.value);*/}
                                  {/*  }}*/}
                                  {/*/>*/}
                                  <Form.Control.Feedback type="invalid">
                                    {errors.School}
                                  </Form.Control.Feedback>
                                </div>
                                }
                              </div>
                              <div className="text-muted font-weight-bold font-size-h4">
                                Can't find your school ?
                                <a
                                  onClick={() =>
                                    this.setState({
                                      NOT_listed: !NOT_listed
                                    })
                                  }
                                  className="text-primary font-weight-bolder"
                                >
                                  {" "}
                                  &nbsp; Add School
                                </a>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between pt-3">
                              <div className="mr-2">
                                <button
                                  onClick={() =>
                                    this.setState({
                                      form1: !form1,
                                      form2: !form2
                                    })
                                  }
                                  type="button"
                                  className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                                >
                                  <span className="svg-icon svg-icon-md mr-1">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                          x={14}
                                          y={7}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                        <path
                                          d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                  Previous
                                </button>
                              </div>
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                                >
                                  Next Step
                                  <span className="svg-icon svg-icon-md ml-1">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                          x="7.5"
                                          y="7.5"
                                          width={2}
                                          height={9}
                                          rx={1}
                                        />
                                        <path
                                          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    ""
                  )}

                  {form3 ? (
                    <Formik
                      // validationSchema={schema}
                      onSubmit={this.onSubmit}
                      initialValues={{}}
                    >
                      {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors
                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div
                            className="pb-5"
                            data-wizard-state="current"
                            data-wizard-type="step-content"
                          >
                            {/*begin::Title*/}
                            <div className="pt-lg-0 pt-5 pb-15">
                              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                                Complete
                              </h3>
                              <div className="text-muted font-weight-bold font-size-h4">
                                Complete Your Signup And Become A Member!
                              </div>
                            </div>
                            {/*end::Title*/}
                            {/*begin::Section*/}
                            <h4 className="font-weight-bolder mb-3">
                              Account Settings:
                            </h4>
                            <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
                              <div>
                                Name:{firstname}&nbsp;{lastname}
                              </div>
                              <div>{email}</div>
                            </div>
                            {/*end::Section*/}
                            {/*begin::Section*/}
                            {/*<h4 className="font-weight-bolder mb-3">*/}
                            {/*  School Details:*/}
                            {/*</h4>*/}
                            {/*<div className="text-dark-50 font-weight-bold line-height-lg mb-8">*/}
                            {/*  <div>{SchoolName}</div>*/}
                            {/*  <div>{CountryName}</div>*/}
                            {/*</div>*/}
                            {/*end::Section*/}
                            {/*begin::Section*/}

                            {this.props.RegisterLoading ? (
                              <div className="row">
                                <div className="col-6">
                                  <Spinner
                                    animation="border"
                                    variant="success"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </div>
                                <div>
                                  <p>
                                    Please Wait While you account is registered
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.props.RegisterIsRegistered ? (
                              <div className="row">
                                <div>
                                  <p>
                                    Congratulations You account is Register,
                                    Please <b>check</b> your email{" "}
                                    <b>{email}</b>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.props.RegisterIsFailed ? (
                              <div className="row">
                                <div>
                                  <p>
                                    Oops. Please <b>Try again</b> or{" "}
                                    <b>contact support</b>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="d-flex justify-content-between pt-3">
                              <div className="mr-2">
                                <button
                                  onClick={() =>
                                    this.setState({
                                      form3: !form3,
                                      form2: !form2
                                    })
                                  }
                                  type="button"
                                  className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3"
                                >
                                  <span className="svg-icon svg-icon-md mr-1">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                          x={14}
                                          y={7}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                        <path
                                          d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                  Previous
                                </button>
                              </div>
                              <div>
                                {RegisterIsRegistered ? (
                                  <a
                                    href="/login"
                                    className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                                    type="submit"
                                  >
                                    Login
                                    <span className="svg-icon svg-icon-md ml-2">
                                      {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <polygon points="0 0 24 0 24 24 0 24" />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                            x="7.5"
                                            y="7.5"
                                            width={2}
                                            height={9}
                                            rx={1}
                                          />
                                          <path
                                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                                    onClick={this.onsubmit}
                                    type="submit"
                                  >
                                    Submit
                                    <span className="svg-icon svg-icon-md ml-2">
                                      {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <polygon points="0 0 24 0 24 24 0 24" />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                            x="7.5"
                                            y="7.5"
                                            width={2}
                                            height={9}
                                            rx={1}
                                          />
                                          <path
                                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>

                            {/*end::Section*/}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={NOT_listed}
          onHide={() => this.setState({ NOT_listed: !NOT_listed })}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add School</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={schemaSchool}
              onSubmit={(values) => this.onsubmit_ADD(values)}
              initialValues={{
                name: ""
              }}
            >
              {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors
                }) => (
                <Form className="login100-form login-content" noValidate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        School Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100"
                        type="text"
                        name="name"
                        placeholder="School Name"
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>

                    </div>
                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        URN / Registration Number{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100"
                        type="text"
                        name="urn"
                        placeholder="URN / Registration Number"
                        onChange={handleChange}
                        isInvalid={!!errors.urn}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.urn}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        URL / Website Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100"
                        type="text"
                        name="url"
                        placeholder="URL / Website"
                        onChange={handleChange}
                        isInvalid={!!errors.url}

                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        Select Country <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        name="country"
                        isLoading={LoadingOption}
                        isInvalid={!!errors.country}
                        options={countryOption}
                        onChange={(e, event) => {
                          handleChange({
                            ...event,
                            target: {
                              name: "country",
                              value: e.value
                            }
                          });
                        }}
                      />
                      {errors.country && (
                        <div style={{ color: "#dc3545" }}>
                          {errors.country}
                        </div>
                      )}
                    </div>

                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        Contact Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100 "
                        type="email"
                        name="ContactEmail"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        isInvalid={!!errors.ContactEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ContactEmail}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-6">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        Contact Phone <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100"
                        type="text"
                        name="ContactPhone"
                        placeholder="Contact Phone"
                        onChange={handleChange}
                        isInvalid={!!errors.ContactPhone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ContactPhone}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-12">
                      <Form.Label className="wrap-input100  m-t-35 m-b-35">
                        Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="input100"
                        type="text"
                        name="Address"
                        placeholder="Address"
                        onChange={handleChange}
                        isInvalid={!!errors.Address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.Address}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="row mt-2 text-center">
                    <div className="col-12">
                      <Button
                        variant="btn btn-danger"
                        onClick={() => this.setState({ NOT_listed: !NOT_listed })}
                      >
                        Close
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        variant="btn btn-success"
                        type="submit"
                        // onClick={(e) => this.onsubmit_ADD(e)}
                      >
                        Add School
                      </Button>
                    </div>
                  </div>
                </Form>

              )}
            </Formik>

          </Modal.Body>
          {/*<Modal.Footer>*/}
          {/**/}
          {/*</Modal.Footer>*/}
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  countries: state.countries.countries,
  countries_loading: state.countries.isLoading,
  RegisterLoading: state.Register.isLoading,
  RegisterIsFailed: state.Register.IsFailed,
  school_list: state.school_list.school_list,
  isAuthenticated: state.auth.isAuthenticated,
  school_list_loading: state.school_list.isLoading,
  RegisterIsRegistered: state.Register.IsRegistered
});

export default connect(mapStateToProps, {
  register,
  getCountry,
  getSchool,
  addSchool
})(Signup);
