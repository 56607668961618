import axios from "axios";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

import {
  VERIFY_OTP_SCHOOL_LOADING,
  VERIFY_OTP_SCHOOL,
  VERIFY_OTP_SCHOOL_LOADING_FAIL,
} from "../types";
import toast from "react-hot-toast";

export const Verify_School_OTP = (data) => (dispatch, getState) => {
  const TID = toast.loading("Verifing School...⏳");
  dispatch({ type: VERIFY_OTP_SCHOOL_LOADING });
  axios
    .post("QLA/verifySchoolEmail/", data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: VERIFY_OTP_SCHOOL,
        payload: res.data,
      });
      toast.success(res.data.message, {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      dispatch({ type: VERIFY_OTP_SCHOOL_LOADING_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};
