import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  getSchoolByCreated,
  updateSchool,
} from "../../actions/School/school_actions";
import {
  getSchoolMapID,
  updateSchoolMapID,
} from "../../actions/School/School_Map_Action";
import { getCountry } from "../../actions/Country/country_action";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import * as yup from "yup";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import WordFinder from "../KeenTheme/WordFinder";
const schema = yup.object({
  ContactEmail: yup.string().email("Invalid Email").required("Email Required"),
  ContactPhone: yup.string().required("Enter Phone Number"),
  name: yup.string().required("Enter School Name"),
  urn: yup.string().required("Enter URN/ Registration Number"),
  url: yup.string().required("Enter URL"),
  Address: yup.string().required("Enter Address"),
  country: yup.string().required("Select a country"),
  subjects: yup
    .array()
    .min(1, "Select atleast one subject")
    .required("Select atleast one subject"),
});
export function AdminSchoolPage() {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(false);

  useEffect(() => {
    dispatch(getSchoolByCreated());
    dispatch(getCountry());
    dispatch(getSubject());
    dispatch(getSchoolMapID(School.user.user.School.id));
  }, []);
  const School=useSelector((state)=>state.auth)
  const SchoolByCreated = useSelector((state) => state.SchoolByCreated);
  const countries = useSelector((state) => state.countries);
  const subjects = useSelector((state) => state.subjectsList);
  const school_map_user = useSelector((state) => state.school_map_user);
  const updateSchoolvalues = (values) => {
    let {
      name,
      urn,
      ContactEmail,
      ContactPhone,
      Address,
      country,
      url,
      subjects,
    } = values;
    subjects = subjects.map((subject) => subject.value);
    const data = {
      name,
      urn,
      ContactEmail,
      ContactPhone,
      Address,
      country,
      url,
      subjects,
    };
    dispatch(updateSchool(SchoolByCreated.SchoolByCreated.id, data));
  };
  const MarkJoin = (e, id) => {
    let Joined = "";
    if (e.target.checked) {
      Joined = "Accepted";
    } else {
      Joined = "Rejected";
    }
    const body = { Joined };
    dispatch(updateSchoolMapID(id, body));
  };

  const MarkAccepted = (e, id) => {
    let Accepted = "";
    if (e.target.checked) {
      Accepted = "Accepted";
    } else {
      Accepted = "Rejected";
    }
    const body = { Accepted };
    dispatch(updateSchoolMapID(id, body));
  };
  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="d-flex flex-row">
          <div className="flex-row-fluid ml-lg-12">
            <div className="card card-custom">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">
                    School Information
                  </h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                    Change your School settings
                  </span>
                </div>
                <div className="card-title align-items-end flex-column">
                  <button
                    onClick={() => setSettings(!settings)}
                    className="btn btn-success btn-sm text-center "
                  >
                    {settings ? (
                      <>
                        <i className="flaticon-settings-1"></i>School
                      </>
                    ) : (
                      <>
                        <i className="flaticon2-user-1"></i>Users
                      </>
                    )}
                  </button>
                </div>
              </div>
              {settings ? (
                SchoolByCreated.isLoading &&
                SchoolByCreated.SchoolByCreated.length === 0 ? (
                  <Spinner animation="border" variant="success" />
                ) : (
                  <Formik
                    validationSchema={schema}
                    onSubmit={(values) => updateSchoolvalues(values)}
                    initialValues={{
                      name: SchoolByCreated.SchoolByCreated.name,
                      urn: SchoolByCreated.SchoolByCreated.urn,
                      ContactEmail:
                        SchoolByCreated.SchoolByCreated.ContactEmail,
                      ContactPhone:
                        SchoolByCreated.SchoolByCreated.ContactPhone,
                      Address: SchoolByCreated.SchoolByCreated.Address,
                      country: "",
                      url: SchoolByCreated.SchoolByCreated.url,
                      subjects: "",
                    }}
                  >
                    {({ handleSubmit, handleChange, values, errors }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="row">
                            <label className="col-xl-3" />
                            <div className="col-lg-9 col-xl-6">
                              <h5 className="font-weight-bold mb-6">School:</h5>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label ">
                              Name:
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <Form.Control
                                className="form-control form-control-lg box-border-style_new"
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={handleChange}
                                placeholder="Name"
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Email Address:
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <div className="input-group input-group-lg input-group-solid">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="la la-at" />
                                  </span>
                                </div>
                                <Form.Control
                                  disabled={true}
                                  name="ContactEmail"
                                  onChange={handleChange}
                                  value={values.ContactEmail}
                                  type="text"
                                  className="form-control form-control-lg "
                                  placeholder="Email"
                                  isInvalid={!!errors.ContactEmail}
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.ContactEmail}
                                </Form.Control.Feedback>
                              </div>
                              <span class="form-text text-muted">
                                You cannot edit school's email
                              </span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              URN / Registration No.
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <Form.Control
                                onChange={handleChange}
                                type="text"
                                name="urn"
                                value={values.urn}
                                className="form-control form-control-lg form-control-solid"
                                placeholder="URN / Registration No."
                                isInvalid={!!errors.urn}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.urn}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Phone Number:
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <div className="input-group input-group-lg input-group-solid">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="la la-phone" />
                                  </span>
                                </div>
                                <Form.Control
                                  name="ContactPhone"
                                  value={values.ContactPhone}
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Phone Number"
                                  isInvalid={!!errors.ContactPhone}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ background: "white" }}
                                >
                                  {errors.ContactPhone}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Website:
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <div className="input-group input-group-lg input-group-solid">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="la la-chain" />
                                  </span>
                                </div>
                                <Form.Control
                                  name="url"
                                  value={values.url}
                                  onChange={handleChange}
                                  type="url"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Website"
                                  isInvalid={!!errors.url}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ background: "white" }}
                                >
                                  {errors.url}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Address:
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <div className="input-group input-group-lg input-group-solid">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="la la-map-marker" />
                                  </span>
                                </div>
                                <Form.Control
                                  name="Address"
                                  value={values.Address}
                                  onChange={handleChange}
                                  placeholder="Address"
                                  isInvalid={!!errors.Address}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ background: "white" }}
                                >
                                  {errors.Address}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Country
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <Select
                                isLoading={countries.isLoading}
                                name="country"
                                // value={values.value}
                                options={countries.countries.map((arr) => ({
                                  value: arr.id,
                                  label: arr.name,
                                }))}
                                onChange={(e, event) => {
                                  handleChange({
                                    ...event,
                                    target: {
                                      name: "country",
                                      value: e.value,
                                    },
                                  });
                                }}
                              />
                              {errors.country && (
                                <span className="text-danger">
                                  {errors.country ? errors.country : ""}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Subjects
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <Select
                                isLoading={subjects.isLoading}
                                name="subjects"
                                isMulti
                                value={values.value}
                                options={subjects.subjectsList.map((arr) => ({
                                  value: arr.id,
                                  label: arr.name,
                                }))}
                                onChange={(selectedOption) => {
                                  let event = {
                                    target: {
                                      name: "subjects",
                                      value: selectedOption,
                                    },
                                  };
                                  handleChange(event);
                                }}
                              />
                              {errors.subjects && (
                                <span className="text-danger">
                                  {errors.subjects ? errors.subjects : ""}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="separator separator-dashed my-5" />
                          <div className="card-toolbar">
                            <button
                              type="submit"
                              className="btn btn-success mr-2"
                            >
                              Save Changes
                            </button>
                            <button type="reset" className="btn btn-danger">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )
              ) : (
                <div className="card-body py-5 px-3">
                  <div className="tab-content">
                    <div className="table-responsive">
                      {school_map_user.isLoading &&
                      school_map_user.school_map_user.length !== 0 ? (
                        ""
                      ) : (
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                          <thead>
                            <tr className="text-left text-uppercase">
                              <th style={{ minWidth: "50px" }}>ID#</th>
                              <th
                                style={{ minWidth: "180px" }}
                                className="pl-7"
                              >
                                <span className="text-dark-50">User</span>
                              </th>
                              <th style={{ minWidth: "80px" }}>Email</th>
                              <th style={{ minWidth: "50px" }}>Phone</th>
                              <th style={{ minWidth: "30px" }}>Joined</th>
                              <th style={{ minWidth: "30px" }}>Accepted</th>
                              <th
                                className="text-center"
                                style={{ minWidth: "200px" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {school_map_user.school_map_user
                              .sort((a, b) => a.id - b.id)
                              .map((user, key) => (
                                <tr key={key}>
                                  <td>
                                    <span className="text-muted font-weight-bold">
                                      {user.id}
                                    </span>
                                  </td>
                                  <td className="pl-0 py-8">
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-50 symbol-light mr-4">
                                        <span className="symbol-label">
                                          {user.User.pic === null ? (
                                            <WordFinder
                                              data={user.User.firstname}
                                            />
                                          ) : (
                                            <img
                                              src={user.User.pic}
                                              className="h-75 align-self-end"
                                              alt=""
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <div>
                                        <p className="text-dark-75 font-weight-bolder mb-1 font-size-lg text-capitalize">
                                          {user.User.firstname}{" "}
                                          {user.User.lastname}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="text-muted font-weight-bold">
                                      {user.User.email}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-muted font-weight-bold d-block font-size-sm">
                                      {user.User.phone
                                        ? user.User.phone
                                        : "Not Provided"}
                                    </span>
                                  </td>

                                  <td>
                                    <span className="text-muted font-weight-bold d-block font-size-sm">
                                      {user.Joined === "Pending" ? (
                                        <i className="text-warning far fa-stop-circle icon-lg" />
                                      ) : user.Joined === "Accepted" ? (
                                        <i className="text-success fa far fa-check-circle icon-lg" />
                                      ) : (
                                        <i className="text-danger fa far fa-times-circle icon-lg" />
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-muted font-weight-bold d-block font-size-sm">
                                      {user.Accepted === "Pending" ? (
                                        <i className="text-warning far fa-stop-circle icon-lg" />
                                      ) : user.Accepted === "Accepted" ? (
                                        <i className="text-success fa far fa-check-circle icon-lg" />
                                      ) : (
                                        <i className="text-danger fa far fa-times-circle icon-lg" />
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-center pr-0">
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {user.Joined === "Accepted"
                                            ? `Mark ${user.User.firstname} as not joined`
                                            : `Mark ${user.User.firstname} as joined`}
                                        </Tooltip>
                                      }
                                      placement="bottom"
                                    >
                                      <div className="btn btn-icon btn-sm mx-3">
                                        <span className="switch switch-sm switch-icon ">
                                          <label>
                                            <input
                                              onChange={(e) =>
                                                MarkJoin(e, user.id)
                                              }
                                              type="checkbox"
                                              checked={
                                                user.Joined === "Accepted"
                                                  ? true
                                                  : false
                                              }
                                              name="select"
                                            />
                                            <span></span>
                                          </label>
                                        </span>
                                      </div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {user.Accepted === "Accepted"
                                            ? `Mark ${user.User.firstname} as not accpeted`
                                            : `Mark ${user.User.firstname} as accepted`}
                                        </Tooltip>
                                      }
                                      placement="bottom"
                                    >
                                      <div className="btn btn-icon btn-sm">
                                        <span className="switch switch-sm switch-icon">
                                          <label>
                                            <input
                                              onChange={(e) =>
                                                MarkAccepted(e, user.id)
                                              }
                                              type="checkbox"
                                              checked={
                                                user.Accepted === "Accepted"
                                                  ? true
                                                  : false
                                              }
                                              name="select"
                                            />
                                            <span></span>
                                          </label>
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AdminSchoolPage);
