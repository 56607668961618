import React from "react";
import moment from "moment";

export default function ProgressFunction(props) {
  var today = new Date();

  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  var dateTime = date + " " + time;

  const expireDate = props.data;
  const ExpiredDateFormatted = moment(expireDate).format("llll");
  const todayFormatted = moment(dateTime).format("llll");
  const RemainingDate = Math.round(
    (Date.parse(ExpiredDateFormatted) - Date.parse(todayFormatted)) /
      (1000 * 60 * 60 * 24)
  );
  const Percentage = (RemainingDate / 365) * 100;

  return (
    <>
      <span className="text-dark mr-2 font-size-lg font-weight-bolder pb-3">
        Remaining Days:&nbsp;{RemainingDate}
      </span>

      <div className="progress progress-xs w-100">
        <div
          className={`progress-bar ${
            Percentage < 50 ? "bg-danger" : "bg-success"
          }`}
          role="progressbar"
          style={{ width: `${Percentage}%` }}
          aria-valuenow={50}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </>
  );
}
