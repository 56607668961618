import React, { Component } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
// import csv from "../../media/svg/csv-file-format.svg";
import CsvFileFormat from '../../media/svg/CsvFileFormat'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  get_Class_Filter,
  getClass,
} from "../../actions/ClassActionFolder/ClassActions";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getExams } from "../../actions/Exam/ExamsAction";
import { getQuestionPaper } from "../../actions/QuestionPaper/QuestionPaperAction";
import { getQuestion } from "../../actions/Question/QuestionAction";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import { addStudentMarks } from "../../actions/StudentMarks/StudentMarksAction";
import { getCSV } from "../../actions/CSVGenerator/CSVGenratorAction";
import { addCSV } from "../../actions/CSVGenerator/CSVGenratorAction";
// import Waiting from "../functions/Waiting";
// import { getCheckExamExist } from "../../actions/CheckMockExamResult/CheckMockResultAction";
import LoadingMarks from "../functions/LoadingMarks";
import StudentLoading from "../functions/StudentLoading";

class StudentMarks extends Component {
  state = {
    class_s: undefined,
    subject: undefined,
    exam: undefined,
    paper: undefined,
    errors: "",
    success: "",
    validate: false,
    scores: [],
    testerx: "",
    classOption: undefined,
    yearGrade: undefined,
    CSVFile: undefined,
    CSVUploaded: false,
    Checking: false,
  };
  static propTypes = {
    addCSV: PropTypes.func.isRequired,
    CsvList: PropTypes.array.isRequired,
    getCSV: PropTypes.array.isRequired,
    getClass: PropTypes.func.isRequired,
    getExams: PropTypes.func.isRequired,
    ExamList: PropTypes.array.isRequired,
    classList: PropTypes.array.isRequired,
    getSubject: PropTypes.array.isRequired,
    getQuestion: PropTypes.array.isRequired,
    studentList: PropTypes.array.isRequired,
    getGradeYear: PropTypes.func.isRequired,
    subjectsList: PropTypes.array.isRequired,
    GradeYearList: PropTypes.array.isRequired,
    QuestionsList: PropTypes.array.isRequired,
    QuestionPaper: PropTypes.array.isRequired,
    CsvListLoading: PropTypes.bool.isRequired,
    addStudentMarks: PropTypes.func.isRequired,
    ExamListLoading: PropTypes.bool.isRequired,
    QuestionLoading: PropTypes.bool.isRequired,
    classFilterList: PropTypes.array.isRequired,
    getQuestionPaper: PropTypes.func.isRequired,
    get_Class_Filter: PropTypes.func.isRequired,
    classListLoading: PropTypes.bool.isRequired,
    studentListLoading: PropTypes.bool.isRequired,
    subjectsListLoading: PropTypes.bool.isRequired,
    getStudentsBySchool: PropTypes.func.isRequired,
    QuestionPaperLoading: PropTypes.bool.isRequired,
    GradeYearListLoading: PropTypes.bool.isRequired,
    classFilterListLoading: PropTypes.bool.isRequired,
    StudentMarksListLoading: PropTypes.bool.isRequired,
  };
  calculator = (event, arr, length, main_array) => {
    let total = 0;
    for (let x = 1; x <= length; x++) {
      total += parseInt(
        document.getElementById(x + "-EnterMarks-" + main_array.id).value
      );
    }
    const element = document.getElementById("totalMarks-" + main_array.id);
    element.value = total;
  };
  validator = (event) => {
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
    }
    this.setState({ validate: true });
  };
  onchangeRow = (e, studentId, questionId) => {
    const element = document.getElementById(e.target.id);
    if (
      Number(e.target.min) <= Number(e.target.value) &&
      Number(e.target.value) <= Number(e.target.max)
    ) {
      if (element.classList.contains("is-invalid")) {
        element.classList.remove("is-invalid");
      }
    } else {
      element.classList.add("is-invalid");
      element.value = "";
    }

    const updatedScore = this.state.scores.map((questionScore) => {
      if (questionScore.questionId !== questionId) return questionScore;
      return {
        ...questionScore,
        studentScores: questionScore.studentScores.map((studentScore) => {
          if (studentScore.studentId !== studentId) return studentScore;
          return {
            ...studentScore,
            score: e.target.value,
          };
        }),
      };
    });
    this.setState({ scores: updatedScore });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeYearGrade = (e) => {
    this.setState({ [e.target.name]: e.target.value, Checking: true }, () =>
      this.classFilter()
    );
  };
  classFilter = () => {
    const schoolid = localStorage.getItem("Schoolid");
    const yearGrade = this.state.yearGrade;
    const subject = this.state.subject;

    const body = { schoolid, yearGrade, subject };
    this.props.get_Class_Filter(body);
  };
  onChangePaper = (e) => {
    const data = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.props.getQuestion({ data })
    );
  };
  classMapper = () => {
    const options = this.props.classList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ classOption: options });
  };
  submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.addStudentMarks(this.state.scores);
  };
  exportCSV = (e) => {
    e.preventDefault();
    const schoolid = localStorage.getItem("Schoolid");
    const { SubjectID, ExamID, PaperID, YearGrade } = this.state;
    const body = { schoolid, SubjectID, ExamID, PaperID, YearGrade };
    this.props.getCSV(body);
  };
  initializeStudentsScore = () => {
    if (this.props.QuestionsList !== undefined) {
      const scores = this.props.QuestionsList.map((question) => ({
        questionId: question.id,
        SubjectID: this.state.subject,
        SchoolID: localStorage.getItem("Schoolid"),
        ExamID: this.state.exam,
        PaperID: this.state.paper,
        studentScores: this.props.classFilterList?.students.map((student) => ({
          studentId: student.id,
          score: undefined,
        })),
      }));
      this.setState({ ...this.state, scores });
    }
  };
  handleFilesChosen = (e) => {
    this.setState({ CSVFile: e.target.files[0], CSVUploaded: true });
  };
  SendCSV = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("CVSFILE", this.state.CSVFile);
    formData.append("SubjectID", this.state.subject);
    formData.append("SchoolID", localStorage.getItem("Schoolid"));
    formData.append("PaperID", this.state.paper);
    formData.append("ExamID", this.state.exam);
    this.props.addCSV(formData);
  };

  componentDidMount() {
    this.props.getClass();
    this.props.getSubject();
    this.props.getExams();
    this.props.getQuestionPaper();
    this.props.getGradeYear();
    // await this.classMapper();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.classFilterList !== this.props.classFilterList ||
      prevProps.QuestionsList !== this.props.QuestionsList
    ) {
      this.initializeStudentsScore();
    }
  }

  render() {
    const { subject, exam, paper, yearGrade, Checking } = this.state;
    const {
      ExamList,
      ExamListLoading,
      subjectsList,
      QuestionPaper,
      QuestionsList,
      GradeYearList,
      QuestionLoading,
      classFilterList,
      subjectsListLoading,
      GradeYearListLoading,
      StudentMarksListLoading,
      classFilterListLoading,
      // studentList,
      // classList,
    } = this.props;
    const empty = (
      <table className="table table-head-custom table-vertical-center">
        <thead>
          <tr className="text-left">
            <th className="pl-0" style={{ width: "100%" }}>
              #
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pl-0 text-center">
              <span className="text-dark-75 font-weight-bolder font-size-lg">
                {(() => {
                  if (classFilterListLoading) {
                    return (
                      <>
                        <Spinner animation="border" variant="success" /> &nbsp;
                        Fetching Student Data
                      </>
                    );
                  } else if (Checking && classFilterList.length === 0) {
                    return <>No student data Found</>;
                  } else {
                    return <>Please Select Data to continue</>;
                  }
                })()}{" "}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
    if (QuestionLoading) {
      return StudentLoading();
    } else if (StudentMarksListLoading) {
      return LoadingMarks();
    } else if (!QuestionLoading && !StudentMarksListLoading) {
      return (
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 text-center">
              <h4>Select Follow Details</h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-3 text-center">
              <div className="d-flex flex-sm-row flex-column">
                {subjectsListLoading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp; Loading subject...
                  </Button>
                ) : (
                  <select
                    className="form-control"
                    name="subject"
                    disabled={subjectsListLoading}
                    onChange={(e) => this.onChange(e)}
                  >
                    <option selected disabled>
                      ---Select Subject---
                    </option>
                    {subjectsList.length !== 0
                      ? subjectsList.map((arr, key) => (
                          <option
                            key={key}
                            value={arr.id}
                            className="text-capitalize"
                          >
                            {arr.name}
                          </option>
                        ))
                      : ""}
                  </select>
                )}
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="d-flex flex-sm-row flex-column">
                <select
                  className="form-control"
                  name="yearGrade"
                  disabled={subject === undefined || GradeYearListLoading}
                  onChange={(e) => this.onChangeYearGrade(e)}
                >
                  <option selected disabled>
                    ---Select Grade Year----
                  </option>
                  {GradeYearList.map((arr, key) => (
                    <option
                      key={key}
                      value={arr.id}
                      className="text-capitalize"
                    >
                      {arr.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="d-flex flex-sm-row flex-column">
                <select
                  disabled={
                    yearGrade === undefined ||
                    ExamListLoading ||
                    classFilterList.length === 0
                  }
                  className="form-control"
                  name="exam"
                  onChange={(e) => this.onChange(e)}
                >
                  <option selected disabled>
                    ---Select Exam---
                  </option>
                  {ExamList.map((arr, key) => (
                    <option key={key} value={arr.id}>
                      {arr.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="d-flex flex-sm-row flex-column">
                <select
                  disabled={
                    exam === undefined || this.props.QuestionPaperLoading
                  }
                  className="form-control"
                  name="paper"
                  onChange={(e) => this.onChangePaper(e)}
                >
                  <option selected disabled>
                    ---Select Paper---
                  </option>
                  {QuestionPaper.map((arr, key) => (
                    <option key={key} value={arr.id}>
                      {arr.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-5 text-center">
            <div className="col-6">
              <OverlayTrigger overlay={<Tooltip>Export CSV</Tooltip>}>
                <span className="d-inline-block">
                  <Button
                    onClick={(e) => this.exportCSV(e)}
                    disabled={
                      // class_s === undefined ||
                      subject === undefined ||
                      exam === undefined ||
                      paper === undefined
                    }
                    variant="outline-warning"
                  >
                    <span>
                      <img height="20px" src={CsvFileFormat} alt="..." />
                    </span>
                    <span className="ml-3">Export CSV</span>
                  </Button>
                  {/*end::Svg Icon*/}
                </span>
              </OverlayTrigger>
            </div>

            <div className="col-3">
              <OverlayTrigger overlay={<Tooltip>Import CSV</Tooltip>}>
                <span className="d-inline-block">
                  <Form.Group controlId="formFile">
                    <input
                      type="file"
                      onChange={(e) => this.handleFilesChosen(e)}
                      accept=".csv"
                      disabled={
                        // class_s === undefined ||
                        subject === undefined ||
                        exam === undefined ||
                        paper === undefined
                      }
                    />
                  </Form.Group>
                </span>
              </OverlayTrigger>
            </div>

            {this.state.CSVUploaded ? (
              <div className="col-3">
                <Button
                  onClick={(e) => this.SendCSV(e)}
                  type="file"
                  disabled={!this.state.CSVUploaded}
                  variant="outline-warning"
                >
                  <span>
                    <img height="20px" src={CsvFileFormat} alt="..." />
                  </span>
                  <span className="ml-3">Upload CSV</span>
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="card card-custom">
                <div className="card-body py-0">
                  <div className="table-responsive">
                    {
                      // class_s === undefined ||
                      subject === undefined ||
                      exam === undefined ||
                      paper === undefined ? (
                        empty
                      ) : (
                        <table className="table table-head-custom table-vertical-center">
                          <thead>
                            <tr className="text-left">
                              <th className="pl-0" style={{ width: "30px" }}>
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                  <input type="checkbox" defaultValue={1} />
                                  <span />
                                </label>
                              </th>
                              <th
                                className="pl-0"
                                style={{ minWidth: "120px" }}
                              >
                                Question Number
                              </th>
                              <th style={{ minWidth: "120px" }}>Topic</th>
                              <th style={{ minWidth: "120px" }}>Total Marks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {QuestionsList !== 0
                              ? QuestionsList.map((arr, key) => (
                                  <tr key={key}>
                                    <td className="pl-0 py-6">
                                      <label className="checkbox checkbox-lg checkbox-inline">
                                        <input
                                          type="checkbox"
                                          defaultValue={1}
                                        />
                                        <span />
                                      </label>
                                    </td>
                                    <td className="pl-0">
                                      <span className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                                        Q {arr.id}
                                      </span>
                                    </td>
                                    <td>
                                      {arr.topics.map((arrx, key) => (
                                        <span
                                          key={key}
                                          className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize"
                                        >
                                          {arrx.name}
                                        </span>
                                      ))}
                                    </td>
                                    <td>
                                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {arr.marks}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="card card-custom">
                <div className="card-body py-0">
                  <div className="table-responsive">
                    {
                      // class_s === undefined ||
                      subject === undefined ||
                      exam === undefined ||
                      paper === undefined ? (
                        empty
                      ) : (
                        <table
                          className="table table-head-custom table-vertical-center"
                          id="kt_advance_table_widget_4"
                        >
                          <thead>
                            <tr className="text-left">
                              <th className="pl-0" style={{ width: "30px" }}>
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                  <input type="checkbox" defaultValue={1} />
                                  <span />
                                </label>
                              </th>
                              <th
                                className="pl-0"
                                style={{ minWidth: "120px" }}
                              >
                                Question/Students
                              </th>
                              <th style={{ minWidth: "120px" }}>Topic</th>

                              {classFilterList.students !== undefined
                                ? classFilterList.students.map((arr, key) => (
                                    <th key={key} style={{ minWidth: "100px" }}>
                                      {arr.firstname} &nbsp;{arr.lastname}
                                    </th>
                                  ))
                                : ""}
                              {/*</th>*/}
                            </tr>
                          </thead>

                          <tbody>
                            {QuestionsList.length !== 0
                              ? QuestionsList.map((question, key) => (
                                  <tr key={key}>
                                    <td className="pl-0 py-6">
                                      <label className="checkbox checkbox-lg checkbox-inline">
                                        <input
                                          type="checkbox"
                                          defaultValue={1}
                                        />
                                        <span />
                                      </label>
                                    </td>
                                    <td className="pl-0">
                                      <span className="text-dark-75 font-weight-bolder font-size-lg">
                                        Q{question.id}
                                      </span>
                                    </td>
                                    <td>
                                      {question !== undefined
                                        ? question.topics.map((arrx, key) => (
                                            <span
                                              key={key}
                                              className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize"
                                            >
                                              {arrx.name}
                                            </span>
                                          ))
                                        : ""}
                                    </td>
                                    {classFilterList.students !== undefined
                                      ? classFilterList.students.map(
                                          (student, key) => (
                                            <td key={key}>
                                              <Form>
                                                <Form.Control
                                                  id={
                                                    student.id +
                                                    `-EnterMarks-` +
                                                    question.id
                                                  }
                                                  onChange={(e) =>
                                                    this.onchangeRow(
                                                      e,
                                                      student.id,
                                                      question.id
                                                    )
                                                  }
                                                  type="number"
                                                  min="1"
                                                  max={question.marks}
                                                  placeholder={
                                                    `Enter Q ${question.id} Marks for ` +
                                                    " " +
                                                    student.firstname
                                                  }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  Please Enter valid Marks
                                                </Form.Control.Feedback>
                                              </Form>
                                            </td>
                                          )
                                        )
                                      : ""}
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <Button
                onClick={(e) => this.submit(e)}
                variant="success"
                disabled={
                  // class_s === undefined ||
                  subject === undefined ||
                  exam === undefined ||
                  paper === undefined
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Hello</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  CsvList: state.CsvList.CsvList,
  ExamList: state.ExamList.ExamList,
  classList: state.classList.classList,
  CsvListLoading: state.CsvList.isLoading,
  ExamListLoading: state.ExamList.isLoading,
  studentList: state.studentList.studentList,
  classListLoading: state.classList.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  QuestionLoading: state.QuestionsList.isLoading,
  studentListLoading: state.studentList.isLoading,
  QuestionPaper: state.QuestionPaper.QuestionPaper,
  GradeYearList: state.GradeYearList.GradeYearList,
  QuestionsList: state.QuestionsList.QuestionsList,
  subjectsListLoading: state.subjectsList.isLoading,
  GradeYearListLoading: state.GradeYearList.isLoading,
  QuestionPaperLoading: state.QuestionPaper.isLoading,
  classFilterList: state.classFilterList.classFilterList,
  classFilterListLoading: state.classFilterList.isLoading,
  StudentMarksListLoading: state.StudentMarksList.isLoading,
});
export default connect(mapStateToProps, {
  addCSV,
  getCSV,
  getClass,
  getExams,
  getSubject,
  getQuestion,
  getGradeYear,
  getQuestionPaper,
  get_Class_Filter,
  addStudentMarks,
})(StudentMarks);
