import { GET_FAQ } from "../actions/types";

const initialState = {
  FAQList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        FAQList: action.payload,
      };
    default:
      return state;
  }
}
