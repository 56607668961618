import React, { Component } from "react";
import SupportPage from "./SupportPage";
import Sidebar from "../KeenTheme/Sidebar";
import Subheader from "../KeenTheme/Subheader";
import NavbarKeen from "../KeenTheme/NavbarKeen";

export default class Help extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Aside*/}
          <Sidebar />
          {/*end::Aside*/}
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper">
            {/*begin::Header*/}
            <NavbarKeen />
            {/*end::Header*/}
            {/*begin::Content*/}
            <div style={{ marginTop: "20px" }} className="content d-flex flex-column flex-column-fluid">
              {/*begin::Subheader*/}
              <Subheader name="Support" />
              {/*end::Subheader*/}
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <SupportPage />
                    </div>
                  </div>
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    );
  }
}
