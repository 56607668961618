import axios from "axios";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

import {
  USER_GROUP_LOADING_FAIL,
  GET_USER_GROUP,
  USER_GROUP_LOADING,
} from "../types";

export const getGroupAction = () => (dispatch, getState) => {
  dispatch({ type: USER_GROUP_LOADING });
  axios
    .get("accounts/groupdata/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_GROUP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_GROUP_LOADING_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
