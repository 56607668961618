import axios from "axios";
import {
  GET_STUDENT_MARKS,
  SET_STUDENT_MARKS_FAIL,
  SET_STUDENT_MARKS_LOADING,
  DELETE_STUDENT_MARKS_LOADING,
  UPDATE_STUDENT_MARKS,
  ADD_STUDENT_MARKS,
} from "../types";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

export const getStudentMarks = () => (dispatch, getState) => {
  axios
    .get("QLA/StudentMarks/", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SET_STUDENT_MARKS_LOADING });

      dispatch({
        type: GET_STUDENT_MARKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_MARKS_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
export const getStudentReport =
  (schoolID, SubjectID) => (dispatch, getState) => {
    axios
      .get(`QLA/StudentMarks/${schoolID}/${SubjectID}/`, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: SET_STUDENT_MARKS_LOADING });

        dispatch({
          type: GET_STUDENT_MARKS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: SET_STUDENT_MARKS_FAIL });
        dispatch(returnErrors(err.response.data), err.response.status);
      });
  };
export const deleteStudentMarks = (id) => (dispatch, getState) => {
  axios
    .delete(`QLA/StudentMarks/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SET_STUDENT_MARKS_LOADING });

      dispatch(createMessage({ deleteLead: "Student Marks Deleted" }));
      dispatch({
        type: DELETE_STUDENT_MARKS_LOADING,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_MARKS_FAIL });

      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
export const addStudentMarks = (data) => (dispatch, getState) => {
  dispatch({ type: SET_STUDENT_MARKS_LOADING });

  const newdata = JSON.stringify(data);
  axios
    .post("QLA/StudentMarksTest/", newdata, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Student Marks Added" }));
      dispatch({
        type: ADD_STUDENT_MARKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_MARKS_FAIL });

      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
export const updateStudentMarks = (id, aboutFYP) => (dispatch, getState) => {
  axios
    .put(`QLA/StudentMarks/${id}/`, aboutFYP, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SET_STUDENT_MARKS_LOADING });

      dispatch(createMessage({ LeadUpdate: "Student Marks Updated" }));
      dispatch({
        type: UPDATE_STUDENT_MARKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_STUDENT_MARKS_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
