import React, { Fragment, useState } from "react";
import { useEffect } from "react";

export default function ProgressBarColor(props) {
  const Percentage = (props.data / props.data1) * 100;
  const [name, setname] = useState("");
  useEffect(() => {
    if (Percentage <= 50) {
      setname("bg-danger");
    } else if (Percentage >= 51 && Percentage <= 60) {
      setname("bg-warning");
    } else if (Percentage >= 61 && Percentage <= 70) {
      setname("bg-info");
    } else if (Percentage >= 71 && Percentage <= 80) {
      setname("bg-dark");
    } else if (Percentage >= 81 && Percentage <= 90) {
      setname("bg-primary");
    } else if (Percentage >= 91) {
      setname("bg-success");
    } else {
      setname("error");
    }
  }, [Percentage]);
  return (
    <Fragment>
      <div className="d-flex mt-4 mt-sm-0">
        <span className="font-weight-bold mr-4">Progress</span>
        <div className="progress progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px">
          <div
            className={`progress-bar ${name}`}
            role="progressbar"
            style={{
              width: `${Percentage}%`,
            }}
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
        <span className="font-weight-bolder text-dark ml-4">
          {Percentage.toFixed(2)}%
        </span>
      </div>
    </Fragment>
  );
}
