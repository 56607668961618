import {
  GET_QUESTION,
  SET_QUESTION_FAIL,
  SET_QUESTION_LOADING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  QuestionsList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION:
      return {
        ...state,
        QuestionsList: action.payload,
        isLoading: false,
      };
    case SET_QUESTION_LOADING:
      return {
        ...state,
        isLoading: true,
        QuestionsList: action.payload,
      };
    case SET_QUESTION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
