import React, { Component } from "react";
import {
  getTeacherEmail,
  updateTeacherSubjectStatus,
  DeleteTeacherSubjectStatus,
} from "../../../../actions/Teacher/TeacherAction";
import { connect } from "react-redux";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import WordFinder from "../../WordFinder";

class AdvanceTableWidget2 extends Component {
  state = {
    openModal: false,
    deleteID: undefined,
    showWidget: true,
  };
  static propTypes = {
    getTeacherEmail: PropTypes.func.isRequired,
    TeacherByEmail: PropTypes.array,
    LoadingTeacher: PropTypes.bool,
    Loaded: PropTypes.bool,
    updateTeacherSubjectStatus: PropTypes.func,
  };
  Action = (id, action) => {
    const data = { status: action };
    this.props.updateTeacherSubjectStatus(id, data);
  };
  DeleteRequest = () => {
    this.props.DeleteTeacherSubjectStatus(this.state.deleteID);
    this.setState({ openModal: false, deleteID: undefined });
  };
  componentDidMount() {
    this.props.getTeacherEmail();
  }

  render() {
    const { TeacherByEmail, Loaded, LoadingTeacher } = this.props;
    const { openModal, showWidget } = this.state;
    if (!showWidget) {
      return <></>;
    }
    if (TeacherByEmail === undefined) {
      return <></>;
    } else if (TeacherByEmail.status !== "Accepted") {
      return <></>;
    } else {
      return (
        <div className="col-xxl-12 order-2 order-xxl-1">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Role Assigned
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  You are added as a teacher
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                  <li className="nav-item">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">Hide Widget</Tooltip>
                      }
                      placement="bottom"
                    >
                      <button
                        onClick={() => this.setState({ showWidget: false })}
                        className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3 cursor-pointer"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger mb-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M19.2078777,9.84836149 C20.3303823,11.0178941 21,12 21,12 C21,12 16.9090909,18 12,18 C11.6893441,18 11.3879033,17.9864845 11.0955026,17.9607365 L19.2078777,9.84836149 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <path
                                d="M14.5051465,6.49485351 L12,9 C10.3431458,9 9,10.3431458 9,12 L5.52661464,15.4733854 C3.75006453,13.8334911 3,12 3,12 C3,12 5.45454545,6 12,6 C12.8665422,6 13.7075911,6.18695134 14.5051465,6.49485351 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                transform="translate(12.524621, 12.424621) rotate(-45.000000) translate(-12.524621, -12.424621) "
                                x="3.02462111"
                                y="11.4246212"
                                width={19}
                                height={2}
                              />
                            </g>
                          </svg>
                        </span>
                      </button>
                    </OverlayTrigger>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body pt-2 pb-0 mt-n3">
              <div className="tab-content mt-5" id="myTabTables11">
                <div className="tab-pane fade show active">
                  <div className="table-responsive tableFixHead">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <thead>
                        <tr className="text-left text-uppercase">
                          <th style={{ minWidth: "100px" }}>Subject</th>
                          <th style={{ minWidth: "100px" }}>Class</th>
                          <th style={{ minWidth: "100px" }}>Status</th>
                          <th style={{ minWidth: "100px" }}>Year Grade</th>
                          <th
                            className="text-center"
                            style={{ minWidth: "130px" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {TeacherByEmail.Subjects.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className="text-dark-75 d-block">
                                {item.Subject.name}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark-75 d-block">
                                {item.Class.name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`label label-lg label-inline label-light-${
                                  item.status === "Pending"
                                    ? "warning"
                                    : item.status === "Rejected"
                                    ? "danger"
                                    : "success"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark-75 d-block">
                                {item.YearGrade.name}
                              </span>
                            </td>

                            <td
                              style={{ display: "inline-flex" }}
                              className="pr-0 text-center"
                            >
                              <button
                                disabled={
                                  LoadingTeacher || item.status === "Accepted"
                                }
                                onClick={() => this.Action(item.id, "Accepted")}
                                className="btn btn-icon btn-light btn-hover-success btn-sm"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-success">
                                  <i className="ki ki-bold-check-1 icon-md text-success" />
                                </span>
                              </button>

                              <button
                                disabled={
                                  LoadingTeacher || item.status === "Rejected"
                                }
                                onClick={() => this.Action(item.id, "Rejected")}
                                className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-warning">
                                  <i className="ki ki-bold-close icon-md text-warning" />
                                </span>
                              </button>

                              <button
                                disabled={LoadingTeacher}
                                onClick={() =>
                                  this.setState({
                                    openModal: true,
                                    deleteID: item.id,
                                  })
                                }
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <i className="flaticon-delete icon-md text-danger" />
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={openModal}
            onHide={() =>
              this.setState({ openModal: false, deleteID: undefined })
            }
            aria-labelledby="contained-modal-title-vcenter"
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete the request?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure to delete? This action is IRREVERSIBLE</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() =>
                  this.setState({ openModal: false, deleteID: undefined })
                }
                variant="success"
              >
                Close
              </Button>
              <Button onClick={() => this.DeleteRequest()} variant="danger">
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  TeacherByEmail: state.TeacherByEmail.TeacherByEmail,
  LoadingTeacher: state.TeacherByEmail.isLoadingTeacher,
  Loaded: state.TeacherByEmail.Loaded,
});

export default connect(mapStateToProps, {
  getTeacherEmail,
  updateTeacherSubjectStatus,
  DeleteTeacherSubjectStatus,
})(AdvanceTableWidget2);
