import { Form, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Formik } from "formik";
import logo from "../../media/Logo/logo.png";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import OtpInput from "react-otp-input";
import email from "../../media/email.png";
import { Verify_USER_OTP } from "../../actions/verifyUser/verifyUserAction";
import {
  ResendEmailOTP,
  RegenerateOTP,
} from "../../actions/VerifyOTPs/ResendEmailAction";
const schema = yup.object({
  otp: yup
    .string()
    .required("Enter OTP")
    .min(6, "OTP must be 6 digits")
    .max(6, "OTP must be 6 digits"),
});
export const AccountVerify = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [seconds, setSeconds] = useState(120); // time in seconds
  const [secondsOTP, setSecondsForOTP] = useState(300); // time in seconds
  const [emailEncrypted, setEmail] = useState("");
  const [iv_get, setIV] = useState("");
  const [decryptedEmail, setDecryptedEmail] = useState("");
  const [staremail, setStaremail] = useState("");
  const [InvalidLink, setInvalidLink] = useState(false);
  const [display, setdisplay] = useState(false);
  const [encryptedID, setEncryptedID] = useState("");
  const [IVID, setIVID] = useState("");
  const [emailID, setEmailID] = useState("");
  const decoder = () => {
    const key = "yDbigwo58k!M9!8f";
    try {
      const iv = CryptoJS.enc.Base64.parse(iv_get);
      const ciphertext = CryptoJS.enc.Base64.parse(emailEncrypted);
      const key_update = CryptoJS.enc.Utf8.parse(key);
      const decipher = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        key_update,
        { iv: iv }
      );
      const decryptedEmail = CryptoJS.enc.Utf8.stringify(decipher);
      const startemail = decryptedEmail.slice(0, 3);
      const emailbeforeA = decryptedEmail.split("@")[0].slice(-2);
      const emailafterA = decryptedEmail.split("@")[1];
      var starString = "";
      for (let x = 1; x <= decryptedEmail.split("@")[0].length - 5; x++) {
        starString += "*";
      }
      const staremail =
        startemail + starString + emailbeforeA + "@" + emailafterA;
      setDecryptedEmail(decryptedEmail);
      setStaremail(staremail);
    } catch (error) {
      setInvalidLink(true);
    }

    try {
      const iv = CryptoJS.enc.Base64.parse(IVID);
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedID);
      const key_update = CryptoJS.enc.Utf8.parse(key);
      const decipher = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        key_update,
        { iv: iv }
      );
      const decryptedEmailID = CryptoJS.enc.Utf8.stringify(decipher);
      setEmailID(decryptedEmailID);
    } catch (error) {
      setInvalidLink(true);
    }
  };

  useEffect(() => {
    let timerInterval = null;
    const updateTimer = () => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timerInterval);
          return 0;
        }
      });
    };
    timerInterval = setInterval(updateTimer, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    let timerInterval = null;
    const updateTimer = () => {
      setSecondsForOTP((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timerInterval);
          return 0;
        }
      });
    };
    timerInterval = setInterval(updateTimer, 1000);
    return () => clearInterval(timerInterval);
  }, []);
  useEffect(() => {
    if (location.search) {
      setEmail(location.search.split("?email=")[1].split("&iv=")[0]);
      setIV(location.search.split("&iv=")[1].split("&encryptedID=")[0]);
      setEncryptedID(
        location.search.split("&encryptedID=")[1].split("&IDIV=")[0]
      );
      setIVID(location.search.split("&IDIV=")[1]);
    } else {
      setInvalidLink(true);
    }
  }, []);
  useEffect(() => {
    setdisplay(true);
    decoder();
  }, [emailEncrypted]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesOTP = Math.floor(secondsOTP / 60);
  const remainingSecondsOTP = secondsOTP % 60;

  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  const resend = () => {
    setSeconds(120);
    dispatch(ResendEmailOTP(emailID));
  };
  const regenerateOTP = () => {
    setSecondsForOTP(300);
    dispatch(RegenerateOTP(emailID));
  };
  const verifyOTP = (values) => {
    const OTP = values.otp;
    const email = decryptedEmail;
    const data = { OTP, email };
    dispatch(Verify_USER_OTP(data));
  };
  return (
    <div className="d-flex flex-column" id="kt_app_root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <a href="#" className="mb-0 mb-lg-5 mt-lg-5">
              <img alt="Logo" src={logo} className="h-60px h-lg-75px" />
            </a>
            <div className="w-lg-500px p-10">
              {!display ? (
                <Spinner animation="border" variant="warning" />
              ) : (
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => verifyOTP(values)}
                  initialValues={{
                    otp: "",
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors }) => (
                    <Form
                      className="form w-100 mb-13"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="text-center mb-10">
                        <img
                          width="100"
                          height="100"
                          alt="Logo"
                          className="mh-125px"
                          src={email}
                        />
                      </div>
                      <div className="text-center mb-10">
                        <h1 className="text-dark mb-3">Account Verification</h1>
                        <div className="text-muted fw-semibold fs-5 mb-5">
                          Enter the verification code we sent for
                        </div>
                        <div className="fw-bold text-dark fs-3">
                          {staremail}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
                          Type your 6 digit security code
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <OtpInput
                            value={values.otp}
                            placeholder="000000"
                            onChange={(e, event) => {
                              handleChange({
                                ...event,
                                target: {
                                  name: "otp",
                                  value: e,
                                },
                              });
                            }}
                            numInputs={6}
                            inputStyle={
                              "form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                            }
                            renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} />}
                          />
                          {errors.otp && (
                            <span className="text-danger">
                              {errors.otp ? errors.otp : ""}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-center">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary fw-bold"
                        >
                          <span className="indicator-label">Submit</span>
                        </button>
                      </div>
                      <div className="text-center fw-semibold fs-5 mt-5 mb-lg-4">
                        <span className="text-muted me-1 ">
                          Didn’t get the code ? <br />
                        </span>
                        <button
                          type="button"
                          disabled={seconds > 0}
                          onClick={() => resend()}
                          className="mt-lg-4 btn btn-success"
                        >
                          {seconds === 0
                            ? "Resend Email"
                            : `${formatTime(minutes)}:${formatTime(
                                remainingSeconds
                              )}`}
                        </button>
                        <button
                          type="button"
                          disabled={secondsOTP > 0}
                          onClick={() => regenerateOTP()}
                          className="mt-lg-4 btn btn-success ml-lg-2"
                        >
                          {secondsOTP === 0
                            ? "Regenerate OTP"
                            : `${formatTime(minutesOTP)}:${formatTime(
                                remainingSecondsOTP
                              )}`}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
