import axios from "axios";
import {
  GET_SCHOOL,
  SET_SCHOOL_LOADING,
  SET_SCHOOL_FAIL,
  ADD_SCHOOL,
  GET_SCHOOL_ID,
  SET_SCHOOL_ID_FAIL,
  SET_SCHOOL_ID_LOADING,
  UPDATE_SCHOOL_LOADING,
  UPDATE_SCHOOL_FAIL,
  UPDATE_SCHOOL,
  GET_SCHOOL_CREATED,
  GET_SCHOOL_CREATED_LOADING,
  GET_SCHOOL_CREATED_LOADING_FAIL,
} from "../types";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

export const getSchool = () => (dispatch) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({ type: SET_SCHOOL_LOADING });
  axios
    .get("QLA/School/", headers)
    .then((res) => {
      dispatch({
        type: GET_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_SCHOOL_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addSchool = (Data) => (dispatch, getState) => {
  dispatch({ type: SET_SCHOOL_LOADING });
  axios
    .post("QLA/School/", Data, tokenConfig(getState), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(createMessage({ addLead: "School Added" }));
      dispatch({
        type: ADD_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data), err.response.status)
    );
};

export const getSchoolById = (id) => (dispatch) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({ type: SET_SCHOOL_ID_LOADING });
  axios
    .get(`QLA/School/${id.id}/`, headers)
    .then((res) => {
      dispatch({
        type: GET_SCHOOL_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_SCHOOL_ID_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const updateSchool = (id, Data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SCHOOL_LOADING });
  axios
    .put(`QLA/School/${id}/`, Data, tokenConfig(getState), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(createMessage({ LeadUpdate: "School Info Updated" }));
      dispatch({
        type: UPDATE_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_SCHOOL_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const getSchoolByCreated = () => (dispatch, getState) => {
  dispatch({ type: GET_SCHOOL_CREATED_LOADING });
  axios
    .get("QLA/SchoolByUserCreated/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SCHOOL_CREATED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_SCHOOL_CREATED_LOADING_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
