import Waiting from "./Waiting";
import React from "react";

export default function StudentLoading() {
  return (
    <div
      className="d-flex flex-column-fluid flex-center"
      style={{ marginTop: "50rem" }}
    >
      <Waiting message="Please wait while Students are loading.." />
    </div>
  );
}
