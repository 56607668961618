import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADD_TEACHER,
  DELETE_TEACHER,
  UPDATE_TEACHER,
  GET_TEACHER,
  LOADING_TEACHER,
  EMAIL_TEACHER,
  TEACHER_LOADING,
  TEACHER_ERROR,
  UPDATE_EMAIL_TEACHER,
  UPDATE_TEACHER_LOADING,
  UPDATE_TEACHER_ERROR,
  DELETE_TEACHER_,
  ASSIGN_TEACHER,
  GET_NEW_TEACHER,
  LOADING_TEACHER_ACTIVE,
  GET_TEACHER_ACTIVE,
  FAIL_TEACHER_ACTIVE

} from "../types";
import toast from "react-hot-toast";

export const getTeacher = () => (dispatch) => {
  dispatch({ type: LOADING_TEACHER });
  axios
    .get("QLA/Teacher/")
    .then((res) => {
      dispatch({
        type: GET_NEW_TEACHER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const getTeacherSchool = (SchoolId) => (dispatch, getState) => {
  dispatch({ type: LOADING_TEACHER });
  axios
    .get(`QLA/UserSchoolID/${SchoolId}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TEACHER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getTeacherSchoolActive = (SchoolId,type) => (dispatch, getState) => {
  dispatch({ type: LOADING_TEACHER_ACTIVE });
  axios
    .get(`QLA/Teacher/${SchoolId}/type/${type}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TEACHER_ACTIVE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: FAIL_TEACHER_ACTIVE });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteTeacher = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_TEACHER });
  axios
    .delete(`QLA/Teacher/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Teacher Delete" }));
      dispatch({
        type: DELETE_TEACHER,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addTeacher = (data) => (dispatch, getState) => {
  dispatch({ type: LOADING_TEACHER });
  const TID = toast.loading("Please wait adding teacher...⏳");
  axios
    .post("QLA/Teacher/", data, tokenConfig(getState))
    .then((res) => {
      toast.success("Teacher Added", {
        id: TID,
      });
      dispatch({
        type: ADD_TEACHER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data,"xx")
      // toast.error("Oops! Something went wrong")
      // toast.remove(TID);
      dispatch(returnErrors(err.response.data, err.response.status,TID));
    });
};
export const updateTeacher = (id, data) => (dispatch, getState) => {
  dispatch({ type: LOADING_TEACHER });
  axios
    .put(`QLA/Teacher/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Teacher Updated!" }));
      dispatch({
        type: UPDATE_TEACHER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getTeacherEmail = () => (dispatch, getState) => {
  dispatch({ type: TEACHER_LOADING });
  axios
    .get("QLA/TeacherSubjectStatus/TID/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: EMAIL_TEACHER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: TEACHER_ERROR });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const AssignTeacherSubject = (data) => (dispatch, getState) => {
  dispatch({ type: TEACHER_LOADING });
  const TID = toast.loading("Please wait Assigning Subject...⏳");
  axios
    .post("QLA/TeacherSubjectStatus/", data, tokenConfig(getState))
    .then((res) => {
      // dispatch({
      //   type: ASSIGN_TEACHER,
      //   payload: res.data,
      // });
      toast.success("Assigned successfully!", {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
      dispatch({ type: TEACHER_ERROR });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const updateTeacherSubjectStatus =
  (id, data) => (dispatch, getState) => {
    dispatch({ type: UPDATE_TEACHER_LOADING });
    const TID = toast.loading("Please wait updaing status...⏳");
    const newdata = JSON.stringify(data);
    axios
      .patch(`QLA/TeacherSubjectStatus/${id}/`, newdata, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_EMAIL_TEACHER,
          payload: {"res":res.data,"id":id},
        });
        toast.success("Status Updated", {id: TID});
      })
      .catch((err) => {
        toast.remove(TID);
        dispatch({ type: UPDATE_TEACHER_ERROR });
        dispatch(returnErrors(err.response.data), err.response.status);
      });
  };
export const DeleteTeacherSubjectStatus = (id) => (dispatch, getState) => {
  dispatch({ type: UPDATE_TEACHER_LOADING });
  const TID = toast.loading("Please wait removing as teacher...⏳");
  axios
    .delete(`QLA/TeacherSubjectStatus/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Deleted successfully!" }));
      dispatch({
        type: DELETE_TEACHER_,
        payload: id,
      });
      toast.remove(TID);
    })
    .catch((err) => {
      toast.remove(TID);
      dispatch({ type: UPDATE_TEACHER_ERROR });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
