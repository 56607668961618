import {
  EMAIL_TEACHER,
  TEACHER_LOADING,
  TEACHER_ERROR,
  UPDATE_EMAIL_TEACHER,
  UPDATE_TEACHER_LOADING,
  UPDATE_TEACHER_ERROR,
  DELETE_TEACHER_,
  ASSIGN_TEACHER,
} from "../actions/types";

const initialState = {
  isLoadingTeacher: undefined,
  Loaded: false,
  TeacherByEmail: undefined,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_TEACHER:
      return {
        ...state,
        TeacherByEmail: [...state.TeacherByEmail, action.payload],
        Loaded: true,
        isLoadingTeacher: false,
      };
    case EMAIL_TEACHER:
      return {
        ...state,
        isLoadingTeacher: false,
        TeacherByEmail: action.payload,
        Loaded: true,
      };

    case TEACHER_LOADING:
      return {
        ...state,
        isLoadingTeacher: true,
        Loaded: false,
      };
    case UPDATE_TEACHER_LOADING:
      return {
        ...state,
        isLoadingTeacher: true,
      };
    case TEACHER_ERROR:
    case UPDATE_TEACHER_ERROR:
      return {
        ...state,
        isLoadingTeacher: false,
        Loaded: true,
      };
    case UPDATE_EMAIL_TEACHER:
      const updatedSubjects = state.TeacherByEmail.Subjects.map((subject) => {
        if (subject.id === action.payload.id) {
          return {
            ...subject,
            status: action.payload.res.status,
          };
        } else {
          return subject;
        }
      });
      return {
        ...state,
        isLoadingTeacher: false,
        Loaded: true,
        TeacherByEmail: {
          ...state.TeacherByEmail,
          Subjects: updatedSubjects,
        },
      };

    case DELETE_TEACHER_:
      const updatedSubjectsAfterDelete = state.TeacherByEmail.Subjects.filter((subject) => {
        return subject.id !== action.payload;
      });
    
      return {
        ...state,
        isLoadingTeacher: false,
        Loaded: true,
        TeacherByEmail: {
          ...state.TeacherByEmail,
          Subjects: updatedSubjectsAfterDelete, // Update the Subjects array after deleting the specified subject
        },
      };

    default:
      return state;
  }
}
