import {
  ADD_CLASS,
  DELETE_CLASS,
  GET_CLASS,
  UPDATE_CLASS,
  SET_CLASS_FAIL,
  SET_CLASS_LOADING,
  SET_CLASS_LOADING_ADD,
  SET_UPDATE_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: undefined,
  classList: [],
  failed: false,
  update_data: false,
  update_fail: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLASS:
      return {
        ...state,
        classList: action.payload,
        isLoading: false,
        update_fail: false,
      };
    case SET_CLASS_LOADING:
      return {
        ...state,
        update_fail: false,
        isLoading: true,
      };
    case DELETE_CLASS:
      return {
        ...state,
        update_fail: false,
        classList: state.classList.filter(
          (about) => about.id !== action.payload
        ),
      };
    case SET_CLASS_LOADING_ADD:
      return {
        ...state,
        update_data: true,
        update_fail: false,
      };
    case SET_UPDATE_FAIL:
      return {
        ...state,
        update_data: false,
        update_fail: true,
      };
    case SET_CLASS_FAIL:
      return {
        ...state,
        update_data: false,
        isLoading: false,
        update_fail: false,
        failed: true,
      };
    case UPDATE_CLASS:
      return {
        ...state,
        update_data: false,
        update_fail: false,
        classList: state.classList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_CLASS:
      return {
        ...state,
        update_fail: false,
        classList: [...state.classList, action.payload],
      };
    default:
      return state;
  }
}
