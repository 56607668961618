import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { getEnhanceClass } from "../../actions/ClassActionFolder/ClassActions";
import { Form, Button, Modal } from "react-bootstrap";
import getClassNameByRating from "../functions/NewClassName";
import { Formik } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NewGenerateTopicPaper } from "../../actions/MockExamResultSave/MockResultSaveAction";
import styled from "styled-components";
import * as Yup from "yup";

export const ViewStudentMockResult = ({
  classid,
  data,
  RAG,
  uniqueID,
  uniqueList,
  updateParentState
}) => {
  const dispatch = useDispatch();
  const Question = useSelector((state) => state.QuestionsList);
  const EnhanceClassObject = useSelector((state) => state.EnhanceClassObject);
  const [studentObject, setStudentObject] = useState(undefined);
  const [StudentHeader, setStudentHeader] = useState(undefined);
  const [GenerateBool, setGenerateBool] = useState(false);
  const [uniqueListLocal, setUniqueListLocal] = useState(uniqueList);
  const [studentstart, setStudentStart] = useState(0);
  const [studentend, setStudentEnd] = useState(10);
  const [small, setSmall] = useState(false);
  const [ShowAll, setShowAll] = useState([]);
  const [downweight, setDownweight] = useState('No');

  const checkboxOptions = [
    { key: "Red", value: "Red", color: "danger", name: "Red" },
    { key: "Amber", value: "Amber", color: "warning", name: "Amber" },
    { key: "Green", value: "Green", color: "success", name: "Green" },
  ];
  const OutputSelection = [
    {
      key: "QandA",
      value: "QandA",
      color: "warning",
      name: "Question and Solutions",
    },
    { key: "Question", value: "Question", color: "info", name: "Question" },
    { key: "Answer", value: "Answer", color: "dark", name: "Solutions" },
  ];

  const Downweight = [
    { key: "No", value: "No", color: "danger", name: "No" },
    { key: "Yes", value: "Yes", color: "success", name: "Yes" },
  ];


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "20px",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggable
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#645f5f" : "#d4d4d4",
    borderRadius: "20px",
    padding: grid,
    width: "auto",
  });
  const CardHeader = styled.div`
    font-weight: 500;
  `;

  useEffect(() => {
    dispatch(getEnhanceClass(classid));
  }, []);

  useEffect(() => {
    ArrayTrimmer();
  }, [StudentHeader]);



  const MakeObject = () => {
    const StudentMarksObject = Question.QuestionsList.map((item) => ({
      topics: item.topics.map((topic) => ({
        topicID: topic.id,
        topicName: topic.name,
      })),
      QuestionID: item.id,
      totalmarks: item.marks,
      isSelected: false,
      question_label: item.question_label,
      // Students Mapping
      classID: EnhanceClassObject.EnhanceClassObject.id,
      Student: EnhanceClassObject.EnhanceClassObject.students.map((student) => {
        // Find the student by ID
        const studentsWithSameId = data.Student_Marks.filter(
          (item) => item.Student.id === student.id
        );
        let obtainMarks = undefined;
        if (studentsWithSameId.length > 0) {
          // Filter students who have attempted the specified question
          const studentsWithQuestion = studentsWithSameId.filter(
            (student) => student.Question === item.id
          );
          if (studentsWithQuestion.length > 0) {
            studentsWithQuestion.forEach((student) => {
              if (student.Marks_Obtained >= 0) {
                obtainMarks = student.Marks_Obtained;
              } else {
                obtainMarks = "Not Attempted";
              }
            });
          } else {
            obtainMarks = `Students with ID ${student.id} did not attempt question ID ${item.id}`;
          }
        } else {
          obtainMarks = `No students found with ID ${student.id}`;
        }
        return {
          studentID: student.id,
          studentName: student.firstname + " " + student.surname,
          obtainMarks: obtainMarks,
          attempted: false,
          isStudentSelected: false,
          QuestionID: item.id,
        };
      }),
    }));

    const StudentHeader = EnhanceClassObject.EnhanceClassObject.students.map(
      (student) => ({
        studentID: student.id,
        studentName: student.firstname + " " + student.surname,
        isSelected: false,
      })
    );
    setStudentHeader(StudentHeader);
    setStudentObject(StudentMarksObject);
  };

  const GenerateTopicPaper = (values) => {
    const uniqueList = uniqueListLocal;
    const body = { uniqueID, values, studentObject, uniqueList };
    dispatch(NewGenerateTopicPaper(body));
    setGenerateBool(!GenerateBool);
    updateParentState(false)



  };

  const ApplyAll = (e) => {
    const updateStudentObject = studentObject.map((student) => {
      return { ...student, isSelected: e.target.checked };
    });

    const updatedStudentMarks = updateStudentObject.map((item) => ({
      ...item,
      Student: item.Student.map((student) => ({
        ...student,
        isStudentSelected: e.target.checked,
      })),
    }));

    const updateStudentHeader = StudentHeader.map((student) => {
      return { ...student, isSelected: e.target.checked };
    });
    setStudentHeader(updateStudentHeader);
    setStudentObject(updatedStudentMarks);
  };

  const StudentCheckBox = (e, id) => {
    const updatedStudentMarksObject = studentObject.map((item) => {
      // Check if the student with the given ID exists in the Student array
      const studentIndex = item.Student.findIndex(
        (student) => student.studentID === id
      );

      if (studentIndex !== -1) {
        // If the studentID exists, update isStudentSelected for that student
        const updatedItem = {
          ...item,
          Student: item.Student.map((student, index) =>
            index === studentIndex
              ? { ...student, isStudentSelected: e.target.checked }
              : student
          ),
        };
        return updatedItem;
      }
      // For other items, return them unchanged
      return item;
    });
    const updateNew = updatedStudentMarksObject.map((item) => {
      return { ...item, isSelected: e.target.checked };
    });
    setStudentObject(updateNew);

    const updatedStudentHeader = StudentHeader.map((student) => {
      if (student.studentID === id) {
        // If the studentID matches, toggle the isSelected property
        return {
          ...student,
          isSelected: e.target.checked,
        };
      }
      // For other students, return them unchanged
      return student;
    });

    setStudentHeader(updatedStudentHeader);
  };

  const TopicCheckBox = (e, id) => {

    const updatedStudentMarksObject = studentObject.map((item) => {
      if (item.QuestionID === id) {
        // If the QuestionID matches, toggle the isSelected property
        const updatedItem = {
          ...item,
          isSelected: !item.isSelected,
        };

        // Update isStudentSelected for each student
        updatedItem.Student = updatedItem.Student.map((student) => ({
          ...student,
          isStudentSelected: updatedItem.isSelected,
        }));

        return updatedItem;
      }
      // For other items, return them unchanged
      return item;
    });

    setStudentObject(updatedStudentMarksObject);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const uniqueList = reorder(
      uniqueListLocal,
      result.source.index,
      result.destination.index
    );
    setUniqueListLocal(uniqueList);
  };

  useEffect(() => {
    if (EnhanceClassObject.EnhanceClassObject !== undefined) {
      MakeObject();
    }
  }, [EnhanceClassObject]);

  useEffect(() => {
    adjustListOrder();
  }, [downweight]);


  const sliceCalculator = (value) => {
    if (value === "showall") {
      setStudentStart(0);
      setStudentEnd(StudentHeader.length);
      setSmall(false);
    } else {
      setStudentStart(value * 10);
      setStudentEnd((value + 1) * 10);
      setSmall(false);
    }
  };

  const ArrayTrimmer = () => {
    if (StudentHeader !== undefined) {
      if (StudentHeader.length > 0) {
        const value = Math.round(StudentHeader.length / 10);
        let localArray = [];
        for (let i = 0; i < value; i++) {
          let temp1 = {
            value: i,
            label: `${i * 10 + 1} to ${(i + 1) * 10}`,
          };
          localArray.push(temp1);
        }
        let temp1 = {
          value: "showall",
          label: "Show All",
        };
        localArray.push(temp1);
        setShowAll(localArray);
      }
    }
  };

  const adjustListOrder = () => {
    const newList = downweight === 'Yes' ? [...uniqueListLocal].reverse() : [...uniqueList];
    setUniqueListLocal(newList);
  };

// console.log(ViewBool)
  return (
    <Fragment>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card-custom ">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">View Student data</h3>
              </div>
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline">
                  <button
                    onClick={() => setSmall(!small)}
                    className="btn btn-icon btn-sm btn-hover-light-primary mr-1"
                  >
                    <i className="ki ki-bold-more-ver  icon-nm" />
                  </button>
                  <div
                    className={`dropdown-menu dropdown-menu-sm dropdown-menu-right ${
                      small ? "show" : "hide"
                    }`}
                  >
                    <ul className="navi navi-hover ScrollerSmall">
                      <li className="navi-header pb-1">
                        <span className="text-primary font-size-sm">
                          Select
                        </span>
                      </li>
                      {ShowAll.map((arr, key) => (
                        <li key={key} className="navi-item">
                          <a
                            className="navi-link"
                            onClick={() => sliceCalculator(arr.value)}
                          >
                            <span className="navi-icon">
                              <i className="flaticon2-graph-1"></i>
                            </span>
                            <span className="navi-text">{arr.label}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body py-0">
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center FreezeTable">
                  <thead>
                    <tr className="text-left">
                      <th className="pl-0" style={{ minWidth: "120px" }}>
                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                          <input
                            onChange={(e) => ApplyAll(e)}
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </th>
                      <th style={{ minWidth: "200px" }}>Topic</th>
                      <th style={{ minWidth: "200px" }}>Marks</th>
                      {StudentHeader !== undefined
                        ? StudentHeader.slice(studentstart, studentend).map(
                            (item, key) => (
                              <th key={key} style={{ maxWidth: "70px" }}>
                                <div className="d-flex gap-2">
                                  <span>
                                    <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                      <input
                                        onChange={(e) =>
                                          StudentCheckBox(e, item.studentID)
                                        }
                                        type="checkbox"
                                        checked={item.isSelected}
                                      />
                                      <span />
                                    </label>
                                  </span>
                                  <span className="mt-1">
                                    {item.studentName}
                                  </span>
                                </div>
                              </th>
                            )
                          )
                        : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {studentObject !== undefined
                      ? studentObject.map((item, index) => (
                          <tr key={index}>
                            <td className="pl-0 py-2">
                              <div className="d-flex align-items-center">
                                <label className="checkbox checkbox-lg checkbox-inline flex-shrink-0 m-0 mr-2">
                                  <input
                                    type="checkbox"
                                    name="select"
                                    onChange={(e) =>
                                      TopicCheckBox(e, item.QuestionID)
                                    }
                                    checked={item.isSelected}
                                  />
                                  <span />
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <span className="text-dark-75 font-weight-bold font-size-lg">
                                    Q {item.question_label}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.topics.map((topic, key) => (
                                <span key={key}>
                                  {topic.topicName}
                                  <br />
                                </span>
                              ))}
                            </td>
                            <td>
                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {item.totalmarks}
                                </span>
                            </td>
                            {item.Student.slice(studentstart, studentend).map(
                              (student, key) => (
                                <td key={key} style={{ minWidth: "100px" }}>
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    disabled
                                    className={getClassNameByRating(
                                      RAG,
                                      item.totalmarks,
                                      student.obtainMarks
                                    )}
                                    id={`S${student.studentID}-EnterMarks-${item.QuestionID}`}
                                    placeholder={`${student.obtainMarks}`}
                                  />
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="row mb-2">
                <div className="col-12 text-center">
                  <Button
                    variant="outline-success"
                    onClick={() => setGenerateBool(!GenerateBool)}
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        size="lg"
        show={GenerateBool}
        onHide={() => setGenerateBool(!GenerateBool)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate topic paper</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={Yup.object({
              TopicNumbers: Yup.number()
                .positive()
                .required(`Enter Maximum Topics`)
                .min(1, "It should be Greater than 1")
                .max(
                  uniqueListLocal.length,
                  `It Must be lesser than ${uniqueListLocal.length}`
                ),
              checkboxOption: Yup.array().min(1, "Please Select one"),
              OutputSelection: Yup.array().min(1, "Please Select one"),
            })}
            onSubmit={(values) => GenerateTopicPaper(values)}
            initialValues={{
              TopicNumbers: 5,
              checkboxOption: ["Red"],
              OutputSelection: ["QandA"],
              Downweight: ["No"],

            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Topics<span className="text-danger">*</span>
                    </label>
                    <div className="col-8 col-form-label">
                      <Form.Control
                          type="number"
                          className="form-control formW"
                          placeholder="Enter Topics Numbers"
                          name="TopicNumbers"
                          value={values.TopicNumbers}
                          onChange={handleChange}
                          isInvalid={!!errors.TopicNumbers}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.TopicNumbers}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Select boundary for marks.
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-8 col-form-label">
                      <div className="checkbox-inline">
                        {checkboxOptions.map((arr, key) => (
                            <label
                                key={key}
                                className={`checkbox checkbox-outline checkbox-${arr.color}`}
                            >
                              <Form.Control
                                  defaultChecked={arr.value === "Red"}
                                  type="checkbox"
                                  name="checkboxOption"
                                  value={arr.value}
                                  onChange={handleChange}
                                  isInvalid={!!errors.checkboxOption}
                              />
                              <span/>
                              {arr.value}
                            </label>
                        ))}
                      </div>
                      {errors.checkboxOption ? (
                          <span
                              className="form-text"
                              style={{color: "#F64E60"}}
                          >
                          {errors.checkboxOption}
                        </span>
                      ) : (
                          ""
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Output Selection
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-8 col-form-label">
                      <div className="checkbox-inline">
                        {OutputSelection.map((arr, key) => (
                            <label
                                key={key}
                                className={`checkbox checkbox-outline checkbox-${arr.color}`}
                            >
                              <Form.Control
                                  defaultChecked={arr.value === "QandA"}
                                  type="checkbox"
                                  name="OutputSelection"
                                  value={arr.value}
                                  onChange={handleChange}
                                  isInvalid={!!errors.checkboxOption}
                              />
                              <span/>
                              {arr.name}
                            </label>
                        ))}
                      </div>
                      {errors.OutputSelection ? (
                          <span
                              className="form-text"
                              style={{color: "#F64E60"}}
                          >
                          {errors.OutputSelection}
                        </span>
                      ) : (
                          ""
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Downweight 1 markers
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-8 col-form-label">
                      <div className="checkbox-inline">
                        {Downweight.map((arr, key) => (
                            <label
                                key={key}
                                className={`checkbox checkbox-outline checkbox-${arr.color}`}
                            >
                              <Form.Control
                                  defaultChecked={arr.value === "No"}
                                  type="radio"
                                  name="Downweight"
                                  checked={downweight === arr.value}
                                  value={arr.value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setDownweight(e.target.value);
                                  }}

                                  isInvalid={!!errors.Downweight}
                              />
                              <span/>
                              {arr.name}
                            </label>
                        ))}
                      </div>
                      {errors.Downweight ? (
                          <span
                              className="form-text"
                              style={{color: "#F64E60"}}
                          >
                          {errors.Downweight}
                        </span>
                      ) : (
                          ""
                      )}
                    </div>
                  </div>

                </div>
                <span className="form-text text-muted mt-2 ml-2">
                  <b>Rearrange Topics (if Required)</b>
                </span>
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, uniqueListLocal)}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {uniqueListLocal.map((arr, key, array) => (
                              <Draggable
                                  key={arr.id}
                                  draggableId={arr.id.toString()}
                                  index={key}
                              >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                          ),
                                          margin:
                                              key === array.length - 1
                                                  ? "0 0 0 0"
                                                  : `0 0 ${grid}px 0`,
                                }}
                              >
                                <CardHeader>
                                  {arr.id} - {arr.name}
                                </CardHeader>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>


                {/*<DragDropContext*/}
                {/*    onDragEnd={(result) => onDragEnd(result, uniqueListLocal)}*/}
                {/*>*/}
                {/*  <Droppable droppableId="droppable2">*/}
                {/*    {(provided, snapshot) => (*/}
                {/*        <div*/}
                {/*            {...provided.droppableProps}*/}
                {/*            ref={provided.innerRef}*/}
                {/*            style={getListStyle(snapshot.isDraggingOver)}*/}
                {/*        >*/}
                {/*          {Question.map((arr, key, array) => (*/}
                {/*              <Draggable*/}
                {/*                  key={arr.id}*/}
                {/*                  draggableId={arr.id.toString()}*/}
                {/*                  index={key}*/}
                {/*              >*/}
                {/*                {(provided, snapshot) => (*/}
                {/*                    <div*/}
                {/*                        ref={provided.innerRef}*/}
                {/*                        {...provided.draggableProps}*/}
                {/*                        {...provided.dragHandleProps}*/}
                {/*                        style={{*/}
                {/*                          ...getItemStyle(*/}
                {/*                              snapshot.isDragging,*/}
                {/*                              provided.draggableProps.style*/}
                {/*                          ),*/}
                {/*                          margin:*/}
                {/*                              key === array.length - 1*/}
                {/*                                  ? "0 0 0 0"*/}
                {/*                                  : `0 0 ${grid}px 0`,*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                      <CardHeader>*/}
                {/*                        {arr.id} - Label: {arr.question_label} - Position: {arr.position} - Topics: {arr.topics[0].name}*/}
                {/*                      </CardHeader>*/}
                {/*                    </div>*/}
                {/*                )}*/}
                {/*              </Draggable>*/}
                {/*          ))}*/}
                {/*          {provided.placeholder}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*  </Droppable>*/}
                {/*</DragDropContext>*/}

                <div className="row my-5 text-center">
                  <div className="col-12">
                    <Button type="submit" variant="outline-success">
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
