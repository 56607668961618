import {
  GET_SCHOOL,
  ADD_SCHOOL,
  SET_SCHOOL_LOADING,
  SET_SCHOOL_FAIL,
  UPDATE_SCHOOL_LOADING,
  UPDATE_SCHOOL_FAIL,
  UPDATE_SCHOOL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  school_list: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCHOOL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_SCHOOL:
    case UPDATE_SCHOOL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case GET_SCHOOL:
      return {
        ...state,
        school_list: action.payload,
        isLoading: false,
      };
    case SET_SCHOOL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SCHOOL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_SCHOOL:
      return {
        ...state,
        school_list: [...state.school_list, action.payload],
        isLoading: false,
      };
    default:
      return state;
  }
}
