import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import NewPaymentPage from "./NewPaymentPage";

class PaymentParent extends Component {
  static propTypes = {
    auth: PropTypes.array.isRequired,
  };

  render() {
    if (this.props.auth.isAuthenticated === false) {
      return (
        <Redirect
          to={{
            pathname: "/Login",
            message: "Please Login to Continue",
          }}
        />
      );
    } else {
      return (
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <Sidebar />
            <div className="d-flex flex-column flex-row-fluid wrapper">
              <NavbarKeen />
              <div className="content d-flex flex-column flex-column-fluid">
                <Subheader name="Subscription" />
                <div className="d-flex flex-column-fluid">
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-12">
                        <NewPaymentPage/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.token,
});
export default connect(mapStateToProps)(PaymentParent);
