import {
  GET_SCHOOLMAP_ID_LOADING,
  GET_SCHOOLMAP_ID,
  GET_SCHOOLMAP_ID_FAIL,
  UPDATE_SCHOOLMAP_ID,
  UPDATE_SCHOOLMAP_ID_LOADING,
  UPDATE_SCHOOLMAP_ID_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  school_map_user: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOLMAP_ID_LOADING:
    case UPDATE_SCHOOLMAP_ID_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SCHOOLMAP_ID:
      return {
        ...state,
        school_map_user: action.payload,
        isLoading: false,
      };
    case UPDATE_SCHOOLMAP_ID:
      return {
        ...state,
        isLoading: false,
        school_map_user: state.school_map_user.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case GET_SCHOOLMAP_ID_FAIL:
    case UPDATE_SCHOOLMAP_ID_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
