import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getFileMedia,
  getFileMediaFolder,
} from "../../actions/FileTesting/FileTestingAction";
import { Spinner } from "react-bootstrap";

class FileTestingPage2 extends Component {
  static propTypes = {
    FileMedia: PropTypes.array.isRequired,
    getFileMedia: PropTypes.func.isRequired,
    getFileMediaFolder: PropTypes.func.isRequired,
  };
  state = {
    Files: [],
    Folders: [],
  };
  FileFolder = () => {
    if (this.props.FileMedia.length !== 0) {
      this.setState({
        Files: this.props.FileMedia["Files"],
        Folders: this.props.FileMedia["Folders"],
      });
    }
  };
  CallFolder = (data) => {
    this.props.getFileMediaFolder(data);
  };

  componentDidMount() {
    this.props.getFileMediaFolder(this.props.match.params.slug);
    // console.log(this.props.match.params.slug)
    // this.props.getFileMedia();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.FileMedia !== this.props.FileMedia) {
      this.FileFolder();
    }
  }

  render() {
    const { Files, Folders } = this.state;
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <Fragment>
              <h2 className="text-center">Files</h2>
              {Files.length !== 0 ? (
                Files.map((arr, key) => (
                  <>
                    <p>
                      <a
                        className={"mb-2"}
                        key={key}
                        href={"http://" + arr}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {arr}
                      </a>
                    </p>
                    <br />
                  </>
                ))
              ) : (
                <Fragment>
                  <Spinner animation="border" variant="warning" />
                  &nbsp;Loading Files...
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>

        {/*<div className="row">*/}
        {/*  <div className="col-12">*/}
        {/*    <Fragment>*/}
        {/*      <h2 className="text-center">Folders</h2>*/}
        {/*      {Folders.length !== 0 ? (*/}
        {/*        Folders.map((arr, key) => (*/}
        {/*          <>*/}
        {/*            <p>*/}
        {/*              <a*/}
        {/*                className={"mb-2"}*/}
        {/*                key={key}*/}
        {/*                onClick={() => this.CallFolder(arr)}*/}
        {/*                // href={"http://" + arr}*/}
        {/*                // rel="noreferrer"*/}
        {/*              >*/}
        {/*                {arr}*/}
        {/*              </a>*/}
        {/*            </p>*/}
        {/*            <br />*/}
        {/*          </>*/}
        {/*        ))*/}
        {/*      ) : (*/}
        {/*        <Fragment>*/}
        {/*          <Spinner animation="border" variant="warning" />*/}
        {/*          &nbsp;Loading Folders...*/}
        {/*        </Fragment>*/}
        {/*      )}*/}
        {/*    </Fragment>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  FileMedia: state.FileMedia.FileMedia,
});

export default connect(mapStateToProps, {
  getFileMedia,
  getFileMediaFolder,
})(FileTestingPage2);
