import {
  GET_SUBJECTS,
  SET_SUBJECT_FAIL,
  SET_SUBJECT_LOADING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  subjectsList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECTS:
      return {
        ...state,
        subjectsList: action.payload,
        isLoading: false,
      };
    case SET_SUBJECT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SUBJECT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
