import React, { Component, Fragment } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getClassSchool,
  updateClass,
} from "../../actions/ClassActionFolder/ClassActions";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import makeAnimated from "react-select/animated";

import Select from "react-select";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getTeacher } from "../../actions/Teacher/TeacherAction";
import { getStudents } from "../../actions/Students/StudentsActions";
import { getLevel } from "../../actions/Level/LevelAction";

const animatedComponents = makeAnimated();

const schema = yup.object({
  name: yup.string().required("Please Enter Class Name"),
  subject: yup.string().required("Please Select Subject"),
  level: yup.string().required("Please Select Level"),
  year_grade: yup
    .array()
    .min(1, "Select At least 1 Year Grade")
    .required("Select Year Grade"),
  teacher: yup
    .array()
    .min(1, "Select At least 1 Teacher")
    .required("Select Teachers"),
  students: yup
    .array()
    .min(1, "Select At least 1 Student")
    .required("Select Student"),
});

class ClassesData extends Component {
  static propTypes = {
    getClassSchool: PropTypes.func.isRequired,
    classList: PropTypes.array.isRequired,
    classListLoading: PropTypes.bool.isRequired,
    subjectLoading: PropTypes.bool.isRequired,
    subjectsList: PropTypes.array.isRequired,
    GradeYearLoading: PropTypes.bool.isRequired,
    GradeYearList: PropTypes.array.isRequired,
    Teacher_List: PropTypes.array.isRequired,
    TeacherLoading: PropTypes.bool.isRequired,
    studentList: PropTypes.array.isRequired,
    studentLoading: PropTypes.bool.isRequired,
    getGradeYear: PropTypes.func.isRequired,
    getSubject: PropTypes.func.isRequired,
    getTeacher: PropTypes.func.isRequired,
    getStudents: PropTypes.func.isRequired,
    updateClass: PropTypes.func.isRequired,
    classListfailed: PropTypes.bool.isRequired,
    update_data: PropTypes.bool.isRequired,
    getLevel: PropTypes.func.isRequired,
    LevelList: PropTypes.array.isRequired,
    LevelListLoading: PropTypes.bool.isRequired,
  };
  state = {
    showClass: false,
    YearGrade: [],
    TeacherList: [],
    studentList: [],
    SubjectList: [],
    subject: undefined,
    year_grade: undefined,
    teacher: undefined,
    students: undefined,
    DataArray: undefined,
    TeacherDefaultArray: [],
    StudentDefaultArray: [],
    Students: [],
    Data_class: [],
    teacher_error: false,
    student_error: false,
    student_loading: true,
    teacher_loading: true,
    LevelList: [],
    YearGradeDefault: [],
    TeacherLoaded: false,
    //
    classesData: [],
  };
  getYearGrade = () => {
    const options = this.props.GradeYearList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ YearGrade: options });
  };
  getSubjectsList = () => {
    const options = this.props.subjectsList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ SubjectList: options });
  };
  getTeacher = () => {
    const options = this.props.Teacher_List.filter(
      (arr) => arr.School.id === Number(localStorage.getItem("Schoolid"))
    ).map((arr) => ({
      value: arr.id,
      label: arr.User.firstname + " " + arr.User.lastname,
    }));
    this.setState({ TeacherList: options });
  };
  getStudentfun = () => {
    const options = this.props.studentList
      .filter(
        (arr) => arr.school.id === Number(localStorage.getItem("Schoolid"))
      )
      .map((arr) => ({
        value: arr.id,
        label: arr.firstname + " " + arr.surname,
      }));
    this.setState({ studentList: options });
  };
  EditModel = (arr) => {
    this.setState({ showClass: !this.state.showClass });
    this.getYearGrade();
    this.getSubjectsList();
    this.setState({ DataArray: arr }, () => this.TeacherDefault());
  };
  TeacherDefault = () => {
    let localYearGrade = [];
    for (let x = 0; x < this.state.DataArray.year_grade.length; x++) {
      for (let y = 0; y < this.state.YearGrade.length; y++) {
        if (
          this.state.YearGrade[y].value ===
          this.state.DataArray.year_grade[x].id
        ) {
          localYearGrade.push(this.state.YearGrade[y]);
        }
      }
    }

    this.setState(
      {TeacherLoaded: true,YearGradeDefault: localYearGrade,},
      () => this.StudentDefault()
    );
  };
  LevelDefault = () => {
    const options = this.props.LevelList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ LevelList: options });
  };
  StudentDefault = () => {
    let localArray = [];
    for (let x = 0; x < this.state.DataArray.students.length; x++) {
      for (let y = 0; y < this.state.studentList.length; y++) {
        if (
          this.state.studentList[y].value ===
          this.state.DataArray.students[x].id
        ) {
          localArray.push(this.state.studentList[y]);
        }
      }
    }
    this.setState({ StudentDefaultArray: localArray }, () =>
      this.setState({ student_loading: false, showClass: true })
    );
  };
  dropdownTeacher = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ YearGrade: value });
  };
  dropdownYearGrade = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ year_grade: value });
  };
  dropdownStudent = (value, { action, removedValue }) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ StudentDefaultArray: value });
  };
  checkErrorTeacher = () => {
    if (this.state.TeacherDefaultArray.length === 0) {
      this.setState({ teacher_error: true });
    } else {
      this.setState({ teacher_error: false });
    }
  };
  checkErrorStudent = () => {
    if (this.state.StudentDefaultArray.length === 0) {
      this.setState({ student_error: true });
    } else {
      this.setState({ student_error: false });
    }
  };
  onSubmitClass = (values) => {
    this.props.updateClass(this.state.DataArray.id, values);
    this.setState({ showClass: false });
  };
  SetData = () => {
    const filter_data = this.props.classList
      .filter(
        (items) => items.school.id === Number(localStorage.getItem("Schoolid"))
      )
      .map((items) => items);
    this.setState({ Data_class: filter_data });
  };
  // New Functions
  resetValues = () => {};

  TypeSearch = (e) => {
    const query = e.target.value;
    const searchAttributes = ["name", "id"];
    const updatedList = this.props.classList.filter((user) => {
      return searchAttributes.some((attribute) =>
        user[attribute].toString().toLowerCase().includes(query.toLowerCase())
      );
    });

    this.setState({ classesData: updatedList });
    this.resetValues();
  };

  YearGrade = (e) => {
    if (e.target.value === "reset") {
      this.setState({ classesData: this.props.classList });
      this.resetValues();
    } else {
      var filteredArray = this.props.classList
        .filter((element) =>
          element.year_grade.some(
            (subElement) => parseInt(subElement.id) === parseInt(e.target.value)
          )
        )
        .map((element) => {
          let n = Object.assign({}, element, {
            subElements: element.year_grade.filter(
              (year_grade) =>
                parseInt(year_grade.id) === parseInt(e.target.value)
            ),
          });
          return n;
        });
      this.setState({ classesData: filteredArray });
      this.resetValues();
    }
  };

  Subject = (e) => {
    if (e.target.value === "reset") {
      this.setState({ classesData: this.props.classList });
      this.resetValues();
    } else {
    }
    const updatedList = this.props.classList.filter((user) => {
      return parseInt(user.subject.id) === parseInt(e.target.value);
    });
    this.setState({ classesData: updatedList });
    this.resetValues();
  };
test=()=>{
  
}
  componentDidMount() {
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
    this.props.getGradeYear();
    this.props.getSubject();
    this.props.getTeacher();
    this.props.getStudents();
    this.props.getLevel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.classList !== this.props.classList) {
      this.setState({ classesData: this.props.classList });
      this.SetData();
    }
    if (prevProps.studentList !== this.props.studentList) {
      this.getStudentfun();
    }
    if (prevProps.LevelList !== this.props.LevelList) {
      this.LevelDefault();
    }
  }

  render() {
    const {
      showClass,
      YearGrade,
      valueSubject,
      SubjectList,
      TeacherList,
      StudentDefaultArray,
      studentList,
      DataArray,
      TeacherDefaultArray,
      Data_class,
      // teacher_error,
      // student_error,
      student_loading,
      LevelList,
      YearGradeDefault,
      TeacherLoaded,
      classesData,
    } = this.state;
    const {
      classListLoading,
      subjectLoading,
      GradeYearLoading,
      classListfailed,
      update_data,
      LevelListLoading,
      update_fail,
      subjectsList,
    } = this.props;
    return (
      <div className="card card-custom">
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {(() => {
                if (classListLoading) {
                  return (
                    <Fragment>
                      <Spinner animation="border" variant="warning" />
                      &nbsp;Loading Class...
                    </Fragment>
                  );
                } else if (update_data) {
                  return (
                    <Fragment>
                      <Spinner animation="border" variant="success" />
                      &nbsp;Updating Class data...
                    </Fragment>
                  );
                } else if (update_fail) {
                  return (
                    <Fragment>
                      <i className="ki ki-solid-plus ki-bold-close text-danger" />
                      &nbsp;Oops Update Fail
                    </Fragment>
                  );
                } else if (classListLoading === undefined) {
                  return (
                    <Fragment>
                      <Spinner animation="border" variant="warning" />
                      &nbsp;Loading Class...
                    </Fragment>
                  );
                } else if (classListfailed) {
                  return <Fragment>No class Found</Fragment>;
                } else {
                  return <Fragment>Classes</Fragment>;
                }
              })()}
            </span>
          </h3>

          <div className="card-toolbar">
            <div className="dropdown dropdown-inline">
              {DataArray !== undefined ? (
                <Modal
                  centered
                  size="xl"
                  show={showClass}
                  onHide={() => this.setState({ showClass: false })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Update Class</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="card card-custom card-shadowless rounded-top-0">
                      <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                          <div className="col-xl-12 col-xxl-10">
                            <Formik
                              // validationSchema={schema}
                              onSubmit={this.onSubmitClass}
                              initialValues={{}}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  <div className="row justify-content-center">
                                    <div className="col-xl-12">
                                      <div className="my-5 step">
                                        {/* school name */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            School
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="school"
                                              type="text"
                                              disabled
                                              value={localStorage.getItem("SchoolName")}
                                              onChange={handleChange}
                                              isInvalid={!!errors.school}
                                            />
                                            <span className="form-text text-muted">
                                              You can add data for your school
                                            </span>
                                            <Form.Control.Feedback type="invalid">
                                              {errors.school}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <label className="col-xl-3" />
                                          <div className="col-lg-9 col-xl-6">
                                            <h5 className="font-weight-bold mt-10 mb-6">
                                              Class Info
                                            </h5>
                                          </div>
                                        </div>
                                        {/* class name */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Class name
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="name"
                                              type="text"
                                              placeholder="name"
                                              defaultValue={DataArray.name}
                                              onChange={handleChange}
                                              isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.name}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>
                                        {/* Subject */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Subject
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Select
                                              name="subject"
                                              components={animatedComponents}
                                              defaultValue={SubjectList.filter(
                                                (arr) =>
                                                  arr.value ===
                                                  DataArray.subject.id
                                              ).map((arr) => arr)}
                                              isDisabled={subjectLoading}
                                              isLoading={subjectLoading}
                                              value={valueSubject}
                                              options={SubjectList}
                                              className="basic-multi-select"
                                              onChange={(e, event) => {
                                                handleChange({
                                                  ...event,
                                                  target: {
                                                    name: "subject",
                                                    value: e.value,
                                                  },
                                                });
                                              }}
                                            />
                                            {errors.subject && (
                                              <span className="text-danger">
                                                {errors.subject
                                                  ? errors.subject
                                                  : ""}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        {/* Level */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Level
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Select
                                              name="level"
                                              components={animatedComponents}
                                              defaultValue={LevelList.filter(
                                                (arr) =>
                                                  arr.value ===
                                                  DataArray.level.id
                                              ).map((arr) => arr)}
                                              isDisabled={LevelListLoading}
                                              isLoading={LevelListLoading}
                                              options={LevelList}
                                              className="basic-multi-select"
                                              onChange={(e, event) => {
                                                handleChange({
                                                  ...event,
                                                  target: {
                                                    name: "level",
                                                    value: e.value,
                                                  },
                                                });
                                              }}
                                            />
                                            {errors.level && (
                                              <span className="text-danger">
                                                {errors.level
                                                  ? errors.level
                                                  : ""}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        {/* Year Grade */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Year Grade
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Select
                                              name="year_grade"
                                              components={animatedComponents}
                                              isDisabled={GradeYearLoading}
                                              isLoading={GradeYearLoading}
                                              isMulti
                                              placeholder="Select Year Grade"
                                              defaultValue={this.props.GradeYearList.filter(
                                                (grade) => {
                                                  return DataArray.year_grade.some(
                                                    (filterGrade) =>
                                                      filterGrade.id ===
                                                      grade.id
                                                  );
                                                }
                                              ).map((arr) => ({
                                                value: arr.id,
                                                label: arr.name,
                                              }))}
                                              options={this.props.GradeYearList.map(
                                                (arr) => ({
                                                  value: arr.id,
                                                  label: arr.name,
                                                })
                                              )}
                                              onChange={(
                                                selectedOption
                                              ) => {
                                                let event = {
                                                  target: {
                                                    name: "year_grade",
                                                    value:
                                                      selectedOption.map(
                                                        (arr) => arr.value
                                                      ),
                                                  },
                                                };
                                                handleChange(event);
                                              }}
                                            />
                                            {errors.year_grade && (
                                              <span className="text-danger">
                                                {errors.year_grade
                                                  ? errors.year_grade
                                                  : ""}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        {/* Teacher */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Teacher
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Select
                                              defaultValue={this.props.Teacher_List.filter(
                                                (grade) => {
                                                  return DataArray.teacher.some((filterGrade) =>filterGrade.id ===grade.id);
                                                }
                                              ).map((arr) => ({
                                                value: arr.id,
                                                label: arr.name,
                                              }))}
                                              components={animatedComponents}
                                              closeMenuOnSelect={false}
                                              name="teacher"
                                              placeholder="Select teacher"
                                              isMulti
                                              isDisabled={!TeacherLoaded}
                                              isLoading={!TeacherLoaded}
                                              options={this.props.Teacher_List.map(
                                                (arr) => ({
                                                  value: arr.id,
                                                  label: arr.name,
                                                })
                                              )}
                                              onChange={(
                                                selectedOption
                                              ) => {
                                                let event = {
                                                  target: {
                                                    name: "teacher",
                                                    value:
                                                      selectedOption.map(
                                                        (arr) => arr.value
                                                      ),
                                                  },
                                                };
                                                handleChange(event);
                                              }}
                                            />
                                            {errors.teacher ? (
                                              <span
                                                className="form-text"
                                                style={{ color: "#F64E60" }}
                                              >
                                                {errors.teacher}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        {/*student  */}
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Students
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            {student_loading ? (
                                              <Button
                                                variant="primary"
                                                disabled
                                              >
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                Loading students...
                                              </Button>
                                            ) : (
                                              <Fragment>
                                                <Select
                                                  name="students"
                                                  placeholder="Select students"
                                                  components={animatedComponents}
                                                  closeMenuOnSelect={false}
                                                  isMulti={true}
                                                    isClearable={true}
                                                  isLoading={this.props.studentLoading}
                                                  defaultValue={this.props.studentList
                                                    .filter((grade) => {
                                                      return DataArray.students.some((filterGrade) =>filterGrade.id ===grade.id);
                                                    })
                                                    .map((arr) => ({
                                                      value: arr.id,
                                                      label:
                                                        arr.firstname +
                                                        " " +
                                                        arr.surname,
                                                    }))}
                                                  options={studentList}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    let event = {
                                                      target: {
                                                        name: "students",
                                                        value:
                                                          selectedOption.map(
                                                            (arr) => arr.value
                                                          ),
                                                      },
                                                    };
                                                    handleChange(event);
                                                  }}
                                                />
                                                {errors.students ? (
                                                  <span
                                                    className="form-text"
                                                    style={{ color: "#F64E60" }}
                                                  >
                                                    {errors.students}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </Fragment>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                        <div>
                                          <button
                                            onClick={() =>
                                              this.setState({
                                                showClass: false,
                                              })
                                            }
                                            type="button"
                                            className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-success font-weight-bolder font-size-sm"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="card-body py-0">
          <div className="mb-10">
            <div className="row align-items-center">
              <div className="col-lg-12 col-xl-12">
                <div className="row align-items-center">
                  <div className="col-md-4 my-2 my-md-0">
                    <div className="input-icon">
                      <input
                        onKeyUp={(e) => this.TypeSearch(e)}
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Type class name or class id"
                      />
                      <span>
                        <i className="flaticon2-search-1 text-muted" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 my-2 my-md-0">
                    <select
                      onChange={(e) => this.Subject(e)}
                      className="form-control form-control-solid"
                    >
                      <option value={"reset"}>-Select subject-</option>
                      {subjectsList.map((arr, key) => (
                        <option key={key} value={arr.id}>
                          {arr.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="col-md-3 my-2 my-md-0">
                    <select
                      // onChange={(e) => this.YearGrade(e)}
                      className="form-control form-control-solid"
                    >
                      <option value={"reset"}>-Teacher-</option>
                      {this.props.Teacher_List.map((arr, key) => (
                        <option key={key} value={arr.id}>
                          {arr.User.firstname}{" "}{arr.User.lastname}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="col-md-4 my-2 my-md-0">
                    <select
                      onChange={(e) => this.YearGrade(e)}
                      className="form-control form-control-solid"
                    >
                      <option value={"reset"}>-Year Grade-</option>
                      {this.props.GradeYearList?.map((arr, key) => (
                        <option key={key} value={arr.id}>
                          {arr.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(() => {
            if (classesData.length >= 1) {
              return (
                <div className="table-responsive">
                  <table className="table table-head-custom table-vertical-center">
                    <thead>
                      <tr className="text-left">
                        <th className="pl-0" style={{ width: "30px" }}>
                          <label className="checkbox checkbox-lg checkbox-inline mr-2">
                            <Form.Control
                              disabled={true}
                              type="checkbox"
                              defaultValue={1}
                            />
                            <span />
                          </label>
                        </th>
                        <th className="pl-0" style={{ minWidth: "120px" }}>
                          id
                        </th>
                        <th style={{ minWidth: "120px" }}>Name</th>
                        <th style={{ minWidth: "120px" }}>Subject</th>
                        <th style={{ minWidth: "120px" }}>Year Grade</th>
                        <th style={{ minWidth: "120px" }}>Teachers</th>
                        <th style={{ minWidth: "120px" }}># Student</th>
                        <th style={{ minWidth: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classesData.length !== 0
                        ? classesData.map((arr, key) => (
                            <tr key={key}>
                              <td className="pl-0 py-6">
                                <label className="checkbox checkbox-lg checkbox-inline">
                                  <Form.Control
                                    type="checkbox"
                                    defaultValue={1}
                                    disabled={true}
                                  />
                                  <span />
                                </label>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder  font-size-lg">
                                  {arr.id}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder font-size-lg">
                                  {arr.name}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.subject.name}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.year_grade.map((item, key1) => (
                                    <div key={key1}>
                                      {item.name}
                                      <br />
                                    </div>
                                  ))}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.teacher.map((teachers, key1) => (
                                    <div key={key1}>
                                      {teachers.name}
                                      <br />
                                    </div>
                                  ))}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.students.length}
                                </span>
                              </td>
                              <td className="pl-0">
                                <Button
                                  onClick={() => this.EditModel(arr)}
                                  className="btn btn-success font-weight-bolder font-size-sm mr-2"
                                >
                                  Edit
                                </Button>
                                <a
                                  href={/ClassExams/ + arr.id + "/" + arr.name}
                                  className="btn btn-outline-warning font-weight-bolder font-size-sm"
                                >
                                  Exams
                                </a>
                              </td>
                            </tr>
                          ))
                        : "empty"}
                    </tbody>
                  </table>
                </div>
              );
            } else {
              return <p>No matching result</p>;
            }
          })()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  classList: state.class_by_School.class_by_School,
  classListLoading: state.class_by_School.isLoading,
  classListfailed: state.classList.failed,
  update_data: state.classList.update_data,
  subjectLoading: state.subjectsList.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  GradeYearLoading: state.GradeYearList.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
  Teacher_List: state.Teacher_List.Teacher,
  Teacher_ListLoading: state.Teacher_List.isLoading,
  studentList: state.studentList.studentList,
  update_fail: state.classList.update_fail,
  studentLoading: state.studentList.isLoading,
  LevelList: state.LevelList.LevelList,
  LevelListLoading: state.LevelList.isLoading,
});
export default connect(mapStateToProps, {
  getClassSchool,
  getStudents,
  getTeacher,
  getSubject,
  getGradeYear,
  updateClass,
  getLevel,
})(ClassesData);
