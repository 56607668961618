import React, { Component } from "react";
import logo from "../../media/Logo/logo.png";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../index.css";

class Sidebar extends Component {
  state = {
    close: true,
  };
  onClickfun = () => {
    let side = document.getElementById("workingSideBar");
    if (this.state.close) {
      side.classList.remove("aside-minimize");
      this.setState({ close: false });
    } else {
      this.setState({ close: true });
      side.classList.add("aside-minimize");
    }
  };
  render() {
    return (
      <div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto">
        <div className="brand flex-column-auto" id="kt_brand">
          <a href="/" className="brand-logo">
            <img alt="Logo" width="30" height="30" src={logo} />
          </a>
          <button
            className="brand-toggle btn btn-sm px-0"
            onClick={() => this.onClickfun()}
          >
            <span className="svg-icon svg-icon svg-icon-xl"></span>
            <div
              style={{
                transition: "all 0.5s ease",
                transform: `rotate(${this.state.close ? 0 : "0.5turn"})`,
              }}
            >
              <i className="icon-xl fas fa-angle-double-right" />
            </div>
          </button>
        </div>
        <div className="aside-menu-wrapper flex-column-fluid">
          <div
            className="aside-menu my-4"
            data-menu-vertical={1}
            data-menu-scroll={1}
            data-menu-dropdown-timeout={500}
          >
            <div className="Scroller">
              <ul className="menu-nav">
                {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Dashboard</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item" aria-haspopup="true">
                    <a href="/Dashboard" className="menu-link">
                      <span className="svg-icon menu-icon">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                            <path
                              d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <span className="menu-text">Dashboard</span>
                    </a>
                  </li>
                </OverlayTrigger>
                <li className="menu-section">
                  <h4 className="menu-text">
                    {localStorage.getItem("SchoolName")}
                  </h4>
                  <i className="menu-icon ki ki-bold-more-hor icon-md" />
                </li>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Home</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu hovertest">
                    <a href="/" className="menu-link">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Home</span>
                    </a>
                  </li>
                </OverlayTrigger>
                {/* <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">School</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/School/" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                              fill="#000000"
                            />
                            <rect
                              fill="#FFFFFF"
                              x={13}
                              y={8}
                              width={3}
                              height={3}
                              rx={1}
                            />
                            <path
                              d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">School</span>
                    </a>
                  </li>
                </OverlayTrigger> */}
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">User Permissions</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a
                      href="/UserPermission/"
                      className="menu-link menu-toggle"
                    >
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">User Permissions</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Admin School</Tooltip>
                  }
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/AdminSchool/" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <i className="fas fa-user-shield svg-icon menu-icon"></i>
                      </span>
                      <span className="menu-text">Admin School</span>
                    </a>
                  </li>
                </OverlayTrigger>

                {/*<OverlayTrigger*/}
                {/*  overlay={*/}
                {/*    <Tooltip id="tooltip-disabled">Student Progress</Tooltip>*/}
                {/*  }*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/StudentMarks" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        /!*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\User.svg*!/*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <polygon points="0 0 24 0 24 24 0 24" />*/}
                {/*            <path*/}
                {/*              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"*/}
                {/*              fill="#000000"*/}
                {/*              fillRule="nonzero"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"*/}
                {/*              fill="#000000"*/}
                {/*              fillRule="nonzero"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Student Progress</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Students</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Students-list" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Group.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>

                      <span className="menu-text">Students</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Subscription</Tooltip>
                  }
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Subscription" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <g
                              transform="translate(12.500000, 12.000000) rotate(-315.000000) translate(-12.500000, -12.000000) translate(6.000000, 1.000000)"
                              fill="#000000"
                              opacity="0.3"
                            >
                              <path
                                d="M0.353553391,7.14644661 L3.35355339,7.14644661 C3.4100716,7.14644661 3.46549471,7.14175791 3.51945496,7.13274826 C3.92739876,8.3050906 5.04222146,9.14644661 6.35355339,9.14644661 C8.01040764,9.14644661 9.35355339,7.80330086 9.35355339,6.14644661 C9.35355339,4.48959236 8.01040764,3.14644661 6.35355339,3.14644661 C5.04222146,3.14644661 3.92739876,3.98780262 3.51945496,5.16014496 C3.46549471,5.15113531 3.4100716,5.14644661 3.35355339,5.14644661 L0.436511831,5.14644661 C0.912589923,2.30873327 3.3805571,0.146446609 6.35355339,0.146446609 C9.66726189,0.146446609 12.3535534,2.83273811 12.3535534,6.14644661 L12.3535534,19.1464466 C12.3535534,20.2510161 11.4581229,21.1464466 10.3535534,21.1464466 L2.35355339,21.1464466 C1.24898389,21.1464466 0.353553391,20.2510161 0.353553391,19.1464466 L0.353553391,7.14644661 Z"
                                transform="translate(6.353553, 10.646447) rotate(-360.000000) translate(-6.353553, -10.646447) "
                              />
                              <rect
                                x="2.35355339"
                                y="13.1464466"
                                width={8}
                                height={2}
                                rx={1}
                              />
                              <rect
                                x="3.35355339"
                                y="17.1464466"
                                width={6}
                                height={2}
                                rx={1}
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Subscription</span>
                    </a>
                  </li>
                </OverlayTrigger>

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/Reports" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*            <rect*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*              x={7}*/}
                {/*              y={10}*/}
                {/*              width={5}*/}
                {/*              height={2}*/}
                {/*              rx={1}*/}
                {/*            />*/}
                {/*            <rect*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*              x={7}*/}
                {/*              y={14}*/}
                {/*              width={9}*/}
                {/*              height={2}*/}
                {/*              rx={1}*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Report</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">Marks</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/Marks" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M7.34,19 L7.34,4.8 L12.68,4.8 C15,4.8 16.9,6.7 16.9,9.02 C16.9,10.02 16.52,10.84 15.9,11.46 C17.1,12.1 17.9,13.26 17.9,14.78 C17.9,17.12 16,19 13.6,19 L7.34,19 Z M10.54,16.06 L13.3,16.06 C14.16,16.06 14.78,15.44 14.78,14.66 C14.78,13.88 14.16,13.24 13.3,13.24 L10.54,13.24 L10.54,16.06 Z M10.54,10.54 L12.32,10.54 C13.18,10.54 13.8,9.92 13.8,9.14 C13.8,8.36 13.18,7.72 12.32,7.72 L10.54,7.72 L10.54,10.54 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Marks</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={*/}
                {/*    <Tooltip id="tooltip-disabled">Question Paper</Tooltip>*/}
                {/*  }*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/Question-Paper" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Question Paper</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Exams</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Exams" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M11,3 L11,11 C11,11.0862364 11.0109158,11.1699233 11.0314412,11.2497543 C10.4163437,11.5908673 10,12.2468125 10,13 C10,14.1045695 10.8954305,15 12,15 C13.1045695,15 14,14.1045695 14,13 C14,12.2468125 13.5836563,11.5908673 12.9685588,11.2497543 C12.9890842,11.1699233 13,11.0862364 13,11 L13,3 L17.7925828,12.5851656 C17.9241309,12.8482619 17.9331722,13.1559315 17.8173006,13.4262985 L15.1298744,19.6969596 C15.051085,19.8808016 14.870316,20 14.6703019,20 L9.32969808,20 C9.12968402,20 8.94891496,19.8808016 8.87012556,19.6969596 L6.18269936,13.4262985 C6.06682778,13.1559315 6.07586907,12.8482619 6.2074172,12.5851656 L11,3 Z"
                              fill="#000000"
                            />
                            <path
                              d="M10,21 L14,21 C14.5522847,21 15,21.4477153 15,22 L15,23 L9,23 L9,22 C9,21.4477153 9.44771525,21 10,21 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Exams</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Classes</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Classes" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M3.5,3 L9.5,3 C10.3284271,3 11,3.67157288 11,4.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L3.5,20 C2.67157288,20 2,19.3284271 2,18.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z M9,9 C8.44771525,9 8,9.44771525 8,10 L8,12 C8,12.5522847 8.44771525,13 9,13 C9.55228475,13 10,12.5522847 10,12 L10,10 C10,9.44771525 9.55228475,9 9,9 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M14.5,3 L20.5,3 C21.3284271,3 22,3.67157288 22,4.5 L22,18.5 C22,19.3284271 21.3284271,20 20.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,4.5 C13,3.67157288 13.6715729,3 14.5,3 Z M20,9 C19.4477153,9 19,9.44771525 19,10 L19,12 C19,12.5522847 19.4477153,13 20,13 C20.5522847,13 21,12.5522847 21,12 L21,10 C21,9.44771525 20.5522847,9 20,9 Z"
                              fill="#000000"
                              transform="translate(17.500000, 11.500000) scale(-1, 1) translate(-17.500000, -11.500000) "
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Classes</span>
                    </a>
                  </li>
                </OverlayTrigger>

                {/*<OverlayTrigger*/}
                {/*  overlay={*/}
                {/*    <Tooltip id="tooltip-disabled">Mock Up Result</Tooltip>*/}
                {/*  }*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/MockUpResult" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect*/}
                {/*              opacity="0.200000003"*/}
                {/*              x={0}*/}
                {/*              y={0}*/}
                {/*              width={24}*/}
                {/*              height={24}*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M4.5,7 L9.5,7 C10.3284271,7 11,7.67157288 11,8.5 C11,9.32842712 10.3284271,10 9.5,10 L4.5,10 C3.67157288,10 3,9.32842712 3,8.5 C3,7.67157288 3.67157288,7 4.5,7 Z M13.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L13.5,18 C12.6715729,18 12,17.3284271 12,16.5 C12,15.6715729 12.6715729,15 13.5,15 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M17,11 C15.3431458,11 14,9.65685425 14,8 C14,6.34314575 15.3431458,5 17,5 C18.6568542,5 20,6.34314575 20,8 C20,9.65685425 18.6568542,11 17,11 Z M6,19 C4.34314575,19 3,17.6568542 3,16 C3,14.3431458 4.34314575,13 6,13 C7.65685425,13 9,14.3431458 9,16 C9,17.6568542 7.65685425,19 6,19 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Mock Up Result</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                <hr />
                <li className="menu-section">
                  <h4 className="menu-text">Documents</h4>
                  <i className="menu-icon ki ki-bold-more-hor icon-md" />
                </li>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Downloads</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Documents" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) "
                              x={11}
                              y={1}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) "
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Downloads</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <hr />
                <li className="menu-section">
                  <h4 className="menu-text">User Profile</h4>
                  <i className="menu-icon ki ki-bold-more-hor icon-md" />
                </li>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Profile</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Profile" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Profile</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <hr />

                {/*<li className="menu-section">*/}
                {/*  <h4 className="menu-text">Company Policy</h4>*/}
                {/*  <i className="menu-icon ki ki-bold-more-hor icon-md" />*/}
                {/*</li>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">Privacy</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/Privacy" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Privacy</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">Support</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/Support" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z"*/}
                {/*              fill="#000000"*/}
                {/*              fillRule="nonzero"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.98630124,11 4.48466491,11.0516454 4,11.1500272 L4,7 C4,5.8954305 4.8954305,5 6,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.9835977,18 Z M19.1444251,6.83964668 L13,10.1481833 L6.85557487,6.83964668 C6.4908718,6.6432681 6.03602525,6.77972206 5.83964668,7.14442513 C5.6432681,7.5091282 5.77972206,7.96397475 6.14442513,8.16035332 L12.6444251,11.6603533 C12.8664074,11.7798822 13.1335926,11.7798822 13.3555749,11.6603533 L19.8555749,8.16035332 C20.2202779,7.96397475 20.3567319,7.5091282 20.1603533,7.14442513 C19.9639747,6.77972206 19.5091282,6.6432681 19.1444251,6.83964668 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Support</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">License</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/License" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <mask fill="white">*/}
                {/*              <use xlinkHref="#path-1" />*/}
                {/*            </mask>*/}
                {/*            <g />*/}
                {/*            <path*/}
                {/*              d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">License</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={<Tooltip id="tooltip-disabled">FAQ</Tooltip>}*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a href="/FAQ" className="menu-link menu-toggle">*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M3,16 L21,16 C21,18.209139 19.209139,20 17,20 L7,20 C4.790861,20 3,18.209139 3,16 Z M3,11 L21,11 L21,12 C21,13.1045695 20.1045695,14 19,14 L5,14 C3.8954305,14 3,13.1045695 3,12 L3,11 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M3,5 L21,5 L21,7 C21,8.1045695 20.1045695,9 19,9 L5,9 C3.8954305,9 3,8.1045695 3,7 L3,5 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">FAQ</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  overlay={*/}
                {/*    <Tooltip id="tooltip-disabled">Terms & Conditions</Tooltip>*/}
                {/*  }*/}
                {/*  placement="right"*/}
                {/*>*/}
                {/*  <li className="menu-item menu-item-submenu">*/}
                {/*    <a*/}
                {/*      href="/Terms-&-Conditions"*/}
                {/*      className="menu-link menu-toggle"*/}
                {/*    >*/}
                {/*      <span className="svg-icon menu-icon">*/}
                {/*        <svg*/}
                {/*          xmlns="http://www.w3.org/2000/svg"*/}
                {/*          xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*          width="24px"*/}
                {/*          height="24px"*/}
                {/*          viewBox="0 0 24 24"*/}
                {/*          version="1.1"*/}
                {/*        >*/}
                {/*          <g*/}
                {/*            stroke="none"*/}
                {/*            strokeWidth={1}*/}
                {/*            fill="none"*/}
                {/*            fillRule="evenodd"*/}
                {/*          >*/}
                {/*            <rect x={0} y={0} width={24} height={24} />*/}
                {/*            <path*/}
                {/*              d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"*/}
                {/*              fill="#000000"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*              d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"*/}
                {/*              fill="#000000"*/}
                {/*              opacity="0.3"*/}
                {/*            />*/}
                {/*          </g>*/}
                {/*        </svg>*/}
                {/*      </span>*/}
                {/*      <span className="menu-text">Terms & Conditions</span>*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</OverlayTrigger>*/}

                <hr />
                <li className="menu-section">
                  <h4 className="menu-text">Support</h4>
                  <i className="menu-icon ki ki-bold-more-hor icon-md" />
                </li>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-disabled">Get Help</Tooltip>}
                  placement="right"
                >
                  <li className="menu-item menu-item-submenu">
                    <a href="/Help" className="menu-link menu-toggle">
                      <span className="svg-icon menu-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx={12}
                              cy={12}
                              r={10}
                            />
                            <path
                              d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="menu-text">Get Help</span>
                    </a>
                  </li>
                </OverlayTrigger>

                <hr />
                <div className="mb-10"></div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
