import { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import StudentList from "./Studentslist";
class StudentListOuter extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Sidebar />
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <NavbarKeen />
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
            >
              <Subheader name="Students" />
              <div className="d-flex flex-column-fluid">
                <div className="container-fluid">
                  <StudentList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentListOuter;
