import React, { Component } from "react";
import PaymentPage from "./PaymentPage";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

class PageParent extends Component {
  static propTypes = {
    auth: PropTypes.array.isRequired,
  };

  render() {
    if (this.props.auth.isAuthenticated === false) {
      return (
        <Redirect
          to={{
            pathname: "/Login",
            message: "Please Login to Continue",
          }}
        />
      );
    } else {
      return (
        <div className="d-flex flex-column flex-root">
          {/*begin::Page*/}
          <div className="d-flex flex-row flex-column-fluid page">
            {/*begin::Aside*/}
            <Sidebar />
            {/*end::Aside*/}
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column flex-row-fluid wrapper">
              {/*begin::Header*/}
              <NavbarKeen />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div className="content d-flex flex-column flex-column-fluid">
                {/*begin::Subheader*/}
                <Subheader name="Subscription" />
                {/*end::Subheader*/}
                {/*begin::Entry*/}
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-12">
                        <PaymentPage />
                      </div>
                    </div>
                    {/*begin::Statistics*/}
                    {/*end::Statistics*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Entry*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.token,
});
export default connect(mapStateToProps)(PageParent);
