import {
  LOADING_TEACHER_ACTIVE,
  GET_TEACHER_ACTIVE,
  FAIL_TEACHER_ACTIVE,
} from "../actions/types";

const initialState = {
  isLoading: false,
  TeacherActive: [],
  isLoadingTeacher: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TEACHER_ACTIVE:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TEACHER_ACTIVE:
      return {
        ...state,
        TeacherActive: action.payload,
        isLoadingTeacher: false,
      };

    default:
      return state;
  }
}
