import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import {
  ADD_SUPPORT,
  ADD_SUPPORT_FAILED,
  ADD_SUPPORT_LOADING,
  DELETE_SUPPORT,
  DELETE_SUPPORT_FAILED,
  DELETE_SUPPORT_LOADING,
  GET_SUPPORT,
  GET_SUPPORT_FAILED,
  GET_SUPPORT_LOADING,
  UPDATE_SUPPORT,
  UPDATE_SUPPORT_FAILED,
  UPDATE_SUPPORT_LOADING,
} from "../types";
import { tokenConfig } from "../auth_action/auth";

export const getSupport = (data) => (dispatch, getState) => {
  dispatch({ type: GET_SUPPORT_LOADING });
  axios
    .get(`Policy/Support/${data}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUPPORT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_SUPPORT_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteSupport = (id) => (dispatch, getState) => {
  dispatch({ type: DELETE_SUPPORT_LOADING });

  axios
    .delete(`Policy/Support/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Ticket Delete" }));
      dispatch({
        type: DELETE_SUPPORT,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_SUPPORT_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const addSupport = (Data) => (dispatch, getState) => {
  dispatch({ type: ADD_SUPPORT_LOADING });
  axios
    .post("Policy/Support/", Data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Ticket Created!" }));
      dispatch({
        type: ADD_SUPPORT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: ADD_SUPPORT_FAILED });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
export const updateSupport = (id, DeadLine) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SUPPORT_LOADING });
  axios
    .put(`Policy/Support/${id}`, DeadLine, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ LeadUpdate: "Ticket Updated" }));
      dispatch({
        type: UPDATE_SUPPORT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_SUPPORT_FAILED });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
