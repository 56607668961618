export const GET_QUESTION_PAPER = "GET_QUESTION_PAPER";
export const SET_QUESTION_PAPER_LOADING = "SET_QUESTION_PAPER_LOADING";
export const SET_QUESTION_PAPER_FAIL = "SET_QUESTION_PAPER_FAIL";
export const CHECK_SUBSCRIPTION = "CHECK_SUBSCRIPTION";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const Remove_SCHOOL = "Remove_SCHOOL";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const CLEAR_LEADS = "CLEAR_LEADS";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_ERRORS = "GET_ERRORS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GET_SCHOOL = "GET_SCHOOL";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const GET_BUNDLE = "GET_BUNDLE";
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_EXAM = "GET_EXAM";
export const GET_FAQ = "GET_FAQ";
export const GET_TERMS = "GET_TERMS";
export const GET_POLICY = "GET_POLICY";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const ADD_STUDENT = "ADD_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const GET_STUDENT = "GET_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const ADD_CLASS = "ADD_CLASS";
export const SET_CLASS_LOADING = "SET_CLASS_LOADING";
export const SET_CLASS_FAIL = "SET_CLASS_FAIL";
export const DELETE_CLASS = "DELETE_CLASS";
export const GET_CLASS = "GET_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const SET_SCHOOL_LOADING = "SET_SCHOOL_LOADING";
export const SET_SCHOOL_FAIL = "SET_SCHOOL_FAIL";
export const SET_SUBJECT_LOADING = "SET_SUBJECT_LOADING";
export const SET_SUBJECT_FAIL = "SET_SUBJECT_FAIL";
export const SET_EXAM_LOADING = "SET_EXAM_LOADING";
export const SET_EXAM_FAIL = "SET_EXAM_FAIL";
export const GET_QUESTION = "GET_QUESTION";
export const SET_QUESTION_LOADING = "SET_QUESTION_LOADING";
export const SET_QUESTION_FAIL = "SET_QUESTION_FAIL";
export const SET_STUDENT_LOADING = "SET_STUDENT_LOADING";
export const SET_STUDENT_FAIL = "SET_STUDENT_FAIL";
export const GET_CLASS_FILTER = "GET_CLASS_FILTER";
export const SET_CLASS_FILTER_LOADING = "SET_CLASS_FILTER_LOADING";
export const SET_CLASS_FILTER_FAIL = "SET_CLASS_FILTER_FAIL";
export const GET_GRADE_YEAR = "GET_GRADE_YEAR";
export const SET_GRADE_YEAR_LOADING = "SET_GRADE_YEAR_LOADING";
export const SET_GRADE_YEAR_FAIL = "SET_GRADE_YEAR_FAIL";
export const GET_SCHOOL_ID = "GET_SCHOOL_ID";
export const SET_SCHOOL_ID_FAIL = "SET_SCHOOL_ID_FAIL";
export const SET_SCHOOL_ID_LOADING = "SET_SCHOOL_ID_LOADING";
export const GET_STUDENT_MARKS = "GET_STUDENT_MARKS";
export const SET_STUDENT_MARKS_FAIL = "SET_STUDENT_MARKS_FAIL";
export const SET_STUDENT_MARKS_LOADING = "SET_STUDENT_MARKS_LOADING";
export const DELETE_STUDENT_MARKS_LOADING = "DELETE_STUDENT_MARKS_LOADING";
export const UPDATE_STUDENT_MARKS = "UPDATE_STUDENT_MARKS";
export const ADD_STUDENT_MARKS = "ADD_STUDENT_MARKS";
export const GET_EXAM_DATA = "GET_EXAM_DATA";
export const SET_EXAM_DATA_LOADING = "SET_EXAM_DATA_LOADING";
export const SET_EXAM_DATA_FAIL = "SET_EXAM_DATA_FAIL";
export const GET_EXAM_GENERATOR = "GET_EXAM_GENERATOR";
export const GET_EXAM_GENERATOR_FAIL = "GET_EXAM_GENERATOR_FAIL";
export const GET_CSV = "GET_CSV";
export const ADD_CSV = "ADD_CSV";
export const SET_CSV_FAIL = "SET_CSV_FAIL";
export const SET_CSV_LOADING = "SET_CSV_LOADING";
export const SET_ADD_CSV_FAIL = "SET_ADD_CSV_FAIL";
export const SET_ADD_CSV_LOADING = "SET_ADD_CSV_LOADING";
export const GET_EXAM_GENERATOR_LOADING = "GET_EXAM_GENERATOR_LOADING";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING";
export const SUBSCRIPTION_FAIL = "SUBSCRIPTION_FAIL";
export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const GET_COUNTRY_LOADING = "GET_COUNTRY_LOADING";
export const GET_COUNTRY_FAILED = "GET_COUNTRY_FAILED";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_FAIL = "UPDATE_USER_INFO_FAIL";
export const UPDATE_USER_INFO_LOADING = "UPDATE_USER_INFO_LOADING";
export const UPDATE_SCHOOL_LOADING = "UPDATE_SCHOOL_LOADING";
export const UPDATE_SCHOOL_FAIL = "UPDATE_SCHOOL_FAIL";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const ADD_SUPPORT = "ADD_SUPPORT";
export const ADD_SUPPORT_FAILED = "ADD_SUPPORT_FAILED";
export const ADD_SUPPORT_LOADING = "ADD_SUPPORT_LOADING";
export const GET_SUPPORT = "GET_SUPPORT";
export const GET_SUPPORT_FAILED = "GET_SUPPORT_FAILED";
export const GET_SUPPORT_LOADING = "GET_SUPPORT_LOADING";
export const DELETE_SUPPORT = "DELETE_SUPPORT";
export const DELETE_SUPPORT_FAILED = "DELETE_SUPPORT_FAILED";
export const DELETE_SUPPORT_LOADING = "DELETE_SUPPORT_LOADING";
export const UPDATE_SUPPORT = "UPDATE_SUPPORT";
export const UPDATE_SUPPORT_FAILED = "UPDATE_SUPPORT_FAILED";
export const UPDATE_SUPPORT_LOADING = "UPDATE_SUPPORT_LOADING";
export const ADD_TEACHER = "ADD_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const UPDATE_TEACHER = "UPDATE_TEACHER";
export const GET_TEACHER = "GET_TEACHER";
export const LOADING_TEACHER = "LOADING_TEACHER";
export const GET_USER = "GET_USER";
export const LOADING_USER = "LOADING_USER";
export const ADD_STUDENT_CSV = "ADD_STUDENT_CSV";
export const DELETE_STUDENT_CSV = "DELETE_STUDENT_CSV";
export const UPDATE_STUDENT_CSV = "UPDATE_STUDENT_CSV";
export const GET_STUDENT_CSV = "GET_STUDENT_CSV";
export const GET_STUDENT_CSV_LOADING = "GET_STUDENT_CSV_LOADING";
export const GET_STUDENT_CSV_FAIL = "GET_STUDENT_CSV_FAIL";
export const GET_MOCK_EXAM = "GET_MOCK_EXAM";
export const ADD_MOCK_EXAM = "ADD_MOCK_EXAM";
export const DELETE_MOCK_EXAM = "DELETE_MOCK_EXAM";
export const UPDATE_MOCK_EXAM = "UPDATE_MOCK_EXAM";
export const MOCK_EXAM_LOADING = "MOCK_EXAM_LOADING";
export const MOCK_EXAM_FAIL = "MOCK_EXAM_FAIL";
export const GET_MOCK_EXAM_FILTER = "GET_MOCK_EXAM_FILTER";
export const ADD_LEVEL = "ADD_LEVEL";
export const DELETE_LEVEL = "DELETE_LEVEL";
export const UPDATE_LEVEL = "UPDATE_LEVEL";
export const LEVEL_LOADING = "LEVEL_LOADING";
export const LEVEL_FAIL = "LEVEL_FAIL";
export const GET_LEVEL = "GET_LEVEL";
export const GET_MOCK_RESULT_EXAM = "GET_MOCK_RESULT_EXAM";
export const ADD_MOCK_RESULT_EXAM = "ADD_MOCK_RESULT_EXAM";
export const DELETE_MOCK_RESULT_EXAM = "DELETE_MOCK_RESULT_EXAM";
export const UPDATE_MOCK_RESULT_EXAM = "UPDATE_MOCK_RESULT_EXAM";
export const MOCK_EXAM_RESULT_LOADING = "MOCK_EXAM_RESULT_LOADING";
export const MOCK_EXAM_RESULT_FAIL = "MOCK_EXAM_RESULT_FAIL";
export const GET_MOCK_EXAM_RESULT_FILTER = "GET_MOCK_EXAM_RESULT_FILTER";
export const GET_CSV_MOCK = "GET_CSV_MOCK";
export const ADD_CSV_MOCK = "ADD_CSV_MOCK";
export const SET_CSV_FAIL_MOCK = "SET_CSV_FAIL_MOCK";
export const SET_CSV_LOADING_MOCK = "SET_CSV_LOADING_MOCK";
export const SET_ADD_CSV_FAIL_MOCK = "SET_ADD_CSV_FAIL_MOCK";
export const SET_ADD_CSV_LOADING_MOCK = "SET_ADD_CSV_LOADING_MOCK";
export const CHECK_EXAM_EXIST = "CHECK_EXAM_EXIST";
export const CHECK_EXAM_EXIST_FAIL = "CHECK_EXAM_EXIST_FAIL";
export const CHECK_EXAM_EXIST_LOADING = "CHECK_EXAM_EXIST_LOADING";
export const CHECKED_EXAM_EXIST = "CHECKED_EXAM_EXIST";
export const ADD_RAG_RATING = "ADD_RAG_RATING";
export const GET_RAG_RATING = "GET_RAG_RATING";
export const UPDATE_RAG_RATING = "UPDATE_RAG_RATING";
export const RAG_RATING_LOADING = "RAG_RATING_LOADING";
export const RAG_RATING_FAIL = "RAG_RATING_FAIL";
export const GET_PAPER_EXAM = "GET_PAPER_EXAM";
export const PAPER_EXAM_LOADING = "PAPER_EXAM_LOADING";
export const PAPER_EXAM_FAIL = "PAPER_EXAM_FAIL";
export const GENERATE_TOPIC_PAPER_ADD = "GENERATE_TOPIC_PAPER_ADD";
export const GENERATE_TOPIC_PAPER_FAIL = "GENERATE_TOPIC_PAPER_FAIL";
export const GENERATE_TOPIC_PAPER_LOADING = "GENERATE_TOPIC_PAPER_LOADING";
export const GET_RAG_RATING_LIST = "GET_RAG_RATING_LIST";
export const RAG_RATING_LOADING_NEW = "RAG_RATING_LOADING_NEW";
export const RAG_RATING_FAIL_NEW = "RAG_RATING_FAIL_NEW";
export const ADD_MOCK_EXAM_TEST = "ADD_MOCK_EXAM_TEST";
export const UPDATE_MOCK_EXAM_TEST = "UPDATE_MOCK_EXAM_TEST";
export const SET_CLASS_LOADING_ADD = "SET_CLASS_LOADING_ADD";
export const GET_CLASS_FILTER_SCHOOL = "GET_CLASS_FILTER_SCHOOL";
export const SET_CLASS_SCHOOL_FILTER_LOADING =
  "SET_CLASS_SCHOOL_FILTER_LOADING";
export const SET_CLASS_SCHOOL_FILTER_FAIL = "SET_CLASS_SCHOOL_FILTER_FAIL";
export const SET_UPDATE_FAIL = "SET_UPDATE_FAIL";
export const ADD_MOCK_EXAM_LOADING = "ADD_MOCK_EXAM_LOADING";
export const ADD_MOCK_EXAM_FAIL = "ADD_MOCK_EXAM_FAIL";
export const Marks_Added_CHECKED = "Marks_Added_CHECKED";
export const ADD_DOWNLOADS_ZIP = "ADD_DOWNLOADS_ZIP";
export const DELETE_DOWNLOADS_ZIP = "DELETE_DOWNLOADS_ZIP";
export const UPDATE_DOWNLOADS_ZIP = "UPDATE_DOWNLOADS_ZIP";
export const DOWNLOADS_ZIP_LOADING = "DOWNLOADS_ZIP_LOADING";
export const DOWNLOADS_ZIP_FAIL = "DOWNLOADS_ZIP_FAIL";
export const GET_DOWNLOADS_ZIP = "GET_DOWNLOADS_ZIP";
export const GET_FILE_MEDIA = "GET_FILE_MEDIA";
export const FILE_LOADING = "FILE_LOADING";
export const FILE_FAILED = "FILE_FAILED";
export const PAY_VIA_EMAIL_ERROR = "PAY_VIA_EMAIL_ERROR";
export const PAY_VIA_EMAIL = "PAY_VIA_EMAIL";
export const PAY_LOADING = "PAY_LOADING";
export const GET_PAY_VIA_EMAIL = "GET_PAY_VIA_EMAIL";
export const LOADING_PAY_VIA_EMAIL = "LOADING_PAY_VIA_EMAIL";
export const ERROR_PAY_VIA_EMAIL = "ERROR_PAY_VIA_EMAIL";
export const SUBVIATID = "SUBVIATID";
export const SUBVIATIDFAIL = "SUBVIATIDFAIL";
export const SUBVIATID_LOADING = "SUBVIATID_LOADING";
export const EMAIL_TEACHER = "EMAIL_TEACHER";
export const TEACHER_LOADING = "TEACHER_LOADING";
export const TEACHER_ERROR = "TEACHER_ERROR";
export const UPDATE_EMAIL_TEACHER = "UPDATE_EMAIL_TEACHER";
export const UPDATE_TEACHER_LOADING = "UPDATE_TEACHER_LOADING";
export const UPDATE_TEACHER_ERROR = "UPDATE_TEACHER_ERROR";
export const DELETE_TEACHER_ = "DELETE_TEACHER_";
export const ASSIGN_TEACHER = "ASSIGN_TEACHER";
export const LOAD_DELETE_USER = "LOAD_DELETE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const UPDATE_USER_NEW = "UPDATE_USER_NEW";
export const UPDATE_USER_NEW_LOADING = "UPDATE_USER_NEW_LOADING";
export const UPDATE_USER_NEW_FAIL = "UPDATE_USER_NEW_FAIL";
export const GET_USER_PERMISSION = "GET_USER_PERMISSION";
export const USER_PERMISSION_LOADING = "USER_PERMISSION_LOADING";
export const USER_PERMISSION_LOADING_FAIL = "USER_PERMISSION_LOADING_FAIL";
export const GET_USER_GROUP = "GET_USER_GROUP";
export const USER_GROUP_LOADING = "USER_GROUP_LOADING";
export const USER_GROUP_LOADING_FAIL = "USER_GROUP_LOADING_FAIL";
export const VERIFY_OTP_SCHOOL = "VERIFY_OTP_SCHOOL";
export const VERIFY_OTP_SCHOOL_LOADING = "VERIFY_OTP_SCHOOL_LOADING";
export const VERIFY_OTP_SCHOOL_LOADING_FAIL = "VERIFY_OTP_SCHOOL_LOADING_FAIL";
export const CHECK_VERIFY_OTP_SCHOOL = "CHECK_VERIFY_OTP_SCHOOL";
export const CHECK_VERIFY_OTP_SCHOOL_LOADING =
  "CHECK_VERIFY_OTP_SCHOOL_LOADING";
export const CHECK_OTP_SCHOOL_LOADING_FAIL = "CHECK_OTP_SCHOOL_LOADING_FAIL";
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_LOADING = "RESEND_OTP_LOADING";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";
export const GET_SCHOOL_CREATED = "GET_SCHOOL_CREATED";
export const GET_SCHOOL_CREATED_LOADING = "GET_SCHOOL_CREATED_LOADING";
export const GET_SCHOOL_CREATED_LOADING_FAIL =
  "GET_SCHOOL_CREATED_LOADING_FAIL";
export const GET_SCHOOLMAP_ID = "GET_SCHOOLMAP_ID";
export const GET_SCHOOLMAP_ID_LOADING = "GET_SCHOOLMAP_ID_LOADING";
export const GET_SCHOOLMAP_ID_FAIL = "GET_SCHOOLMAP_ID_FAIL";
export const UPDATE_SCHOOLMAP_ID = "UPDATE_SCHOOLMAP_ID";
export const UPDATE_SCHOOLMAP_ID_LOADING = "UPDATE_SCHOOLMAP_ID_LOADING";
export const UPDATE_SCHOOLMAP_ID_FAIL = "UPDATE_SCHOOLMAP_ID_FAIL";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_LOADING = "VERIFY_USER_LOADING";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_LOADING = "RESEND_EMAIL_LOADING";
export const RESEND_EMAIL_FAIL = "RESEND_EMAIL_FAIL";
export const ADD_LIST_STUDENT = "ADD_LIST_STUDENT";
export const GET_NEW_TEACHER = "GET_NEW_TEACHER";
export const GET_NEW_MOCK_EXAM = "GET_NEW_MOCK_EXAM";
export const NEW_MOCK_EXAM_LOADING = "NEW_MOCK_EXAM_LOADING";
export const NEW_MOCK_EXAM_FAIL = "NEW_MOCK_EXAM_FAIL";
export const GET_CLASS_ENHANCE = "GET_CLASS_ENHANCE";
export const ENHANCE_CLASS_LOADING = "ENHANCE_CLASS_LOADING";
export const ENHANCE_CLASS_FAIL = "ENHANCE_CLASS_FAIL";
export const ADDING_MOCK_RESULTS = "ADDING_MOCK_RESULTS";
export const MOCK_RESULT_LOADING = "MOCK_RESULT_LOADING";
export const MOCK_RESULT_FAILED = "MOCK_RESULT_FAILED";
export const CREATE_RAG_RATING = "CREATE_RAG_RATING";
export const NEW_RAG_RATING_LOADING = "NEW_RAG_RATING_LOADING";
export const RAG_RATING_FAILED = "RAG_RATING_FAILED";
export const DELETE_MOCK_RESULTS = "DELETE_MOCK_RESULTS";
export const DELETE_MOCK_RESULT_LOADING = "DELETE_MOCK_RESULT_LOADING";
export const DELETE_MOCK_RESULT_FAILED = "DELETE_MOCK_RESULT_FAILED";
export const GENERATE_TOPIC_PAPER_NEW = "GENERATE_TOPIC_PAPER_NEW";
export const GENERATE_TOPIC_PAPER_NEW_LOADING =
  "GENERATE_TOPIC_PAPER_NEW_LOADING";
export const GENERATE_TOPIC_PAPER_NEW_FAILED =
  "GENERATE_TOPIC_PAPER_NEW_FAILED";
export const LOADING_TEACHER_ACTIVE = "LOADING_TEACHER_ACTIVE";
export const GET_TEACHER_ACTIVE = "GET_TEACHER_ACTIVE";
export const FAIL_TEACHER_ACTIVE = "FAIL_TEACHER_ACTIVE";
