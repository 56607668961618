import React from "react";

function CsvFileFormat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 24 24"
    >
      <path d="M19.5 24h-15A2.503 2.503 0 012 21.5v-19C2 1.122 3.122 0 4.5 0h15C20.878 0 22 1.122 22 2.5v19c0 1.378-1.122 2.5-2.5 2.5zM4.5 1C3.673 1 3 1.673 3 2.5v19c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z"></path>
      <path d="M8.5 21h-2c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h2a.5.5 0 010 1h-2a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2a.5.5 0 010 1zM12.25 21H10.5a.5.5 0 010-1h1.75a.75.75 0 000-1.5h-.5c-.965 0-1.75-.785-1.75-1.75S10.785 15 11.75 15h1.75a.5.5 0 010 1h-1.75a.75.75 0 000 1.5h.5c.965 0 1.75.785 1.75 1.75S13.215 21 12.25 21zM17 21a.5.5 0 01-.479-.356l-1.5-5a.501.501 0 01.335-.623.502.502 0 01.623.335L17 18.76l1.021-3.403a.5.5 0 11.958.288l-1.5 5A.5.5 0 0117 21z"></path>
    </svg>
  );
}

export default CsvFileFormat;
