import axios from "axios";
import { returnErrors, createMessage } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  GET_FEEDBACK,
  ADD_FEEDBACK,
  DELETE_FEEDBACK,
  UPDATE_FEEDBACK,
} from "../types";

export const getSupport = () => (dispatch, getState) => {
  axios
    .get("Policy/Support/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FEEDBACK,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const deleteSupport = (id) => (dispatch, getState) => {
  axios
    .delete(`Policy/Support/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Ticket Deleted" }));
      dispatch({
        type: DELETE_FEEDBACK,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addSupport = (aboutFyp) => (dispatch, getState) => {
  axios
    .post("Policy/Support/", aboutFyp, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Your Response is Recorded" }));
      dispatch({
        type: ADD_FEEDBACK,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data), err.response.status)
    );
};
export const updateSupport = (id, dataPram) => (dispatch, getState) => {
  axios
    .put(`Policy/Support/${id}`, dataPram, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ LeadUpdate: "Response Update" }));
      dispatch({
        type: UPDATE_FEEDBACK,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data), err.response.status)
    );
};
