import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.name) toast.error(`Name:${error.msg.name.join()}`,{id: error.TID});
      if (error.msg.email) toast.error(`Email:${error.msg.email.join()}`,{id: error.TID});
      if (error.msg.message) toast.error(`${error.msg.message}`,{id: error.TID});
      if (error.msg.non_field_error) toast.error(error.msg.non_field_error,{id: error.TID});
      if (error.msg.Error) toast.error(error.msg.Error.join(),{id: error.TID});
      if (error.msg.username) toast.error(error.msg.username.join(),{id: error.TID});
      if (error.msg.detail) toast.error(error.msg.detail,{id: error.TID});
      // toast.remove(error.TID)
    }
    if (message !== prevProps.message) {
      if (message.addLead)  toast.success(message.addLead, {id: message.TID});
      if (message.deleteLead) toast.error(message.deleteLead, {id: message.TID});
      if (message.LeadUpdate) toast.success(message.LeadUpdate, {id: message.TID});
      if (message.passwordNotMatch) toast.error(message.passwordNotMatch, {id: message.TID});
        
      // if (message.addLead) toast.success(message.addLead);
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.message
});
export default connect(mapStateToProps)((Alerts));
