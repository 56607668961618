import { GET_CSV, SET_CSV_LOADING, SET_CSV_FAIL } from "../actions/types";

const initialState = {
  isLoading: false,
  CsvList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CSV:
      return {
        ...state,
        CsvList: action.payload,
        isLoading: false,
      };

    case SET_CSV_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CSV_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
