import axios from "axios";
import { returnErrors } from "../message/message";
import { GET_TERMS } from "../types";

export const getTerms = () => (dispatch) => {
  axios
    .get("Policy/Terms/")
    .then((res) =>
      dispatch({
        type: GET_TERMS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
