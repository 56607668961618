import React, { Component, Fragment } from "react";
import blank from "../../../assets/media/users/blank.png";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as yup from "yup";
import { UpdateUser } from "../../../actions/auth_action/auth";
import Waiting from "../../functions/Waiting";

const schema = yup.object({
  firstname: yup.string().required("Please Type First Name"),
  lastname: yup.string().required("Please Type lastname Name"),
  phone: yup.string().required("Please Type Phone Number"),
  email: yup.string().email("Invalid Email").required("Email Required"),
});

class Personal_Info extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
  };
  state = {
    pic: undefined,
    firstname: undefined,
    lastname: undefined,
    phone: undefined,
    email: undefined,
    profilePic: undefined,
  };
  onSubmitImg = (e) => {
    if (this.state.profilePic === undefined) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let formData = new FormData();
      formData.append("pic", this.state.profilePic);
      this.props.addDocuments(formData);
    }
  };
  handleFilesChosen = (e) => {
    this.setState({ profilePic: e.target.files[0] });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onsubmit = (values) => {
    let formData = new FormData();
    formData.append("firstname", values.firstname);
    formData.append("lastname", values.lastname);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("pic", this.state.profilePic);
    const id = this.props.auth.user.user.id;
    this.props.UpdateUser(formData, id);
  };

  render() {
    const { auth } = this.props;
    // const{profilePic}=this.state
    if (auth.isLoadingChange) {
      return (
        <Fragment>
          <div className="container " style={{ marginTop: 180 }}>
            <div className="row">
              <div className="col-12 text-center">
                <div className="mb-5 shadow-soft bg-white rounded-sm">
                  <div className="">
                    <Waiting message="Please Wait While Details are Updated!" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <div className="flex-row-fluid ml-lg-8">
        {/*begin::Card*/}
        <div className="card card-custom card-stretch">
          {/*begin::Header*/}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Personal Information
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Update your personal information
              </span>
            </div>
          </div>

          <Formik
            validationSchema={schema}
            onSubmit={this.onsubmit}
            initialValues={{
              firstname: auth.user.user.firstname,
              lastname: auth.user.user.lastname,
              phone: auth.user.user.phone,
              email: auth.user.user.email,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="row">
                    <label className="col-xl-3" />
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mb-6">User Info</h5>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-6 col-form-label">
                      Picture
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div
                        className="image-input image-input-outline"
                        id="kt_profile_avatar"
                        style={{
                          backgroundImage: `url(${blank})`,
                        }}
                      >
                        <div
                          className="image-input-wrapper"
                          style={{
                            backgroundImage: `url(${auth.user.user.pic})`,
                          }}
                        />
                        <label
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title
                          data-original-title="Change avatar"
                        >
                          <i className="fa fa-pen icon-sm text-muted" />
                          <Form.Control
                            type="file"
                            onChange={this.handleFilesChosen}
                            required
                            name="profile_avatar"
                            accept=".png, .jpg, .jpeg"
                          />
                          <Form.Control
                            type="hidden"
                            name="profile_avatar_remove"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Fill me
                          </Form.Control.Feedback>
                        </label>
                        <span
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted" />
                        </span>
                        <span
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title="Remove avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted" />
                        </span>
                      </div>
                      <span className="form-text text-muted">
                        Allowed file types: png, jpg, jpeg.
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      First Name
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <Form.Control
                        name="firstname"
                        className="form-control form-control-lg form-control-solid"
                        value={values.firstname}
                        isInvalid={!!errors.firstname}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        <span>{errors.firstname}</span>
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Last Name
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <Form.Control
                        name="lastname"
                        onChange={handleChange}
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={values.lastname}
                        isInvalid={!!errors.lastname}
                      />
                      <Form.Control.Feedback type="invalid">
                        <span>{errors.lastname}</span>
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-xl-3" />
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mt-10 mb-6">
                        Contact Info
                      </h5>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Contact Phone
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-phone" />
                          </span>
                        </div>
                        <Form.Control
                          onChange={handleChange}
                          type="text"
                          name="phone"
                          className="form-control form-control-lg form-control-solid"
                          value={values.phone}
                          isInvalid={!!errors.phone}
                          placeholder="Phone"
                        />
                        <Form.Control.Feedback
                          className="feedback-color"
                          type="invalid"
                        >
                          <span>{errors.phone}</span>
                        </Form.Control.Feedback>
                      </div>
                      <span className="form-text text-muted">
                        We'll never share your phone number with anyone else.
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Email Address
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-at" />
                          </span>
                        </div>
                        <Form.Control
                          onChange={handleChange}
                          type="email"
                          name="email"
                          className="form-control form-control-lg form-control-solid"
                          value={values.email}
                          disabled={true}
                          isInvalid={!!errors.email}
                          placeholder="Type Email"
                        />
                        <Form.Control.Feedback
                          className="feedback-color"
                          type="invalid"
                        >
                          <span>{errors.email}</span>
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-xl-3" />
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mt-10 mb-6">
                        School Info
                      </h5>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      School Name
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <Form.Control
                        name="School"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={auth.user.user.School.name}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      School Website
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group input-group-lg input-group-solid">
                        <Form.Control
                          disabled
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="School URL"
                          defaultValue={auth.user.user.School.url}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">.com</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="form-text text-muted text-center">
                    In order to update School information please Contact &nbsp;
                    <a href={"mailto:abdullahrafi@outlook.com"}>
                      <b>Support</b>
                    </a>
                  </span>
                </div>
                <div className="row">
                  <div className="col-12 text-center mb-5">
                    <div className="card-toolbar">
                      <button type="submit" className="btn btn-success mr-2">
                        Save Changes
                      </button>
                      <button type="reset" className="btn btn-secondary">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                {/*end::Body*/}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { UpdateUser })(Personal_Info);
