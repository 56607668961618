import React from 'react'
import './StyleClass.css'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import CheckoutForm from './components/CheckoutForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Base = (props) => (
<Elements stripe={stripePromise}>  
    <CheckoutForm data={props} />
  </Elements>
)

export default Base
