import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth_action/auth";
import { Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { banList } from "../../Banlist";
import { Link, Redirect } from "react-router-dom";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import "../../style/KeenTheme/css/pages/login/login-3.css";
import Waiting from "../functions/Waiting";

const schema = yup.object({
  email: yup.string().email("Invalid Email").required("Email Required"),
  password: yup.string().required("Password required"),
});

class Login extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
  };
  state = {
    email: undefined,
    password: undefined,
    validate: false,
    show: true,
    password_show: "password",
    Password_Class: "fa-eye",
  };
  showPassword = (e) => {
    if (this.state.password_show === "password") {
      this.setState({
        password_show: "text",
        Password_Class: "fa-eye fa-eye-slash",
      });
    } else {
      this.setState({ password_show: "password", Password_Class: "fa-eye" });
    }
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onSubmit = (value) => {
    this.props.login(value.email, value.password);
    this.setState({ email: undefined, password: undefined });
  };
  validateEmail = (value) => {
    if (value !== undefined) {
      let error;
      for (const x in banList) {
        if (value.toLowerCase().includes(banList[x])) {
          error = "Domain not allowed";
        }
      }
      if (!value) {
        error = "Email Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
      }
      return error;
    }
  };

  render() {
    const { show, password_show, Password_Class } = this.state;
    const { auth } = this.props;

    if (auth.isLoading === true && auth.isAuthenticated !== null) {
      return <Waiting message="Logging into the system" />;
    }
    if (auth.token !== null && auth.MembershipExpired === false) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    } else if (auth.token !== null && auth.MembershipExpired === true) {
      return (
        <Redirect
          to={{
            pathname: "/Subscription",
          }}
        />
      );
    } else {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={this.onSubmit}
          initialValues={{}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Fragment>
              <NavbarKeen logo={true} navbar={true} />
              <div
                className="mt-4 grow flex items-center justify-around d-flex flex-column flex-root"
                style={{ marginTop: 50, height: "134%" }}
              >
                <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
                  <div className="login-content flex-row-fluid d-flex flex-column p-10">
                    <div className="d-flex flex-row-fluid flex-center2">
                      <div className="login-form">
                        <div className="col-12 text-center">
                          {/*{console.log(this.props.location.message)}*/}
                          {/*{this.props.location.message !== undefined && show ? (*/}
                          {/*  <Alert*/}
                          {/*    style={{ marginTop: "50px" }}*/}
                          {/*    variant="danger"*/}
                          {/*    onClose={() => this.setState({ show: false })}*/}
                          {/*    dismissible*/}
                          {/*  >*/}
                          {/*    {this.props.location.message} &nbsp;*/}
                          {/*  </Alert>*/}
                          {/*) : (*/}
                          {/*  ""*/}
                          {/*)}*/}
                        </div>
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="pb-5 pb-lg-15">
                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                              Sign In
                            </h3>
                            <div className="text-muted font-weight-bold font-size-h4">
                              New Here?
                              <Link
                                to={"/Signup/"}
                                className="text-primary font-weight-bolder"
                              >
                                {" "}
                                &nbsp; Create Account
                              </Link>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="font-size-h6 font-weight-bolder text-dark">
                              Your Email
                            </label>
                            <Field
                              id="emailform"
                              className="form-control h-auto py-7 px-6 rounded-lg border-0"
                              name="email"
                              validate={this.validateEmail}
                            />
                            {errors.email && touched.email && (
                              <div style={{ color: "#dc3545" }}>
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="d-flex justify-content-between mt-n5">
                              <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                                Your Password
                              </label>
                            </div>

                            <Form.Control
                              className="form-control h-auto py-7 px-6 rounded-lg border-0"
                              required
                              type={password_show}
                              placeholder="Password"
                              name="password"
                              value={values.region}
                              onChange={handleChange}
                              isInvalid={!!errors.password}
                            />
                            <span
                              onClick={(e) => this.showPassword(e)}
                              className={`fa fa-fw ${Password_Class}  field-icon toggle-password `}
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          </div>

                          <div className="d-flex justify-content-between mt-n5">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5"></label>
                            <a
                              href={
                                process.env.REACT_APP_BASE_URL +
                                "accounts/reset_password"
                              }
                              className="text-primary font-size-h6 font-weight-bolder pt-5"
                            >
                              Forgot Password ?
                            </a>
                          </div>
                          <div className="pb-lg-0 pb-5">
                            <button
                              type="submit"
                              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                            >
                              Sign In
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Formik>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});
export default connect(mapStateToProps, { login })(Login);
