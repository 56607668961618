import {
  ADD_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  UPDATE_STUDENT,
  SET_STUDENT_LOADING,
  SET_STUDENT_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  studentList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT:
      return {
        ...state,
        studentList: action.payload,
        isLoading: false,
      };
    case DELETE_STUDENT:
      return {
        ...state,
        studentList: state.studentList.filter(
          (about) => about.id !== action.payload
        ),
      };
    case SET_STUDENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_STUDENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_STUDENT:
      return {
        ...state,
        studentList: state.studentList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_STUDENT:
      return {
        ...state,
        studentList: [...state.studentList, action.payload],
      };
    default:
      return state;
  }
}
