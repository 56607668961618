import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Footer } from "./Footer";
import MobileHeader from "./KeenTheme/MobileHeader";
import UserPanel from "./KeenTheme/UserPanel";
import QuickCart from "./KeenTheme/QuickCart";
import QuickPanel from "./KeenTheme/QuickPanel";
import ChatPanel from "./KeenTheme/ChatPanel";
import Scrolltop from "./KeenTheme/Scrolltop";
import StickyToolbar from "./KeenTheme/StickyToolbar";
import DemoPanel from "./KeenTheme/DemoPanel";
import "../index.css";
import Waiting from "./functions/Waiting";

const PrivateRoute = ({ component: Component, auth, user, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          auth.MembershipExpired === true &&
          (auth.MembershipExpired === null ||
            auth.MembershipExpired === true) &&
          auth.isAuthenticated
        ) {
          return (
            <Redirect
              to={{
                pathname: "/Subscription",
                message: "Please Login to Continue",
              }}
            />
          );
        } else if (auth.isAuthenticated && auth.MembershipExpired === false) {
          return (
            //
            <div
              id="workingSideBar"
              className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable aside-minimize"
            >
              <MobileHeader />
              <Component {...props} />
              <UserPanel />
              <QuickCart />
              <QuickPanel />
              <ChatPanel />
              <Scrolltop />
              <StickyToolbar />
              <DemoPanel />
              <Footer />
            </div>
          );
        } else if (auth.isLoading === true) {
          return <Waiting message="Please Wait While content is loading!" />;
        } else if (auth.token === null) {
          return (
            <Redirect
              to={{
                pathname: "/Login",
                message: "Please Login to Continue",
              }}
            />
          );
        }
      }}
    />
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.token,
});
export default connect(mapStateToProps)(PrivateRoute);
