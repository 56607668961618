import {
  CHECK_EXAM_EXIST,
  CHECK_EXAM_EXIST_FAIL,
  CHECK_EXAM_EXIST_LOADING,
  CHECKED_EXAM_EXIST,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isFailed: false,
  Requested: false,
  DataFound: false,
  CheckExamExist: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_EXAM_EXIST:
      return {
        ...state,
        CheckExamExist: action.payload,
        isLoading: false,
        Requested: true,
        isFailed: false,
      };
    case CHECK_EXAM_EXIST_LOADING:
      return {
        ...state,
        isLoading: true,
        Requested: true,
      };
    case CHECK_EXAM_EXIST_FAIL:
      return {
        ...state,
        isFailed: true,
        isLoading: false,

        DataFound: true,
        CheckExamExist: [],
      };

    case CHECKED_EXAM_EXIST:
      return {
        ...state,
        isFailed: false,
        Requested: false,
      };
    default:
      return state;
  }
}
