import {
  ADD_LEVEL,
  DELETE_LEVEL,
  UPDATE_LEVEL,
  LEVEL_LOADING,
  LEVEL_FAIL,
  GET_LEVEL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  LevelList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEVEL:
      return {
        ...state,
        LevelList: action.payload,
        isLoading: false,
      };
    case LEVEL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_LEVEL:
      return {
        ...state,
        LevelList: state.LevelList.filter(
          (about) => about.id !== action.payload
        ),
      };
    case LEVEL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_LEVEL:
      return {
        ...state,
        LevelList: state.LevelList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_LEVEL:
      return {
        ...state,
        LevelList: [...state.LevelList, action.payload],
      };
    default:
      return state;
  }
}
