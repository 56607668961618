import {
  CHECK_VERIFY_OTP_SCHOOL,
  CHECK_VERIFY_OTP_SCHOOL_LOADING,
  CHECK_OTP_SCHOOL_LOADING_FAIL,
} from "../actions/types";

const initialState = {
  otpStatus: [],
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_VERIFY_OTP_SCHOOL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CHECK_VERIFY_OTP_SCHOOL:
      return {
        ...state,
        loading: false,
        otpStatus: action.payload,
      };
    case CHECK_OTP_SCHOOL_LOADING_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
