import axios from "axios";
import { returnErrors } from "../message/message";
import {
  GET_EXAM_GENERATOR,
  GET_EXAM_GENERATOR_LOADING,
  GET_EXAM_GENERATOR_FAIL,
} from "../types";
import { tokenConfig } from "../auth_action/auth";
// import fileDownload from "js-file-download";

export const PaperGenerator =
  (SchoolID, StudentID, ExamID, Percentage) => (dispatch, getState) => {
    axios
      .get(
        `QLA/QuestionPaperGenerator/${SchoolID}/${StudentID}/${ExamID}/${Percentage}/`,
        tokenConfig(getState)
      )
      .then((res) => {
        const url_local = res.data["FileUrl"];
        const splittedURL = url_local.split(process.env.REACT_APP_LIVE_EXTENSTION);
        const url_final = splittedURL[1];
        const filename = url_final.split("/")[2];
        axios({
          url: url_final,
          method: "GET",
          responseType: "blob", // important
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            dispatch({ type: GET_EXAM_GENERATOR_FAIL });
            dispatch(returnErrors(err.response.data, err.response.status));
          });
        dispatch({
          type: GET_EXAM_GENERATOR,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_EXAM_GENERATOR_FAIL });
        dispatch(returnErrors(err.response.data, err.response.status));
      });

    dispatch({ type: GET_EXAM_GENERATOR_LOADING });
  };
