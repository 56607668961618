import axios from "axios";
import { returnErrors } from "../message/message";
import {
    GET_PAY_VIA_EMAIL,
    LOADING_PAY_VIA_EMAIL,
    ERROR_PAY_VIA_EMAIL
} from "../types";
import { tokenConfig } from "../auth_action/auth";


export const getPayViaEmailAction = (id) => (dispatch, getState) => {
    dispatch({ type: LOADING_PAY_VIA_EMAIL });
    axios
      .get(`payments/PayContent/${id}/`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PAY_VIA_EMAIL,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: ERROR_PAY_VIA_EMAIL });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };