import axios from "axios";
import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";

import {
  USER_PERMISSION_LOADING_FAIL,
  USER_PERMISSION_LOADING,
  GET_USER_PERMISSION,
} from "../types";

export const getUserPermission = () => (dispatch, getState) => {
  dispatch({ type: USER_PERMISSION_LOADING });
  axios
    .get("accounts/permissiondata/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_PERMISSION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_PERMISSION_LOADING_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
