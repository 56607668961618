import React from "react";
import { InfinitySpin  } from  'react-loader-spinner'

export default function Waiting(props) {
  return (
    <div className="center-content">
      <InfinitySpin color="grey" />
      {/*<SunspotLoader*/}
      {/*  gradientColors={["#6366F1", "#E0E7FF"]}*/}
      {/*  shadowColor={"#3730A3"}*/}
      {/*  desktopSize={"128px"}*/}
      {/*  mobileSize={"100px"}*/}
      {/*/>*/}

      <div style={{ marginTop: 40 }}>{props.message}</div>
    </div>
  );
}
