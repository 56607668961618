import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADD_LEVEL,
  DELETE_LEVEL,
  UPDATE_LEVEL,
  LEVEL_LOADING,
  LEVEL_FAIL,
  GET_LEVEL,
} from "../types";

export const getLevel = () => (dispatch, getState) => {
  dispatch({ type: LEVEL_LOADING });
  axios
    .get("QLA/Level/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LEVEL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: LEVEL_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteLevel = (id) => (dispatch, getState) => {
  axios
    .delete(`QLA/Level/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Level Delete" }));
      dispatch({
        type: DELETE_LEVEL,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addLevel = (data) => (dispatch, getState) => {
  axios
    .post("QLA/Level/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Level Added" }));
      dispatch({
        type: ADD_LEVEL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const updateLevel = (id, data) => (dispatch, getState) => {
  axios
    .put(`QLA/Level/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Level Updated!" }));
      dispatch({
        type: UPDATE_LEVEL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
