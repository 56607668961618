import React from "react";

export default function DobCal(props) {
  if (props.data === undefined) {
    return <>0 Years</>;
  }
  else if (props.data === null) {
    return <>0 Years</>;
  }
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const today_str = year + "-" + month + "-" + day;
  const today = new Date(today_str);
  const data_date = new Date(props.data);
  const differ = Math.abs(today - data_date);
  const diffDays = Math.round(differ / (1000 * 60 * 60 * 24) / 356);
  return <>{diffDays}&nbsp;Years</>;
}
