import React from "react";

export default function Status(props) {
  if (props.data === "Green") {
    return (
      <span className="label label-inline label-light-success font-weight-bold">
        {props.data}
      </span>
    );
  } else if (props.data === "Amber") {
    return (
      <span className="label label-inline label-light-warning font-weight-bold">
        {props.data}
      </span>
    );
  } else if (props.data === "Red") {
    return (
      <span className="label label-inline label-light-danger font-weight-bold">
        {props.data}
      </span>
    );
  } else {
    return (
      <span className="label label-inline label-light-dark font-weight-bold">
        No status
      </span>
    );
  }
}
