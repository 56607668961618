import React, { Component } from "react";
import {
  getUserBySchool,
  UpdateUserStatus,
} from "../../actions/User/UserAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WordFinder from "./WordFinder";
import { deleteUser } from "../../actions/User/UserAction";
import { Modal, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getUserPermission } from "../../actions/userPermissions/userPermissionAction";
import { getGroupAction } from "../../actions/UserGroup/userGroupAction";
import { Formik } from "formik";
import * as yup from "yup";
const animatedComponents = makeAnimated();
const TypeofUser = [
  { value: "Super User", label: "Super User" },
  { value: "Account Admin", label: "Account Admin" },
  { value: "Head of Subject", label: "Head of Subject" },
  { value: "Subject Teacher", label: "Subject Teacher" },
  { value: "Head Teacher", label: "Head Teacher" },
  { value: "Print Manager", label: "Print Manager" },
  { value: "TBD", label: "TBD" },
];

const schema = yup.object({
  Country: yup.string().required("Enter Country"),
  School: yup.string().required("Enter School"),
});
class UserPermissionPage extends Component {
  static propTypes = {
    getUserBySchool: PropTypes.func,
    UsersList: PropTypes.array,
    UserLoading: PropTypes.bool,
    deleteUser: PropTypes.func,
    UpdateUserStatus: PropTypes.func,
    getUserPermission: PropTypes.func,
    UserPermissions: PropTypes.array,
    UserPermissionsLoading: PropTypes.bool,
    getGroupAction: PropTypes.func,
    UserGroup: PropTypes.array,
    UserGroupLoading: PropTypes.bool,
  };
  state = {
    show: false,
    userid: undefined,
    showEdit: false,
    currentuser: undefined,
    Permissiondata: [],
    groupdata: [],
  };
  deleteUserfun = (e) => {
    e.preventDefault();
    this.props.deleteUser(this.state.userid);
    this.setState({ show: false, showEdit: false, userid: undefined });
  };
  deleteUserfun1 = (e, id) => {
    e.preventDefault();
    this.props.deleteUser(id);
    this.setState({ showEdit: false, userid: undefined });
  };

  MarkActive = (e, id) => {
    let is_active = undefined;
    if (e.target.checked) {
      is_active = true;
    } else {
      is_active = false;
    }
    const data = { is_active: is_active };
    this.props.UpdateUserStatus(id, data);
  };
  MarkStaff = (e, id) => {
    let is_staff = undefined;
    if (e.target.checked) {
      is_staff = true;
    } else {
      is_staff = false;
    }
    const data = { is_staff: is_staff };
    this.props.UpdateUserStatus(id, data);
  };
  EditModel = (user) => {
    this.setState({ showEdit: !this.state.showEdit, currentuser: user });
    const filterdata = this.props.UserPermissions.filter((o1) =>
      user.user_permissions.some((o2) => o1.id === o2.id)
    );
    const Permissiondata = filterdata.map((arr) => {
      return { value: arr.id, label: arr.name };
    });
    const filterdatagroup = this.props.UserGroup.filter((o1) =>
      user.groups.some((o2) => o1.id === o2.id)
    );
    const groupdata = filterdatagroup.map((arr) => {
      return { value: arr.id, label: arr.name };
    });
    this.setState({ Permissiondata: Permissiondata, groupdata: groupdata });
  };
  UpdateUser = (data) => {
    let type_of_user = "";
    if (typeof data.type_of_user == "string") {
      type_of_user = data.type_of_user;
    } else {
      type_of_user = data.type_of_user.map((arr) => arr.value)[0];
    }
    const groups = data.groups.map((arr) => arr.value);
    const user_permissions = data.user_permissions.map((arr) => arr.value);
    const {
      is_active,
      is_staff,
      username,
      phone,
      userid,
      firstname,
      lastname,
    } = data;
    const data_new = {
      is_active,
      is_staff,
      username,
      phone,
      groups,
      user_permissions,
      type_of_user,
      firstname,
      lastname,
    };
    this.props.UpdateUserStatus(userid, data_new);
    this.setState({ showEdit: !this.state.showEdit, currentuser: undefined });
  };
  componentDidMount() {
    this.props.getUserBySchool(localStorage.getItem("Schoolid"));
    this.props.getUserPermission();
    this.props.getGroupAction();
  }
  render() {
    const { UsersList, UserPermissions, UserGroup } = this.props;
    const { show, showEdit, currentuser, Permissiondata, groupdata } =
      this.state;
    return (
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              User Permissions
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              More than {UsersList.length} Teachers
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th style={{ minWidth: "50px" }}>ID#</th>
                    <th style={{ minWidth: "180px" }} className="pl-7">
                      <span className="text-dark-50">User</span>
                    </th>
                    <th style={{ minWidth: "80px" }}>Email</th>
                    <th style={{ minWidth: "50px" }}>Permission level</th>
                    <th style={{ minWidth: "50px" }}>Phone</th>
                    <th style={{ minWidth: "30px" }}>Active</th>
                    <th style={{ minWidth: "30px" }}>Staff</th>
                    <th style={{ minWidth: "30px" }}>User Permission</th>
                    <th style={{ minWidth: "30px" }}>Group</th>
                    <th className="text-center" style={{ minWidth: "200px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {UsersList.sort((a, b) => a.id - b.id).map((user, key) => (
                    <tr key={key}>
                      <td>
                        <span className="text-muted font-weight-bold">
                          {user.id}
                        </span>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="symbol-label">
                              {user.pic === null ? (
                                <WordFinder data={user.firstname} />
                              ) : (
                                <img
                                  src={user.pic}
                                  className="h-75 align-self-end"
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          <div>
                            <p className="text-dark-75 font-weight-bolder mb-1 font-size-lg text-capitalize">
                              {user.firstname} {user.lastname}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted font-weight-bold">
                          {user.email}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {user.type_of_user}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted font-weight-bold d-block font-size-sm">
                          {user.phone ? user.phone : "Not Provided"}
                        </span>
                      </td>

                      <td>
                        <span className="text-muted font-weight-bold d-block font-size-sm">
                          {user.is_active ? (
                            <i className="text-success fa far fa-check-circle icon-lg" />
                          ) : (
                            <i className="text-danger fa far fa-times-circle icon-lg" />
                          )}
                        </span>
                      </td>

                      <td>
                        <span className="text-muted font-weight-bold d-block font-size-sm">
                          {user.is_staff ? (
                            <i className="text-success fa far fa-check-circle icon-lg" />
                          ) : (
                            <i className="text-danger fa far fa-times-circle icon-lg" />
                          )}
                        </span>
                      </td>
                      <td>
                        {user.user_permissions.length !== 0 ? (
                          user.user_permissions.map((permission, key) => (
                            <span
                              key={key}
                              className="text-muted font-weight-bold d-block font-size-sm"
                            >
                              {permission.name + " "}
                            </span>
                          ))
                        ) : (
                          <span className="text-muted font-weight-bold d-block font-size-sm">
                            No permission
                          </span>
                        )}
                      </td>
                      <td>
                        <td>
                          {user.groups.length !== 0 ? (
                            user.groups.map((group, key) => (
                              <span
                                key={key}
                                className="text-muted font-weight-bold d-block font-size-sm"
                              >
                                {group.name + " "}
                              </span>
                            ))
                          ) : (
                            <span className="text-muted font-weight-bold d-block font-size-sm">
                              No Group
                            </span>
                          )}
                        </td>
                      </td>
                      <td className="text-right pr-0">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Mark {user.firstname}{" "}
                              {user.is_active ? "Inactive" : "Active"}
                            </Tooltip>
                          }
                          placement="bottom"
                        >
                          <div className="btn btn-icon btn-sm mx-3">
                            <span className="switch switch-sm switch-icon ">
                              <label>
                                <input
                                  onChange={(e) => this.MarkActive(e, user.id)}
                                  type="checkbox"
                                  checked={user.is_active}
                                  name="select"
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Mark {user.firstname}{" "}
                              {user.is_staff ? "Not Staff" : "Staff"}
                            </Tooltip>
                          }
                          placement="bottom"
                        >
                          <div className="btn btn-icon btn-sm">
                            <span className="switch switch-sm switch-icon ">
                              <label>
                                <input
                                  onChange={(e) => this.MarkStaff(e, user.id)}
                                  type="checkbox"
                                  checked={user.is_staff}
                                  name="select"
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Edit {user.firstname}
                            </Tooltip>
                          }
                          placement="bottom"
                        >
                          <button
                            onClick={() => this.EditModel(user)}
                            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x={0} y={0} width={24} height={24} />
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                  />
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  />
                                </g>
                              </svg>
                            </span>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Delete {user.firstname}
                            </Tooltip>
                          }
                          placement="bottom"
                        >
                          <button
                            onClick={() =>
                              this.setState({ show: !show, userid: user.id })
                            }
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x={0} y={0} width={24} height={24} />
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  />
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  />
                                </g>
                              </svg>
                            </span>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={() => this.setState({ show: !show })}
          aria-labelledby="contained-modal-title-vcenter"
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <p>Are you sure to delete user? This action is irreversible</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light-warning font-weight-bolder font-size-sm mx-3"
              onClick={() => this.setState({ show: !this.state.show })}
            >
              Close
            </button>
            <button
              onClick={(e) => this.deleteUserfun(e)}
              className="btn btn-light-danger font-weight-bolder font-size-sm mx-3"
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showEdit}
          onHide={() => this.setState({ showEdit: !showEdit })}
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit user Permission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentuser !== undefined ? (
              <div className="card-body">
                <Formik
                  // validationSchema={schema}
                  onSubmit={(values) => this.UpdateUser(values)}
                  initialValues={{
                    user_permissions: Permissiondata,
                    groups: groupdata,
                    type_of_user: TypeofUser.filter(
                      (arr) => arr.value === currentuser.type_of_user
                    ).map((arr) => arr),
                    userid: currentuser.id,
                    firstname: currentuser.firstname,
                    lastname: currentuser.lastname,
                    username: currentuser.username,
                    phone: currentuser.phone,
                    is_active: currentuser.is_active,
                    is_staff: currentuser.is_staff,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form className="form" noValidate onSubmit={handleSubmit}>
                      <div className="tab-content">
                        <div
                          className="tab-pane show px-7 active"
                          id="kt_user_edit_tab_2"
                        >
                          <div className="row">
                            <div className="col-xl-2" />
                            <div className="col-xl-7">
                              <div className="my-2">
                                <div className="row">
                                  <label className="col-form-label col-3 text-lg-right text-left" />
                                  <div className="col-9">
                                    <h6 className="text-dark font-weight-bold mb-10">
                                      Details:
                                    </h6>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label col-3 text-lg-right text-left">
                                    Email Address
                                  </label>
                                  <div className="col-9">
                                    <div className="input-group input-group-lg input-group-solid">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="la la-at" />
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        disabled={true}
                                        className="form-control form-control-lg form-control-solid"
                                        defaultValue={currentuser.email}
                                        placeholder="Email"
                                      />
                                    </div>
                                    <span className="form-text text-muted">
                                      Email can't be changed
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-form-label col-3 text-lg-right text-left">
                                    Firstname
                                  </label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="firstname"
                                      className="form-control form-control-lg form-control-solid"
                                      type="text"
                                      defaultValue={currentuser.firstname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.firstname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.firstname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-form-label col-3 text-lg-right text-left">
                                    Lastname
                                  </label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="lastname"
                                      className="form-control form-control-lg form-control-solid"
                                      type="text"
                                      defaultValue={currentuser.lastname}
                                      onChange={handleChange}
                                      isInvalid={!!errors.lastname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.lastname}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-form-label col-3 text-lg-right text-left">
                                    Username
                                  </label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="username"
                                      className="form-control form-control-lg form-control-solid"
                                      type="text"
                                      defaultValue={currentuser.username}
                                      onChange={handleChange}
                                      isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.username}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label col-3 text-lg-right text-left">
                                    Phone
                                  </label>
                                  <div className="col-9">
                                    <Form.Control
                                      name="phone"
                                      className="form-control form-control-lg form-control-solid"
                                      type="tel"
                                      defaultValue={currentuser.phone}
                                      onChange={handleChange}
                                      isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.phone}
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-10" />
                          <div className="row">
                            <div className="col-xl-2" />
                            <div className="col-xl-7">
                              <div className="row">
                                <label className="col-form-label col-3 text-lg-right text-left" />
                                <div className="col-9">
                                  <h6 className="text-dark font-weight-bold mb-10">
                                    Permissions:
                                  </h6>
                                </div>
                              </div>
                              <div className="form-group row align-items-center">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Permission
                                </label>
                                <div className="col-9">
                                  <div className="checkbox-inline">
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        onChange={handleChange}
                                        name="is_active"
                                        defaultChecked={currentuser.is_active}
                                      />
                                      <span />
                                      Active
                                    </label>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        onChange={handleChange}
                                        name="is_staff"
                                        defaultChecked={currentuser.is_staff}
                                      />
                                      <span />
                                      Staff
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  User Permissions
                                </label>
                                <div className="col-9">
                                  <Select
                                    name="user_permissions"
                                    components={animatedComponents}
                                    isMulti
                                    defaultValue={Permissiondata}
                                    isDisabled={false}
                                    isLoading={false}
                                    options={UserPermissions.map((arr) => ({
                                      value: arr.id,
                                      label: arr.name,
                                    }))}
                                    className="basic-multi-select"
                                    onChange={(selectedOption) => {
                                      let event = {
                                        target: {
                                          name: "user_permissions",
                                          value: selectedOption,
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Groups
                                </label>
                                <div className="col-9">
                                  <Select
                                    name="groups"
                                    components={animatedComponents}
                                    isMulti
                                    defaultValue={groupdata}
                                    isDisabled={false}
                                    isLoading={false}
                                    options={UserGroup.map((arr) => ({
                                      value: arr.id,
                                      label: arr.name,
                                    }))}
                                    className="basic-multi-select"
                                    onChange={(selectedOption) => {
                                      let event = {
                                        target: {
                                          name: "groups",
                                          value: selectedOption,
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Type of User
                                </label>
                                <div className="col-9">
                                  <Select
                                    name="type_of_user"
                                    components={animatedComponents}
                                    defaultValue={TypeofUser.filter(
                                      (arr) =>
                                        arr.value === currentuser.type_of_user
                                    ).map((arr) => arr)}
                                    isDisabled={false}
                                    isLoading={false}
                                    options={TypeofUser}
                                    className="basic-multi-select"
                                    onChange={(e, event) => {
                                      handleChange({
                                        ...event,
                                        target: {
                                          name: "type_of_user",
                                          value: e.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group row mt-10">
                                <label className="col-3" />
                                <div className="col-9">
                                  <button
                                    onClick={(e) =>
                                      this.deleteUserfun1(e, currentuser.id)
                                    }
                                    type="button"
                                    className="btn btn-light-danger font-weight-bold btn-sm"
                                  >
                                    Delete this user ?
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2" />
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div className="col-6">
                          <button
                            className="btn btn-light-warning font-weight-bolder font-size-sm mx-3"
                            onClick={() =>
                              this.setState({ showEdit: !showEdit })
                            }
                          >
                            Close
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="submit"
                            className="btn btn-light-success font-weight-bolder font-size-sm mx-3"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UsersList: state.UsersList.UsersList,
  UserLoading: state.UsersList.isLoading,
  UserPermissions: state.UserPermissions.UserPermissions,
  UserPermissionsLoading: state.UserPermissions.isLoading,
  UserGroup: state.UserGroup.UserGroup,
  UserGroupLoading: state.UserGroup.isLoading,
});

export default connect(mapStateToProps, {
  getUserBySchool,
  deleteUser,
  UpdateUserStatus,
  getUserPermission,
  getGroupAction,
})(UserPermissionPage);
