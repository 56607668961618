import {
  GET_CSV_MOCK,
  SET_CSV_LOADING_MOCK,
  SET_CSV_FAIL_MOCK,
  SET_ADD_CSV_LOADING_MOCK,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isLoadingAdd: false,
  CsvListMockUp: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ADD_CSV_LOADING_MOCK:
      return {
        ...state,
        CsvList: action.payload,
        isLoadingAdd: true,
      };
    case GET_CSV_MOCK:
      return {
        ...state,
        CsvList: action.payload,
        isLoading: false,
      };

    case SET_CSV_LOADING_MOCK:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CSV_FAIL_MOCK:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
