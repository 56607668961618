import Waiting from "./Waiting";
import React from "react";

export default function LoadingMarks() {
  return (
    <div className="mt-3">
      <Waiting message="Please wait while Students are added.." />
    </div>
  );
}
