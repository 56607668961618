import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import { getDownloadUnique } from "../../actions/DownloadsAPI/DownloadsAction";
import Moment from "react-moment";

class DocumentPageUID extends Component {
  static propTypes = {
    DownloadZip: PropTypes.array.isRequired,
    DownloadZipFail: PropTypes.bool.isRequired,
    DownloadZipLoading: PropTypes.bool.isRequired,
  };

  state = {};

  getTopicPaper = () => {
    this.props.getDownloadUnique(this.props.UID);
  };

  componentDidMount() {
    this.getTopicPaper();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.DownloadZip !== this.props.DownloadZip) {
      this.setState({ DownloadZipFile: this.props.DownloadZip });
    }
  }

  render() {
    const { DownloadZipFile } = this.state;
    const { DownloadZipFail, DownloadZipLoading } = this.props;

    const empty = (
      <table className="table table-head-custom table-vertical-center">
        <thead>
          <tr className="text-left">
            <th className="pl-0" style={{ width: "100%" }}>
              #
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {(() => {
              if (DownloadZipLoading) {
                return (
                  <td className="pl-0 text-center">
                    <Spinner animation="border" variant="success" /> &nbsp;
                    Fetching Archive Folder data
                  </td>
                );
              } else if (DownloadZipFail && DownloadZipFile === undefined) {
                return (
                  <td className="pl-0 text-center">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                      No Data Found{" "}
                    </span>
                  </td>
                );
              } else {
                return (
                  <td className="pl-0 text-center">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                      Invalid URL
                    </span>
                  </td>
                );
              }
            })()}
          </tr>
        </tbody>
      </table>
    );
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 text-center"></div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <div className="card card-custom">
              <div class="card-header border-0">
                <div className="card-title">
                  <h3 className="card-label">Topic Papers</h3>
                </div>
                <div className="card-toolbar">
                  <button
                    onClick={() => this.getTopicPaper()}
                    className="btn btn-sm btn-icon btn-light-success mr-2"
                  >
                    <i className="flaticon2-refresh-1"></i>
                  </button>
                </div>
              </div>
              <div className="card-body py-0">
                <div className="table-responsive">
                  {(() => {
                    if (DownloadZipFile !== undefined) {
                      return (
                        <table className="table table-head-custom table-vertical-center">
                          <thead>
                            <tr className="text-left">
                              <th className="pl-0" style={{ width: "30px" }}>
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                  <input
                                    type="checkbox"
                                    disabled={true}
                                    defaultValue={1}
                                  />
                                  <span />
                                </label>
                              </th>
                              <th className="pl-0" style={{ minWidth: "50px" }}>
                                id
                              </th>
                              <th style={{ minWidth: "120px" }}>unique ID</th>
                              <th style={{ minWidth: "120px" }}>Zip file</th>
                              <th style={{ minWidth: "120px" }}>Created at</th>
                              <th style={{ minWidth: "120px" }}>Updated at</th>
                            </tr>
                          </thead>
                          <tbody>
                            {DownloadZipFile.sort(
                              (a, b) =>
                                new Date(b.updated_at) - new Date(a.updated_at)
                            )
                              .slice(0, 10)
                              .map((arr, key) => (
                                <tr key={key}>
                                  <td className="pl-0 py-6">
                                    <label className="checkbox checkbox-lg checkbox-inline">
                                      <input
                                        type="checkbox"
                                        disabled={true}
                                        defaultValue={1}
                                      />
                                      <span />
                                    </label>
                                  </td>
                                  <th scope="row">{arr.id}</th>
                                  <td
                                    className="pl-0"
                                    style={{ maxWidth: "30px" }}
                                  >
                                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                                      {arr.uniqueID.slice(0, 10) +
                                        "..." +
                                        arr.uniqueID.slice(-6)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                        className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">
                                      <a
                                          href={arr.FileLink}
                                          className="btn btn-success font-weight-bold mr-2"
                                      >
                                        <i className="fa fas fa-file-archive icon-lg"></i>{" "}
                                        Download Zip
                                      </a>
                                    </span>
                                    <span
                                        className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">
                                      <a
                                          href={arr.RemoteFile}
                                          className="btn btn-success font-weight-bold mr-2"
                                      >
                                        <i className="fa fas fa-file-archive icon-lg"></i>{" "}
                                        Download Zip B
                                      </a>
                                    </span>

                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      <Moment
                                          format={"ddd, DD MMM YY ,  h:mm A"}
                                      >
                                        {arr.created_at}
                                      </Moment>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      <Moment
                                        format={"ddd, DD MMM YY ,  h:mm A"}
                                      >
                                        {arr.updated_at}
                                      </Moment>
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      );
                    } else {
                      return empty;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  DownloadZipFail: state.DownloadZip.failed,
  DownloadZip: state.DownloadZip.DownloadZip,
  DownloadZipLoading: state.DownloadZip.isLoading,
});
export default connect(mapStateToProps, { getDownloadUnique })(DocumentPageUID);
