import React, { Component, Fragment } from "react";
// import PrintPayment from "./PrintPayment";
import { connect } from "react-redux";
import "../../index.css";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import logo from "../../media/Logo/logo.png";
import { Formik } from "formik";
import { getBundle } from "../../actions/Bundle/BundleAction";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import {getSubscription,PayViaEmail,} from "../../actions/Subscription/Subscription_Action";
import PropTypes from "prop-types";
import * as yup from "yup";
import Select from "react-select";
import Paypal from "./Paypal/Paypal";
import Base from "./Stripe/Base";
import TimeZoneName from "../functions/TimezoneName";
import DaysCalculator from "../functions/DaysCalculator";
const schema = yup.object({
  firstname: yup.string().required("Enter First Name"),
  lastname: yup.string().required("Enter last Name"),
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});
const schemaEMAIL = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});
class NewPaymentPage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getBundle: PropTypes.func,
    getSubject: PropTypes.func,
    getSubscription: PropTypes.func,
    PayViaEmail: PropTypes.func,
    subjectsList: PropTypes.array,
    subscription: PropTypes.array,
    subscriptionSubscribe: PropTypes.bool,
    subscriptionLoading: PropTypes.bool,
  };
  state = {
    actionStack: 0,
    subjectsOption: [],
    mySubjectsList: [],
    maths_key: 0,
    value: [],
    Annual: true,
    Price: undefined,
    Name: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    phone: undefined,
    PackageID: undefined,
    checkout: undefined,
    Subject: undefined,
    Citi_data: null,
    Country_state: null,
    stateName: null,
    Clipboardhide: true,
    sendButton: false,
    DownloadModal: false,
  };

  SubmitEmailPayment = (value) => {
    const email = value.email;
    const school = parseInt(localStorage.getItem("Schoolid"));
    const Requested_By = parseInt(localStorage.getItem("id"));
    const Amount = this.state.Price;
    const bundle_id = this.state.PackageID;
    const Requested_From_URL = window.location.href;
    const name = this.state.firstname.concat(" ", this.state.lastname);
    const Subject = this.state.Subject;
    const Phone = this.state.phone;
    const data = {
      email,
      school,
      Requested_By,
      Amount,
      bundle_id,
      Requested_From_URL,
      name,
      Subject,
      Phone,
    };
    this.props.PayViaEmail(data);
    this.setState({ sendButton: true });
  };

  ButtonClick = (data, value) => {
    if (value === "back") {
      if (this.state.actionStack !== 0) {
        this.setState({ actionStack: this.state.actionStack - 1 });
      }
    }
    if (value === "continue") {
      if (this.state.actionStack < 2) {
        this.setState({ actionStack: this.state.actionStack + 1 });
      }
    }
    if (data.type === undefined) {
      const { firstname, lastname, phone, email } = data;
      this.setState({
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        email: email,
      });
      if (data.selectedSubjects === "") {
        this.setState({
          Subject: [this.state.subjectsOption[this.state.maths_key].value],
        });
      } else {
        const Subject = data.selectedSubjects.map((arr) => arr.value);
        this.setState({ Subject: Subject });
      }
    }
  };
  subjectsList = () => {
    const options = this.props.subjectsList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ subjectsOption: options });

    // Maths finder
    const maths = this.props.subjectsList.findIndex(
      (arr) => arr.name === "Maths"
    );
    this.setState({ maths_key: maths });
  };
  lowestkeyFinder = () => {
    let key = this.props.bundles.reduce((prev, curr) =>
      prev.id < curr.id ? prev : curr
    );
    this.setState({
      PackageID: key.id,
      Price: key.Annual_Price,
      Name: key.Name,
    });
  };

  copytoClip = () => {
    let copyText = document.querySelector(".copy-text");
    let input = copyText.querySelector("input.text");
    navigator.clipboard.writeText(input.value);
    copyText.classList.add("active");
    window.getSelection().removeAllRanges();
    setTimeout(function () {
      copyText.classList.remove("active");
    }, 2500);
  };
  componentDidMount() {
    this.props.getSubscription();
    this.props.getSubject();
    this.props.getBundle();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.subjectsList.length !== this.props.subjectsList.length) {
      this.subjectsList();
    }
    if (this.state.subjectsOption.length === 0) {
      this.subjectsList();
    }
    if (prevProps.bundles.length !== this.props.bundles.length) {
      this.lowestkeyFinder();
    }

    if (this.state.PackageID === undefined && this.props.bundles.length !== 0) {
      this.lowestkeyFinder();
    }
    if (prevProps.subscription.length !== this.props.subscription.length) {
      this.setState({ actionStack: 3 });
    }
  }
  PackageSelector = (id, Annual_Price, Name) => {
    this.setState({
      PackageID: id,
      Price: Annual_Price,
      Name: Name,
    });
  };

  render() {
    const {
      actionStack,
      subjectsOption,
      maths_key,
      PackageID,
      checkout,
      firstname,
      lastname,
      phone,
      Price,
      Name,
      email,
      Annual,
      Subject,
      Citi_data,
      Country_state,
      stateName,
      Clipboardhide,
      DownloadModal,
    } = this.state;
    const {
      auth,
      bundles,
      subscription,
      subscriptionLoading,
      array_sub,
      subscriptionLoadingSend,
    } = this.props;
    return (
      <>
        <div className="app-content flex-column-fluid">
          <div className="app-container container-sm">
            <div className="card">
              <div className="card-body p-lg-20">
                <div className="modal-body scroll-y m-5">
                  <div className="stepper stepper-links d-flex flex-column gap-5 first">
                    <div className="stepper-nav justify-content-center py-2">
                      <div
                        className={`stepper-item ${
                          actionStack === 0 ? "current" : "completed"
                        }`}
                      >
                        <h3 className="stepper-title">Customer Information</h3>
                      </div>
                      <div
                        className={`stepper-item ${
                          actionStack < 1
                            ? "pending"
                            : actionStack === 1
                            ? "current"
                            : "completed"
                        }`}
                      >
                        <h3 className="stepper-title">Pick a Plan</h3>
                      </div>
                      <div
                        className={`stepper-item ${
                          actionStack < 2
                            ? "pending"
                            : actionStack === 2
                            ? "current"
                            : "completed"
                        }`}
                      >
                        <h3 className="stepper-title">Payment</h3>
                      </div>
                      <div
                        className={`stepper-item ${
                          actionStack < 3
                            ? "pending"
                            : actionStack === 3
                            ? "current"
                            : "completed"
                        }`}
                      >
                        <h3 className="stepper-title">Completed</h3>
                      </div>
                    </div>
                    {(() => {
                      if (subscriptionLoading) {
                        return (
                          <Fragment>
                            <div className="d-flex flex-column flex-xl-row">
                              <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                <div className="mt-n1">
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                  &nbsp;Checking your subscription...
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      } else if (
                        subscriptionLoading === false &&
                        subscription.length !== 0
                      ) {
                        return (
                          <div className="d-flex flex-column flex-xl-row">
                            <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                              <div className="mt-n1">
                                <div className="d-flex flex-stack pb-10">
                                  <img
                                    alt="Logo"
                                    width="30"
                                    height="30"
                                    src={logo}
                                  />
                                  <button
                                    onClick={() =>
                                      this.setState({ DownloadModal: true })
                                    }
                                    type="button"
                                    className="btn btn-sm btn-success"
                                  >
                                    Print now
                                  </button>
                                </div>
                                <div className="m-0">
                                  <div className="fw-bold fs-3 text-gray-800 mb-8">
                                    Invoice # {subscription.id}
                                  </div>
                                  <div className="row g-5 mb-11">
                                    <div className="col-sm-6">
                                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                                        Issue Date:
                                      </div>
                                      <div className="fw-bold fs-6 text-gray-800">
                                        <TimeZoneName
                                          data={subscription.TimeStart}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                                        End Date:
                                      </div>
                                      <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                        <span className="pe-2">
                                          <TimeZoneName
                                            data={subscription.EndDate}
                                          />
                                        </span>
                                        <DaysCalculator
                                          data={subscription.EndDate}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-5 mb-12">
                                    <div className="col-sm-6">
                                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                                        Issue For:
                                      </div>
                                      <div className="fw-bold fs-6 text-gray-800 text-capitalize">
                                        {subscription.firstName}{" "}
                                        {subscription.lastName}
                                      </div>
                                      <div className="fw-semibold fs-7 text-gray-600 text-capitalize">
                                        {subscription.School.name} <br />
                                        {subscription.School.Address}
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="fw-semibold fs-7 text-gray-600 mb-1">
                                        Issued By:
                                      </div>
                                      <div className="fw-bold fs-6 text-gray-800">
                                        Target QLA.
                                      </div>
                                      <div className="fw-semibold fs-7 text-gray-600">
                                        1-13 St Giles High St,
                                        <br />
                                        London WC2H 8AG
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex-grow-1">
                                    <div className="table-responsive mb-9">
                                      <table className="table mb-3">
                                        <thead>
                                          <tr className="border-bottom fs-6 fw-bold text-muted">
                                            <th className="min-w-175px pb-2">
                                              Bundle Names
                                            </th>
                                            <th className="min-w-50px text-end pb-2">
                                              Price
                                            </th>
                                            <th className="min-w-50px text-end pb-2">
                                              VAT %
                                            </th>
                                            <th className="min-w-50px text-end pb-2">
                                              Annual
                                            </th>
                                            <th className="min-w-80px text-end pb-2">
                                              Amount
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr className="fw-bold text-gray-700 fs-5 text-end">
                                            <td className="d-flex align-items-center pt-6">
                                              <i className="fa fa-genderless text-danger fs-2 me-2" />
                                              {subscription.bundle.Name}
                                            </td>
                                            <td className="pt-6">
                                              £ {subscription.bundle.Price}
                                            </td>
                                            <td className="pt-6">
                                              {subscription.bundle.Tax} %
                                            </td>
                                            <td className="pt-6">
                                              {subscription.Annual ? (
                                                <i className="fas fa-check-circle text-success" />
                                              ) : (
                                                <i className="far fa-times-circle text-danger" />
                                              )}
                                            </td>
                                            <td className="pt-6 text-dark fw-bolder">
                                              £{" "}
                                              {subscription.bundle.Annual_Price}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                      <div className="mw-300px">
                                        <div className="d-flex flex-stack mb-3">
                                          <div className="fw-semibold pe-10 text-gray-600 fs-7">
                                            Subtotal:
                                          </div>
                                          <div className="text-end fw-bold fs-6 text-gray-800">
                                            £ {subscription.Price}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-stack mb-3">
                                          <div className="fw-semibold pe-10 text-gray-600 fs-7">
                                            VAT {subscription.bundle.Tax}%
                                          </div>
                                          <div className="text-end fw-bold fs-6 text-gray-800">
                                            £{" "}
                                            {(subscription.Price *
                                              subscription.bundle.Tax) /
                                              100}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-stack mb-3">
                                          <div className="fw-semibold pe-10 text-gray-600 fs-7">
                                            Subtotal + VAT
                                          </div>
                                          <div className="text-end fw-bold fs-6 text-gray-800">
                                            £ {subscription.Price} +{" "}
                                            {(subscription.Price *
                                              subscription.bundle.Tax) /
                                              100}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-stack">
                                          <hr />
                                          <div className="fw-semibold pe-10 text-gray-600 fs-7">
                                            Total
                                          </div>
                                          <div className="text-end fw-bold fs-6 text-gray-800">
                                            £{" "}
                                            {subscription.Price +
                                              (subscription.Price *
                                                subscription.bundle.Tax) /
                                                100}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="m-0">
                              <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                <div className="mb-8">
                                  <span className="badge badge-light-success me-2">
                                    Approved
                                  </span>
                                  {/* <span className="badge badge-light-warning">
                                          Pending Payment
                                        </span> */}
                                </div>
                                <h6 className="mb-8 fw-bolder text-gray-600">
                                  PAYMENT DETAILS
                                </h6>
                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Payment Type:
                                  </div>
                                  <div className="fw-bold text-gray-800 fs-6">
                                    {subscription.PaymentMethod}{" "}
                                    {subscription.PaymentMethod === "Stripe" ? (
                                      <i className="fab fa-cc-stripe text-info" />
                                    ) : (
                                      <i className="fab fa-cc-paypal text-primary" />
                                    )}
                                  </div>
                                </div>
                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Transaction ID:
                                  </div>
                                  <div className="fw-bold text-gray-800 fs-6">
                                    {subscription.Transaction_ID}
                                  </div>
                                </div>
                                <div className="mb-15">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Payment:
                                  </div>
                                  <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
                                    <DaysCalculator
                                      data={subscription.EndDate}
                                    />
                                  </div>
                                </div>
                                <h6 className="mb-6 fw-bolder text-gray-600">
                                  Bill OVERVIEW
                                </h6>

                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Bundle Name:
                                  </div>
                                  <div className="fw-bold fs-6 text-gray-800">
                                    {subscription.bundle.Name}
                                  </div>
                                </div>
                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Valid from:
                                  </div>
                                  <div className="fw-bold fs-6 text-gray-800">
                                    <TimeZoneName
                                      data={subscription.TimeStart}
                                    />
                                  </div>
                                </div>
                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Valid Till:
                                  </div>
                                  <div className="fw-bold fs-6 text-gray-800">
                                    <TimeZoneName data={subscription.EndDate} />
                                  </div>
                                </div>
                                <div className="mb-6">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Completed By:
                                  </div>
                                  <div className="fw-bold text-gray-800 fs-6">
                                    {subscription.firstName}{" "}
                                    {subscription.lastName}
                                  </div>
                                </div>
                                <div className="m-0">
                                  <div className="fw-semibold text-gray-600 fs-7">
                                    Amount Spent:
                                  </div>
                                  <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
                                    {subscription.bundle.Annual_Price} £ /{" "}
                                    {subscription.Annual ? "Year" : "Month"}
                                    {/* <span className="fs-7 text-success d-flex align-items-center">
                                            <span className="bullet bullet-dot bg-success mx-2" />
                                            {subscription.bundle.Annual_Price} $/h Rate
                                          </span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (actionStack === 0) {
                        return (
                          <div className="current">
                            <div className="w-100">
                              <div className="pb-5 pb-lg-10">
                                <h4 className="d-flex align-items-center text-dark">
                                  Let us know who you are
                                </h4>
                              </div>
                              <Formik
                                validationSchema={schema}
                                onSubmit={(values) =>
                                  this.ButtonClick(values, "continue")
                                }
                                initialValues={{
                                  firstname: auth.user.user.firstname,
                                  lastname: auth.user.user.lastname,
                                  email: auth.user.user.email,
                                  phone: auth.user.user.phone,
                                  School: auth.user.user.School.name,
                                  selectedSubjects: "",
                                }}
                              >
                                {({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  errors,
                                }) => (
                                  <Form
                                    className="mx-auto w-100 pt-5 pb-10 fv-plugins-bootstrap5 fv-plugins-framework"
                                    noValidate
                                    onSubmit={handleSubmit}
                                  >
                                    <div className="login-form login-form-signup">
                                      <div className="form-group row">
                                        <div className="col-6">
                                          <label className="font-size-h6 font-weight-bolder text-dark">
                                            First Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Form.Control
                                            className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                            type="text"
                                            name="firstname"
                                            placeholder="First Name"
                                            value={values.firstname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.firstname}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.firstname}
                                          </Form.Control.Feedback>
                                        </div>
                                        <div className="col-6">
                                          <label className="font-size-h6 font-weight-bolder text-dark">
                                            Last Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Form.Control
                                            className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                            type="text"
                                            name="lastname"
                                            placeholder="Last Name"
                                            value={values.lastname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.lastname}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.lastname}
                                          </Form.Control.Feedback>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col-6">
                                          <label className="font-size-h6 font-weight-bolder text-dark">
                                            Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Form.Control
                                            className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                          </Form.Control.Feedback>
                                        </div>
                                        <div className="col-6">
                                          <label className="font-size-h6 font-weight-bold">
                                            Phone number{" "}
                                          </label>
                                          <Form.Control
                                            className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                            type="text"
                                            name="phone"
                                            placeholder="Phone number"
                                            value={values.phone}
                                            onChange={handleChange}
                                            isInvalid={!!errors.phone}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                          </Form.Control.Feedback>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col-12">
                                          <label className="font-size-h6 font-weight-bolder text-dark">
                                            School
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Form.Control
                                            className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                            type="text"
                                            disabled
                                            placeholder="School"
                                            value={values.School}
                                            onChange={handleChange}
                                            isInvalid={!!errors.School}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.School}
                                          </Form.Control.Feedback>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col-12">
                                          <label className="font-size-h6 font-weight-bolder text-dark">
                                            Subject(s)
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          {subjectsOption.length !== 0 ? (
                                            <Select
                                              defaultValue={[
                                                subjectsOption[maths_key],
                                              ]}
                                              name="selectedSubjects"
                                              isMulti
                                              options={subjectsOption}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              onChange={(selectedOption) => {
                                                let event = {
                                                  target: {
                                                    name: "selectedSubjects",
                                                    value: selectedOption,
                                                  },
                                                };
                                                handleChange(event);
                                              }}
                                            />
                                          ) : (
                                            <Fragment>
                                              <Spinner
                                                animation="grow"
                                                variant="warning"
                                              />
                                            </Fragment>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {actionStack !== 0 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex flex-stack pt-10">
                                        <div className="me-2">
                                          <Button
                                            type="button"
                                            onClick={(e) =>
                                              this.ButtonClick(e, "back")
                                            }
                                            disabled={actionStack === 0}
                                            className="btn btn-lg btn-primary"
                                            data-kt-stepper-action="next"
                                          >
                                            <span className="text-white">
                                              <i className="flaticon2-fast-back">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                              Back
                                            </span>
                                          </Button>
                                        </div>
                                        <div>
                                          <Button
                                            type="submit"
                                            disabled={actionStack === 3}
                                            className="btn btn-lg btn-primary "
                                          >
                                            <span className="text-white">
                                              <i className="flaticon2-fast-next">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                              Continue
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        );
                      } else if (actionStack === 1) {
                        return (
                          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                            <div className="mb-13 text-center">
                              <h1 className="mb-3">Pick a Plan</h1>
                              <div className="text-muted fw-semibold fs-5">
                                If you need more info, please check{" "}
                                <a
                                  href="#"
                                  className="link-primary link-color-new-P fw-bold"
                                >
                                  Pricing Guidelines
                                </a>
                                .
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="row mt-10">
                                <div className="col-lg-6 mb-10 mb-lg-0">
                                  <div className="nav flex-column">
                                    {bundles.map((arr, key) => (
                                      <label
                                        key={key}
                                        className={`nav-link btnP btnP-outline btnP-outline-dashed btnP-color-dark btnP-active btnP-active-primary d-flex flex-stack text-start p-6 mb-6 ${
                                          PackageID === arr.id ? "active" : ""
                                        }`}
                                      >
                                        <div className="d-flex align-items-center me-2">
                                          <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                            {PackageID !== undefined ? (
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="plan"
                                                onChange={() =>
                                                  this.PackageSelector(
                                                    arr.id,
                                                    arr.Annual_Price,
                                                    arr.Name
                                                  )
                                                }
                                                value={arr.id}
                                                defaultChecked={
                                                  PackageID === arr.id
                                                }
                                              />
                                            ) : (
                                              <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                          <div className="flex-grow-1">
                                            <div className="d-flex align-items-center fw-semibold flex-wrap">
                                              {arr.Name}
                                              {arr.Popular ? (
                                                <span className="badge badge-light-success ms-2 py-2 px-3 fs-7">
                                                  Popular
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="fw-semibold opacity-75">
                                              {arr.Moto_Line}{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ms-5">
                                          <span className="mb-2">£</span>
                                          <span className="fs-3x fw-bold">
                                            {arr.Annual_Price}
                                          </span>
                                          <span className="fs-7 opacity-50">
                                            /
                                            <span data-kt-element="period">
                                              Year
                                            </span>
                                          </span>
                                        </div>
                                      </label>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="tab-content rounded h-100 bg-light p-10">
                                    {bundles.map((arr, key) => (
                                      <div
                                        key={key}
                                        className={`tab-pane fade ${
                                          PackageID === arr.id
                                            ? "show active"
                                            : ""
                                        }`}
                                      >
                                        <div className="pb-5">
                                          <h2 className="fw-bold text-dark">
                                            What’s in {arr.Name}?
                                          </h2>
                                          <div className="text-muted fw-semibold">
                                            Optimal for 10+ team size and new
                                            startup
                                          </div>
                                        </div>
                                        <div className="pt-1">
                                          {arr.Point.map((pp, key) => (
                                            <div
                                              key={key}
                                              className="d-flex align-items-center mb-7"
                                            >
                                              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                {pp.Point}
                                              </span>
                                              <i className="fas fa-check-circle text-success" />
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (actionStack === 2) {
                        return (
                          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
                            <div className="mb-13 text-center">
                              <h1 className="mb-3">Select Payment method</h1>
                              <div className="text-muted fw-semibold fs-5">
                                Secure Payment method powered by Paypal & Stripe
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="row mt-10">
                                <div className="col-lg-6 mb-10 mb-lg-0">
                                  {checkout === "Stripe" ? (
                                    <div style={{ marginTop: "-20px" }}>
                                      <Base
                                        Price={Price}
                                        Name={Name}
                                        firstName={firstname}
                                        lastName={lastname}
                                        email={email}
                                        phone={phone}
                                        Subject={Subject}
                                        PackageID={PackageID}
                                        checkout={checkout}
                                        Annual={Annual}
                                        Citi_data={Citi_data}
                                        Country_state={Country_state}
                                        stateName={stateName}
                                        School={localStorage.getItem(
                                          "Schoolid"
                                        )}
                                        user={localStorage.getItem("id")}
                                      />
                                    </div>
                                  ) : (
                                    <div className="nav flex-column">
                                      <label
                                        className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                                          checkout === "Stripe" ? "active" : ""
                                        }`}
                                      >
                                        <input
                                          className="btn-check"
                                          type="radio"
                                          name="offer_type"
                                          defaultValue={1}
                                          value={1}
                                          onClick={() =>
                                            this.setState({
                                              checkout: "Stripe",
                                            })
                                          }
                                        />
                                        <span className="d-flex">
                                          <i className="fab fa-cc-stripe icon-5x text-info" />
                                          <span className="ms-4">
                                            <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                                              Stripe
                                            </span>
                                            <span className="fw-semibold fs-4 text-muted">
                                              Pay with credit or debit card
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  )}
                                </div>

                                <div className="col-lg-6 mb-10 mb-lg-0">
                                  <div className="nav flex-column">
                                    {checkout === "Paypal" ? (
                                      <Paypal
                                        Price={Price}
                                        Name={Name}
                                        firstName={firstname}
                                        lastName={lastname}
                                        email={email}
                                        phone={phone}
                                        Subject={Subject}
                                        PackageID={PackageID}
                                        checkout={checkout}
                                        Annual={Annual}
                                        School={localStorage.getItem(
                                          "Schoolid"
                                        )}
                                        user={localStorage.getItem("id")}
                                      />
                                    ) : (
                                      <label
                                        className={` btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                                          checkout === "Paypal" ? "active" : ""
                                        }`}
                                      >
                                        <input
                                          className="btn-check"
                                          type="radio"
                                          name="offer_type"
                                          defaultValue={1}
                                          value={2}
                                          onClick={() =>
                                            this.setState({
                                              checkout: "Paypal",
                                            })
                                          }
                                        />
                                        <span className="d-flex">
                                          <i className="fab fa-cc-paypal icon-5x text-primary" />
                                          <span className="ms-4">
                                            <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                                              Paypal
                                            </span>
                                            <span className="fw-semibold fs-4 text-muted">
                                              Pay with <b>Paypal</b>
                                            </span>
                                          </span>
                                        </span>
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <Formik
                                validationSchema={schemaEMAIL}
                                onSubmit={(values) =>
                                  this.SubmitEmailPayment(values)
                                }
                                initialValues={{
                                  email: "",
                                }}
                              >
                                {({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  touched,
                                  errors,
                                }) => (
                                  <Form noValidate onSubmit={handleSubmit}>
                                    <div className="row mt-5">
                                      <div className="col-lg-12 mb-12 mb-lg-0">
                                        <div className="mb-13 text-center">
                                          <h5 className="mb-3">
                                            Send Payment Link to Finance Team
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-5">
                                      <div className="col-lg-8 mb-12 mb-lg-0">
                                        <Form.Control
                                          className="form-control h-10 py-7 px-6 rounded-lg font-size-h6 box-border-style"
                                          type="email"
                                          name="email"
                                          placeholder="Type an email"
                                          value={values.email}
                                          onChange={handleChange}
                                          isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      </div>
                                      <div className="col-lg-4 p-2">
                                        <button
                                          disabled={subscriptionLoadingSend}
                                          type="submit"
                                          className="btn btn-outline-warning"
                                        >
                                          {subscriptionLoadingSend ? (
                                            <Spinner
                                              animation="border"
                                              variant="success"
                                            />
                                          ) : (
                                            "Send to Team"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        );
                      }
                    })()}
                    {actionStack === 0 ? (
                      ""
                    ) : (
                      <div className="d-flex flex-stack pt-10">
                        <div className="me-2">
                          <Button
                            type="button"
                            onClick={(e) => this.ButtonClick(e, "back")}
                            disabled={actionStack === 0}
                            className="btn btn-lg btn-primary"
                            data-kt-stepper-action="next"
                          >
                            <span className="text-white">
                              <i className="flaticon2-fast-back">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              Back
                            </span>
                          </Button>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            disabled={actionStack >= 2}
                            className="btn btn-lg btn-primary"
                            onClick={(e) => this.ButtonClick(e, "continue")}
                          >
                            <span className="text-white">
                              <i className="flaticon2-fast-next">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              Continue
                            </span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {array_sub.length !== 0 ? (
            <Modal
              show={Clipboardhide}
              onHide={() => this.setState({ Clipboardhide: false })}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Payment Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="container_mine text-center">
                    <div className="row">
                      <div className="col-12">
                        <div className="label_mine">Copy Link to clipboard</div>
                        <p>
                          You can copy and share the link directly with your
                          finance Team, We have already send an email ✉️
                        </p>
                        <div className="copy-text">
                          <input
                            type="text"
                            className="text"
                            value={array_sub.URL}
                          />
                          <button onClick={() => this.copytoClip()}>
                            <i className="fa fa-clone colorwhite" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          ) : (
            <></>
          )}

          <Modal
            show={DownloadModal}
            onHide={() => this.setState({ DownloadModal: false })}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Download Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="">
                  <div className="">
                    <div className="">
                      {(() => {
                        if (
                          subscriptionLoading === false &&
                          subscription.length !== 0
                        ) {
                          return (
                            <div className="row">
                              <div className="col-12 text-center">
                                {/* <PrintPayment data={subscription} /> */}
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bundles: state.bundles.bundles,
  subjectsList: state.subjectsList.subjectsList,
  subscription: state.subscription.subscription,
  subscriptionLoading: state.subscription.isLoading,
  subscriptionSubscribe: state.subscription.subscribe,
  subscriptionLoadingSend: state.subscription.isLoadingSend,
  array_sub: state.subscription.array_sub,
});
export default connect(mapStateToProps, {
  getBundle,
  getSubject,
  getSubscription,
  PayViaEmail,
})(NewPaymentPage);
