import {
  RAG_RATING_LOADING_NEW,
  RAG_RATING_FAIL_NEW,
  GET_RAG_RATING_LIST,
} from "../actions/types";

const initialState = {
  isLoading: false,
  GETRABRatingArray: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RAG_RATING_LIST:
      return {
        ...state,
        GETRABRatingArray: action.payload,
        isLoading: false,
      };
    case RAG_RATING_LOADING_NEW:
      return {
        ...state,
        GETRABRatingArray: [],
        isLoading: true,
      };
    case RAG_RATING_FAIL_NEW:
      return {
        ...state,
        GETRABRatingArray: [],
        isLoading: false,
      };
    default:
      return state;
  }
}
