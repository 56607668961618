import React, { Component } from "react";
import userimg from "../../../../assets/media/svg/misc/008-infography.svg";
import { connect } from "react-redux";
import Moment from "react-moment";
import ProgressFunction from "../../../functions/ProgressFunction";

class MixedWidget7 extends Component {
  render() {
    return (
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-center py-1">
            <div className="symbol symbol-80 symbol-light-danger mr-5 mb-2">
                <span className="symbol-label">
                  <img
                    src={userimg}
                    className="h-50 align-self-center"
                    alt="School_logo"
                  />
                </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                <span className="text-dark font-weight-bolder font-size-h5">
                  Subscription Valid till:
                </span>
              <span className="text-muted font-weight-bold font-size-sm">
                  <Moment format={"dddd, MMM Do YYYY, h:mm a"}>{this.props.auth.MemberShipDate}</Moment>
                </span>
            </div>
            <div className="d-flex flex-column w-100 mt-12">
              <ProgressFunction data={this.props.auth.MemberShipDate} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});
export default connect(mapStateToProps, {})(MixedWidget7);
