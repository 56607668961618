import axios from "axios";
import { returnErrors } from "../message/message";
import { GET_FILE_MEDIA, FILE_LOADING, FILE_FAILED } from "../types";

export const getFileMedia = () => (dispatch) => {
  dispatch({ type: FILE_LOADING });
  axios
    .get("File/")
    .then((res) => {
      dispatch({
        type: GET_FILE_MEDIA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: FILE_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getFileMediaFolder = (FolderName) => (dispatch) => {
  console.log(FolderName, "hello world");
  dispatch({ type: FILE_LOADING });
  axios
    .get(`Folders/${FolderName}`)
    .then((res) => {
      console.log(res.data, "hello world");
      dispatch({
        type: GET_FILE_MEDIA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: FILE_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
