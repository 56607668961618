import axios from "axios";
import { tokenConfig } from "../auth_action/auth";

import {
  CHECK_VERIFY_OTP_SCHOOL,
  CHECK_VERIFY_OTP_SCHOOL_LOADING,
  CHECK_OTP_SCHOOL_LOADING_FAIL,
} from "../types";
import toast from "react-hot-toast";

export const CHECK_STATUS_School_OTP = (data) => (dispatch, getState) => {
  const TID = toast.loading("Verifing School...⏳");
  dispatch({ type: CHECK_VERIFY_OTP_SCHOOL_LOADING });
  axios
    .post("QLA/verifySchoolIsVerified/", data, tokenConfig(getState))
    .then((res) => {
      toast.remove(TID);
      dispatch({
        type: CHECK_VERIFY_OTP_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      if (
        err.response.data.detail === "OTP already verified" ||
        err.response.data.detail === "Invalid Link"
      ) {
        dispatch({
          type: CHECK_VERIFY_OTP_SCHOOL,
          payload: err.response.data.detail,
        });
      }

      dispatch({ type: CHECK_OTP_SCHOOL_LOADING_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};
