import React, { Component } from "react";
import Sidebar from "../KeenTheme/Sidebar";
import NavbarKeen from "../KeenTheme/NavbarKeen";
import Subheader from "../KeenTheme/Subheader";
import StudentMarks from "../KeenTheme/StudentMarks";

class AddMarks extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Aside*/}
          <Sidebar />
          {/*end::Aside*/}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
          >
            {/*begin::Header*/}
            <NavbarKeen />
            {/*end::Header*/}
            {/*begin::Content*/}
            <div
              style={{ marginTop: "20px" }}
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              {/*begin::Subheader*/}

              <Subheader name="Marks" />
              {/*end::Subheader*/}
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <StudentMarks />
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    );
  }
}

export default AddMarks;
