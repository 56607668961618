import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  NEW_RAG_RATING_LOADING,
  RAG_RATING_FAILED,
  CREATE_RAG_RATING,
} from "../types";

export const CreateNewRAG = (id,data) => (dispatch, getState) => {
  dispatch({ type: NEW_RAG_RATING_LOADING });
  axios
    .post(`QLA/AddNewRAG/${id}`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Rating Added" }));
      dispatch({
        type: CREATE_RAG_RATING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: RAG_RATING_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
