// api.js
import axios from "axios";
import { createMessage, returnErrors } from "../../../actions/message/message";
import {
  ADD_SUBSCRIPTION,
  SUBSCRIPTION_FAIL,
  SUBSCRIPTION_LOADING,
} from "../../../actions/types";
import { tokenConfig } from "../../../actions/auth_action/auth";

export const saveStripeInfo = (data) => (dispatch, getState) => {
  dispatch({ type: SUBSCRIPTION_LOADING });
  const user = data.x.user;
  const firstName = data.x.firstName;
  const lastName = data.x.lastName;
  const email = data.x.email;
  const phone = data.x.phone;
  // const Country_state = data.x.Country_state;
  // const stateName = data.x.stateName;
  const Price = data.x.Price;
  // const Citi_data = data.x.Citi_data;
  const bundle = data.x.PackageID;
  const Subject = data.x.Subject;
  const School = data.x.School;
  const PaymentMethod = data.x.checkout;
  const Transaction_ID = data.payment_method_id;
  const Annual = data.x.Annual;

  const body = {
    user,
    firstName,
    lastName,
    email,
    phone,
    // Country_state,
    // stateName,
    Annual,
    // Citi_data,
    bundle,
    Subject,
    School,
    Price,
    PaymentMethod,
    Transaction_ID,
  };
  axios.post("/payments/save-stripe-info/", body, tokenConfig(getState))
    .then((res) => {
      console.log(res.data,"return data")
      dispatch(createMessage({ addLead: "Subscribed" }));
      dispatch({
        type: ADD_SUBSCRIPTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err,"return error")
      dispatch({ type: SUBSCRIPTION_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};



export const saveStripeInfoViaEmail = (data) => (dispatch, getState) => {
  dispatch({ type: SUBSCRIPTION_LOADING });
  const type='Stripe'
  const body={data,type}
  axios
  .put(`payments/PayContent/${data.local_data.unique_ID}/`, body, tokenConfig(getState))
  .then((res) => {
    dispatch(createMessage({ addLead: 'Subscribed Successfully' }))
    dispatch({
      type: ADD_SUBSCRIPTION,
      payload: res.data,
    })
  })
  .catch((err) => {
    dispatch(createMessage({ deleteLead: 'Oops! Error occurred' }))
    dispatch({ type: SUBSCRIPTION_FAIL })
    dispatch(returnErrors(err.response.data), err.response.status)
  })
};