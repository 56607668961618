import React, { Component, Fragment } from 'react'
import { Country, State, City } from 'country-state-city'
import Select from 'react-select'
import '../../style/theme.css'
import { Alert, Spinner, Modal,Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getBundle } from '../../actions/Bundle/BundleAction'
import { getSchool } from '../../actions/School/school_actions'
import { getSubject } from '../../actions/Subjects/Subjects_actions'
import ListPage from './ListPage'
import '../../index.css'
import Paypal from './Paypal/Paypal'
import Base from './Stripe/Base'
import Imagex from '../../assets/media/bg/bg-6.jpg'
import paypalimg from '../../assets/Paypal/img2.jpg'
import stripelogo from '../../assets/Stripe/250px-Stripe_Logo,_revised_2016.svg.png'
import {
  getSubscription,
  PayViaEmail,
} from '../../actions/Subscription/Subscription_Action'
import TableRowInvoice from '../functions/TableRowInvoice'
import Moment from 'react-moment'
import Waiting from '../functions/Waiting'
import * as yup from 'yup'

const schema = yup.object({
  firstname: yup.string().required('Enter First Name'),
  lastname: yup.string().required('Enter last Name'),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Enter an email address Required'),
})
const schemaEMAIL = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
})

class PaymentPage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getBundle: PropTypes.func.isRequired,
    getSubscription: PropTypes.func.isRequired,
    bundles: PropTypes.array.isRequired,
    getSubject: PropTypes.func.isRequired,
    subjectsList: PropTypes.array.isRequired,
    getSchool: PropTypes.func.isRequired,
    school_list: PropTypes.array.isRequired,
    subscription: PropTypes.array.isRequired,
    subscriptionSubscribe: PropTypes.bool.isRequired,
    subscriptionLoading: PropTypes.bool.isRequired,
    subscriptionLoadingSend: PropTypes.bool.isRequired,
    PayViaEmail: PropTypes.func.isRequired,
    array_sub: PropTypes.array,
  }
  state = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    Clipboardhide: true,
    Country: '',
    Country_state: null,
    CloseAlert: false,
    City: null,
    selectOption: '',
    country_ISO: '',
    state_data: '',
    stateName: '',
    stateISO: '',
    city_data: '',
    city_name: '',
    city_ISO: '',
    TotalPrice: '',
    Citi_data: '',
    Inform: 'block',
    Payment: 'none',
    Checkout: 'none',
    Paypal: 'none',
    Stripe: 'none',
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    validate: false,
    bundle_array: [],
    subscription_id: null,
    switch_State: true,
    value: [{ value: 2, label: 'Maths' }],
    subjectsOption: [],
    schoolOption: '',
    checkout: '',
    PackagePrice: '',
    PackageName: '',
    PackageID: '',
    Subject: '',
    school: '',
    active1: 'active',
    active2: '',
    valueSubjects: '',
    sendButton: false,
  }
  handleChange = (e) => {
    this.setState(
      {
        Country_state: e.label,
        country_ISO: e.value,
      },
      () => this.getOption_state(this.state.country_ISO),
    )
  }
  getOption_state = (data) => {
    const options = State.getStatesOfCountry(data).map((arr) => ({
      value: arr.isoCode,
      label: arr.name,
    }))
    if (options.length === 0) {
      this.getCity_data(data)
    }
    this.setState({ state_data: options })
  }
  getCity_data = (data) => {
    const options = City.getCitiesOfCountry(data).map((arr) => ({
      value: arr.isoCode,
      label: arr.name,
    }))
    this.setState({ city_data: options })
  }
  handleChange_state = (e) => {
    this.setState({ stateName: e.label, stateISO: e.value }, () =>
      this.getOption_country(this.state.stateISO),
    )
  }
  getOption_country = (data) => {
    const options = City.getCitiesOfState(this.state.country_ISO, data).map(
      (arr) => ({
        value: arr.isoCode,
        label: arr.name,
      }),
    )

    this.setState({ city_data: options })
  }
  handleChangeCity = (e) => {
    this.setState({ Citi_data: e.label })
  }
  Subject = (e) => {
    this.setState({ Subject: e.target.value })
  }
  School = (e) => {
    this.setState({ school: e.target.value })
  }
  getOption = () => {
    const options = Country.getAllCountries().map((arr) => ({
      value: arr.isoCode,
      label: arr.name,
    }))
    this.setState({ selectOption: options })
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  addclass = (data) => {
    data.classList.add('border-primary')
    data.classList.add('text-white')
    data.classList.add('bg-primary')
  }
  removeclass = (data1, data2) => {
    if (data1.classList.contains('border-primary')) {
      data1.classList.remove('border-primary')
      data1.classList.remove('text-white')
      data1.classList.remove('bg-primary')
    }
    if (data2.classList.contains('border-primary')) {
      data2.classList.remove('border-primary')
      data2.classList.remove('text-white')
      data2.classList.remove('bg-primary')
    }
    // if (data3.classList.contains("border-primary")) {
    //   data3.classList.remove("border-primary");
    //   data3.classList.remove("text-white");
    //   data3.classList.remove("bg-primary");
    // }
  }
  Step1 = () => {
    const element1 = document.getElementById('step1')
    const element2 = document.getElementById('step2')
    const element3 = document.getElementById('step3')
    // const element4 = document.getElementById("step4");
    this.addclass(element1)
    this.removeclass(element2, element3)
    this.setState({
      step1: true,
      step2: false,
      step3: false,
      // step4: false,
    })
  }
  step2setter = () => {
    const element1 = document.getElementById('step1')
    const element2 = document.getElementById('step2')
    const element3 = document.getElementById('step3')
    // const element4 = document.getElementById("step4");
    this.addclass(element2)
    this.removeclass(element1, element3)
    this.setState({
      step1: false,
      step2: true,
      step3: false,
      // step4: false,
    })
  }
  Step2 = (e) => {
    this.step2setter()
    const { firstname, lastname, email, phone, Citi_data, stateName } = e
    const Subject = this.state.value.map((arr) => arr.value)
    this.setState({
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      Citi_data: Citi_data,
      stateName: stateName,
      Subject: Subject,
    })
  }
  Step3 = () => {
    const element1 = document.getElementById('step1')
    const element2 = document.getElementById('step2')
    const element3 = document.getElementById('step3')
    // const element4 = document.getElementById("step4");
    this.addclass(element3)
    this.removeclass(
      element1,
      element2,
      // element4
    )
    this.setState({
      step1: false,
      step2: false,
      step3: true,
      // step4: false,
    })
  }
  onClick = () => {
    this.setState({ switch_State: !this.state.switch_State })
  }
  PackageSelector = (key, arr, len) => {
    for (let x = 0; x <= len; x++) {
      if (x === key) {
        const element = document.getElementById('PriceBorder-' + x)
        element.classList.add('style_border')
      } else {
        const ele = document.getElementById('PriceBorder-' + x)
        if (ele) {
          if (ele.classList.contains('style_border')) {
            ele.classList.remove('style_border')
          }
        }
      }
    }
    if (this.state.switch_State) {
      this.setState({
        PackagePrice: arr.Annual_Price,
        PackageName: arr.Name,
        PackageID: arr.id,
      })
    } else {
      this.setState({
        PackagePrice: arr.Price,
        PackageName: arr.Name,
        PackageID: arr.id,
      })
    }

    this.setState({ subscription_id: arr })
  }
  MultiSelect = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
    }
    this.setState({ school: value })
  }
  getSubjects = () => {
    const options = this.props.subjectsList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }))
    this.setState({ subjectsOption: options })
  }
  getSchool = () => {
    const options = this.props.school_list.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }))
    this.setState({ schoolOption: options })
  }
  dropdownArea = (value, { action, removedValue }) => {
    this.setState({ value: [] })
    // eslint-disable-next-line default-case
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
    }
    this.setState({ value: value })
  }
  SubmitEmailPayment = (value) => {
    const email = value.email
    const school = parseInt(localStorage.getItem('Schoolid'))
    const Requested_By = parseInt(localStorage.getItem('id'))
    const Amount = this.state.PackagePrice
    const bundle_id = this.state.PackageID
    const Requested_From_URL = window.location.href
    const name = this.state.firstName.concat(' ', this.state.lastName)
    const Subject = this.state.Subject
    const Phone = this.state.phone
    const data = {
      email,
      school,
      Requested_By,
      Amount,
      bundle_id,
      Requested_From_URL,
      name,
      Subject,
      Phone,
    }
    this.props.PayViaEmail(data)
    this.setState({ sendButton: true })
  }
  async componentDidMount() {
    this.getOption()
    this.props.getSubscription()
    await this.props.getBundle()
    await this.props.getSubject()
    await this.props.getSchool()
    setTimeout(this.getSubjects, 1000)
    setTimeout(this.getSchool, 1000)
  }
  closeable = () => {
    this.setState({ CloseAlert: false })
  }
  copytoClip = () => {
    let copyText = document.querySelector('.copy-text')
    let input = copyText.querySelector('input.text')
    navigator.clipboard.writeText(input.value)
    copyText.classList.add('active')
    window.getSelection().removeAllRanges()
    setTimeout(function () {
      copyText.classList.remove('active')
    }, 2500)
  }
  render() {
    const {
      step1,
      Clipboardhide,
      step3,
      step2,
      firstName,
      lastName,
      email,
      phone,
      switch_State,
      subjectsOption,
      checkout,
      PackagePrice,
      PackageName,
      PackageID,
      Citi_data,
      Country_state,
      stateName,
      subscription_id,
      Subject,
      sendButton,
    } = this.state

    const {
      subscriptionLoading,
      subscription,
      subscriptionSubscribe,
      bundles,
      auth,
      subscriptionLoadingSend,
      array_sub,
    } = this.props

    console.log("Price=",PackagePrice,
      "Name=",PackageName,
      "firstName=",firstName,
      "lastName=",lastName,
      "email=",email,
      "phone=",phone,
      "Annual=",switch_State,
      "PackageID=",PackageID,
      "checkout=",checkout,

      "Subject=",Subject,
      "School=",localStorage.getItem('Schoolid'),
      "user=",localStorage.getItem('id'))
    const listItem = (
      <>
        {this.state.CloseAlert ? (
          <div className="row">
            <div className="col-12">
              <Alert
                variant="danger"
                onClose={() => this.closeable()}
                dismissible
              >
                Please buy a subscription to continue
              </Alert>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="py-3 px-4 px-xl-12 border-bottom">
          <ul className="list-group flex-nowrap overflow-auto overflow-md-visble list-group-horizontal list-group-borderless flex-center-between pt-1">
            <li className="list-group-item text-center flex-shrink-0 flex-xl-shrink-1">
              <div
                id="step1"
                className="flex-content-center mb-3 width-40 height-40 border border-width-2 mx-auto rounded-circle border-primary text-white bg-primary "
              >
                1
              </div>
              <div className="text-primary">Customer information</div>
            </li>
            <li className="list-group-item text-center flex-shrink-0 flex-md-shrink-1">
              <div
                id="step2"
                className="flex-content-center mb-3 width-40 height-40 border border-width-2 border-gray mx-auto rounded-circle"
              >
                2
              </div>
              <div className="text-gray-1">Choice Subscription</div>
            </li>
            <li className="list-group-item text-center flex-shrink-0 flex-md-shrink-1">
              <div
                id="step3"
                className="flex-content-center mb-3 width-40 height-40 border border-width-2 border-gray mx-auto rounded-circle"
              >
                3
              </div>
              <div className="text-gray-1">Payment information</div>
            </li>
          </ul>
        </div>
      </>
    )
    const element1 = (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-2" />
            <div className="col-8">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                {listItem}
                <div className="pt-4 pb-5 px-5">
                  <h5 className="font-size-21 font-weight-bold text-dark mb-4">
                    Let us know who you are
                  </h5>
                  <Formik
                    validationSchema={schema}
                    onSubmit={this.Step2}
                    initialValues={{
                      firstname: auth.user.user.firstname,
                      lastname: auth.user.user.lastname,
                      email: auth.user.user.email,
                      phone: auth.user.user.phone,
                      School: auth.user.user.School.name,
                    }}
                  >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-sm-6 mb-4">
                            <div className="js-form-message">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control text-capitalize formW"
                                name="firstname"
                                placeholder="First Name"
                                value={values.firstname}
                                onChange={handleChange}
                                isInvalid={!!errors.firstname}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.firstname}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <div className="js-form-message">
                              <Form.Label>Last name</Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control text-capitalize formW"
                                name="lastname"
                                placeholder="Last Name"
                                value={values.lastname}
                                onChange={handleChange}
                                isInvalid={!!errors.lastname}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.lastname}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <div className="js-form-message">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                className="form-control formW"
                                name="email"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <div className="js-form-message">
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control formW"
                                name="phone"
                                placeholder="Phone number"
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.phone}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <div className="js-form-message">
                              <Form.Label>School</Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control text-capitalize  formW"
                                name="School"
                                disabled
                                placeholder="School"
                                value={values.School}
                                onChange={handleChange}
                                isInvalid={!!errors.School}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.School}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="w-100" />
                          <div className="col-sm-12 mb-6">
                            <div className="js-form-message">
                              <label className="form-label">Subject</label>
                            </div>
                            {subjectsOption.length !== 0 ? (
                              <Select
                                defaultValue={[subjectsOption[1]]}
                                name="colors"
                                isMulti
                                options={subjectsOption}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.dropdownArea}
                              />
                            ) : (
                              <Fragment>
                                <Spinner animation="grow" variant="warning" />
                              </Fragment>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Pick at least 1
                            </Form.Control.Feedback>
                          </div>
                          <div className="w-100" />
                          <div className="col-sm-12 align-self-end">
                            <div className="text-center">
                              <Button
                                type="submit"
                                className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                              >
                                Subscription
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-2" />
          </div>
        </div>
      </Fragment>
    )
    const element2 = (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                {listItem}
                <div>
                  <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className="container">
                      {/*begin::Card*/}
                      <div className="card">
                        {/* begin: custom background*/}
                        <div
                          className="position-absolute w-100 h-50 rounded-card-top"
                          style={{ backgroundColor: '#22B9FF' }}
                        />
                        {/* end: custom background*/}
                        <div className="card-body position-relative">
                          <h3 className="7 text-white text-center my-5 my-lg-5">
                            Ready to start with Target QLA?
                          </h3>
                          <p className="text-white text-center mb-5">
                            Choose the package that suits you!
                          </p>
                          {/* begin: Tabs*/}
                          {/*<div className="d-flex justify-content-center">*/}
                          {/*  <ul*/}
                          {/*    className="nav nav-pills nav-primary mb-10 mb-lg-20 bg-white rounded"*/}
                          {/*    id="pills-tab"*/}
                          {/*    role="tablist"*/}
                          {/*  >*/}
                          {/*    <li className="nav-item p-0 m-0">*/}
                          {/*      <button*/}
                          {/*        onClick={() =>*/}
                          {/*          this.setState({*/}
                          {/*            switch_State: !switch_State,*/}
                          {/*            active1: "active",*/}
                          {/*            active2: "",*/}
                          {/*            subscription_id: null,*/}
                          {/*            PackageID: "",*/}
                          {/*            PackageName: "",*/}
                          {/*            PackagePrice: ""*/}
                          {/*          })*/}
                          {/*        }*/}
                          {/*        className={`nav-link font-weight-bolder rounded-right-0 px-8 py-5 ${active1}`}*/}
                          {/*      >*/}
                          {/*        Monthly Plans*/}
                          {/*      </button>*/}
                          {/*    </li>*/}
                          {/*    <li className="nav-item p-0 m-0">*/}
                          {/*      <button*/}
                          {/*        onClick={() =>*/}
                          {/*          this.setState({*/}
                          {/*            switch_State: !switch_State,*/}
                          {/*            active2: "active",*/}
                          {/*            active1: "",*/}
                          {/*            subscription_id: null,*/}
                          {/*            PackageID: "",*/}
                          {/*            PackageName: "",*/}
                          {/*            PackagePrice: ""*/}
                          {/*          })*/}
                          {/*        }*/}
                          {/*        className={`nav-link font-weight-bolder rounded-right-0 px-8 py-5 ${active2}`}*/}
                          {/*      >*/}
                          {/*        Annual Plans*/}
                          {/*      </button>*/}
                          {/*    </li>*/}
                          {/*  </ul>*/}
                          {/*</div>*/}
                          {/* end: Tabs*/}
                          <div className="tab-content">
                            {/* begin: Tab pane*/}
                            <div
                              className="tab-pane show active row text-center"
                              id="kt-pricing-2_content1"
                              role="tabpanel"
                              aria-labelledby="pills-tab-1"
                            >
                              <div className="card-body bg-white col-11 col-lg-12 col-xxl-10 mx-auto">
                                <div className="row">
                                  {this.props.bundles.map((arr, key) => (
                                    <div key={key} className="col-md-4">
                                      <div
                                        id={'PriceBorder-' + key}
                                        className="pt-30 pt-md-25 pb-15 px-5 text-center"
                                      >
                                        {/*begin::Icon*/}
                                        <div className="d-flex flex-center position-relative mb-25">
                                          <span className="svg svg-fill-primary opacity-4 position-absolute">
                                            <svg width={175} height={200}>
                                              <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" />
                                            </svg>
                                          </span>
                                          <span className="svg-icon svg-icon-5x svg-icon-primary">
                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Home/Flower3.svg*/}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth={1}
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path
                                                  d="M1.4152146,4.84010415 C11.1782334,10.3362599 14.7076452,16.4493804 12.0034499,23.1794656 C5.02500006,22.0396582 1.4955883,15.9265377 1.4152146,4.84010415 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                                <path
                                                  d="M22.5950046,4.84010415 C12.8319858,10.3362599 9.30257403,16.4493804 12.0067693,23.1794656 C18.9852192,22.0396582 22.5146309,15.9265377 22.5950046,4.84010415 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                                <path
                                                  d="M12.0002081,2 C6.29326368,11.6413199 6.29326368,18.7001435 12.0002081,23.1764706 C17.4738192,18.7001435 17.4738192,11.6413199 12.0002081,2 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                          </span>
                                        </div>
                                        {/*end::Icon*/}
                                        {/*begin::Content*/}
                                        <h4 className="font-size-h3 mb-10">
                                          {arr.Name}
                                        </h4>
                                        <div className="d-flex flex-column line-height-xl pb-10">
                                          <ListPage data={arr.Point} />
                                        </div>
                                        <span className="font-size-h1 d-block font-weight-boldest text-dark">
                                          {arr.Annual_Price}
                                          {/*{switch_State === false*/}
                                          {/*  ? arr.Price*/}
                                          {/*  : arr.Annual_Price}*/}
                                          <sup className="font-size-h3 font-weight-normal pl-1">
                                            £
                                          </sup>
                                        </span>
                                        <span>Annually</span>
                                        <div className="mt-7">
                                          <button
                                            onClick={() =>
                                              this.PackageSelector(
                                                key,
                                                arr,
                                                this.props.bundles.length,
                                              )
                                            }
                                            type="button"
                                            className="btn btn-primary text-uppercase font-weight-bolder px-15 py-3"
                                          >
                                            Purchase
                                          </button>
                                        </div>
                                        {/*end::Content*/}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* end: Tab pane*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Card*/}
                    </div>
                    {/*end::Container*/}
                  </div>

                  <div className="row container">
                    <div className="w-100" />
                    <div
                      className="col-sm-6 align-self-end"
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                      <div className="text-left">
                        <button
                          onClick={() => this.Step1()}
                          className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                        >
                          BACK PAGE
                        </button>
                      </div>
                    </div>
                    <div
                      className="col-sm-6 align-self-end"
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                      <div className="text-right">
                        <button
                          disabled={
                            subscription_id === null ||
                            PackageID === '' ||
                            PackageName === '' ||
                            PackagePrice === ''
                          }
                          onClick={() => this.Step3()}
                          className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                        >
                          NEXT PAGE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
    const element3 = (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                {listItem}

                <div className="pt-4 pb-5 px-5">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-4"
                  >
                    Your Card Information
                  </h5>

                  <ul
                    className="nav nav-classic nav-choose border-0 nav-justified border mx-n3"
                    role="tablist"
                  >
                    <li className="nav-item mx-3">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a id="pills-two-example2-tab">
                        <div className="width-25 flex-content-center bg-primary rounded-circle position-absolute left-0 top-0 ml-2 mt-2">
                          <i className="flaticon-tick text-white font-size-15" />
                        </div>
                        <div className="d-md-flex justify-content-md-center align-items-md-center flex-wrap">
                          <div className="row mt-5">
                            <div className="col-12 text-center">
                              <h4>Please Select Payment Method</h4>
                            </div>
                            <div className="col-6 text-center">
                              {checkout === 'Paypal' ? (
                                <Paypal
                                  Price={PackagePrice}
                                  Name={PackageName}
                                  firstName={firstName}
                                  lastName={lastName}
                                  email={email}
                                  phone={phone}
                                  Annual={switch_State}
                                  PackageID={PackageID}
                                  checkout={checkout}
                                  Citi_data={Citi_data}
                                  Country_state={Country_state}
                                  stateName={stateName}
                                  Subject={Subject}
                                  School={localStorage.getItem('Schoolid')}
                                  user={localStorage.getItem('id')}
                                />
                              ) : (
                                <button
                                  onClick={() =>
                                    this.setState({ checkout: 'Paypal' })
                                  }
                                >
                                  <img src={paypalimg} alt="paypal img" />
                                </button>
                              )}
                            </div>
                            <div className="col-6 text-center">
                              {checkout === 'Stripe' ? (
                                <Base
                                  Price={PackagePrice}
                                  Name={PackageName}
                                  firstName={firstName}
                                  lastName={lastName}
                                  email={email}
                                  phone={phone}
                                  Annual={switch_State}
                                  PackageID={PackageID}
                                  checkout={checkout}
                                  Citi_data={Citi_data}
                                  Country_state={Country_state}
                                  stateName={stateName}
                                  School={localStorage.getItem('Schoolid')}
                                  Subject={Subject}
                                  user={localStorage.getItem('id')}
                                />
                              ) : (
                                <button
                                  onClick={() =>
                                    this.setState({ checkout: 'Stripe' })
                                  }
                                >
                                  <img
                                    style={{ height: '40px' }}
                                    src={stripelogo}
                                    alt="stripe img"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <hr className="hrClass" />
                          <div className="col-12 mt-5 mb-5 text-center">
                            <h6>Send Payment Link to Finance Team</h6>
                          </div>
                          <Formik
                            validationSchema={schemaEMAIL}
                            onSubmit={(values) =>
                              this.SubmitEmailPayment(values)
                            }
                            initialValues={{
                              email: email,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              values,
                              touched,
                              errors,
                            }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <div className="row ">
                                  <div className="col-1"></div>
                                  <div className="col-sm-6 mb-4 ">
                                    <Form.Control
                                      type="email"
                                      className="form-control formW"
                                      name="email"
                                      placeholder="Enter an email address"
                                      onChange={handleChange}
                                      isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </div>
                                  <div className="col-sm-4">
                                    <button
                                      disabled={sendButton}
                                      type="submit"
                                      className="btn btn-warning btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                                    >
                                      {subscriptionLoadingSend ? (
                                        <Spinner
                                          animation="border"
                                          variant="success"
                                        />
                                      ) : (
                                        'Send to Team'
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div className="row mt-5">
                    <div className="w-100" />
                    <div className="col-sm-6 align-self-end">
                      <div className="text-left">
                        <button
                          onClick={() => this.Step2()}
                          className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                        >
                          BACK PAGE
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6 align-self-end">
                      <div className="text-right">
                        <button
                          disabled={!subscriptionSubscribe}
                          onClick={() => this.Step4()}
                          className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                        >
                          NEXT PAGE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {array_sub.length !== 0 ? (
          <Modal
            show={Clipboardhide}
            onHide={() => this.setState({ Clipboardhide: false })}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Payment Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="container_mine text-center">
                  <div className="row">
                    <div className="col-12">
                      <div className="label_mine">Copy Link to clipboard</div>
                      <p>
                        You can copy and share the link directly with your
                        finance Team, We have already send an email ✉️
                      </p>
                      <div className="copy-text">
                        <input
                          type="text"
                          className="text"
                          value={array_sub.URL}
                        />
                        <button onClick={() => this.copytoClip()}>
                          <i className="fa fa-clone colorwhite" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <></>
        )}
      </Fragment>
    )
    const loading = (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-5   rounded-sm">
                <div className="pt-4 pb-5 px-5" style={{ marginTop: 300 }}>
                  <Waiting message="Please Wait While we are checking your Subscription!" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )

    if (subscriptionLoading) {
      return loading
    } else if (subscription.length === 0) {
      if (step1 === true) {
        return element1
      } else if (step2 === true) {
        return element2
      } else if (step3 === true) {
        return element3
      }
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                {subscription.length !== 0
                  ? subscription.map((arr, key) => (
                      <div key={key} className="d-flex flex-column-fluid">
                        {/*begin::Container*/}
                        <div className="container">
                          {/* begin::Card*/}
                          <div className="card card-custom overflow-hidden">
                            <div className="card-body p-0">
                              {/* begin: Invoice*/}
                              {/* begin: Invoice header*/}
                              <div
                                className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0"
                                style={{ backgroundImage: `url(${Imagex})` }}
                              >
                                <div className="col-md-9">
                                  <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                    <h1 className="display-4 text-white font-weight-boldest mb-10">
                                      INVOICE
                                    </h1>
                                    <div className="d-flex flex-column align-items-md-end px-0">
                                      {/*begin::Logo*/}
                                      <span className="mb-5 navbar-brand text-black">
                                        Target QLA
                                        {/*<img*/}
                                        {/*  src={Logo}*/}
                                        {/*  alt="..."*/}
                                        {/*  height="50px"*/}
                                        {/*/>*/}
                                      </span>
                                      {/*end::Logo*/}
                                      <span className="text-white d-flex flex-column align-items-md-end opacity-70">
                                        <span>
                                          221-B Baker St, London NW1 6XE,
                                        </span>
                                        <span>United Kingdom</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="border-bottom w-100 opacity-20" />
                                  <div className="d-flex justify-content-between text-white pt-6">
                                    <div className="d-flex flex-column flex-root">
                                      <span className="font-weight-bolde mb-2r">
                                        DATE
                                      </span>
                                      <span className="opacity-70">
                                        <Moment>{arr.TimeStart}</Moment>
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column flex-root">
                                      <span className="font-weight-bolder mb-2">
                                        INVOICE NO.
                                      </span>
                                      <span className="opacity-70">
                                        {arr.Transaction_ID}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column flex-root">
                                      <span className="font-weight-bolder mb-2">
                                        INVOICE TO.
                                      </span>
                                      <span className="opacity-70 text-capitalize">
                                        Name:&nbsp;{arr.firstName}&nbsp;
                                        {arr.lastName}
                                        &nbsp;
                                        <br />
                                        Address:&nbsp;{arr.Citi_data}&nbsp;
                                        {arr.stateName}&nbsp;
                                        {arr.Country_state}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end: Invoice header*/}
                              {/* begin: Invoice body*/}
                              <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="pl-0 font-weight-bold text-muted text-uppercase">
                                            Description
                                          </th>
                                          <th className="text-center font-weight-bold text-muted text-uppercase">
                                            Valid till
                                          </th>

                                          <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                                            Amount:
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <TableRowInvoice
                                          subscription={subscription}
                                          bundles={bundles}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              {/* end: Invoice body*/}
                              {/* begin: Invoice footer*/}
                              <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                  <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                                    <div className="d-flex flex-column mb-10 mb-md-0">
                                      <div className="d-flex justify-content-between mb-3">
                                        <span className="mr-15 font-weight-bold">
                                          Paid Via:
                                        </span>
                                        <span className="text-right font-weight-bolder ">
                                          {arr.PaymentMethod}
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between mb-3">
                                        <span className="mr-15 font-weight-bold">
                                          Account Name:
                                        </span>
                                        <span className="text-right text-capitalize">
                                          {arr.firstName}
                                          &nbsp;{arr.lastName}
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between mb-3">
                                        <span className="mr-15 font-weight-bold">
                                          Account Email:
                                        </span>
                                        <span className="text-right">
                                          {arr.email}
                                        </span>
                                      </div>
                                      {/*<div className="d-flex justify-content-between">*/}
                                      {/*      <span className="mr-15 font-weight-bold">*/}
                                      {/*        Payer ID:*/}
                                      {/*      </span>*/}
                                      {/*  <span className="text-right">*/}
                                      {/*        {arr.order_payer_payer_id}*/}
                                      {/*      </span>*/}
                                      {/*</div>*/}
                                    </div>
                                    <div className="d-flex flex-column text-md-right">
                                      <span className="font-size-lg font-weight-bolder mb-1">
                                        TOTAL AMOUNT
                                      </span>
                                      <span className="font-size-h2 font-weight-boldest text-danger mb-1">
                                        £ {arr.Price}
                                        {/*/-*/}
                                        &nbsp; {/*{*/}
                                        {/*  arr.order_purchase_units_amount_currency_code*/}
                                        {/*}*/}
                                      </span>
                                      <span>Taxes Included</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end: Invoice footer*/}
                              {/* begin: Invoice action*/}
                              <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                  <div className="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      className="btn btn-light-primary font-weight-bold"
                                      onClick={() => window.print()}
                                    >
                                      Download Invoice
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary font-weight-bold"
                                      onClick={() => window.print()}
                                    >
                                      Print Invoice
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* end: Invoice action*/}
                              {/* end: Invoice*/}
                            </div>
                          </div>
                          {/* end::Card*/}
                        </div>
                        {/*end::Container*/}
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bundles: state.bundles.bundles,
  school_list: state.school_list.school_list,
  subjectsList: state.subjectsList.subjectsList,
  subscription: state.subscription.subscription,
  subscriptionLoading: state.subscription.isLoading,
  subscriptionSubscribe: state.subscription.subscribe,
  subscriptionLoadingSend: state.subscription.isLoadingSend,
  array_sub: state.subscription.array_sub,
})
export default connect(mapStateToProps, {
  getBundle,
  getSubject,
  getSchool,
  getSubscription,
  PayViaEmail,
})(PaymentPage)
