import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropType from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import downloadSvg from "../../assets/media/svg/icons/Files/Download.svg";
import { getQuestionPaper } from "../../actions/QuestionPaper/QuestionPaperAction";

class QuestionPaperData extends Component {
  static propTypes = {
    QuestionPaper: PropType.array.isRequired,
    getQuestionPaper: PropType.func.isRequired,
    QuestionPaperLoading: PropType.bool,
  };

  componentDidMount() {
    this.props.getQuestionPaper();
  }

  render() {
    const { QuestionPaper, QuestionPaperLoading } = this.props;

    return (
      <Fragment>
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {(() => {
                  if (QuestionPaperLoading) {
                    return (
                      <Fragment>
                        {" "}
                        <Spinner animation="border" variant="success" />
                        &nbsp;Loading Exams data
                      </Fragment>
                    );
                  } else {
                    return <div>Exams</div>;
                  }
                })()}
              </span>
            </h3>
          </div>
          <div className="card-body py-0">
            {(() => {
              if (QuestionPaperLoading) {
                return <Fragment />;
              } else {
                return (
                  <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center">
                      <thead>
                        <tr className="text-left">
                          <th className="pl-0" style={{ width: "30px" }}>
                            <label className="checkbox checkbox-lg checkbox-inline mr-2">
                              <input type="checkbox" defaultValue={1} />
                              <span />
                            </label>
                          </th>
                          <th className="pl-0" style={{ minWidth: "10px" }}>
                            id
                          </th>
                          <th style={{ minWidth: "100px" }}>Subject</th>
                          <th style={{ minWidth: "120px" }}>Name</th>
                          <th style={{ minWidth: "120px" }}>Paper Reference</th>
                          <th style={{ minWidth: "120px" }}>Paper Code</th>
                          <th style={{ minWidth: "10px" }}>Length</th>
                          <th style={{ minWidth: "120px" }}>Date</th>
                          <th style={{ minWidth: "120px" }}>Exam Board</th>
                          <th style={{ minWidth: "120px" }}>Exam</th>
                          <th style={{ minWidth: "10px" }}>Total Marks</th>
                          <th style={{ minWidth: "10px" }}>Total Question</th>
                          <th style={{ minWidth: "120px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {QuestionPaper !== undefined
                          ? QuestionPaper.map((arr, key) => (
                              <tr key={key}>
                                <td className="pl-0 py-6">
                                  <label className="checkbox checkbox-lg checkbox-inline">
                                    <input type="checkbox" defaultValue={1} />
                                    <span />
                                  </label>
                                </td>
                                <td className="pl-0">
                                  <span className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                                    {arr.id}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.subject.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.paper_reference}
                                  </span>
                                </td>
                                {/*<td>*/}
                                {/*  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">*/}
                                {/*    <JSONPretty id="json-pretty" data={arr.grade_boundaries}></JSONPretty>*/}
                                {/*  </span>*/}
                                {/*</td>*/}
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.paper_code}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.length}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.date}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.examBoard.fullname}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.exam.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.total_marks}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {arr.total_questions}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    href={arr.pdf}
                                  >
                                    <img
                                      src={downloadSvg}
                                      style={{ height: "20px", width: "50px" }}
                                      alt="download Svg"
                                    />
                                  </Button>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  QuestionPaper: state.QuestionPaper.QuestionPaper,
  QuestionPaperLoading: state.QuestionPaper.isLoading,
});
export default connect(mapStateToProps, { getQuestionPaper })(
  QuestionPaperData
);
