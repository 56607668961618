import axios from "axios";
import {
  GET_SCHOOLMAP_ID_LOADING,
  GET_SCHOOLMAP_ID,
  GET_SCHOOLMAP_ID_FAIL,
  UPDATE_SCHOOLMAP_ID,
  UPDATE_SCHOOLMAP_ID_LOADING,
  UPDATE_SCHOOLMAP_ID_FAIL,
} from "../types";
import toast from "react-hot-toast";

import { returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
export const getSchoolMapID = (id) => (dispatch, getState) => {
  dispatch({ type: GET_SCHOOLMAP_ID_LOADING });
  axios
    .get(`QLA/UserSchoolID/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SCHOOLMAP_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_SCHOOLMAP_ID_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const updateSchoolMapID = (id, data) => (dispatch, getState) => {
  const TID = toast.loading("Updating...⏳");
  // dispatch({ type: UPDATE_SCHOOLMAP_ID_LOADING });
  axios
    .patch(`QLA/UserSchool/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_SCHOOLMAP_ID,
        payload: res.data,
      });
      toast.success("User updated!", {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error("Oops! Something went wrong", {
        id: TID,
      });
      dispatch({ type: UPDATE_SCHOOLMAP_ID_FAIL });
      dispatch(returnErrors(err.response.data), err.response.status);
    });
};
