import axios from "axios";
import { tokenConfig } from "../auth_action/auth";

import {
  RESEND_EMAIL,
  RESEND_EMAIL_LOADING,
  RESEND_EMAIL_FAIL,
} from "../types";
import toast from "react-hot-toast";

export const ResendEmailOTP = (id) => (dispatch, getState) => {
  const TID = toast.loading("Resending Email...📨");
  dispatch({ type: RESEND_EMAIL_LOADING });
  axios
    .get(`EmailServices/ResendEmail/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RESEND_EMAIL,
        payload: res.data,
      });
      toast.success(res.data.message, {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      dispatch({ type: RESEND_EMAIL_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};

export const RegenerateOTP = (id) => (dispatch, getState) => {
  const TID = toast.loading("Regenerating Email...📨");
  dispatch({ type: RESEND_EMAIL_LOADING });
  axios
    .get(`EmailServices/ResendOTP/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RESEND_EMAIL,
        payload: res.data,
      });
      toast.success(res.data.message, {
        id: TID,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.detail, {
        id: TID,
      });
      dispatch({ type: RESEND_EMAIL_FAIL });
      //   dispatch(returnErrors(err.response.data), err.response.status);
    });
};
