import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  USER_LOADING,
  USER_LOADED,
  AUTH_ERRORS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_LOADING,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_LOADING,
} from "../actions/types";
// import CryptoJS from "crypto-js";

const initialState = {
  user: null,
  permissions: null,
  groups: null,
  isLoading: false,
  MemberShipDate: null,
  isAuthenticated: null,
  MembershipExpired: true,
  isLoadingRegister: false,
  isLoadingPassword: false,
  isLoadingChange: false,
  MembershipExpiredLoading: true,
  token: localStorage.getItem("token"),
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_INFO_LOADING:
      return {
        ...state,
        isLoadingChange: true,
      };
    case UPDATE_USER_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        MembershipExpiredLoading: false,
        MembershipExpired: true,
        isLoadingChange: false,
        MemberShipDate: null,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        isAuthenticated: true,
        isLoadingChange: false,
        MembershipExpiredLoading: false,
        MemberShipDate: action.payload.MemberShipValidTill,
        MembershipExpired: action.payload.MembershipExpired,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        isLoadingPassword: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        isLoadingPassword: false,
      };
    case USER_LOADED:
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("Name", action.payload.user.firstname);
      localStorage.setItem("type_of_user", action.payload.user.type_of_user);
      localStorage.setItem("id", action.payload.user.id);
      localStorage.setItem("pic", action.payload.user.pic);
      localStorage.setItem("email", action.payload.user.email);
      return {
        ...state,
        user: action.payload,
        groups: action.payload.user.groups,
        permissions: action.payload.user.user_permissions,
        isAuthenticated: true,
        isLoading: false,
        MembershipExpiredLoading: false,
        MembershipExpired: action.payload.MembershipExpired,
        MemberShipDate: action.payload.MemberShipValidTill,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("Name", action.payload.user.firstname);
      localStorage.setItem("type_of_user", action.payload.user.type_of_user);
      localStorage.setItem("id", action.payload.user.id);
      localStorage.setItem("pic", action.payload.user.pic);
      localStorage.setItem("email", action.payload.user.email);
      if (action.payload.user.School !== null) {
        localStorage.setItem("Schoolid", action.payload.user.School.id);
        localStorage.setItem("SchoolName", action.payload.user.School.name);
      }
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        MembershipExpiredLoading: false,
        user: action.payload,
        MembershipExpired: action.payload.MembershipExpired,
        MemberShipDate: action.payload.MemberShipValidTill,
      };
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case AUTH_ERRORS:
      localStorage.clear();
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("Name");
      localStorage.removeItem("type_of_user");
      localStorage.removeItem("id");
      localStorage.removeItem("pic");
      localStorage.removeItem("Schoolid");
      localStorage.removeItem("SchoolName");
      localStorage.removeItem("email");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
