import {
  GET_MOCK_EXAM,
  ADD_MOCK_EXAM,
  DELETE_MOCK_EXAM,
  UPDATE_MOCK_EXAM,
  MOCK_EXAM_LOADING,
  MOCK_EXAM_FAIL,
  GET_MOCK_EXAM_FILTER,
  ADD_MOCK_EXAM_LOADING,
  ADD_MOCK_EXAM_FAIL, ADD_MOCK_EXAM_TEST,Marks_Added_CHECKED
} from "../actions/types";

const initialState = {
  isLoading: false,
  isLoadingAdd: false,
  MockList: [],
  Mock_Added_loading: false,
  Mock_added_fail: false,
  Marks_Added:false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_MOCK_EXAM_LOADING:
      return {
        ...state,
        Mock_Added_loading: true,
        Mock_added_fail: false,
        Marks_Added:false,

      };
    case ADD_MOCK_EXAM_TEST:
      return{
        ...state,
        Mock_Added_loading: false,
        Mock_added_fail: false,
        Marks_Added:true,

      }
    case ADD_MOCK_EXAM_FAIL:
      return {
        ...state,
        Mock_Added_loading: false,
        Mock_added_fail: true,
        Marks_Added:false,

      };
    case GET_MOCK_EXAM:
    case GET_MOCK_EXAM_FILTER:
      return {
        ...state,
        MockList: action.payload,
        isLoading: false,
        Mock_Added_loading: false,
        Mock_added_fail: false,
        Marks_Added:false,

      };

    case MOCK_EXAM_LOADING:
      return {
        ...state,
        isLoading: true,
        Mock_Added_loading: false,
        Mock_added_fail: false,
        Marks_Added:false,

      };
    case DELETE_MOCK_EXAM:
      return {
        ...state,
        Mock_Added_loading: false,
        Mock_added_fail: false,
        Marks_Added:false,
        MockList: state.MockList.filter((about) => about.id !== action.payload),
      };
    case Marks_Added_CHECKED:
      return {
        ...state,
        Marks_Added:false,

      }
    case MOCK_EXAM_FAIL:
      return {
        ...state,
        isLoading: false,
        Mock_Added_loading: false,
        Marks_Added:false,
        Mock_added_fail: false,
      };
    case UPDATE_MOCK_EXAM:
      return {
        ...state,
        Mock_Added_loading: false,
        Marks_Added:false,

        Mock_added_fail: false,
        MockList: state.MockList.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case ADD_MOCK_EXAM:
      return {
        ...state,
        isLoading: false,
        Mock_Added_loading: false,
        Mock_added_fail: false,
        Marks_Added:false,

        MockList: [...state.MockList, action.payload],
      };
    default:
      return state;
  }
}
