import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import downloadSvg from "../../assets/media/svg/icons/Files/Download.svg";
import { getStudentReport } from "../../actions/StudentMarks/StudentMarksAction";

class ReportData extends Component {
  static propTypes = {
    getSubject: PropTypes.func.isRequired,
    subjectsList: PropTypes.array.isRequired,
    getStudentReport: PropTypes.func.isRequired,
    StudentMarksList: PropTypes.array.isRequired,
    subjectsListLoading: PropTypes.bool.isRequired,
    StudentMarksListLoading: PropTypes.bool.isRequired,
  };
  state = {
    subject: undefined,
    search:null
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  FindStudent = (e) => {
    e.preventDefault(e);
    const schoolid = localStorage.getItem("Schoolid");
    if (
      this.state.subject !== undefined &&
      localStorage.getItem("Schoolid") !== null
    ) {
      this.props.getStudentReport(schoolid, this.state.subject);
    } else {
      e.stopPropagation();
      window.alert("Error");
    }
  };

  componentDidMount() {
    this.props.getSubject();
  }

  render() {
    const { subjectsList, StudentMarksList } = this.props;
    const { subject } = this.state;
    return (

        <div className="card card-custom">
          <div className="card-body text-center">

            <div className="mb-10">
              <div className="row align-items-center">
                <div className="col-lg-9 col-xl-8">
                  <div className="row align-items-center">
                    {/*<div className="col-md-4 my-2 my-md-0">*/}
                    {/*  /!*<div className="input-icon">*!/*/}
                    {/*  /!*  <input*!/*/}
                    {/*  /!*    type="text"*!/*/}
                    {/*  /!*    name="search"*!/*/}
                    {/*  /!*    onChange={(e)=>this.onChange(e)}*!/*/}
                    {/*  /!*    className="form-control form-control-solid"*!/*/}
                    {/*  /!*    placeholder="Search..."*!/*/}
                    {/*  /!*    id="kt_datatable_search_query"*!/*/}
                    {/*  /!*  />*!/*/}
                    {/*  /!*  <span>*!/*/}
                    {/*  /!*    <i className="flaticon2-search-1 text-muted" />*!/*/}
                    {/*  /!*  </span>*!/*/}
                    {/*  /!*</div>*!/*/}
                    {/*</div>*/}
                    <div className="col-md-4 my-2 my-md-0">
                      <select
                        name="subject"
                        onChange={(e) => this.onChange(e)}
                        className="form-control form-control-solid"
                        id="kt_datatable_search_status"
                      >
                        <option selected disabled>
                          ---Select Subject---
                        </option>
                        {subjectsList.map((arr, key) => (
                          <option key={key} value={arr.id}>
                            {arr.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                  <button
                    onClick={(e) => this.FindStudent(e)}
                    disabled={subject === undefined}
                    className="btn btn-light-primary px-6 font-weight-bold"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            {/*begin::Table*/}
            <div className="table-responsive">
              <table className="table table-head-custom table-vertical-center">
                <thead>
                  <tr className="text-left">
                    <th className="pl-0" style={{ width: "30px" }}>
                      <label className="checkbox checkbox-lg checkbox-inline mr-2">
                        <input type="checkbox" defaultValue={1} />
                        <span />
                      </label>
                    </th>
                    <th className="pl-0" style={{ minWidth: "10px" }}>
                      #
                    </th>
                    <th style={{ minWidth: "120px" }}>Name</th>
                    <th style={{ minWidth: "100px" }}>Subject(s)</th>
                    <th style={{ minWidth: "120px" }}>Paper Code</th>
                    <th style={{ minWidth: "120px" }}>Date</th>
                    <th style={{ minWidth: "120px" }}>Exam Board</th>
                    <th style={{ minWidth: "120px" }}>Exam</th>
                    <th style={{ minWidth: "10px" }}>Total Marks</th>
                    <th style={{ minWidth: "10px" }}>Status</th>
                    <th style={{ minWidth: "120px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {StudentMarksList !== undefined ||
                  StudentMarksList.length !== 0
                    ? StudentMarksList.map((arr, key) => (
                        <tr key={key}>
                          <td className="pl-0 py-6">
                            <label className="checkbox checkbox-lg checkbox-inline">
                              <input type="checkbox" defaultValue={1} />
                              <span />
                            </label>
                          </td>
                          <td className="pl-0">
                            <a
                              href="#"
                              className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                            >
                              {key + 1}
                            </a>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.StudentID.firstname}&nbsp;
                              {arr.StudentID.surname}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.SubjectID.name}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.id}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.Exam.exam_date}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.Exam.examBoard.name}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.Exam.name}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {arr.TotalMarks}
                            </span>
                          </td>
                          <td>
                            <span className="label label-lg label-light-success label-inline">
                              {arr.Status}
                            </span>
                          </td>
                          <td>
                            <Button
                              variant="outline-primary"
                              href="https://docs.google.com/document/d/1tgvcKb2IwdZQomle9BAgy4Uahtad515K0_YeQjRv7RA/edit#heading=h.vpmhmrz1y2kc"
                            >
                              <img
                                src={downloadSvg}
                                style={{ height: "20px", width: "50px" }}
                                alt="download Svg"
                              />
                            </Button>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            {/*end::Table*/}
          </div>
          {/*end::Body*/}
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  StudentMarksListLoading: state.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  StudentMarksList: state.StudentMarksList.StudentMarksList,
});
export default connect(mapStateToProps, { getStudentReport, getSubject })(
  ReportData
);
