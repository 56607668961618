import {
  GET_USER,
  LOADING_USER,
  DELETE_USER_FAIL,
  DELETE_USER,
  LOAD_DELETE_USER,
  UPDATE_USER_NEW,
  UPDATE_USER_NEW_LOADING,
  UPDATE_USER_NEW_FAIL,
} from "../actions/types";

const initialState = {
  UsersList: [],
  isLoading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USER:
    case LOAD_DELETE_USER:
    case UPDATE_USER_NEW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER:
      return {
        ...state,
        isLoading: false,
        UsersList: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        isLoading: false,
        UsersList: state.UsersList.filter((user) => user.id !== action.payload),
      };
    case DELETE_USER_FAIL:
    case UPDATE_USER_NEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_USER_NEW:
      return {
        ...state,
        isLoading: false,
        UsersList: state.UsersList.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        }),
      };
    default:
      return state;
  }
}
