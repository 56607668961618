import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADD_DOWNLOADS_ZIP,
  DELETE_DOWNLOADS_ZIP,
  UPDATE_DOWNLOADS_ZIP,
  DOWNLOADS_ZIP_LOADING,
  DOWNLOADS_ZIP_FAIL,
  GET_DOWNLOADS_ZIP,
} from "../types";

export const getDownloads = () => (dispatch, getState) => {
  dispatch({ type: DOWNLOADS_ZIP_LOADING });
  axios
    .get("QLA/Downloads/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_DOWNLOADS_ZIP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: DOWNLOADS_ZIP_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getDownloadUnique = (UniqueID) => (dispatch, getState) => {
  dispatch({ type: DOWNLOADS_ZIP_LOADING });
  axios
    .get(`QLA/GetDownloads/${UniqueID}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_DOWNLOADS_ZIP,
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log("hello world",err)
      dispatch({ type: DOWNLOADS_ZIP_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const deleteDownloads = (id) => (dispatch, getState) => {
  dispatch({ type: DOWNLOADS_ZIP_LOADING });

  axios
    .delete(`QLA/Downloads/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteLead: "Downloads Delete" }));
      dispatch({
        type: DELETE_DOWNLOADS_ZIP,
        payload: id,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addDownloads = (data) => (dispatch, getState) => {
  dispatch({ type: DOWNLOADS_ZIP_LOADING });

  axios
    .post("QLA/Downloads/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Downloads Added" }));
      dispatch({
        type: ADD_DOWNLOADS_ZIP,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const updateDownloads = (id, data) => (dispatch, getState) => {
  dispatch({ type: DOWNLOADS_ZIP_LOADING });

  axios
    .put(`QLA/Downloads/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Downloads Updated!" }));
      dispatch({
        type: UPDATE_DOWNLOADS_ZIP,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
