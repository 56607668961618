import React, {Component} from "react";

class Team extends Component {
    render() {
        return (
            <div>
                Team
            </div>
        );
    }
}

export default Team