import React, { Fragment } from "react";
import Moment from "react-moment";

export default function TableRowInvoice(props) {
  return (
    <Fragment>
      {props.bundles
        .filter((arr) => arr.id === props.subscription[0].bundle)
        .map((arr, key) => (
          <tr key={key} className="font-weight-boldest font-size-lg">
            <td className="pl-0 pt-7">{arr.Name}</td>
            <td className="text-center pt-7">
              <Moment>{props.subscription[0].EndDate}</Moment>
            </td>
            <td className="text-right pt-7">£{arr.Annual_Price}</td>
          </tr>
        ))}
    </Fragment>
  );
}
