import {
  ADD_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  Remove_SCHOOL,
  SUBSCRIPTION_FAIL,
  SUBSCRIPTION_LOADING,
  // PAY_VIA_EMAIL_ERROR,
  PAY_VIA_EMAIL,
  PAY_LOADING,
  SUBVIATID,
  SUBVIATIDFAIL,
  SUBVIATID_LOADING,
} from "../actions/types";

const initialState = {
  subscription: [],
  array_sub: [],
  array_sub_TID: undefined,
  isLoading: true,
  subscribe: false,
  isLoadingSend: false,
  isLoading_SubViaTID: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PAY_LOADING:
      return {
        ...state,
        isLoadingSend: true,
      };
    case CHECK_SUBSCRIPTION:
      localStorage.setItem("Subject", action.payload.Subject.name);
      localStorage.setItem("TimeStart", action.payload.TimeStart);
      localStorage.setItem("EndDate", action.payload.EndDate);
      localStorage.setItem("School", action.payload.School.name);
      return {
        ...state,
        ...action.payload,
        subscription: action.payload,
        isLoading: false,
      };
    case ADD_SUBSCRIPTION:
      return {
        ...state,
        // subscription: [...state.subscription, action.payload],
        subscription: action.payload,

        isLoading: false,
        subscribe: true,
      };
    case SUBSCRIPTION_FAIL:
      return {
        ...state,
        isLoading: false,
        subscribe: false,
      };
    case Remove_SCHOOL:
      localStorage.removeItem("TimeStart");
      localStorage.removeItem("EndDate");
      localStorage.removeItem("Subject");
      localStorage.removeItem("School");
      return {
        ...state,
        ...action.payload,
      };
    case PAY_VIA_EMAIL:
      return {
        ...state,
        ...action.payload,
        isLoadingSend: false,
        array_sub: action.payload,
      };
    // Check subscription via Trans ID
    case SUBVIATID:
      return {
        ...state,
        array_sub_TID:action.payload,
        isLoading_SubViaTID: false,
      };
    case SUBVIATID_LOADING:
      return {
        ...state,
        isLoading_SubViaTID: true,
      };
    case SUBVIATIDFAIL:
      return {
        ...state,
        isLoading_SubViaTID: false,
      };
    default:
      return state;
  }
}
