import { Fragment, useEffect, useState } from "react";
const LiveClock = () => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);
  return (
    <Fragment>
      {dateState.toLocaleDateString("en-US", {
        timeZone: "Europe/London",
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric"
      })}
      {" "}
      {dateState.toLocaleString("en-US", {
        timeZone: "Europe/London",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      })}
    </Fragment>
  );
};
export default LiveClock;
