import axios from "axios";
import { createMessage, returnErrors } from "../message/message";
import { tokenConfig } from "../auth_action/auth";
import {
  ADDING_MOCK_RESULTS,
  MOCK_RESULT_LOADING,
  MOCK_RESULT_FAILED,
  DELETE_MOCK_RESULTS,
  DELETE_MOCK_RESULT_LOADING,
  DELETE_MOCK_RESULT_FAILED,
  GENERATE_TOPIC_PAPER_NEW,
  GENERATE_TOPIC_PAPER_NEW_LOADING,
  GENERATE_TOPIC_PAPER_NEW_FAILED,
  NEW_MOCK_EXAM_FAIL,
  NEW_MOCK_EXAM_LOADING,
  GET_NEW_MOCK_EXAM,
} from "../types";
import toast from "react-hot-toast";

export const MockResultSave = (data) => (dispatch, getState) => {
  const TID = toast.loading("Saving mock exam...⏳");

  dispatch({ type: MOCK_RESULT_LOADING });
  axios
    .post("QLA/MockExamResultSave/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Mock exam created", TID }));
      dispatch({
        type: ADDING_MOCK_RESULTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_RESULT_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status, TID));
    });
};

export const MockResultDelete = (id) => (dispatch, getState) => {
  const TID = toast.loading("deleting mock exam...⏳");
  dispatch({ type: DELETE_MOCK_RESULT_LOADING });
  axios
    .delete(`QLA/MockExamResultDummyData/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Mock exam deleted", TID }));
      dispatch({
        type: DELETE_MOCK_RESULTS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_MOCK_RESULT_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status, TID));
    });
};

export const NewGenerateTopicPaper = (data) => (dispatch, getState) => {
  const TID = toast.loading("Generating Topic Papers...⏳");

  dispatch({ type: GENERATE_TOPIC_PAPER_NEW_LOADING });
  axios
    .post("QLA/GenerateNewTopicPaper/", data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Topic Paper Created", TID }));
      dispatch({
        type: GENERATE_TOPIC_PAPER_NEW,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GENERATE_TOPIC_PAPER_NEW_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status, TID));
    });
};

export const MockResultUpdate = (data, uniqueID) => (dispatch, getState) => {
  const TID = toast.loading("Please wait updating mock exam...⏳");

  dispatch({ type: MOCK_RESULT_LOADING });
  axios
    .post(`QLA/MockExamResultUpdate/${uniqueID}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addLead: "Marks Updated",TID }));
      dispatch({
        type: ADDING_MOCK_RESULTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: MOCK_RESULT_FAILED });
      dispatch(returnErrors(err.response.data, err.response.status, TID));
    });
};

export const getMockExamByUniqueID = (UniqueID) => (dispatch, getState) => {
  dispatch({ type: NEW_MOCK_EXAM_LOADING });
  axios
    .get(`QLA/MockExamResultUID/${UniqueID}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_NEW_MOCK_EXAM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: NEW_MOCK_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
