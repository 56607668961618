import {
  GET_EXAM_DATA,
  SET_EXAM_DATA_LOADING,
  SET_EXAM_DATA_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  ExamDataList: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_DATA:
      return {
        ...state,
        ExamDataList: action.payload,
        isLoading: false,
      };

    case SET_EXAM_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_EXAM_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
