export default function getClassNameByRating(RAGRating, maxMarks, marks) {
  const RAG = RAGRating;
  const percentage = Math.round((marks / maxMarks) * 100);
  const color = "form-control form-control-color ";

  for (const key in RAG) {
    const { color, Lower, Upper } = RAG[key];

    if (color === "Green" && percentage >= Lower) {
      return `${color} form-green`;
    } else if (
      color === "Amber" &&
      percentage >= Lower &&
      percentage <= Upper
    ) {
      return `${color} form-amber`;
    } else if (color === "Red" && percentage >= Lower && percentage <= Upper) {
      return `${color} form-red`;
    }
  }
  if (marks === "New Record") {
    return `light-green form-light-green`;
  }
  if (marks === "Not Attempted") {
    return `purple form-purple`;
  }
  return color;
}
