import {
  GET_CLASS_ENHANCE,
  ENHANCE_CLASS_LOADING,
  ENHANCE_CLASS_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  EnhanceClassObject: undefined,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLASS_ENHANCE:
      return {
        ...state,
        EnhanceClassObject: action.payload,
        isLoading: false,
      };

    case ENHANCE_CLASS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ENHANCE_CLASS_FAIL:
      return {
        ...state,
        isLoading: false,
        // EnhanceClassObject: undefined,
      };
    default:
      return state;
  }
}
