import { combineReducers } from "redux";
import errors from "./errors";
import message from "./message";
import auth from "./auth";
import countries from "./country_reducers";
import school_list from "./school_reducers";
import subscription from "./subscription_reducers";
import bundles from "./BundleReducer";
import subjectsList from "./SubjectReducers";
import QuestionPaper from "./QuestionPaperReducer";
import ExamList from "./ExamReducer";
import FAQList from "./FAQReducer";
import TermsList from "./TermsReducer";
import PolicyList from "./PolicyReducer";
import FeedbackArray from "./FeedbackReducer";
import studentList from "./studentReducers";
import classList from "./ClassReducers";
import QuestionsList from "./QuestionReducers";
import classFilterList from "./ClassFilterReducers";
import GradeYearList from "./GradeYearReducer";
import SchoolById from "./SchoolByIdReducer";
import ExamDataList from "./StudentExamReducers";
import ExamGenerator from "./PaperGeneratorReducers";
import CsvList from "./CSVGeneratorReducer";
import Register from "./RegisterReducer";
import StudentMarksList from "./StudentMarksReducers";
import Support from "./SupportReducer";
import Teacher_List from "./TeacherReducer";
import UsersList from "./UserReducer";
import CsvStudentList from "./CSVStudentReducers";
import MockList from "./MockReducer";
import LevelList from "./LevelReducer";
import MockFilterList from "./MockExamResultsReducer";
import CsvListMockUp from "./CSVMockUpReducer";
import CheckExamExist from "./CheckMockResultReducers";
import RABRatingList from "./RAGRatingReducers";
import PaperExam from "./PaperExamReducer";
import GenerateTopicPaperArray from "./GenerateTopicPaperReducer";
import GETRABRatingArray from "./GETRABRatingReducers";
import class_by_School from "./ClassFilterSchoolReducer";
import DownloadZip from "./DownloadsReducer";
import FileMedia from "./FileTestingReducer";
import PayViaEmailArray from "./PayViaEmailReducer";
import array_sub from "./subscription_reducers";
import TeacherByEmail from "./TeacherEmailReducer";
import UserPermissions from "./UserPermissionReducer";
import UserGroup from "./UserGroupReducer";
import otpStatus from "./checkOTPStatusReducer";
import SchoolByCreated from "./schoolCreatedReducer";
import school_map_user from "./School_Map_User_Reducers";
import NewMockResultList from "./NewMockExamReducers";
import EnhanceClassObject from "./EnhanceClassReducers";
import TeacherActive from "./TeacherActive";
export default combineReducers({
  auth,
  errors,
  bundles,
  FAQList,
  CsvList,
  message,
  Support,
  ExamList,
  Register,
  MockList,
  countries,
  classList,
  TermsList,
  LevelList,
  UsersList,
  PaperExam,
  FileMedia,
  PolicyList,
  SchoolById,
  studentList,
  school_list,
  subscription,
  subjectsList,
  ExamDataList,
  DownloadZip,
  Teacher_List,
  QuestionsList,
  school_map_user,
  array_sub,
  TeacherActive,
  FeedbackArray,
  GradeYearList,
  CheckExamExist,
  SchoolByCreated,
  ExamGenerator,
  CsvListMockUp,
  RABRatingList,
  MockFilterList,
  UserGroup,
  TeacherByEmail,
  otpStatus,
  QuestionPaper,
  CsvStudentList,
  class_by_School,
  classFilterList,
  StudentMarksList,
  PayViaEmailArray,
  GETRABRatingArray,
  UserPermissions,
  GenerateTopicPaperArray,
  NewMockResultList,
  EnhanceClassObject,
});
