import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes, { string } from "prop-types";
import { SHA256 } from "crypto-js";
import { Button, Form, Spinner } from "react-bootstrap";
import { getMockFilterSchool } from "../../actions/Mock/MockAction";
import { getClass } from "../../actions/ClassActionFolder/ClassActions";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getQuestionPaperFilter } from "../../actions/QuestionPaper/QuestionPaperAction";
import { getDownloadUnique } from "../../actions/DownloadsAPI/DownloadsAction";
import Moment from "react-moment";

class DocumentPage extends Component {

  static propTypes = {
    getMockFilterSchool: PropTypes.func.isRequired,
    getClass: PropTypes.func.isRequired,
    classList: PropTypes.array.isRequired,
    MockList: PropTypes.array.isRequired,
    getGradeYear: PropTypes.func.isRequired,
    GradeYearList: PropTypes.array.isRequired,
    getSubject: PropTypes.func.isRequired,
    subjectsList: PropTypes.array.isRequired,
    QuestionPaper: PropTypes.array.isRequired,
    getQuestionPaperFilter: PropTypes.func.isRequired,
    getDownloadUnique: PropTypes.func.isRequired,
    DownloadZip: PropTypes.array.isRequired,
    DownloadZipFail: PropTypes.bool.isRequired,
    DownloadZipLoading: PropTypes.bool.isRequired,
    MockListLoading: PropTypes.bool.isRequired,
  };
  
  state = {
    MockExamValue: undefined,
    ClassValue: undefined,
    YearValue: undefined,
    SubjectValue: undefined,
    PaperValue: undefined,
    MockExamData: [],
    ClassData: [],
    YearData: [],
    SubjectData: [],
    QuestionPaperData: [],
    DownloadZipFile: undefined,
    displayDropDown: true,
    uniqueID: undefined,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  PaperGetter = () => {
    const { MockExamValue, SubjectValue } = this.state;
    this.props.getQuestionPaperFilter(SubjectValue, MockExamValue);
  };
  
  SearchPapers = () => {
    const ClassId = this.state.ClassValue;
    const MockExamId = this.state.MockExamValue;
    const SubjectId = this.state.SubjectValue;
    const School = localStorage.getItem("Schoolid");
    const PaperID = this.state.PaperValue;
    const GradeId = this.state.YearValue;
    const inputText =
      "C" +
      String(ClassId) +
      "GY" +
      String(GradeId) +
      "Sub" +
      String(SubjectId) +
      "Sch" +
      String(School) +
      "M" +
      String(MockExamId) +
      "P" +
      String(PaperID);
    const hash = SHA256(inputText).toString();
    this.setState({ uniqueID: hash });
    this.props.getDownloadUnique(hash);
  };
  
  componentDidMount() {
    this.props.getMockFilterSchool(localStorage.getItem("Schoolid"));
    this.props.getClass();
    this.props.getGradeYear();
    this.props.getSubject();
    const { MockExamId, ClassID, YearID, SubjectID, PaperID } = this.props;
    if (
      MockExamId !== undefined ||
      ClassID !== undefined ||
      YearID !== undefined ||
      SubjectID !== undefined ||
      PaperID !== undefined
    ) {
      this.setState({
        MockExamValue: MockExamId,
        ClassValue: ClassID,
        YearValue: YearID,
        SubjectValue: SubjectID,
        PaperValue: PaperID,
        displayDropDown: false,
      });
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.MockExamValue !== this.state.MockExamValue ||
      prevState.SubjectValue !== this.state.SubjectValue
    ) {
      this.PaperGetter();
    }
    if (prevProps.MockList !== this.props.MockList) {
      this.setState({ MockExamData: this.props.MockList });
    }
    if (prevProps.classList !== this.props.classList) {
      this.setState({ ClassData: this.props.classList });
    }
    if (prevProps.GradeYearList !== this.props.GradeYearList) {
      this.setState({ YearData: this.props.GradeYearList });
    }
    if (prevProps.subjectsList !== this.props.subjectsList) {
      this.setState({ SubjectData: this.props.subjectsList });
    }
    if (prevProps.QuestionPaper !== this.props.QuestionPaper) {
      this.setState({ QuestionPaperData: this.props.QuestionPaper });
    }
    if (prevState.PaperValue !== this.state.PaperValue) {
      this.SearchPapers();
    }
    if (prevProps.DownloadZip !== this.props.DownloadZip) {
      this.setState({ DownloadZipFile: this.props.DownloadZip });
    }
  }

  render() {
    const {
      displayDropDown,
      ClassData,
      YearData,
      SubjectData,
      MockExamData,
      DownloadZipFile,
      QuestionPaperData,
    } = this.state;
    const {
      DownloadZipFail,
      DownloadZipLoading,
      MockListLoading,
      MockExamId,
      ClassID,
      YearID,
      SubjectID,
      PaperID,
    } = this.props;

    const empty = (
      <table className="table table-head-custom table-vertical-center">
        <thead>
          <tr className="text-left">
            <th className="pl-0" style={{ width: "100%" }}>
              #
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {(() => {
              if (DownloadZipLoading) {
                return (
                  <td className="pl-0 text-center">
                    <Spinner animation="border" variant="success" /> &nbsp;
                    Fetching Archive Folder data
                  </td>
                );
              } else if (DownloadZipFail && DownloadZipFile === undefined) {
                return (
                  <td className="pl-0 text-center">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                      No Data Found{" "}
                    </span>
                  </td>
                );
              } else {
                return (
                  <td className="pl-0 text-center">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                      Please Select Data to continue
                    </span>
                  </td>
                );
              }
            })()}
          </tr>
        </tbody>
      </table>
    );
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 text-center">
            <h4>Select Following Details</h4>
          </div>
        </div>

        <Fragment>
          <div className="row mt-5">
            <div
              className={
                !displayDropDown
                  ? `p-0 m-0 col-3 text-center`
                  : "p-0 m-0 col-2 text-center"
              }
            >
              <div className="d-flex flex-sm-row flex-column">
                {(() => {
                  if (MockListLoading) {
                    return (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp; Loading Mock Exam...
                      </Button>
                    );
                  } else {
                    return (
                      <Form.Select
                        value={!displayDropDown ? MockExamId : undefined}
                        disabled={!displayDropDown}
                        className="form-control"
                        name="MockExamValue"
                        onChange={(e) => this.onChange(e)}
                      >
                        <option selected disabled>
                          --Select Mock Exam--
                        </option>
                        {MockExamData.map((arr, key) => (
                          <option
                            key={key}
                            value={arr.id}
                            className="text-capitalize"
                          >
                            {arr.id}-{arr.exam.name}-{arr.name}-
                            {arr.exam.exam_year}{" "}
                          </option>
                        ))}
                      </Form.Select>
                    );
                  }
                })()}
              </div>
            </div>
            <div
              className={
                !displayDropDown
                  ? `px-1 m-0  col-3 text-center`
                  : "px-1 m-0 col-2 text-center"
              }
            >
              <div className="d-flex flex-sm-row flex-column">
                <Form.Select
                  className="form-control"
                  name="ClassValue"
                  // value={ClassID}
                  value={!displayDropDown ? ClassID : undefined}
                  disabled={!displayDropDown}
                  onChange={(e) => this.onChange(e)}
                >
                  <option selected>--Select Class--</option>
                  {ClassData.map((arr, key) => (
                    <option
                      key={key}
                      value={arr.id}
                      className="text-capitalize"
                    >
                      {arr.id}
                      {arr.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div
              className={
                !displayDropDown
                  ? `p-0 m-0 col-2 text-center`
                  : "p-0 m-0 col-2 text-center"
              }
            >
              <div className="d-flex flex-sm-row flex-column">
                <Form.Select
                  className="form-control"
                  name="YearValue"
                  // value={YearID}
                  value={!displayDropDown ? YearID : undefined}
                  disabled={!displayDropDown}
                  onChange={(e) => this.onChange(e)}
                >
                  <option selected>--Select Year--</option>
                  {YearData.map((arr, key) => (
                    <option key={key} value={arr.id}>
                      {arr.id}-{arr.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div
              className={
                !displayDropDown
                  ? `px-1 m-0 col-2 text-center`
                  : "px-1 m-0 col-2 text-center"
              }
            >
              <div className="d-flex flex-sm-row flex-column">
                <Form.Select
                  className="form-control"
                  name="SubjectValue"
                  // value={SubjectID}
                  value={!displayDropDown ? SubjectID : undefined}
                  disabled={!displayDropDown}
                  onChange={(e) => this.onChange(e)}
                >
                  <option selected disabled>
                    --Select Subject--
                  </option>
                  {SubjectData.map((arr, key) => (
                    <option key={key} value={arr.id}>
                      {arr.id}-{arr.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div
              className={
                !displayDropDown
                  ? `p-0 m-0 col-2 text-center`
                  : "px-1 m-0 col-2 text-center"
              }
            >
              <div className="d-flex flex-sm-row flex-column">
                <Form.Select
                  className="form-control"
                  name="PaperValue"
                  // value={PaperID}
                  value={!displayDropDown ? PaperID : undefined}
                  disabled={!displayDropDown}
                  onChange={(e) => this.onChange(e)}
                >
                  <option selected disabled>
                    --Select Paper--
                  </option>
                  {QuestionPaperData.map((arr, key) => (
                    <option key={key} value={arr.id}>
                      {arr.id}-{arr.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            {displayDropDown ? (
              <div className={`px-1 m-0 col-2 text-center`}>
                <div className="d-flex flex-sm-row flex-column">
                  <button
                    className="btn btn-success"
                    onClick={(e) => this.SearchPapers(e)}
                  >
                    <i className="flaticon2-search-1 icon-md" />
                    Search
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Fragment>

        <div className="row mt-5">
          <div className="col-12">
            <div className="card card-custom">
              <div className="card-body py-0">
                <div className="table-responsive">
                  {(() => {
                    if (DownloadZipFile !== undefined) {
                      return (
                        <table className="table table-head-custom table-vertical-center">
                          <thead>
                            <tr className="text-left">
                              <th className="pl-0" style={{ width: "30px" }}>
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                  <input
                                    type="checkbox"
                                    disabled={true}
                                    defaultValue={1}
                                  />
                                  <span />
                                </label>
                              </th>
                              <th className="pl-0" style={{ minWidth: "50px" }}>
                                id
                              </th>
                              <th style={{ minWidth: "120px" }}>unique ID</th>
                              <th style={{ minWidth: "120px" }}>Zip file</th>
                              <th style={{ minWidth: "120px" }}>Created at</th>
                              <th style={{ minWidth: "120px" }}>Updated at</th>
                            </tr>
                          </thead>
                          <tbody>
                            {DownloadZipFile.sort(
                              (a, b) =>
                                new Date(b.updated_at) - new Date(a.updated_at)
                            )
                              .slice(0, 10)
                              .map((arr, key) => (
                                <tr key={key}>
                                  <td className="pl-0 py-6">
                                    <label className="checkbox checkbox-lg checkbox-inline">
                                      <input
                                        type="checkbox"
                                        disabled={true}
                                        defaultValue={1}
                                      />
                                      <span />
                                    </label>
                                  </td>
                                  <th scope="row">{arr.id}</th>
                                  <td
                                    className="pl-0"
                                    style={{ maxWidth: "30px" }}
                                  >
                                    <span className="text-dark-75 font-weight-bolder font-size-lg">
                                      {arr.uniqueID.slice(0, 5) +
                                        "..." +
                                        arr.uniqueID.slice(-5)}
                                    </span>
                                  </td>
                                  <td>
                                    {/*<span*/}
                                    {/*    className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">*/}
                                    {/*  <a*/}
                                    {/*      href={arr.FileLink}*/}
                                    {/*      className="btn btn-success font-weight-bold mr-2"*/}
                                    {/*  >*/}
                                    {/*    <i className="fa fas fa-file-archive icon-lg"></i>{" "}*/}
                                    {/*    Download files*/}
                                    {/*  </a>*/}
                                    {/*</span>*/}
                                    <span
                                        className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">
                                      <a
                                          href={arr.RemoteFile}
                                          className="btn btn-success font-weight-bold mr-2"
                                      >
                                        <i className="fa fas fa-file-archive icon-lg"></i>{" "}
                                        Download Zip
                                      </a>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      <Moment
                                          format={"ddd, DD MMM YY ,  h:mm A"}
                                      >
                                        {arr.created_at}
                                      </Moment>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      <Moment
                                        format={"ddd, DD MMM YY ,  h:mm A"}
                                      >
                                        {arr.updated_at}
                                      </Moment>
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      );
                    } else {
                      return empty;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  MockList: state.MockList.MockList,
  MockListLoading: state.MockList.isLoading,
  classList: state.classList.classList,
  DownloadZipFail: state.DownloadZip.failed,
  DownloadZip: state.DownloadZip.DownloadZip,
  subjectsList: state.subjectsList.subjectsList,
  QuestionPaper: state.QuestionPaper.QuestionPaper,
  DownloadZipLoading: state.DownloadZip.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
});
export default connect(mapStateToProps, {
  getClass,
  getSubject,
  getGradeYear,
  getDownloadUnique,
  getMockFilterSchool,
  getQuestionPaperFilter,
})(DocumentPage);
