import React, { Component, Fragment } from "react";
import { Form, Modal, Spinner,Tooltip,OverlayTrigger } from "react-bootstrap";
import { Formik } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubject } from "../../actions/Subjects/Subjects_actions";
import { getLevel } from "../../actions/Level/LevelAction";
import { addMock, getMockFilter } from "../../actions/Mock/MockAction";
import Waiting from "../functions/Waiting";
import { getClassSchool } from "../../actions/ClassActionFolder/ClassActions";
import * as yup from "yup";
import { getExamsById } from "../../actions/Exam/ExamsAction";
import { getPaperExam } from "../../actions/QuestionPaper/QuestionPaperAction";
import Status from "../functions/Status";
import { addRatingRAG } from "../../actions/RAGRating/RAGRatingAction";
import { getGradeYear } from "../../actions/GradeYear/GradeYearAction";

const schema = yup.object({
  name: yup.string().required("Enter name"),
  threshold_buffer: yup.string().required("Enter threshold Buffer"),
  exam: yup.string().required("Select Class"),
  year_grade: yup.string().required("Select Year"),
});
// const schema1 = yup.object({
//   grade_year: yup.string().required("Select Grade Year"),
// Marks_Upper: yup.string().required("Enter Upper Limit"),
// Marks_lower: yup.string().required("Enter Lower Limit"),
// });

class ExamDataMock extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    permissions: PropTypes.array,
    groups: PropTypes.array,
    subjectsListLoading: PropTypes.bool.isRequired,
    subjectsList: PropTypes.array.isRequired,
    getSubject: PropTypes.func.isRequired,
    LevelList: PropTypes.func.isRequired,
    LevelListLoading: PropTypes.bool.isRequired,
    getMockFilter: PropTypes.func.isRequired,
    MockList: PropTypes.func.isRequired,
    addRatingRAG: PropTypes.func.isRequired,
    MockLoading: PropTypes.bool.isRequired,
    getClassSchool: PropTypes.func.isRequired,
    classFilterList: PropTypes.array.isRequired,
    classListLoading: PropTypes.bool.isRequired,
    getExams: PropTypes.func.isRequired,
    getExamsById: PropTypes.func.isRequired,
    ExamList: PropTypes.array.isRequired,
    getLevel: PropTypes.func.isRequired,
    ExamListLoading: PropTypes.bool.isRequired,
    addMock: PropTypes.func.isRequired,
    getPaperExam: PropTypes.func.isRequired,
    PaperExam: PropTypes.array.isRequired,
    PaperExamLoading: PropTypes.bool.isRequired,
    GradeYearList: PropTypes.array.isRequired,
    getGradeYear: PropTypes.func.isRequired,
    GradeYearListLoading: PropTypes.bool.isRequired,
    class_by_School: PropTypes.array.isRequired,
    authLoading: PropTypes.bool,
  };
  state = {
    Level: undefined,
    subject: undefined,
    response: false,
    classState: undefined,
    showMockExam: false,
    exam: undefined,
    ExamListLocal: undefined,
    ExamID: undefined,
    RAG: [],
    RAGModal: false,
    MockExamID: undefined,
    grade_year: undefined,
    year_grade_error: false,
    CanEditRag: false,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  search = () => {
    this.setState({ response: true });
  };
  onSubmitExam = (values) => {
    const name = values.name;
    const threshold_buffer = values.threshold_buffer;
    const exam = parseInt(values.exam);
    const school = parseInt(localStorage.getItem("Schoolid"));
    const classes = [parseInt(this.props.id)];
    const year_grade = parseInt(values.year_grade);
    const body = { name, threshold_buffer, school, exam, classes, year_grade };
    this.props.addMock(body);
    this.setState({ showMockExam: !this.state.showMockExam });
  };
  Searcher = () => {
    const class_id = this.props.id;
    const school_id = localStorage.getItem("Schoolid");
    this.props.getMockFilter(class_id, school_id);
    this.setState({ response: true });
  };
  getExamList = () => {
    const options = this.props.ExamList.map((arr) => ({
      value: arr.id,
      label: arr.name,
    }));
    this.setState({ ExamListLocal: options });
  };
  PaperExam = (e) => {
    this.setState({ exam: e.target.value });
  };
  RAGRatingOpen = (data) => {
    this.setState({ MockExamID: data.id, RAGModal: !this.state.RAGModal }, () =>
      this.props.getPaperExam(data.exam.id)
    );
  };
  onSubmitRAGRating = (values) => {};
  ActiveRow = (id) => {
    const updatedRAG = this.state.RAG.map((Ratings) => {
      if (Ratings.id !== id) return { ...Ratings, disable: true };
      return {
        ...Ratings,
        disable: false,
      };
    });
    this.setState({ RAG: updatedRAG });
  };
  RowUpdate = (e, id, status, call) => {
    const RowUpdated = this.state.RAG.map((Rating) => {
      if (call === "Marks_Upper") {
        if (Rating.id !== id) return Rating;
        return {
          ...Rating,
          RAGRating: Rating.RAGRating.map((arr) => {
            if (arr["status"] !== status) return arr;
            return {
              ...arr,
              Marks_Upper: parseInt(e.target.value),
            };
          }),
        };
      } else {
        // eslint-disable-next-line no-lone-blocks
        {
          if (Rating.id !== id) return Rating;
          return {
            ...Rating,
            RAGRating: Rating.RAGRating.map((arr) => {
              if (arr["status"] !== status) return arr;
              return {
                ...arr,
                Marks_lower: parseInt(e.target.value),
              };
            }),
          };
        }
      }
    });
    this.setState({ RAG: RowUpdated });
  };
  SaveRating = (arr) => {
    if (this.state.grade_year === undefined) {
      this.setState({ year_grade_error: true });
    } else {
      this.setState({ year_grade_error: false });

      this.props.addRatingRAG(arr);
    }
    //
  };
  RAGCheck = (data) => {
    const defaultValue = [
      { status: "Green", Marks_Upper: 100, Marks_lower: 76 },
      { status: "Amber", Marks_Upper: 75, Marks_lower: 51 },
      { status: "Red", Marks_Upper: 50, Marks_lower: 0 },
    ];
    // const nullValue=[
    //   {status: 'Green', Marks_Upper: 0, Marks_lower: 0},
    //   {status: 'Amber', Marks_Upper: 0, Marks_lower: 0},
    //   {status: 'Red', Marks_Upper: 0, Marks_lower: 0},
    // ]
    // eslint-disable-next-line array-callback-return
    const x = data.map((arr) => {
      if (arr.status === "Green") {
        if (arr.Marks_Upper === 0) {
          return null;
        } else {
          return "Not Null";
        }
      }
    });
    if (x[0] === null) {
      return defaultValue;
    } else {
      return data;
    }
  };
  initializeRAGRating = () => {
    if (
      this.props.PaperExam !== undefined ||
      this.props.PaperExam.length !== 0
    ) {
      // eslint-disable-next-line no-sequences
      const RAG = this.props.PaperExam.map((ids) => ({
        id: ids[0],
        Name: ids[1],
        RAGRatingID: ids[3],
        disable: true,
        ClassID: this.props.id,
        grade_year: undefined,
        ExamID: this.state.exam,
        MockExamID: this.state.MockExamID,
        RAGRating: this.RAGCheck(ids[2]),
        // RAGRating: ids[2],
      }));
      this.setState({ ...this.state, RAG });
    }
  };
  keyUpFunction = (e, id, status) => {
    const element = document.getElementById(
      "MarksUpper" + "" + id + "" + status
    );
    //MarksUpper
    // if (
    //   Number(e.target.min) <= Number(e.target.value) &&
    //   Number(e.target.value) <= Number(e.target.max)
    // ) {
    //   if (element.classList.contains("is-invalid")) {
    //     element.classList.remove("is-invalid");
    //   }
    // } else {
    //   element.classList.add("is-invalid");
    //   element.value = "";
    // }
  };
  updateYearGrade = () => {
    if (this.state.grade_year === undefined) {
      this.setState({ year_grade_error: true });
    } else {
      this.setState({ year_grade_error: false });
    }
    const RAGupdate = this.state.RAG.map((ids) => {
      return {
        ...ids,
        grade_year: this.state.grade_year,
      };
    });
    this.setState({ RAG: RAGupdate });
  };
  onGradeYear = (e) => {
    this.setState({ grade_year: e.target.value }, () => this.updateYearGrade());
  };
  preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  permissions = () => {
    if (this.props.groups !== undefined) {
      const newdata = this.props.groups.map((arr) =>
        arr.permissions.some((obj) => obj === 110)
      );
      if (newdata.includes(true)) {
        this.setState({ CanEditRag: true });
      }
    }
  };
  componentDidMount() {
    console.log("Pid", this.props.id);
    this.Searcher();
    this.props.getSubject();
    this.props.getLevel();
    this.props.getExamsById(this.props.id);
    this.props.getClassSchool(localStorage.getItem("Schoolid"));
    this.getExamList();
    this.props.getGradeYear();
    this.permissions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.PaperExam !== this.props.PaperExam) {
      this.initializeRAGRating();
    }
    if (prevProps.permissions !== this.props.permissions) {
      console.log("called");
    }
  }

  render() {
    const empty = (
      <table className="table table-head-custom table-vertical-center">
        <thead></thead>
        <tbody>
          <tr>
            <td className="pl-0 text-center">
              <span className="text-dark-75 font-weight-bolder font-size-lg">
                Please Select Data to continue
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
    const NoData = (
      <table className="table table-head-custom table-vertical-center">
        <thead></thead>
        <tbody>
          <tr>
            <td className="pl-0 text-center">
              <span className="text-dark-75 font-weight-bolder font-size-lg">
                No details found
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
    const {
      MockList,
      MockLoading,
      ExamList,
      ExamListLoading,
      // PaperExam,GradeYearListLoading,
      GradeYearList,
      class_by_School,
      auth,
      permissions,
      groups,
    } = this.props;
    const { response, showMockExam, RAG, RAGModal, year_grade_error } =
      this.state;

    console.log(ExamList);
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-10" />
          <div className="col-2 text-center">
            <div className="d-flex flex-sm-row flex-column">
              <button
                className="btn btn-success"
                onClick={() => this.setState({ showMockExam: !showMockExam })}
              >
                Create Mock Exam
              </button>
            </div>
          </div>
        </div>
        {/* {permissions.map(arr=>console.log(arr.name))} */}
        <div className="card card-custom mt-5">
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label">
                Class{" "}
                <span className="font-weight-bolder text-dark">
                  {this.props.classname}
                </span>
              </span>
            </h3>
            <div className="card-toolbar">
              <div className="dropdown dropdown-inline">
                <Modal
                  centered
                  size="xl"
                  show={showMockExam}
                  onHide={() => this.setState({ showMockExam: !showMockExam })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Mock Exam</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="card card-custom card-shadowless rounded-top-0">
                      <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                          <div className="col-xl-12 col-xxl-10">
                            <Formik
                              validationSchema={schema}
                              onSubmit={this.onSubmitExam}
                              initialValues={{
                                name: "",
                                school: localStorage.getItem("SchoolName"),
                                classes: this.props.classname,
                                threshold_buffer: "",
                                exam: "",
                                year_grade: "",
                              }}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  <div className="row justify-content-center">
                                    <div className="col-xl-12">
                                      <div className="my-5 step">
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Name
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="name"
                                              type="text"
                                              placeholder="Name"
                                              value={values.name}
                                              onChange={handleChange}
                                              isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.name}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Threshold
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="threshold_buffer"
                                              type="number"
                                              placeholder="Threshold"
                                              value={values.threshold_buffer}
                                              onChange={handleChange}
                                              isInvalid={
                                                !!errors.threshold_buffer
                                              }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {errors.threshold_buffer}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Exam
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Select
                                              name="exam"
                                              disabled={ExamListLoading}
                                              isInvalid={!!errors.exam}
                                              onChange={(e, event) => {
                                                handleChange({
                                                  ...event,
                                                  target: {
                                                    name: "exam",
                                                    value: e.target.value,
                                                  },
                                                });
                                              }}
                                              className="form-control form-control-solid"
                                            >
                                              {(() => {
                                                if (ExamList.length === 0) {
                                                  return (
                                                    <option selected disabled>
                                                      No Data Found
                                                    </option>
                                                  );
                                                } else {
                                                  return (
                                                    <Fragment>
                                                      <option selected disabled>
                                                        --Select Exam--
                                                      </option>
                                                      {ExamList.map(
                                                        (arr, key) => (
                                                          <option
                                                            key={key}
                                                            value={arr.id}
                                                          >
                                                            {
                                                              arr.examBoard
                                                                .fullname
                                                            }
                                                            -{arr.exam_year}-
                                                            {arr.exam_month}-
                                                            {arr.name}
                                                          </option>
                                                        )
                                                      )}
                                                    </Fragment>
                                                  );
                                                }
                                              })()}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                              {errors.exam}
                                            </Form.Control.Feedback>
                                            {/* TODO:remove me */}
                                            <span className="form-text text-muted">
                                              TODO: Remove later Result will be based on subject and level of class
                                            </span>
                                          </div>
                                        </div>

                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Year Grade
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Select
                                              name="year_grade"
                                              // disabled={year_grade}
                                              isInvalid={!!errors.year_grade}
                                              // onChange={(e)=>console.log(e.target)}
                                              onChange={(e, event) => {
                                                handleChange({
                                                  ...event,
                                                  target: {
                                                    name: "year_grade",
                                                    value: e.target.value,
                                                  },
                                                });
                                              }}
                                              className="form-control form-control-solid"
                                            >
                                              {(() => {
                                                if (
                                                  class_by_School.length === 0
                                                ) {
                                                  return (
                                                    <option selected disabled>
                                                      ---No Data Found---
                                                    </option>
                                                  );
                                                } else {
                                                  return (
                                                    <Fragment>
                                                      <option selected disabled>
                                                        ---Select Year Grade---
                                                      </option>
                                                      {class_by_School
                                                        .filter(
                                                          (arr) =>
                                                            arr.id ===
                                                            Number(
                                                              this.props.id
                                                            )
                                                        )
                                                        .map((arr) =>
                                                          arr.year_grade.map(
                                                            (item, key) => (
                                                              <option
                                                                key={key}
                                                                value={item.id}
                                                              >
                                                                {item.id}-
                                                                {item.name}
                                                              </option>
                                                            )
                                                          )
                                                        )}
                                                    </Fragment>
                                                  );
                                                }
                                              })()}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                              {errors.year_grade}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <label className="col-xl-3" />
                                          <div className="col-lg-9 col-xl-6">
                                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                            <h5 className="font-weight-bold mt-10 mb-6" />
                                          </div>
                                        </div>
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            Class
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="classes"
                                              type="text"
                                              disabled
                                              value={values.classes}
                                              onChange={handleChange}
                                              isInvalid={!!errors.classes}
                                            />
                                            <span className="form-text text-muted">
                                              &nbsp;You can add Data for your
                                              class only
                                            </span>
                                            <Form.Control.Feedback type="invalid">
                                              {errors.classes}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>
                                        <div className="form-group row">
                                          <label className="col-xl-3 col-lg-3 col-form-label">
                                            School
                                          </label>
                                          <div className="col-lg-9 col-xl-9">
                                            <Form.Control
                                              className="form-control form-control-solid form-control-lg"
                                              name="school"
                                              type="text"
                                              disabled
                                              value={values.school}
                                              onChange={handleChange}
                                              isInvalid={!!errors.school}
                                            />
                                            <span className="form-text text-muted">
                                              &nbsp;You can add Data for your
                                              school only
                                            </span>
                                            <Form.Control.Feedback type="invalid">
                                              {errors.school}
                                            </Form.Control.Feedback>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center">
                                        <div>
                                          <button
                                            onClick={() =>
                                              this.setState({
                                                showMockExam: !showMockExam,
                                              })
                                            }
                                            type="button"
                                            className="btn btn-outline-danger bg-hover-outline-danger font-weight-bolder font-size-sm mr-lg-3"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-success font-weight-bolder font-size-sm"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>

          <Modal
            centered
            size="xl"
            show={RAGModal}
            onHide={() => this.setState({ RAGModal: !RAGModal })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add RAG Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-2" />
                    <div className="col-8">
                      <Form.Select
                        className={
                          year_grade_error
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="grade_year"
                        onChange={(e) => this.onGradeYear(e)}
                      >
                        <option selected disabled>
                          ---Select Year Grade---
                        </option>
                        {GradeYearList.map((arr, key) => (
                          <option
                            key={key}
                            value={arr.id}
                            className="text-capitalize"
                          >
                            {arr.name}
                          </option>
                        ))}
                      </Form.Select>
                      {year_grade_error ? (
                        <span
                          className="form-text"
                          style={{ color: "#F64E60" }}
                        >
                          &nbsp;Please Select Year grade
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="form-text text-muted text-center">
                        <b>
                          Please Edit Data to modify RAG rating or save to use{" "}
                          <strong>Default values</strong>
                        </b>
                      </span>
                    </div>
                    <div className="col-2" />
                  </div>
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-10">
                      <Formik
                        onSubmit={this.onSubmitRAGRating}
                        initialValues={{
                          Marks_Upper: "",
                          Marks_lower: "",
                          grade_year: "",
                        }}
                      >
                        {({ handleSubmit, handleChange, values, errors }) => (
                          <Form
                            className="scroller"
                            noValidate
                            onSubmit={handleSubmit}
                          >
                            <div className="row mt-5">
                              {RAG.length !== 0 ? (
                                RAG.map((arr, key) => (
                                  <div key={key} className="col-6 ">
                                    <h5>
                                      {arr.id}#&nbsp;&nbsp;{arr.Name}
                                    </h5>

                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Status</th>
                                          <th scope="col">Upper Boundary</th>
                                          <th scope="col">Lower Boundary</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {arr.RAGRating.map((arr1, key1) => (
                                          <tr key={key1}>
                                            <th>
                                              {/*{console.log(arr1)}*/}
                                              <Status data={arr1.status} />
                                            </th>
                                            <td>
                                              <Form.Control
                                                id={
                                                  "MarksUpper" +
                                                  "" +
                                                  arr.id +
                                                  "" +
                                                  arr1.status
                                                }
                                                disabled={arr.disable}
                                                size="sm"
                                                onKeyPress={(e) =>
                                                  this.preventMinus(e)
                                                }
                                                name="Marks_Upper"
                                                defaultValue={arr1.Marks_Upper}
                                                onChange={(e) =>
                                                  this.RowUpdate(
                                                    e,
                                                    arr.id,
                                                    arr1.status,
                                                    "Marks_Upper"
                                                  )
                                                }
                                                isInvalid={!!errors.Marks_Upper}
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter For Green in Percentage"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.Marks_Upper}
                                              </Form.Control.Feedback>
                                            </td>
                                            <td>
                                              <Form.Control
                                                id={
                                                  "MarksLower" +
                                                  arr.id +
                                                  arr1.status
                                                }
                                                disabled={arr.disable}
                                                size="sm"
                                                name="Marks_lower"
                                                defaultValue={arr1.Marks_lower}
                                                onChange={(e) =>
                                                  this.RowUpdate(
                                                    e,
                                                    arr.id,
                                                    arr1.status,
                                                    "Marks_lower"
                                                  )
                                                }
                                                isInvalid={!!errors.Marks_lower}
                                                type="number"
                                                className="form-control "
                                                placeholder="Enter For Green in Percentage"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.Marks_lower}
                                              </Form.Control.Feedback>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>

                                    <div className="row d-flex justify-content-between border-top pt-10 mt-15 text-center mb-10">
                                      <div className="col-6">
                                        <button
                                          onClick={() => this.ActiveRow(arr.id)}
                                          type="button"
                                          className="btn btn-outline-warning bg-hover-outline-warning font-weight-bolder font-size-sm mr-lg-3"
                                        >
                                          Edit
                                        </button>
                                      </div>

                                      <div className="col-6">
                                        {" "}
                                        <button
                                          onClick={() => this.SaveRating(arr)}
                                          disabled={arr.disable}
                                          type="submit"
                                          className="btn btn-outline-success font-weight-bolder font-size-sm"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Fragment>
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                  &nbsp;Fetching RAG Rating
                                </Fragment>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="card-body py-0">
            {(() => {
              if (response === false) {
                return empty;
              } else if (MockLoading === false && MockList.length !== 0) {
                return (
                  <Fragment>
                    <div className="table-responsive">
                      <table className="table table-head-custom table-vertical-center">
                        <thead>
                          <tr className="text-left">
                            <th className="pl-0" style={{ width: "30px" }}>
                              <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                <Form.Control
                                  type="checkbox"
                                  defaultValue={1}
                                />
                                <span />
                              </label>
                            </th>
                            <th className="pl-0" style={{ minWidth: "120px" }}>
                              id
                            </th>
                            <th style={{ minWidth: "120px" }}>Name</th>
                            <th style={{ minWidth: "120px" }}>Threshold</th>
                            <th style={{ minWidth: "120px" }}>Exam</th>
                            <th
                              className="text-center"
                              style={{ minWidth: "120px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {MockList.map((arr, key) => (
                            <tr key={key}>
                              <td className="pl-0 py-6">
                                <label className="checkbox checkbox-lg checkbox-inline">
                                  <Form.Control
                                    type="checkbox"
                                    defaultValue={1}
                                  />
                                  <span />
                                </label>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder  font-size-lg">
                                  {arr.id}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder font-size-lg">
                                  {arr.exam.name}-{arr.name}-
                                  {arr.exam.exam_year}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.threshold_buffer}
                                </span>
                              </td>
                              <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {arr.exam.exam_year}{" "}{arr.exam.exam_month}{" "}-{" "}{arr.exam.name}
                                </span>
                              </td>
                              <td className="pl-0 text-center">
                                <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Add Result
                                      </Tooltip>
                                    }
                                    placement="bottom"
                                  >
                                    <a
                                    className="btn btn-icon btn-light btn-hover-success btn-sm mx-3 cursor-pointer"
                                    href={
                                      "/MockUpResult/" +
                                      arr.id +
                                      "/" +
                                      arr.exam.year_grade +
                                      "/" +
                                      arr.exam.subject +
                                      "/" +
                                      arr.classes[0].id
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-success mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                                        <rect fill="#000000" opacity="0.3" x={5} y={20} width={15} height={2} rx={1} />
                                        </g>
                                    </svg></span>

                                  </a>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Download Paper
                                      </Tooltip>
                                    }
                                    placement="bottom"
                                  >
                                    <a
                                    className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3 cursor-pointer"
                                    href={arr.exam.PaperFile}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-warning mb-2">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\Download.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) " x={11} y={1} width={2} height={14} rx={1} />
                                        <path d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) " />
                                        </g>
                                    </svg>{/*end::Svg Icon*/}</span>

                                  </a>
                                  </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                );
              } else if (MockLoading) {
                return <Waiting message="Loading Data" />;
              } else if (MockLoading === false && MockList.length === 0) {
                return NoData;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  permissions: state.auth.permissions,
  groups: state.auth.groups,
  authLoading: state.auth.isLoading,
  subjectsList: state.subjectsList.subjectsList,
  subjectsListLoading: state.subjectsList.isLoading,
  LevelList: state.LevelList.LevelList,
  LevelListLoading: state.LevelList.isLoading,
  MockList: state.MockList.MockList,
  MockLoading: state.MockList.isLoading,
  classFilterList: state.classFilterList.classFilterList,
  classListLoading: state.classFilterList.isLoading,
  ExamList: state.ExamList.ExamList,
  ExamListLoading: state.ExamList.isLoading,
  PaperExam: state.PaperExam.PaperExam,
  PaperExamLoading: state.PaperExam.isLoading,
  GradeYearList: state.GradeYearList.GradeYearList,
  GradeYearListLoading: state.GradeYearList.isLoading,
  class_by_School: state.class_by_School.class_by_School,
});
export default connect(mapStateToProps, {
  addMock,
  getLevel,
  getSubject,
  getExamsById,
  getPaperExam,
  addRatingRAG,
  getMockFilter,
  getGradeYear,
  getClassSchool,
})(ExamDataMock);