import {
  ADD_DOWNLOADS_ZIP,
  DELETE_DOWNLOADS_ZIP,
  UPDATE_DOWNLOADS_ZIP,
  DOWNLOADS_ZIP_LOADING,
  DOWNLOADS_ZIP_FAIL,
  GET_DOWNLOADS_ZIP,
} from "../actions/types";

const initialState = {
  DownloadZip: [],
  failed: false,
  isLoading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case DOWNLOADS_ZIP_LOADING:
      return {
        isLoading: true,
        failed: false,
      };
    case DOWNLOADS_ZIP_FAIL:
      return {
        isLoading: false,
        failed: true,
      };
    case GET_DOWNLOADS_ZIP:
      return {
        ...state,
        DownloadZip: action.payload,
        isLoading: false,
        failed: false,
      };
    case DELETE_DOWNLOADS_ZIP:
      return {
        ...state,
        isLoading: false,
        failed: false,
        DownloadZip: state.DownloadZip.filter(
          (about) => about.id !== action.payload
        ),
      };
    case ADD_DOWNLOADS_ZIP:
      return {
        ...state,
        isLoading: false,
        failed: false,
        DownloadZip: [...state.DownloadZip, action.payload],
      };
    case UPDATE_DOWNLOADS_ZIP:
      return {
        ...state,
        isLoading: false,
        failed: false,
        DownloadZip: state.DownloadZip.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    default:
      return state;
  }
}
