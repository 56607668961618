import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_LOADING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  IsRegistered:false,
  IsFailed:false,
  Register:null
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_SUCCESS:

      return {
        ...state,
        isLoading: false,
        IsRegistered:true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        IsFailed:true,
        isLoading: false,
      };
    default:
      return state;
  }
}
