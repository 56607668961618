import axios from "axios";
import { returnErrors } from "../message/message";
import {
  CHECKED_EXAM_EXIST,
  GET_QUESTION,
  SET_QUESTION_FAIL,
  SET_QUESTION_LOADING,
} from "../types";

export const getQuestion = (data) => (dispatch) => {
  dispatch({ type: SET_QUESTION_LOADING });
  axios
    .get(`/QLA/Question/finder/${data.data}/`)
    .then((res) => {
      dispatch({ type: CHECKED_EXAM_EXIST });
      dispatch({
        type: GET_QUESTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_QUESTION_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
