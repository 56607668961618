import React, { Component } from "react";
import Backgroundimg from "../../assets/media/bg/bg-8.jpg";

export default class LicensePage extends Component {
  render() {
    return (
      <>
        <div
          className="content pt-0 d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          {/*begin::Entry*/}
          {/*begin::Hero*/}
          <div
            className="d-flex flex-row-fluid bgi-size-cover bgi-position-top"
            style={{ backgroundImage: `url(${Backgroundimg})` }}
          >
            <div className="container">
              <div className="d-flex justify-content-between align-items-center pt-25 pb-35">
                <h2 className="font-weight-bolder text-light mb-0">
                  License Information
                </h2>
                <div className="d-flex">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" className="h5 text-light font-weight-bold">
                    Help Center
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*end::Hero*/}
          {/*begin::Section*/}
          <div className="container mt-n15 gutter-b">
            <div className="card card-custom">
              <div className="card-body py-12">
                <div className="row">
                  <div className="col-lg-3">
                    {/*begin::Navigation*/}
                    <ul
                      className="navi navi-link-rounded navi-accent navi-success navi-hover mb-8 mb-md-0"
                      role="tablist"
                    >
                      {/*begin::Nav Item*/}
                      <li className="navi-item mb-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="navi-link" data-toggle="tab">
                          <span className="navi-text font-size-h5 font-weight-bold mb-0">
                            Standard
                          </span>
                        </a>
                      </li>
                      {/*end::Nav Item*/}
                      {/*begin::Nav Item*/}
                      <li className="navi-item mb-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="navi-link active" data-toggle="tab">
                          <span className="navi-text font-size-h5 font-weight-bold mb-0">
                            Multiple
                          </span>
                        </a>
                      </li>
                      {/*end::Nav Item*/}
                      {/*begin::Nav Item*/}
                      <li className="navi-item mb-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="navi-link" data-toggle="tab">
                          <span className="navi-text font-size-h5 font-weight-bold mb-0">
                            Extended
                          </span>
                        </a>
                      </li>
                      {/*end::Nav Item*/}
                      {/*begin::Nav Item*/}
                      <li className="navi-item mb-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="navi-link" data-toggle="tab">
                          <span className="navi-text font-size-h5 font-weight-bold mb-0">
                            Custom Licenses
                          </span>
                        </a>
                      </li>
                      {/*end::Nav Item*/}
                    </ul>
                    {/*end::Navigation*/}
                  </div>
                  <div className="col-lg-7">
                    <h3 className="font-weight-bold mb-10 text-dark">
                      The point here is that anyone can ramble?
                    </h3>
                    <div className="font-weight-nromal font-size-lg mb-6">
                      <p>
                        The point here is that anyone can ramble on and on or
                        even write brief statement about something that really
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">Download Sample</a>. If you want people to
                        continue to follow you and your blog Your blog post
                        fonts are automatically styled according to your site's
                        theme. However, you can change the text font and format
                        by selecting the text and clicking the options.
                      </p>
                    </div>
                    <div className="mb-5">
                      {/*begin::Table*/}
                      <div className="table-responsive">
                        <table className="table table-light table-light-success">
                          <thead>
                            <tr>
                              <th />
                              <th className="table-center">Standard</th>
                              <th className="table-center">Multiple</th>
                              <th className="table-center">Extended</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-bold table-row-title">
                                Installations
                              </td>
                              <td className="table-center">1</td>
                              <td className="table-center">10</td>
                              <td className="table-center">Unlimited</td>
                            </tr>
                            <tr className="bg-gray-100">
                              <td className="font-weight-bold table-row-title">
                                End Product Usage
                              </td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-danger">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Close.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <g
                                        transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                        fill="#000000"
                                      >
                                        <rect
                                          x={0}
                                          y={7}
                                          width={16}
                                          height={2}
                                          rx={1}
                                        />
                                        <rect
                                          opacity="0.3"
                                          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
                                          x={0}
                                          y={7}
                                          width={16}
                                          height={2}
                                          rx={1}
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-success">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Double-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <polygon points="0 0 24 0 24 24 0 24" />
                                      <path
                                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)"
                                      />
                                      <path
                                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002)"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-success">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Double-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <polygon points="0 0 24 0 24 24 0 24" />
                                      <path
                                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)"
                                      />
                                      <path
                                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002)"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold table-row-title">
                                Unlimited Users
                              </td>
                              <td className="table-center">Fixed</td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-success">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Double-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <polygon points="0 0 24 0 24 24 0 24" />
                                      <path
                                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)"
                                      />
                                      <path
                                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002)"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-success">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Double-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <polygon points="0 0 24 0 24 24 0 24" />
                                      <path
                                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)"
                                      />
                                      <path
                                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002)"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                            </tr>
                            <tr className="bg-gray-100">
                              <td className="font-weight-bold table-row-title">
                                Unlimited Domains
                              </td>
                              <td className="table-center">Fixed</td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-danger">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Close.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <g
                                        transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                        fill="#000000"
                                      >
                                        <rect
                                          x={0}
                                          y={7}
                                          width={16}
                                          height={2}
                                          rx={1}
                                        />
                                        <rect
                                          opacity="0.3"
                                          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
                                          x={0}
                                          y={7}
                                          width={16}
                                          height={2}
                                          rx={1}
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                              <td className="table-center">
                                <span className="svg-icon svg-icon-success">
                                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Double-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <polygon points="0 0 24 0 24 24 0 24" />
                                      <path
                                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)"
                                      />
                                      <path
                                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002)"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/*end::Table*/}
                    </div>
                    <div className="font-weight-nromal font-size-lg mb-6">
                      <p>
                        If you have prepared your text in a Word document or
                        another external source, you can paste it into your Wix
                        Blog post in plain text format. In order to do so, when
                        pasting, use
                        <span className="font-weight-bold">
                          Ctrl + Shift + V
                        </span>
                        (Cmd + Shift + V on a Mac). This will remove all
                        formatting and prevent any formatting issues.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end::Section*/}
          {/*begin::Section*/}
          <div className="container gutter-b">
            <div className="row">
              <div className="col-lg-4">
                {/*begin::Callout*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="card card-custom wave wave-animate-slow wave-success mb-8 mb-lg-0"
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center p-6">
                      {/*begin::Icon*/}
                      <div className="mr-6">
                        <span className="svg-icon svg-icon-4x">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Icon*/}
                      {/*begin::Content*/}
                      <div className="d-flex flex-column">
                        <h3 className="text-dark h3 mb-3">Get Started</h3>
                        <div className="text-dark-50">Base FAQ Questions</div>
                      </div>
                      {/*end::Content*/}
                    </div>
                  </div>
                </a>
                {/*end::Callout*/}
              </div>
              <div className="col-lg-4">
                {/*begin::Callout*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="card card-custom wave wave-animate wave-success mb-8 mb-lg-0"
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center p-6">
                      {/*begin::Icon*/}
                      <div className="mr-6">
                        <span className="svg-icon svg-icon-4x">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Clipboard-check.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                fill="#000000"
                              />
                              <path
                                d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Icon*/}
                      {/*begin::Content*/}
                      <div className="d-flex flex-column">
                        <h3 className="text-dark h3 mb-3">Tutorials</h3>
                        <div className="text-dark-50">Books &amp; Articles</div>
                      </div>
                      {/*end::Content*/}
                    </div>
                  </div>
                </a>
                {/*end::Callout*/}
              </div>
              <div className="col-lg-4">
                {/*begin::Callout*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="card card-custom wave wave-animate-fast wave-success mb-8 mb-lg-0"
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center p-6">
                      {/*begin::Icon*/}
                      <div className="mr-6">
                        <span className="svg-icon svg-icon-4x">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Icon*/}
                      {/*begin::Content*/}
                      <div className="d-flex flex-column">
                        <h3 className="text-dark h3 mb-3">User Guide</h3>
                        <div className="text-dark-50">
                          Complete Knowledgebase
                        </div>
                      </div>
                      {/*end::Content*/}
                    </div>
                  </div>
                </a>
                {/*end::Callout*/}
              </div>
            </div>
          </div>
          {/*end::Section*/}
          {/*end::Entry*/}
        </div>
      </>
    );
  }
}
