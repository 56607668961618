import {
  SET_SCHOOL_ID_LOADING,
  SET_SCHOOL_ID_FAIL,
  GET_SCHOOL_ID,
} from "../actions/types";

const initialState = {
  isLoading: false,
  SchoolById: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOL_ID:
      return {
        ...state,
        SchoolById: action.payload,
        isLoading: false,
      };

    case SET_SCHOOL_ID_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SCHOOL_ID_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
