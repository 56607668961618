import {
  ADD_TEACHER,
  DELETE_TEACHER,
  UPDATE_TEACHER,
  GET_TEACHER,
  LOADING_TEACHER,
  GET_NEW_TEACHER
} from "../actions/types";

const initialState = {
  isLoading: false,
  Teacher_List: [],
  Teacher: [],
  isLoadingTeacher: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TEACHER:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_TEACHER:
      return {
        ...state,
        Teacher_List: state.Teacher_List.map((arr) => {
          if (arr.id === action.payload.id) {
            return action.payload;
          }
          return arr;
        }),
      };
    case GET_NEW_TEACHER:
      return {
        ...state,
        Teacher: action.payload,
        isLoadingTeacher: false,
      };
    case GET_TEACHER:
      return {
        ...state,
        Teacher_List: action.payload,
        isLoading: false,
      };
    case ADD_TEACHER:
      return {
        ...state,
        Teacher_List: [...state.Teacher_List, action.payload],
      };
    case DELETE_TEACHER:
      return {
        ...state,
        Teacher_List: state.Teacher_List.filter(
          (about) => about.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
