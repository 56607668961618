import {
    USER_PERMISSION_LOADING_FAIL,
    USER_PERMISSION_LOADING,
    GET_USER_PERMISSION,
} from "../actions/types";

const initialState = {
  UserPermissions: [],
  isLoading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_PERMISSION_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_PERMISSION:
      return {
        ...state,
        UserPermissions: action.payload,
        isLoading: false,
      };
    case USER_PERMISSION_LOADING_FAIL:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
