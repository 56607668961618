import axios from "axios";
import { returnErrors } from "../message/message";
import {
  GET_QUESTION_PAPER,
  SET_QUESTION_PAPER_FAIL,
  SET_QUESTION_PAPER_LOADING,
  GET_PAPER_EXAM,
  PAPER_EXAM_LOADING,
  PAPER_EXAM_FAIL,
} from "../types";

export const getQuestionPaper = () => (dispatch) => {
  dispatch({ type: SET_QUESTION_PAPER_LOADING });
  axios
    .get("QLA/Paper/")
    .then((res) =>
      dispatch({
        type: GET_QUESTION_PAPER,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: SET_QUESTION_PAPER_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getQuestionPaperFilter = (subject, Mockid) => (dispatch) => {
  dispatch({ type: SET_QUESTION_PAPER_LOADING });
  axios.get(`QLA/Paper/${subject}/Subject/${Mockid}/`)
    .then((res) => {
      dispatch({
        type: GET_QUESTION_PAPER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_QUESTION_PAPER_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getPaperExam = (ExamID) => (dispatch) => {
  dispatch({ type: PAPER_EXAM_LOADING });
  axios
    .get(`QLA/PaperExam/${ExamID}`)
    .then((res) =>
      dispatch({
        type: GET_PAPER_EXAM,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: PAPER_EXAM_FAIL });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
