import React from 'react'
import './StyleClass.css'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import CheckoutFormViaEmail from './components/checkoutFormPayViaEmail'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const BaseViaEmail = (props) => (
  <Elements  stripe={stripePromise}>
    <CheckoutFormViaEmail data={props} />
  </Elements>
)

export default BaseViaEmail
