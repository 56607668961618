import {
  GENERATE_TOPIC_PAPER_ADD,
  GENERATE_TOPIC_PAPER_FAIL,
  GENERATE_TOPIC_PAPER_LOADING
} from "../actions/types";

const initialState = {
  isLoading: false,
  is_Generated: false,
  GenerateTopicPaperArray: [],
  GenerateTopicPaper: false
};
// eslint-disable-next-line
export default function(state = initialState, action) {
  switch (action.type) {
    case GENERATE_TOPIC_PAPER_ADD:
      return {
        ...state,
        GenerateTopicPaperArray: action.payload,
        GenerateTopicPaper: false,
        isLoading: false,
        is_Generated: true
      };

    case GENERATE_TOPIC_PAPER_LOADING:
      return {
        ...state,
        isLoading: true,
        GenerateTopicPaper: true,
        is_Generated: false

      };
    case GENERATE_TOPIC_PAPER_FAIL:
      return {
        ...state,
        GenerateTopicPaper: false,
        isLoading: false,
        is_Generated: false
      };
    default:
      return state;
  }
}
